import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IFranchisee } from './types'

export const fetch = async (
    search: string
): Promise<AxiosResponse<IFranchisee[]>> => {
    return await get<IFranchisee[]>('/admin/franchisees', {
        count: 10,
        'filter[name]': search
    })
}
