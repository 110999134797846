import type { CSSProperties, ReactElement } from 'react'
import { Select } from 'antd'
import { useDebounce } from '../../hooks/useDebounce'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import LoadingSelector from '../../components/LoadingSelector'

interface Props {
    initialValue?: string
    onChange: (value: string) => void
    style?: CSSProperties
    disabled?: boolean
}

interface IBusinessUser {
    email: string
    phone: string
    businessAccountId: string
}

export default function BusinessUserSelector({
    initialValue,
    onChange,
    style,
    disabled
}: Props): ReactElement {
    const [hasInitialize, setHasInitialize] = useState(false)
    const [preData, setPreData] = useState<{ label: string; value: string }[]>(
        []
    )
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce<string>(search, 600)

    const dispatch = useDispatch()

    const { data, isFetching } = useAppSelector(
        state => state.businessUserSelector
    )

    useEffect(() => {
        if (!hasInitialize && initialValue) {
            if (initialValue.includes('{d}')) {
                const [label, value] = initialValue.split('{d}')
                setPreData([{ label, value: `${label}{d}${value}` }])
            }
        }
    }, [initialValue])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.fetch({ search: debounceSearch }))
            setHasInitialize(true)
        } else if (data.length > 0) {
            dispatch(actions.destroy())
        }
    }, [debounceSearch])

    return (
        <Select
            showSearch
            allowClear
            value={!hasInitialize ? preData[0]?.label : undefined}
            style={style}
            disabled={disabled}
            options={
                !hasInitialize
                    ? preData
                    : data.map((item: IBusinessUser) => ({
                          label: `${item.email} - ${item.phone}`,
                          value: item.businessAccountId
                      }))
            }
            filterOption={false}
            notFoundContent={isFetching ? <LoadingSelector /> : null}
            loading={isFetching}
            placeholder={'Бизнес аккаунт'}
            searchValue={search}
            onSearch={setSearch}
            onClear={() => setHasInitialize(true)}
            onChange={(value, options) => {
                if (options && !Array.isArray(options)) {
                    onChange(`${options.label}{d}${options.value}`)
                } else {
                    onChange(value)
                }
            }}
        />
    )
}
