import { Button, Result } from 'antd'
import type { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface Props {
    title?: string
    description?: string
    hideButton?: boolean
}

export default function Error403({
    title,
    description,
    hideButton
}: Props): ReactElement {
    const navigate = useNavigate()

    return (
        <Result
            status={'403'}
            title={title ?? '403'}
            subTitle={description ?? 'Произошла ошибка'}
            extra={
                hideButton ? null : (
                    <Button
                        onClick={() => navigate(-1)}
                        type={'primary'}
                        icon={<ArrowLeftOutlined />}
                    >
                        {'Вернуться назад'}
                    </Button>
                )
            }
        />
    )
}
