import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, getContext, put, takeEvery } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    CreateNormalBannerPayload,
    CreatePayload,
    EditPayload,
    FetchCoveragePayload,
    FetchPayload
} from './types.ts'
import * as effects from 'redux-saga/effects'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import type { createBrowserRouter } from 'react-router-dom'

export function* handleCreate(
    action: PayloadAction<CreatePayload>
): SagaIterator {
    try {
        yield effects.call(api.create, action.payload.form)
        if (!action.payload.isCreateInfoModal) {
            const router: ReturnType<typeof createBrowserRouter> =
                yield getContext('router')
            void router.navigate('/advertising')
        }
        yield put(
            actions.createSuccess({
                isCreateInfoModal: action.payload.isCreateInfoModal
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleEdit(action: PayloadAction<EditPayload>): SagaIterator {
    try {
        const { advertisingGroupId, form, isChangedOnlyPaymentStrategy } =
            action.payload

        const formatForm = {
            ...form,
            banners: form.banners.map(banner => {
                if (banner.custom_banner) {
                    return {
                        ...banner,
                        custom_banner: {
                            ...banner.custom_banner,
                            thumbnails: Array.isArray(banner.thumbnails)
                                ? banner.thumbnails.map(thumb => ({
                                      ...thumb,
                                      image: undefined
                                  }))
                                : undefined
                        },
                        thumbnails: undefined
                    }
                }
                return banner
            })
        }

        if (isChangedOnlyPaymentStrategy) {
            yield effects.call(
                api.editPaymentStrategy,
                advertisingGroupId,
                formatForm.payment_strategy
            )
        } else {
            yield effects.call(api.edit, advertisingGroupId, formatForm)
        }
        yield put(actions.editSuccess())
        const notification = yield getContext('notification')
        notification.success({
            message: 'Изменение рекламной кампании',
            description: 'Кампания была успешно сохранена',
            placemen: 'topRight'
        })
        const router: ReturnType<typeof createBrowserRouter> =
            yield getContext('router')
        void router.navigate('/advertising')
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(
            api.fetch,
            action.payload.advertisingGroupId
        )
        yield put(actions.fetchSuccess({ data }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleFetchCategories(): SagaIterator {
    try {
        const [{ data: online }, { data: offline }] = yield all([
            effects.call(api.fetchCategories, true),
            effects.call(api.fetchCategories)
        ])
        yield put(
            actions.fetchCategoriesSuccess({
                data: [
                    {
                        name: 'Онлайн',
                        full_name: 'Онлайн',
                        children: online,
                        companyCategoryId: '',
                        icoUrl: null
                    },
                    {
                        name: 'Оффлайн',
                        full_name: 'Оффлайн',
                        children: offline,
                        companyCategoryId: '',
                        icoUrl: null
                    }
                ]
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.fetchCategoriesFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCategories(): SagaIterator {
    yield takeEvery(actions.fetchCategories, handleFetchCategories)
}

export function* handleCreateNormalBanner(
    action: PayloadAction<CreateNormalBannerPayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(
            api.uploadImageBanner,
            action.payload.image
        )
        yield put(
            actions.createNormalBannerSuccess({
                data,
                isCustomBanner: action.payload.isCustomBanner
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.createNormalBannerFailure({
                error: message
            })
        )
    }
}

export function* watchCreateNormalBanner(): SagaIterator {
    yield takeEvery(actions.createNormalBanner, handleCreateNormalBanner)
}

export function* handleFetchCoverage(
    action: PayloadAction<FetchCoveragePayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(
            api.fetchCoverage,
            action.payload.form
        )
        yield put(
            actions.fetchCoverageSuccess({ coverage: data.data.coverage })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.fetchCoverageFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCoverage(): SagaIterator {
    yield takeEvery(actions.fetchCoverage, handleFetchCoverage)
}

export default function* watchAdvertisingWizard(): SagaIterator {
    yield all([
        fork(watchCreate),
        fork(watchFetch),
        fork(watchEdit),
        fork(watchFetchCategories),
        fork(watchCreateNormalBanner),
        fork(watchFetchCoverage)
    ])
}
