import type { ReactElement } from 'react'
import { Spin } from 'antd'

// Styles
import styles from './styles.module.scss'

export default function LoadingSelector(): ReactElement {
    return (
        <div className={styles.loading}>
            <Spin size={'small'} />
        </div>
    )
}
