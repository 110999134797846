import type { FetchPagePayload } from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import * as effects from 'redux-saga/effects'
import type { IPage } from './types'

export function* handleFetchPage(action: {
    payload: FetchPagePayload
}): SagaIterator {
    try {
        const { data }: { data: IPage } = yield effects.call(
            api.fetchPage,
            action.payload.code
        )
        yield put(actions.fetchPageSuccess({ data, code: action.payload.code }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        if (code !== 404) {
            yield call(sagaNotificationError, message, code)
        }
        yield put(
            actions.fetchPageFailure({
                code: action.payload.code,
                error: message
            })
        )
    }
}

export function* watchFetchPage(): SagaIterator {
    yield takeEvery(actions.fetchPage, handleFetchPage)
}

export default function* watchContentPage(): SagaIterator {
    yield all([fork(watchFetchPage)])
}
