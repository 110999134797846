import type { ReactElement } from 'react'
import Authorization from '../containers/Authorization'
import { useAppSelector } from '../hooks/useAppSelector'
import { authorizationSelector } from '../containers/Authorization/selectors'
import Dashboard from '../containers/Dashboard'

export default function GeneralPage(): ReactElement {
    const { token } = useAppSelector(authorizationSelector)

    if (token) {
        return <Dashboard />
    }

    return <Authorization />
}
