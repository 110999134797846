import { useEffect, type ReactElement } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions as applicationActions } from '../../containers/Application/slice'

export default function Initialization(): ReactElement {
    const dispatch = useDispatch()
    const { token } = useAppSelector(state => state.authorization)

    useEffect(() => {
        if (token) {
            dispatch(applicationActions.initialize())
        }
    }, [token])

    return <></>
}
