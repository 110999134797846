import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Divider, Pagination, Row, Table, Typography } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { ignoreKeys } from '../../utils/helpers.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { balanceSelector } from './selectors.ts'
import type { ColumnsType } from 'antd/es/table'
import type { IBalanceHistoryItem } from './types.ts'
import dayjs from 'dayjs'

const { Title } = Typography

const mapPaymentTypes = {
    target_clicks: 'Оплата за клики',
    target_views: 'Оплата за просмотры',
    balance_top_up: 'Пополение баланса',
    promocode_reward: 'Награда за использование промокода',
    plan_cashback: 'Кешбек за покупку премиума'
}

const columns: ColumnsType<IBalanceHistoryItem> = [
    {
        title: 'Операция',
        dataIndex: '',
        key: 'operation',
        render: (data: IBalanceHistoryItem) => (
            <Title level={5} style={{ margin: 0 }}>
                {data.description
                    ? data.description
                    : mapPaymentTypes[data.type] ?? data.type}
            </Title>
        )
    },
    {
        title: 'Сумма',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        render: value => (
            <span style={{ color: value < 0 ? 'red' : 'green' }}>
                {(value / 100).toLocaleString('ru')}
                {' ₽'}
            </span>
        )
    },
    {
        title: 'Дата и время платежа',
        dataIndex: 'datetime',
        key: 'datetime',
        align: 'center',
        render: value => <b>{dayjs(value).format('DD.MM.YYYY HH:mm:ss')}</b>
    }
]

export default function Balance(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams()
    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const dispatch = useDispatch()

    const { data, meta, isFetching } = useAppSelector(balanceSelector)

    useEffect(() => {
        dispatch(actions.fetch({ page, pageSize }))
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    return (
        <>
            <Row style={{ marginBottom: 20 }} justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'История операций'}
                </Title>
            </Row>
            <Divider style={{ background: '#ff57a5' }} />
            <Table
                dataSource={data}
                columns={columns}
                rowKey={'balance_transaction_id'}
                loading={isFetching}
                pagination={false}
                sticky={{ offsetHeader: 0 }}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={meta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
        </>
    )
}
