import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    SearchPayload,
    SearchSuccessPayload,
    SearchFailurePayload,
    AreaItemType
} from './types'

const initialState: InitialStateType = {
    areas: [],
    areasSearch: [],
    areasIsFetching: [],
    searchIsFetching: false,
    error: null
}

export const areaSelectorSlice = createSlice({
    name: 'areaSelectorSlice',
    initialState,
    reducers: {
        fetch(
            state: InitialStateType,
            action: PayloadAction<FetchPayload>
        ): void {
            state.areasIsFetching = [
                ...state.areasIsFetching,
                action.payload.id
            ]
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ): void {
            const parentId = Array.isArray(action.payload.data)
                ? action.payload.id
                : false

            state.areasIsFetching = state.areasIsFetching.filter(
                (area: string) => area !== action.payload.id
            )

            const sort = (a: AreaItemType, b: AreaItemType): number => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1
                }
                return 0
            }

            state.areas =
                action.payload.id !== 'country'
                    ? state.areas.map((area: AreaItemType) => {
                          const deepSearch = (obj: AreaItemType): void => {
                              if (parentId && obj.area_id === parentId) {
                                  Object.assign(obj, {
                                      children: action.payload.data.sort(sort)
                                  })
                              } else if (Array.isArray(obj?.children)) {
                                  obj.children.forEach(deepSearch)
                              }
                          }

                          deepSearch(area)

                          return area
                      })
                    : action.payload.data.sort(sort)
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ): void {
            state.areasIsFetching = state.areasIsFetching.filter(
                (area: string) => area !== action.payload.id
            )
            state.error = action.payload.error
        },
        search(
            state: InitialStateType,
            _action: PayloadAction<SearchPayload>
        ): void {
            state.searchIsFetching = true
        },
        searchSuccess(
            state: InitialStateType,
            action: PayloadAction<SearchSuccessPayload>
        ): void {
            state.areasSearch = action.payload
            state.searchIsFetching = false
        },
        searchFailure(
            state: InitialStateType,
            action: PayloadAction<SearchFailurePayload>
        ): void {
            state.searchIsFetching = false
            state.error = action.payload.error
        },
        searchDestroy(
            state: InitialStateType,
            _action: PayloadAction<undefined>
        ): void {
            state.areasSearch = initialState.areasSearch
            state.searchIsFetching = initialState.searchIsFetching
        },
        destroy(): InitialStateType {
            return initialState
        }
    }
})

export const actions = areaSelectorSlice.actions

export default areaSelectorSlice.reducer
