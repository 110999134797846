import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type {
    CompanyPropertiesFailurePayload,
    CompanyPropertiesPayload,
    CompanyPropertiesSuccessPayload,
    InitialStateType,
    FetchPlansFailurePayload,
    FetchPlansPayload,
    FetchPlansSuccessPayload
} from './types'

const initialState: InitialStateType = {
    companyProperties: [],
    companyPropertiesIsFetching: false,
    plans: [],
    plansIsFetching: false,
    currentPathName: '/',
    error: null
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        initialize(
            _state: InitialStateType,
            _action: PayloadAction<undefined>
        ) {},
        fetchCompanyProperties(
            state: InitialStateType,
            _action: PayloadAction<CompanyPropertiesPayload>
        ) {
            state.companyPropertiesIsFetching = true
        },
        fetchCompanyPropertiesSuccess(
            state: InitialStateType,
            action: PayloadAction<CompanyPropertiesSuccessPayload>
        ) {
            state.companyProperties = action.payload.data
            state.companyPropertiesIsFetching = false
        },
        fetchCompanyPropertiesFailure(
            state: InitialStateType,
            action: PayloadAction<CompanyPropertiesFailurePayload>
        ) {
            state.companyPropertiesIsFetching = false
            state.error = action.payload.error
        },
        fetchPlans(
            state: InitialStateType,
            _action: PayloadAction<FetchPlansPayload>
        ) {
            state.plansIsFetching = true
        },
        fetchPlansSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPlansSuccessPayload>
        ) {
            state.plans = action.payload.data
            state.plansIsFetching = false
        },
        fetchPlansFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPlansFailurePayload>
        ) {
            state.plansIsFetching = false
            state.error = action.payload.error
        },
        changePathName(state: InitialStateType, action: PayloadAction<string>) {
            state.currentPathName = action.payload
        }
    }
})

export const actions = applicationSlice.actions

export default applicationSlice.reducer
