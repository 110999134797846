import type { AxiosResponse } from 'axios'
import { get, post, patch, del } from '../../utils/api'
import type { IPromoCode, FormItemsType } from './types'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse<IPromoCode[]>> =>
    get<IPromoCode[]>('/admin/promocodes', {
        page,
        page_size,
        filter,
        include: 'businessAccount,creator'
    })

export const createPromoCode = (
    data: Partial<FormItemsType>
): Promise<AxiosResponse<FormItemsType>> =>
    post<FormItemsType>('/admin/promocodes', data)

export const checkPromoCode = (code: string): Promise<AxiosResponse<boolean>> =>
    get<boolean>('/admin/promocodes/exist', { code })

export const editPromoCode = (
    promoCodeId: string,
    data: Partial<FormItemsType>
): Promise<AxiosResponse> =>
    patch<FormItemsType>(`/admin/promocodes/${promoCodeId}`, data)

export const checkPromoCodeDeletable = (
    promoCodeId: string
): Promise<AxiosResponse<boolean>> =>
    get<boolean>(`/admin/promocodes/${promoCodeId}/deletable`)

export const removePromoCode = (promoCodeId: string): Promise<AxiosResponse> =>
    del<void>(`/admin/promocodes/${promoCodeId}`)
