import type { ReactElement } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { Select, Spin } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { citySelector } from './selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'

interface Props {
    disabled?: boolean
    onChange: (value: ICityValue) => void
}

export interface ICityValue {
    caption: string
    label: string
    location: string
    value: string
}

export default function CitySelector({
    disabled,
    onChange
}: Props): ReactElement {
    const [key, setKey] = useState(Date.now())
    const { data, isFetching } = useAppSelector(citySelector)

    const dispatch = useDispatch()

    const refSelect = useRef(null)

    useEffect(() => {
        if (!disabled && refSelect.current) {
            dispatch(actions.destroy())
            setKey(Date.now())
        }
    }, [disabled])

    const handleSearchCity = (query: string) => {
        dispatch(actions.fetch({ query }))
    }

    return (
        <Select
            ref={refSelect}
            key={key.toString()}
            labelInValue
            showSearch
            disabled={disabled}
            loading={isFetching}
            placeholder={'Начните вводить город'}
            onSearch={handleSearchCity}
            notFoundContent={isFetching ? <Spin size={'small'} /> : null}
            onChange={(_, option) => onChange(option as ICityValue)}
            className={'authorization-city-selector'}
            suffixIcon={null}
            options={[...data]
                .filter(city => !!city.data.city_with_type)
                .map(city => ({
                    label: city.data.city_with_type,
                    value: city.data.city_with_type,
                    caption: city.data.city,
                    location: `${city.data.geo_lat}-${city.data.geo_lon}`
                }))}
        />
    )
}
