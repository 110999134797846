import type { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

// Styles
import styles from './styles.module.scss'

interface Props {
    url?: string | null
    image?: string
    onLoad?: () => void
    imageClassName?: string
}

export default function Banner({
    url,
    image,
    onLoad,
    imageClassName
}: Props): ReactElement {
    const { origin } = document.location

    if (!image) {
        return <></>
    }

    const Image = () => (
        <LazyLoadImage
            width={500}
            style={{ maxWidth: '100%' }}
            className={imageClassName}
            src={image}
            onLoad={onLoad}
        />
    )

    if (image && url) {
        if (url.startsWith(origin)) {
            return (
                <Link className={styles.link} to={url}>
                    <Image />
                </Link>
            )
        }
        return (
            <a
                href={url}
                className={styles.link}
                target={'_blank'}
                rel={'noreferrer'}
            >
                <Image />
            </a>
        )
    }

    return <Image />
}
