import type { ReactNode } from 'react'
import { memo } from 'react'
import { useMemo } from 'react'
import { Fragment } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { Button, Col, Result, Row, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { dashboardSelector } from './selectors'
import { LoadingStep } from '../../components/LoadingStep'
import type { IBlock } from './types'
import Banner from '../../components/Banner'
import {
    adminFranchiseeBlocks,
    adminFranchiseeHorizontalBlocks,
    getRole,
    gridBanners,
    horizontalBlocks,
    sortBanners,
    statsSlows,
    userBlocks,
    verticalBlocks
} from '../../utils/helpers'

// Styles
import styles from './styles.module.scss'
import { authorizationSelector } from '../Authorization/selectors'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { LoadingOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

const indicators = [
    {
        key: 'companies_cached_displayed_count',
        title: 'Опубликовано компаний'
    },
    {
        key: 'events_cached_displayed_count',
        title: 'Опубликовано предложений'
    },
    {
        key: 'companies_cached_undisplayed_count',
        title: 'Компании в архиве'
    },
    {
        key: 'events_cached_undisplayed_count',
        title: 'Предложения в архиве'
    }
]

const HorizontalBannersMemo = memo(
    ({
        horizontalBanners,
        isMounted
    }: {
        horizontalBanners: IBlock[]
        isMounted: boolean
    }) => (
        <div className={styles.banners}>
            {horizontalBanners.length > 0 || !isMounted ? (
                horizontalBanners.map((block, index) => (
                    <div
                        key={block.block_id}
                        style={index === 0 ? { marginTop: -23 } : {}}
                    >
                        <Title style={{ margin: '23px 0 9px' }} level={5}>
                            {block.caption}
                        </Title>
                        {gridBanners.includes(block.name) ? (
                            <Row wrap={true} gutter={[8, 8]}>
                                {sortBanners(block.banners).map(banner => (
                                    <Col span={6} key={banner.banner_id}>
                                        <Banner
                                            url={
                                                banner.target_action === 'page'
                                                    ? `${document.location.href}content/${banner.page_name}`
                                                    : banner.target_url
                                            }
                                            image={banner.image.url}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <Swiper
                                modules={[Pagination, Navigation]}
                                pagination={{
                                    enabled: true,
                                    clickable: true,
                                    dynamicBullets: true
                                }}
                                navigation={true}
                                spaceBetween={8}
                                slidesPerView={4}
                            >
                                {sortBanners(block.banners).map(banner => (
                                    <SwiperSlide key={banner.banner_id}>
                                        <Banner
                                            url={
                                                banner.target_action === 'page'
                                                    ? `${document.location.href}content/${banner.page_name}`
                                                    : banner.target_url
                                            }
                                            image={banner.image.url}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                ))
            ) : (
                <Result title={'Ой, ничего не нашли :('} />
            )}
        </div>
    )
)

export default function Dashboard(): ReactNode {
    const [isMounted, setIsMounted] = useState(false)
    const [horizontalBanners, setHorizontalBanners] = useState<IBlock[]>([])
    const [verticalBanners, setVerticalBanners] = useState<IBlock[]>([])

    const { profile } = useAppSelector(authorizationSelector)
    const {
        blocks,
        stats,
        blocksIsFetching,
        statsIsFetching,
        statsSlowIsFetching,
        statsSlow,
        lastDateStats
    } = useAppSelector(dashboardSelector)

    const dispatch = useDispatch()

    const role = profile ? getRole(profile.roles) : null

    const memoIsEveryBlocksEmpty = useMemo(
        () =>
            Object.keys(blocks).every(
                name => blocks[name].banners.length === 0
            ),
        [blocks]
    )

    useEffect(() => {
        if (!lastDateStats) {
            dispatch(actions.fetchStats({ isFetchSlowStats: role === 'user' }))
        }
    }, [])

    useEffect(() => {
        if (!memoIsEveryBlocksEmpty) {
            const horizontalBanners: IBlock[] = []
            const verticalBanners: IBlock[] = []

            const role = profile ? getRole(profile.roles) : 'user'

            const isRoleAdminOrFranchisee =
                role === 'admin' || role === 'franchisee'

            const profileBlocks = isRoleAdminOrFranchisee
                ? adminFranchiseeBlocks
                : userBlocks

            for (const name in blocks) {
                if (profileBlocks.includes(name)) {
                    if (
                        blocks[name as keyof typeof blocks].banners.length > 0
                    ) {
                        horizontalBanners.push(
                            blocks[name as keyof typeof blocks]
                        )
                    }
                }
                if (
                    isRoleAdminOrFranchisee &&
                    adminFranchiseeHorizontalBlocks.includes(name)
                ) {
                    if (
                        blocks[name as keyof typeof blocks].banners.length > 0
                    ) {
                        horizontalBanners.push(
                            blocks[name as keyof typeof blocks]
                        )
                    }
                }
                if (horizontalBlocks.includes(name)) {
                    if (
                        blocks[name as keyof typeof blocks].banners.length > 0
                    ) {
                        horizontalBanners.push(
                            blocks[name as keyof typeof blocks]
                        )
                    }
                }
                if (verticalBlocks.includes(name)) {
                    if (
                        blocks[name as keyof typeof blocks].banners.length > 0
                    ) {
                        verticalBanners.push(
                            blocks[name as keyof typeof blocks]
                        )
                    }
                }
            }

            setHorizontalBanners(
                horizontalBanners.filter(block => block.banners.length > 0)
            )
            setVerticalBanners(
                verticalBanners.filter(block => block.banners.length > 0)
            )

            setIsMounted(true)
        }
    }, [memoIsEveryBlocksEmpty])

    if (blocksIsFetching) {
        return <LoadingStep size={'large'} />
    }

    if (memoIsEveryBlocksEmpty) {
        return <Result title={'Ой, ничего не нашли :('} />
    }

    return (
        <Row gutter={16}>
            <Col span={17}>
                <HorizontalBannersMemo
                    horizontalBanners={horizontalBanners}
                    isMounted={isMounted}
                />
            </Col>
            <Col span={7}>
                <div className={styles.outer}>
                    {verticalBanners.length > 0 ||
                    !isMounted ||
                    stats ||
                    statsIsFetching ? (
                        <>
                            {statsIsFetching ? (
                                <LoadingStep size={'large'} />
                            ) : typeof stats === 'object' &&
                              !Array.isArray(stats) &&
                              stats != null ? (
                                <>
                                    <div
                                        style={
                                            verticalBanners.length === 0 &&
                                            !statsSlow
                                                ? { marginBottom: 0 }
                                                : undefined
                                        }
                                        className={styles.indicators}
                                    >
                                        <div
                                            className={styles.indicators__title}
                                        >
                                            {'Онлайн-табло статистики'}
                                        </div>
                                        {indicators.map((indicator, index) => (
                                            <Fragment
                                                key={`indicator-${index}`}
                                            >
                                                <div
                                                    className={
                                                        styles.indicators__divider
                                                    }
                                                />
                                                <div
                                                    className={
                                                        styles.indicators__block
                                                    }
                                                >
                                                    <span>
                                                        {indicator.title}
                                                    </span>
                                                    <div
                                                        className={
                                                            styles[
                                                                'indicators__block-value'
                                                            ]
                                                        }
                                                    >
                                                        {stats[
                                                            indicator.key as keyof typeof stats
                                                        ].toLocaleString('ru')}
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ))}
                                    </div>
                                    {statsSlowIsFetching ? (
                                        <Col
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                margin: '30px 0 10px'
                                            }}
                                        >
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: 24,
                                                            color: '#FFF',
                                                            margin: '0px auto 10px'
                                                        }}
                                                        spin
                                                    />
                                                }
                                            />
                                            <Paragraph
                                                style={{
                                                    color: '#FFF',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {
                                                    'Получение подробной статистики...'
                                                }
                                            </Paragraph>
                                        </Col>
                                    ) : statsSlow && role === 'user' ? (
                                        Object.keys(statsSlow).map(item => {
                                            return (
                                                <div
                                                    key={item}
                                                    className={
                                                        styles.indicators
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.indicators__title
                                                        }
                                                    >
                                                        {item === 'companies'
                                                            ? 'Аналитика по компаниям за 30 дней'
                                                            : 'Аналитика по предложениям за 30 дней'}
                                                    </div>
                                                    {statsSlow[
                                                        item as keyof typeof statsSlow
                                                    ].month
                                                        .filter(
                                                            item =>
                                                                item.name in
                                                                statsSlows
                                                        )
                                                        .map(
                                                            (
                                                                indicator,
                                                                index
                                                            ) => (
                                                                <Fragment
                                                                    key={`indicator-${index}`}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.indicators__divider
                                                                        }
                                                                    />
                                                                    <div
                                                                        className={
                                                                            styles.indicators__block
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                statsSlows[
                                                                                    indicator.name as keyof typeof statsSlows
                                                                                ]
                                                                            }
                                                                        </span>
                                                                        <div
                                                                            className={
                                                                                styles[
                                                                                    'indicators__block-value'
                                                                                ]
                                                                            }
                                                                        >
                                                                            {indicator.count.toLocaleString(
                                                                                'ru'
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        )}
                                                </div>
                                            )
                                        })
                                    ) : null}
                                    {!statsSlowIsFetching && role === 'user' ? (
                                        <Row
                                            align={'middle'}
                                            justify={'center'}
                                            wrap
                                        >
                                            <Col
                                                style={{ display: 'flex' }}
                                                span={24}
                                            >
                                                <Button
                                                    style={{
                                                        margin: '0 auto 15px',
                                                        boxShadow: 'unset'
                                                    }}
                                                    type={'primary'}
                                                    onClick={() => {
                                                        window.scrollTo({
                                                            top: 0,
                                                            behavior: 'auto'
                                                        })
                                                        dispatch(
                                                            actions.fetchStats({
                                                                isFetchSlowStats:
                                                                    role ===
                                                                    'user'
                                                            })
                                                        )
                                                    }}
                                                >
                                                    {'Обновить аналитику'}
                                                </Button>
                                            </Col>
                                            <Col span={24}>
                                                <Paragraph
                                                    style={{
                                                        color: '#FFF',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {
                                                        'Последнее обновление статистики: '
                                                    }
                                                    <br />
                                                    {lastDateStats}
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </>
                            ) : null}
                            {verticalBanners.map(block => (
                                <Row
                                    gutter={[0, 15]}
                                    wrap={true}
                                    key={block.block_id}
                                >
                                    {sortBanners(block.banners).map(banner => (
                                        <Col span={24} key={banner.banner_id}>
                                            <Banner
                                                url={
                                                    banner.target_action ===
                                                    'page'
                                                        ? `${document.location.href}content/${banner.page_name}`
                                                        : banner.target_url
                                                }
                                                image={banner.image.url}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            ))}
                        </>
                    ) : (
                        <Result status={404} />
                    )}
                </div>
            </Col>
        </Row>
    )
}
