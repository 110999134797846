import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    CreateAgentPayload,
    CreateAgentSuccessPayload,
    CreateAgentFailurePayload,
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    EditAgentPayload,
    EditAgentSuccessPayload,
    EditAgentFailurePayload,
    RemoveAgentPayload,
    RemoveAgentSuccessPayload,
    RemoveAgentFailurePayload,
    InitialStateType
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    filter: {},
    isFetching: false,
    isWizardModal: false,
    isProcessCreate: false,
    isProcessEdit: false,
    isProcessRemove: false,
    error: null
}

const promoCodesAgentsSlice = createSlice({
    name: 'promoCodesAgents',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.filter = action.payload.filter
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        create(
            state: InitialStateType,
            _action: PayloadAction<CreateAgentPayload>
        ): void {
            state.isProcessCreate = true
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateAgentSuccessPayload>
        ): void {
            state.isWizardModal = false
            state.isProcessCreate = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateAgentFailurePayload>
        ): void {
            state.isProcessCreate = false
            state.error = action.payload.error
        },
        edit(
            state: InitialStateType,
            _action: PayloadAction<EditAgentPayload>
        ): void {
            state.isProcessEdit = true
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditAgentSuccessPayload>
        ): void {
            state.isWizardModal = false
            state.isProcessEdit = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditAgentFailurePayload>
        ): void {
            state.isProcessEdit = false
            state.error = action.payload.error
        },
        remove(
            state: InitialStateType,
            _action: PayloadAction<RemoveAgentPayload>
        ): void {
            state.isProcessRemove = true
        },
        removeSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveAgentSuccessPayload>
        ): void {
            state.isProcessRemove = false
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveAgentFailurePayload>
        ): void {
            state.isProcessRemove = false
            state.error = action.payload.error
        },
        setWizardModal(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ): void {
            state.isWizardModal = action.payload
        }
    }
})

export const actions = promoCodesAgentsSlice.actions

export default promoCodesAgentsSlice.reducer
