import type { CSSProperties, ReactElement, ReactNode } from 'react'
import { Col, Empty, Row } from 'antd'

interface Props {
    children?: ReactNode
    style?: CSSProperties
}

export default function NotFoundStep({ children, style }: Props): ReactElement {
    return (
        <Row style={{ width: '100%', ...style }} justify={'center'}>
            <Col>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={children}
                />
            </Col>
        </Row>
    )
}
