import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IUser } from './types'

export const fetch = async (
    search: string
): Promise<AxiosResponse<IUser[]>> => {
    return await get<IUser[]>('/admin/businessAccounts', {
        count: 10,
        'filter[search]': search
    })
}
