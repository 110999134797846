import type { ReactElement } from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons'

export default function Error404(): ReactElement {
    const navigate = useNavigate()

    return (
        <Result
            status={'404'}
            title={'404'}
            subTitle={'Этой страницы не существует'}
            style={{ marginTop: '10vh' }}
            extra={
                <Button
                    onClick={() => navigate('/', { replace: true })}
                    type={'primary'}
                    icon={<HomeOutlined />}
                >
                    {'Вернуться на главную'}
                </Button>
            }
        />
    )
}
