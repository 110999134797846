import type { SagaIterator } from '@redux-saga/core'
import {
    all,
    call,
    fork,
    getContext,
    put,
    select,
    takeEvery
} from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import type { createBrowserRouter } from 'react-router-dom'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import { companyWizardSelector } from './selectors'
import type {
    FetchCompanyPayload,
    FetchFeaturesPayload,
    SaveCompanyPayload,
    RemovePhotoPayload,
    SortGalleryPayload,
    UploadGalleryPayload,
    FetchCategoriesPayload,
    RemoveVideoPayload,
    SaveVideoPayload,
    SaveDeliveryPayload,
    CroppingPhotoPayload
} from './types'

export function* handleCreateCompany(): SagaIterator {
    try {
        const { preCreateData } = yield select(companyWizardSelector)
        const { data } = yield call(api.createCompany, preCreateData)
        yield put(actions.createCompanySuccess())
        const router: ReturnType<typeof createBrowserRouter> =
            yield getContext('router')
        void router.navigate(
            `/companies/${data.company_id}/edit/?page=schedule&mode=create`
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createCompanyFailure({
                error: message
            })
        )
    }
}

export function* watchCreateCompany(): SagaIterator {
    yield takeEvery(actions.createCompany, handleCreateCompany)
}

export function* handleSaveCompany(action: {
    payload: SaveCompanyPayload
}): SagaIterator {
    try {
        yield call(
            api.saveCompany,
            action.payload.company_id,
            action.payload.form
        )

        yield put(
            actions.saveCompanySuccess({
                isLastStep: action.payload.isLastStep
            })
        )

        yield put(actions.fetchCompany(action.payload.company_id))

        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })

        if (action.payload.isBackSettings) {
            const router: ReturnType<typeof createBrowserRouter> =
                yield getContext('router')
            void router.navigate(`/companies/${action.payload.company_id}`)
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveCompanyFailure({
                error: message
            })
        )
    }
}

export function* watchSaveCompany(): SagaIterator {
    yield takeEvery(actions.saveCompany, handleSaveCompany)
}

export function* handleFetchCompany(action: {
    payload: FetchCompanyPayload
}): SagaIterator {
    try {
        const { data } = yield call(api.fetchCompany, action.payload)

        if (data.status === 'draft' && data.summary_status === null) {
            yield call(api.sendFinishCreationCompany, action.payload)
            yield put(actions.fetchCompany(action.payload))
        }

        yield put(
            actions.fetchCompanySuccess({
                data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCompanyFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCompany(): SagaIterator {
    yield takeEvery(actions.fetchCompany, handleFetchCompany)
}

export function* handleFetchCategories(action: {
    payload: FetchCategoriesPayload
}): SagaIterator {
    try {
        const { data } = yield call(api.fetchCategories, action.payload.type)
        yield put(actions.fetchCategoriesSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCategoriesFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCategories(): SagaIterator {
    yield takeEvery(actions.fetchCategories, handleFetchCategories)
}

export function* handleFetchFeatures(action: {
    payload: FetchFeaturesPayload
}): SagaIterator {
    try {
        const fetchApi = action.payload.isAllFeatures
            ? api.fetchAllFeatures
            : api.fetchFeatures

        const { data } = yield call(fetchApi, action.payload.categories)

        yield put(actions.fetchFeaturesSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFeaturesFailure({
                error: message
            })
        )
    }
}

export function* watchFetchFeatures(): SagaIterator {
    yield takeEvery(actions.fetchFeatures, handleFetchFeatures)
}

export function* handleCroppingPhoto(action: {
    payload: CroppingPhotoPayload
}): SagaIterator {
    try {
        yield call(api.croppingPhoto, action.payload)
        yield put(actions.croppingPhotoSuccess())
        yield put(actions.fetchCompany(action.payload.company_id))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.croppingPhotoFailure({
                error: message
            })
        )
    }
}

export function* watchCroppingPhoto(): SagaIterator {
    yield takeEvery(actions.croppingPhoto, handleCroppingPhoto)
}

export function* handleUploadGallery(action: {
    payload: UploadGalleryPayload
}): SagaIterator {
    try {
        const { company_id, files } = action.payload
        const { data } = yield call(api.uploadGallery, company_id, files)
        yield put(actions.uploadGallerySuccess({ gallery: data.gallery }))
        yield put(actions.fetchCompany(company_id))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.uploadGalleryFailure({
                error: message
            })
        )
    }
}

export function* watchUploadGallery(): SagaIterator {
    yield takeEvery(actions.uploadGallery, handleUploadGallery)
}

export function* handleRemovePhoto(action: {
    payload: RemovePhotoPayload
}): SagaIterator {
    const { company_id, media_id } = action.payload
    try {
        const { data } = yield call(api.removeMedia, company_id, media_id)
        yield put(
            actions.removePhotoSuccess({ media_id, gallery: data.gallery })
        )
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removePhotoFailure({
                media_id,
                error: message
            })
        )
    }
}

export function* watchRemovePhoto(): SagaIterator {
    yield takeEvery(actions.removePhoto, handleRemovePhoto)
}

export function* handleSortGallery(action: {
    payload: SortGalleryPayload
}): SagaIterator {
    try {
        const { company_id, sort_ids } = action.payload
        yield call(api.sortGallery, company_id, sort_ids)
        yield put(actions.sortGallerySuccess())
        yield put(actions.fetchCompany(company_id))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sortGalleryFailure({
                error: message
            })
        )
    }
}

export function* watchSortGallery(): SagaIterator {
    yield takeEvery(actions.sortGallery, handleSortGallery)
}

export function* handleSaveVideo(action: {
    payload: SaveVideoPayload
}): SagaIterator {
    try {
        const { company_id, videos } = action.payload
        const { data } = yield call(api.saveVideo, company_id, videos)
        yield put(actions.saveVideoSuccess({ videos: data.videos }))
        yield put(actions.fetchCompany(company_id))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveVideoFailure({
                error: message
            })
        )
    }
}

export function* watchSaveVideo(): SagaIterator {
    yield takeEvery(actions.saveVideo, handleSaveVideo)
}

export function* handleRemoveVideo(action: {
    payload: RemoveVideoPayload
}): SagaIterator {
    const { company_id, media_id } = action.payload
    try {
        const { data } = yield call(api.removeMedia, company_id, media_id)
        yield put(actions.removeVideoSuccess({ media_id, videos: data.videos }))
        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeVideoFailure({
                media_id,
                error: message
            })
        )
    }
}

export function* watchRemoveVideo(): SagaIterator {
    yield takeEvery(actions.removeVideo, handleRemoveVideo)
}

export function* handleSaveDelivery(action: {
    payload: SaveDeliveryPayload
}): SagaIterator {
    try {
        const calls = []

        for (const item of action.payload.data) {
            calls.push(
                call(api.saveCompany, item.company_id, {
                    on_house_type: item.on_house_type,
                    on_house_radius: item.on_house_radius,
                    on_house_areas: item.on_house_areas
                })
            )
        }

        yield all(calls)

        yield put(actions.saveDeliverySuccess())

        yield put(actions.fetchCompany(action.payload.general_company_id))

        const message = yield getContext('message')

        message.open({
            type: 'success',
            content: 'Изменения успешно сохранены'
        })

        if (action.payload.isBackSettings) {
            const router: ReturnType<typeof createBrowserRouter> =
                yield getContext('router')
            void router.navigate(
                `/companies/${action.payload.general_company_id}`
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveDeliveryFailure({
                error: message
            })
        )
    }
}

export function* watchSaveDelivery(): SagaIterator {
    yield takeEvery(actions.saveDelivery, handleSaveDelivery)
}

export default function* watchCompanyWizard(): SagaIterator {
    yield all([
        fork(watchCreateCompany),
        fork(watchSaveCompany),
        fork(watchFetchCompany),
        fork(watchFetchCategories),
        fork(watchFetchFeatures),
        fork(watchCroppingPhoto),
        fork(watchUploadGallery),
        fork(watchRemovePhoto),
        fork(watchSortGallery),
        fork(watchSaveVideo),
        fork(watchRemoveVideo),
        fork(watchSaveDelivery)
    ])
}
