export const fetchCities = async (query: string): Promise<Response> => {
    const response = await fetch(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Token ${process.env.DADATA_KEY}`
            },
            body: JSON.stringify({
                query,
                count: 10,
                from_bound: {
                    value: 'city'
                },
                to_bound: {
                    value: 'city'
                },
                language: 'ru'
            })
        }
    )

    return await response.json()
}
