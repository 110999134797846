import type { ReactElement } from 'react'
import { Select } from 'antd'
import { useDebounce } from '../../hooks/useDebounce'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import LoadingSelector from '../../components/LoadingSelector'
import { regionSelectorSelector } from './selectors.ts'

type AreaItemSelect = { label: string; value: string }[]

interface Props {
    initialValue?: string | string[] | AreaItemSelect
    onChange: (value: string | AreaItemSelect) => void
    placeholder?: string
    isMultiple?: boolean
}

export default function RegionSelector({
    initialValue,
    onChange,
    placeholder,
    isMultiple
}: Props): ReactElement {
    const [hasInitialize, setHasInitialize] = useState(false)
    const [preData, setPreData] = useState<{ label: string; value: string }[]>(
        []
    )
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce<string>(search, 600)

    const dispatch = useDispatch()

    const { data, isFetching } = useAppSelector(regionSelectorSelector)

    useEffect(() => {
        if ((!hasInitialize && initialValue) || isMultiple) {
            if (isMultiple && Array.isArray(initialValue)) {
                setPreData(initialValue as AreaItemSelect)
            } else {
                if (
                    typeof initialValue === 'string' &&
                    initialValue.includes('{d}')
                ) {
                    const [label, value] = initialValue.split('{d}')
                    setPreData([{ label, value: `${label}{d}${value}` }])
                }
            }
        }
    }, [initialValue])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.fetch({ search: debounceSearch }))
            setHasInitialize(true)
        } else if (data.length > 0) {
            dispatch(actions.destroy())
        }
    }, [debounceSearch])

    return (
        <Select
            showSearch
            allowClear={!isMultiple}
            value={
                isMultiple
                    ? preData
                    : !hasInitialize
                      ? preData[0]?.label
                      : undefined
            }
            mode={isMultiple ? 'multiple' : undefined}
            options={
                !hasInitialize
                    ? preData
                    : data.map(item => ({
                          label: item.name,
                          value: item.area_id
                      }))
            }
            filterOption={false}
            notFoundContent={isFetching ? <LoadingSelector /> : null}
            loading={isFetching}
            placeholder={placeholder ?? 'Регион'}
            searchValue={search}
            onSearch={setSearch}
            onClear={() => setHasInitialize(true)}
            onChange={(value, options) => {
                if (options && !Array.isArray(options) && !isMultiple) {
                    onChange(`${options.label}{d}${options.value}`)
                } else {
                    onChange(isMultiple ? (options as AreaItemSelect) : value)
                }
            }}
        />
    )
}
