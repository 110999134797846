import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPagePayload,
    FetchPageSuccessPayload,
    FetchPageFailurePayload
} from './types'

const initialState: InitialStateType = {
    pages: {},
    pagesIsFetching: [],
    error: null
}

export const contentPageSlice = createSlice({
    name: 'contentPage',
    initialState,
    reducers: {
        fetchPage(
            state: InitialStateType,
            action: PayloadAction<FetchPagePayload>
        ): void {
            state.pagesIsFetching.push(action.payload.code)
        },
        fetchPageSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPageSuccessPayload>
        ): void {
            state.pagesIsFetching = state.pagesIsFetching.filter(
                code => code !== action.payload.code
            )
            state.pages[action.payload.code] = action.payload.data
        },
        fetchPageFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPageFailurePayload>
        ): void {
            state.pagesIsFetching = state.pagesIsFetching.filter(
                code => code !== action.payload.code
            )
            state.error = action.payload.error
        }
    }
})

export const actions = contentPageSlice.actions

export default contentPageSlice.reducer
