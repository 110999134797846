import { useEffect } from 'react'
import type { ReactElement } from 'react'
import { useForm, useWatch } from 'antd/es/form/Form'
import { useDispatch } from 'react-redux'
import { Typography, Form, Input, Radio, Slider, Row } from 'antd'
import { actions } from '../../slice'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { eventWizardSelector } from '../../selectors'
import NextStepButton from '../../../../components/NextStepButton'
import RadioDescription from '../../../../components/RadioDescription'

// Styles
import styles from './styles.module.scss'

const { Title } = Typography

export default function BasicStep(): ReactElement {
    const dispatch = useDispatch()

    const [form] = useForm()

    const {
        currentEvent,
        hasStepChanges,
        preCreateData,
        isProcessCreate,
        isSaving
    } = useAppSelector(eventWizardSelector)

    const watchAgeCategory = useWatch('age_category', form)

    useEffect(() => {
        if (currentEvent) {
            const keys = ['name', 'age_category', 'age_min', 'age_max'].filter(
                item => {
                    if (currentEvent.age_category === 'adult') {
                        return !['age_min', 'age_max'].includes(item)
                    }
                    return true
                }
            )
            const hasChanges = keys.some(key => {
                const eventValue =
                    currentEvent[key as keyof typeof currentEvent] === 100
                        ? 18
                        : currentEvent[key as keyof typeof currentEvent]
                return (
                    preCreateData[key as keyof typeof preCreateData] !==
                    eventValue
                )
            })

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [preCreateData, currentEvent])

    const handleUndoChanges = () => {
        if (currentEvent) {
            const params = {
                name: currentEvent.name,
                age_category: currentEvent.age_category,
                age_min:
                    currentEvent.age_category !== 'adult'
                        ? currentEvent.age_min
                        : 0,
                age_max:
                    currentEvent.age_category !== 'adult'
                        ? currentEvent.age_max > 18
                            ? 18
                            : currentEvent.age_max
                        : 18
            }

            dispatch(actions.setParam(params))
            form.setFieldsValue(params)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const isChildrenSelected = preCreateData.age_category === 'children'

    const handleChangeAgeCategoryMultiple = (value: number[]) => {
        dispatch(actions.setParam({ age_min: value[0], age_max: value[1] }))
    }

    const handleChangeAgeCategory = (value: number) => {
        dispatch(actions.setParam({ age_min: value }))
    }

    const handleSendBasic = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        name: preCreateData.name,
                        age_category: preCreateData.age_category,
                        age_min: preCreateData.age_min,
                        age_max: preCreateData.age_max
                    }
                })
            )
        } else {
            dispatch(actions.createEvent())
        }
    }

    return (
        <>
            <Form
                form={form}
                layout={'vertical'}
                initialValues={{
                    name: preCreateData.name,
                    age_category: preCreateData.age_category
                }}
                onValuesChange={values => dispatch(actions.setParam(values))}
            >
                <Form.Item label={'Название предложения'} name={'name'}>
                    <Input allowClear placeholder={'Название предложения'} />
                </Form.Item>
                <div className={styles.children}>
                    <Title style={{ margin: 0 }} level={5}>
                        {'Предложение подходит детям?'}
                    </Title>
                    <RadioDescription>
                        {
                            'Насколько ваша предложение подходит для детей и посещения с детьми'
                        }
                    </RadioDescription>
                    <Form.Item name={'age_category'}>
                        <Radio.Group
                            style={{ marginTop: 15 }}
                            buttonStyle={'solid'}
                        >
                            <Radio.Button value={'adult'}>
                                {'Не подходит детям'}
                            </Radio.Button>
                            <Radio.Button value={'all'}>
                                {'Удобно взрослым и детям'}
                            </Radio.Button>
                            <Radio.Button value={'children'}>
                                {'Услуги только для детей'}
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {preCreateData.age_category !== 'adult' ? (
                    <Form.Item label={'Ограничение по возрасту ребенка'}>
                        {isChildrenSelected ? (
                            <Slider
                                range
                                value={[
                                    preCreateData.age_min ?? 0,
                                    preCreateData.age_max ?? 18
                                ]}
                                onChange={handleChangeAgeCategoryMultiple}
                                style={{ margin: '0 5px 10px' }}
                                min={0}
                                max={18}
                            />
                        ) : (
                            <Slider
                                value={preCreateData.age_min}
                                onChange={handleChangeAgeCategory}
                                style={{ margin: '0 5px 10px' }}
                                reverse={watchAgeCategory === 'all'}
                                tooltip={{
                                    formatter() {
                                        return (
                                            (preCreateData.age_max ?? 18) -
                                            (preCreateData.age_min ?? 0)
                                        )
                                    }
                                }}
                                min={0}
                                max={18}
                            />
                        )}
                        {watchAgeCategory === 'all' ? (
                            <Row justify={'space-between'}>
                                <span className={styles.age}>{`от ${
                                    (preCreateData.age_max ?? 18) -
                                    (preCreateData.age_min ?? 0)
                                } лет`}</span>
                                <span className={styles.age}>
                                    {'до 18 лет'}
                                </span>
                            </Row>
                        ) : (
                            <Row justify={'space-between'}>
                                <span
                                    className={styles.age}
                                >{`от ${preCreateData.age_min} лет`}</span>
                                <span className={styles.age}>{`до ${
                                    isChildrenSelected
                                        ? preCreateData.age_max
                                        : 18
                                } лет`}</span>
                            </Row>
                        )}
                    </Form.Item>
                ) : null}
            </Form>
            <NextStepButton
                loading={isProcessCreate || isSaving}
                disabled={
                    preCreateData.name!.length < 3 || currentEvent
                        ? !hasStepChanges
                        : false
                }
                onClick={handleSendBasic}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {currentEvent ? 'Сохранить' : 'Создать предложение'}
            </NextStepButton>
        </>
    )
}
