import type { ReactElement } from 'react'
import { useEffect } from 'react'
import {
    Button,
    Col,
    Pagination,
    Popconfirm,
    Row,
    Spin,
    Table,
    Typography
} from 'antd'
import {
    DeleteOutlined,
    PlayCircleOutlined,
    PlusOutlined,
    StopOutlined
} from '@ant-design/icons'
import styles from './styles.module.scss'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table'
import AdvertisingFilter from '../../components/AdvertisingFilter'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { authorizationSelector } from '../Authorization/selectors.ts'
import {
    advertisingStatus,
    advertisingStatusColor,
    getRole,
    ignoreKeys
} from '../../utils/helpers.ts'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { advertisingSelector } from './selectors.ts'
import type { IAdvertisingItem } from './types.ts'
import type { IFranchisee } from '../FranchiseeSelector/types.ts'
import type { IUser } from '../Users/types.ts'
import dayjs from 'dayjs'

const { Title } = Typography

const columns = (
    isAdminOrFranchisee: boolean,
    onRemove: (id: string) => void,
    removing: string[],
    changingStatus: string[],
    onChangeStatus: (id: string) => void
): ColumnsType<IAdvertisingItem> => [
    {
        title: 'Название',
        dataIndex: '',
        key: 'title',
        width: 200,
        render: (data: IAdvertisingItem) => (
            <Link to={`/advertising/${data.advertising_group_id}`}>
                {data.title}
            </Link>
        )
    },
    {
        title: 'Статус',
        dataIndex: '',
        key: 'status',
        align: 'center',
        width: isAdminOrFranchisee ? 150 : 250,
        render: ({ advertising_group_id, status }: IAdvertisingItem) =>
            changingStatus.includes(advertising_group_id) ? (
                <Spin />
            ) : status === 1 || status === 2 ? (
                <span style={{ color: advertisingStatusColor[status] }}>
                    {advertisingStatus[status]}
                </span>
            ) : status === 3 ? (
                <Col>
                    <PlayCircleOutlined
                        style={{ color: 'green', marginRight: 5 }}
                    />
                    {'Идут показы'}
                    <br />
                    <span
                        onClick={() => onChangeStatus(advertising_group_id)}
                        className={styles.link}
                    >
                        {'Остановить'}
                    </span>
                </Col>
            ) : (
                <Col>
                    <StopOutlined style={{ color: 'red', marginRight: 5 }} />
                    {'Остановлено'}
                    <br />
                    <span
                        onClick={() => onChangeStatus(advertising_group_id)}
                        className={styles.link}
                    >
                        {'Запустить'}
                    </span>
                </Col>
            )
    },
    {
        title: 'Потрачено, ₽',
        dataIndex: 'money_spent',
        key: 'money_spent',
        align: 'center',
        width: 120,
        render: value => `${(value / 100).toLocaleString()} ₽`
    },
    {
        title: 'Просмотров',
        dataIndex: 'views_count',
        key: 'views_count',
        align: 'center',
        render: value => value.toLocaleString()
    },
    {
        title: 'Переходов',
        dataIndex: 'visits_count',
        key: 'visits_count',
        align: 'center',
        render: value => value.toLocaleString()
    },
    {
        title: 'Вид списания',
        dataIndex: 'payment_strategy',
        key: 'payment_strategy',
        align: 'center',
        render: value => (value === 'view' ? '1000 просмотров' : 'Клики')
    },
    {
        title: 'Стоимость, ₽',
        dataIndex: '',
        key: 'click_cost',
        align: 'center',
        render: ({ click_cost, view_cost, status, payment_strategy }) =>
            isAdminOrFranchisee || status === 3 || status === 4
                ? `${((payment_strategy === 'click' ? click_cost : view_cost) / 100).toLocaleString()} ₽`
                : '—'
    },
    ...(isAdminOrFranchisee
        ? ([
              {
                  title: 'Владелец объявления',
                  dataIndex: 'user',
                  key: 'user',
                  align: 'center',
                  render: (data: IUser) =>
                      data.full_name || data.email || data.phone
              },
              {
                  title: 'Дата создания',
                  dataIndex: 'created_at',
                  key: 'created_at',
                  width: 150,
                  align: 'center',
                  render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
              },
              {
                  title: 'Дата последнего изменения',
                  dataIndex: 'updated_at',
                  key: 'updated_at',
                  align: 'center',
                  width: 150,
                  render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
              },
              {
                  title: 'Франчайзи',
                  dataIndex: 'franchisee',
                  key: 'franchisee',
                  align: 'center',
                  render: (data: IFranchisee | null) =>
                      data ? data.name : 'Не установлен'
              }
          ] as ColumnsType<IAdvertisingItem>)
        : []),
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        align: 'center',
        width: 50,
        render: (data: IAdvertisingItem) => (
            <Popconfirm
                title={'Удаление рекламной кампании'}
                description={'Вы уверены, что хотите удалить?'}
                onConfirm={() => onRemove(data.advertising_group_id)}
                okText={'Да'}
                cancelText={'Нет'}
            >
                <Button
                    loading={removing.includes(data.advertising_group_id)}
                    icon={<DeleteOutlined />}
                    danger={true}
                />
            </Popconfirm>
        )
    }
]

export default function Advertising(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const navigate = useNavigate()

    const { profile } = useAppSelector(authorizationSelector)
    const { data, meta, removing, isFetching, changingStatus } =
        useAppSelector(advertisingSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.fetch({
                page,
                pageSize,
                filter
            })
        )

        setSearchParams({
            ...filter,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const handleRemove = (advertising_id: string) => {
        dispatch(actions.remove({ advertising_id }))
    }

    const isAdminOrFranchisee = profile
        ? getRole(profile.roles) === 'admin' ||
          getRole(profile.roles) === 'franchisee'
        : false

    const onChangeStatus = (advertisingGroupId: string) => {
        dispatch(actions.changeStatus({ advertisingGroupId }))
    }

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Row>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => navigate('/advertising/create')}
                        type={'primary'}
                    >
                        {'Добавить кампанию'}
                    </Button>
                </Row>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Row justify={'space-between'}>
                    <Title level={3} style={{ margin: 0 }}>
                        {'Поиск рекламных кампаний'}
                    </Title>
                </Row>
                <div style={{ height: 25 }} />
                <AdvertisingFilter />
            </div>
            <div className={styles.container}>
                <Table
                    dataSource={data}
                    columns={columns(
                        isAdminOrFranchisee,
                        handleRemove,
                        removing,
                        changingStatus,
                        onChangeStatus
                    )}
                    rowKey={'advertising_group_id'}
                    loading={isFetching}
                    pagination={false}
                    scroll={isAdminOrFranchisee ? { x: 1600 } : undefined}
                    sticky={{ offsetHeader: 0 }}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
            </div>
        </>
    )
}
