import type { ReactElement, ReactNode, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Tree } from 'antd'
import type { DataNode } from 'antd/es/tree'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useEffect, useMemo, useState } from 'react'
import { actions } from '../../slice'
import { LoadingStep } from '../../../../components/LoadingStep'
import NextStepButton from '../../../../components/NextStepButton'
import { DownOutlined } from '@ant-design/icons'
import type { Key } from 'antd/es/table/interface'
import type { IFeature } from '../../types'
import { companyWizardSelector } from '../../selectors'
import NotFoundStep from '../../../../components/NotFoundStep'
import {
    getRole,
    getTreeParentKey,
    hasTreeChildrenChecked
} from '../../../../utils/helpers'
import SelectedTreeParent from '../../../../components/SelectedTreeParent'
import { useDebouncedCallback } from 'use-debounce'
import { authorizationSelector } from '../../../Authorization/selectors.ts'

interface ITreeItem {
    title: string
    key: string
    children?: ITreeItem[]
}

export default function FeaturesStep(): ReactElement {
    const [isEmptyCategories, setIsEmptyCategories] = useState(false)
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [selectedFeatures, setSelectedFeatures] = useState<Key[]>([])
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
    const [searchValue, setSearchValue] = useState<string>('')

    const dispatch = useDispatch()

    const {
        currentCompany,
        hasStepChanges,
        features,
        isFetchingFeatures,
        isSaving
    } = useAppSelector(companyWizardSelector)

    const { profile } = useAppSelector(authorizationSelector)

    const role = profile ? getRole(profile.roles) : false

    const isDisabled = isFetchingFeatures || isSaving

    const isAllFeatures = role === 'admin' || role === 'franchisee'

    const defaultData = useMemo(() => {
        const result: DataNode[] = []
        const loop = (item: DataNode[]) => {
            item.forEach(i => {
                result.push({ title: i.title, key: i.key })
                if (i.children) {
                    loop(i.children)
                }
            })
        }
        loop(treeData)
        return result
    }, [treeData])

    const findCollection = useMemo(() => {
        const loop = (data: DataNode[]): DataNode[] =>
            data.map(item => {
                const strTitle = item.title as string
                const strTitleLower = (item.title as string).toLowerCase()
                const index = strTitleLower.indexOf(searchValue.toLowerCase())
                const beforeStr = strTitle.substring(0, index)
                const afterStr = strTitle.slice(index + searchValue.length)
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className={'site-tree-search-value'}>
                                {strTitle.substring(
                                    index,
                                    index + searchValue.length
                                )}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{strTitle}</span>
                    )
                if (item.children) {
                    return {
                        title,
                        icon: item.icon,
                        key: item.key,
                        children: loop(item.children)
                    }
                }

                return {
                    title,
                    icon: item.icon,
                    key: item.key
                }
            })

        return loop(treeData)
    }, [treeData, searchValue])

    useEffect(() => {
        if (currentCompany) {
            const companyFeatures = currentCompany.companyFeatures.map(
                item => item.companyFeatureId
            )

            const hasChanges =
                companyFeatures.length !== selectedFeatures.length ||
                companyFeatures.some(
                    feature_id => !selectedFeatures.includes(feature_id)
                )

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentCompany, selectedFeatures])

    const handleUndoChanges = () => {
        if (currentCompany) {
            setIsEmptyCategories(currentCompany.companyCategories.length === 0)
            setSelectedFeatures(
                currentCompany.companyFeatures.map(
                    feature => feature.companyFeatureId
                )
            )
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    useEffect(() => {
        const deepMap = (category: IFeature): ITreeItem => {
            return {
                title: category.name,
                key: category.companyFeatureId,
                children: category.children
                    ? category.children.map(deepMap)
                    : undefined
            }
        }

        setTreeData(features.map(deepMap))
    }, [features])

    useEffect(() => {
        if (currentCompany && currentCompany.companyCategories.length > 0) {
            dispatch(
                actions.fetchFeatures({
                    categories: currentCompany.companyCategories
                        .map(item => item.companyCategoryId)
                        .join(','),
                    isAllFeatures
                })
            )
        }
    }, [])

    const handleSaveEvent = (isTrusted: boolean): void => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: { features: selectedFeatures },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const expandToggle = (selectedKey: Key) =>
        setExpandedKeys(prev =>
            prev.includes(selectedKey)
                ? prev.filter(key => key !== selectedKey)
                : [...prev, selectedKey]
        )

    const debounced = useDebouncedCallback(value => {
        const additional: Key[] = []
        const newExpandedKeys = [
            ...defaultData.map(item => {
                if (
                    (item.title as string)
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                ) {
                    const parent = getTreeParentKey(item.key, treeData)
                    additional.push(getTreeParentKey(parent, treeData))
                    return parent
                }
                return null
            }),
            ...additional
        ].filter(
            (item, i, self): item is Key =>
                !!(item && self.indexOf(item) === i) ||
                treeData.some(data => data.key === item)
        )
        setExpandedKeys(value ? newExpandedKeys : [])
    }, 500)

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSearchValue(value)
        debounced(value)
    }

    return (
        <>
            {isEmptyCategories ? (
                <NotFoundStep>
                    {
                        'Для выбора особенностей, сначала нужно выбрать категории!'
                    }
                </NotFoundStep>
            ) : isFetchingFeatures ? (
                <LoadingStep />
            ) : findCollection.length > 0 ? (
                <>
                    <Input
                        value={searchValue}
                        onChange={onChange}
                        placeholder={'Найти особенности'}
                        style={{ marginBottom: 15 }}
                        allowClear={true}
                    />
                    <Tree
                        showIcon
                        checkable
                        expandedKeys={expandedKeys}
                        checkedKeys={selectedFeatures}
                        switcherIcon={<DownOutlined />}
                        treeData={findCollection.map(item => ({
                            ...item,
                            title: hasTreeChildrenChecked(
                                item,
                                selectedFeatures
                            ) ? (
                                <SelectedTreeParent>
                                    {item.title as ReactNode}
                                </SelectedTreeParent>
                            ) : (
                                item.title
                            )
                        }))}
                        style={{ background: '#FFF' }}
                        onCheck={(value, { node }) => {
                            if (Array.isArray(value)) {
                                setSelectedFeatures(value)
                                if (!node.expanded) {
                                    expandToggle(node.key)
                                }
                            }
                        }}
                        onSelect={(_, { node }) => {
                            if (typeof node.checkable !== 'boolean') {
                                if (
                                    node.children &&
                                    !selectedFeatures.includes(node.key)
                                ) {
                                    setSelectedFeatures(prev => [
                                        ...prev,
                                        node.key
                                    ])
                                }
                            }
                            if (!expandedKeys.includes(node.key)) {
                                expandToggle(node.key)
                            }
                        }}
                        onExpand={(_, { node }) => expandToggle(node.key)}
                    />
                </>
            ) : (
                <NotFoundStep>
                    {'Особенности для выбранных категорий не найдены!'}
                </NotFoundStep>
            )}
            <NextStepButton
                disabled={
                    isDisabled ||
                    selectedFeatures.length === 0 ||
                    !hasStepChanges
                }
                loading={isDisabled}
                onClick={handleSaveEvent}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
