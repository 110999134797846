import type { AxiosResponse } from 'axios'
import { del, get, post, patch } from '../../utils/api'
import type { FormItemsType, IAgent } from './types.ts'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse<IAgent[]>> =>
    get<IAgent[]>('/admin/agents', {
        page,
        page_size,
        include: ['user', 'franchisee', 'franchisee.user'],
        filter
    })

export const createAgent = (
    data: Partial<FormItemsType>
): Promise<AxiosResponse> => post<FormItemsType>('/admin/agents', data)

export const editAgent = (
    agentId: string,
    data: Partial<FormItemsType>
): Promise<AxiosResponse> =>
    patch<FormItemsType>(`/admin/agents/${agentId}`, data)

export const removeAgent = (agentId: string): Promise<AxiosResponse> =>
    del<void>(`/admin/agents/${agentId}`)
