import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Button, Form, Input, Modal, InputNumber } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { actions } from '../../containers/PromoCodesAgents/slice.ts'
import { promoCodesAgentsSelector } from '../../containers/PromoCodesAgents/selectors.ts'
import { authorizationSelector } from '../../containers/Authorization/selectors.ts'
import { getRole } from '../../utils/helpers.ts'
import type {
    FormItemsType,
    IAgent
} from '../../containers/PromoCodesAgents/types.ts'
import UserSelector from '../../containers/UserSelector'
import { useSearchParams } from 'react-router-dom'
import MaskedInput from 'antd-mask-input'
import FranchiseeSelector from '../../containers/FranchiseeSelector'

const { TextArea } = Input

interface Props {
    selectedAgent: IAgent | null
    onClose: () => void
}

export default function PromoCodesAgentsWizardModal({
    selectedAgent,
    onClose
}: Props): ReactElement {
    const { isWizardModal, isProcessCreate, isProcessEdit } = useAppSelector(
        promoCodesAgentsSelector
    )
    const { profile } = useAppSelector(authorizationSelector)

    const [_, setSearchParams] = useSearchParams()

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : true
    const isFranchisee = profile
        ? getRole(profile.roles) === 'franchisee'
        : true

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const userIdWatch = Form.useWatch('user_id', form)
    const franchiseeIdWatch = Form.useWatch('franchisee_id', form)

    const handleResetForm = () => {
        form.resetFields()
    }

    useEffect(() => {
        if (selectedAgent) {
            const {
                name,
                contact_phone,
                contact_email,
                requisites,
                comment,
                franchisee,
                user,
                agent_commission
            } = selectedAgent
            const data = [
                {
                    name: 'name',
                    value: name
                },
                {
                    name: 'contact_phone',
                    value: contact_phone
                },
                {
                    name: 'contact_email',
                    value: contact_email
                },
                {
                    name: 'requisites',
                    value: requisites
                },
                {
                    name: 'comment',
                    value: comment
                },
                {
                    name: 'agent_commission',
                    value: agent_commission
                }
            ]

            data.push({
                name: 'user_id',
                value: `${[user.full_name, user.email, user.phone]
                    .filter(Boolean)
                    .join(' - ')}{d}${user.user_id}`
            })

            if (isAdmin && franchisee) {
                data.push({
                    name: 'franchisee_id',
                    value: `${[
                        franchisee.name,
                        franchisee.user?.full_name,
                        franchisee.user?.email
                    ]
                        .filter(Boolean)
                        .join(' - ')}{d}${franchisee.franchisee_id}`
                })
            }

            form.setFields(data)
        } else {
            handleResetForm()
        }
    }, [selectedAgent])

    const handleSendForm = (data: FormItemsType) => {
        const selectors = ['user_id', 'franchisee_id']

        selectors.forEach(name => {
            if (data[name as keyof FormItemsType]) {
                data[name as keyof FormItemsType] =
                    data[name as keyof FormItemsType]!.split('{d}')[1]
            }
        })

        if (!data.franchisee_id && profile) {
            data.franchisee_id = profile.franchisee?.franchisee_id
        }

        const isCorrectPhone = /\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(
            data.contact_phone
        )

        if (!isCorrectPhone) {
            form.setFields([
                {
                    name: 'contact_phone',
                    errors: ['Вы не верно ввели телефон!']
                }
            ])
            return
        }

        if (selectedAgent) {
            dispatch(
                actions.edit({
                    agentId: selectedAgent.agent_id,
                    data
                })
            )
        } else {
            dispatch(
                actions.create({
                    data,
                    clearSearchParams: () => setSearchParams({})
                })
            )
        }
    }

    useEffect(() => {
        if (!isWizardModal) {
            onClose()
            handleResetForm()
        }
    }, [isWizardModal])

    return (
        <Modal
            title={
                <>
                    <UserOutlined style={{ marginRight: 5 }} />{' '}
                    {selectedAgent ? 'Редактировать агента' : 'Создать агента'}
                </>
            }
            width={750}
            style={{ top: 30 }}
            open={isWizardModal}
            onCancel={() => dispatch(actions.setWizardModal(false))}
            destroyOnClose={true}
            footer={null}
        >
            <Form
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                autoComplete={'off'}
                onFinish={handleSendForm}
                initialValues={{
                    agent_commission: '15'
                }}
            >
                <Form.Item
                    name={'name'}
                    label={'Название агента'}
                    rules={[
                        {
                            required: true,
                            message: 'Введите название агента!'
                        }
                    ]}
                >
                    <Input maxLength={255} placeholder={'Название агента'} />
                </Form.Item>
                {isAdmin ? (
                    <>
                        <Form.Item
                            name={'user_id'}
                            label={'Пользователь'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введите пользователя!'
                                }
                            ]}
                        >
                            <UserSelector
                                initialValue={form.getFieldValue('user_id')}
                                onChange={value =>
                                    form.setFieldValue('user_id', value)
                                }
                            />
                        </Form.Item>
                        <Form.Item name={'franchisee_id'} label={'Франчайзи'}>
                            <FranchiseeSelector
                                initialValue={franchiseeIdWatch}
                                onChange={value =>
                                    form.setFieldValue('franchisee_id', value)
                                }
                            />
                        </Form.Item>
                    </>
                ) : (
                    <Form.Item
                        name={'user_id'}
                        label={'Пользователь'}
                        rules={[
                            {
                                required: true,
                                message: 'Введите пользователя!'
                            }
                        ]}
                    >
                        <UserSelector
                            initialValue={userIdWatch}
                            onChange={value =>
                                form.setFieldValue('user_id', value)
                            }
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={'contact_phone'}
                    label={'Контактный телефон'}
                    rules={[
                        {
                            required: true,
                            message: 'Введите телефон!'
                        }
                    ]}
                >
                    <MaskedInput mask={'+7 (000) 000-00-00'} />
                </Form.Item>
                <Form.Item
                    name={'contact_email'}
                    label={'Контактная почта'}
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Введите верный Email!'
                        }
                    ]}
                >
                    <Input placeholder={'Контактная почта'} />
                </Form.Item>
                <Form.Item
                    name={'requisites'}
                    label={'Реквизиты в свободной форме'}
                >
                    <TextArea placeholder={'Реквизиты в свободной форме'} />
                </Form.Item>
                <Form.Item name={'comment'} label={'Комментарий'}>
                    <TextArea placeholder={'Комментарий'} />
                </Form.Item>
                {isAdmin || isFranchisee ? (
                    <Form.Item
                        name={'agent_commission'}
                        label={'Процент агента'}
                        rules={[
                            {
                                required: true,
                                message: 'Введите процент агента!'
                            }
                        ]}
                    >
                        <InputNumber
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            // @ts-ignore
                            parser={value => value!.replace('%', '')}
                        />
                    </Form.Item>
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        loading={isProcessCreate || isProcessEdit}
                        type={'primary'}
                        htmlType={'submit'}
                    >
                        {`${selectedAgent ? 'Сохранить' : 'Создать'} агента`}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}
