import type { AxiosResponse } from 'axios'
import type { IMeta } from '../Advertising/types.ts'
import { del, get } from '../../utils/api.ts'
import type { IPost } from './types.ts'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse<{ data: IPost[]; meta: IMeta }>> => {
    const filterParams: Record<string, unknown> = {}

    const selectorsKeys = ['franchisee_id', 'user_id']
    const dateKeys = ['created', 'updated']
    const arrayKeys = ['tags']

    for (const key in filter) {
        if (dateKeys.includes(key)) {
            const [from, to] = filter[key].split(',')

            const regExp = /(\d{4})(\d{2})(\d{2})/

            const formatFrom = from.replace(regExp, '$1-$2-$3')
            const formatTo = to.replace(regExp, '$1-$2-$3')

            filterParams[key] = `${formatFrom},${formatTo}`
            continue
        }
        if (arrayKeys.includes(key)) {
            filterParams[key] = filter[key].split(',')
            continue
        }
        if (selectorsKeys.includes(key)) {
            filterParams[key] =
                filter[key as keyof typeof filter]?.split('{d}')[1]
            continue
        }
        filterParams[key] = filter[key]
    }

    return get<{ data: IPost[]; meta: IMeta }>('/admin/blog/posts', {
        page,
        page_size,
        filter: filterParams,
        include: ['user', 'franchisee'],
        sort: '-created_at'
    })
}

export const remove = (post_id: string): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/blog/posts/${post_id}`)
