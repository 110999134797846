import type { AxiosResponse } from 'axios'
import { del, get, patch, post, upload } from '../../utils/api'
import type { ICompany } from '../Companies/types'
import type { ICategory, IPreCreateData, CroppingPhotoPayload } from './types'
import type { IEvent } from './types'

export const sendFinishCreationEvent = async (
    event_id: string
): Promise<AxiosResponse<void>> =>
    await post<void>(`/admin/events/${event_id}/finish-creation`)

export const fetchPlaceCompanies = async (): Promise<
    AxiosResponse<ICompany[]>
> => await get<ICompany[]>('/v2/companies/my')

export const fetchCategories = async (
    type: 'online' | 'offline'
): Promise<AxiosResponse<ICategory[]>> =>
    await get<ICategory[]>('/event-categories', { type })

export const createEvent = async (
    form: IPreCreateData
): Promise<AxiosResponse<IEvent>> => {
    const additional = {
        badge: form.badge ?? 'default',
        age_min:
            form.age_category === 'all' || form.age_category === 'children'
                ? form.age_min
                : undefined,
        age_max: form.age_category === 'children' ? form.age_max : undefined,
        areas:
            form.areas && form.areas.length > 0
                ? form.areas.map(item => item.area_id)
                : undefined
    }

    return await post<IEvent>('/admin/events', {
        ...form,
        status: 'draft',
        ...additional
    })
}

export const fetchEvent = async (
    event_id: string
): Promise<AxiosResponse<IEvent>> =>
    await get<IEvent>(`/admin/events/${event_id}`, {
        append: 'summary_status',
        include: [
            'companies',
            // 'companies.subwayStation',
            // 'companies.subwayStation.subwayLine',
            'gallery',
            'videos',
            'eventTimetables',
            'eventSchedules',
            'eventCategories',
            'eventAreas',
            'eventTerms',
            'eventPrices',
            'companies.activePlanSubscription',
            'mainImage'
            // 'latestReview',
            // 'latestReview.user',
            // 'latestReview.user.photo',
            // 'latestReview.media'
        ].join(',')
    })

export const saveEvent = async (
    event_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<IEvent>> =>
    await patch<IEvent>(`/admin/events/${event_id}`, form)

export const uploadGallery = async (
    event_id: string,
    files: File[]
): Promise<AxiosResponse<IEvent>> =>
    await upload<IEvent>(
        `/admin/events/${event_id}/gallery/upload`,
        files.map(file => ({
            field: 'gallery',
            file
        }))
    )

export const removeMedia = async (
    event_id: string,
    media_id: number
): Promise<AxiosResponse<IEvent>> =>
    await del<IEvent>(`/admin/events/${event_id}/media/${media_id}`)

export const sortGallery = async (
    event_id: string,
    ids: number[]
): Promise<AxiosResponse<IEvent>> =>
    await post<IEvent>(`/admin/events/${event_id}/gallery/order`, { ids })

export const saveVideo = async (
    event_id: string,
    videos: string[]
): Promise<AxiosResponse<IEvent>> =>
    await post<IEvent>(`/admin/events/${event_id}/videos/upload`, { videos })

export const fetchAddress = async (
    company_id: string
): Promise<AxiosResponse<ICategory[]>> =>
    await get<ICategory[]>(`/admin/companies/${company_id}/addresses`)

export const croppingPhoto = async (
    data: CroppingPhotoPayload
): Promise<AxiosResponse<IEvent>> =>
    await patch<IEvent>(`/events/${data.event_id}/media/${data.media_id}`, {
        ...data,
        event_id: undefined,
        media_id: undefined
    })
