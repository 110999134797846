import type { FetchPayload } from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import {
    formatApiError,
    getRole,
    sagaNotificationError
} from '../../utils/helpers'
import { authorizationSelector } from '../Authorization/selectors'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { profile } = yield select(authorizationSelector)
        const role = getRole(profile.roles)
        const isUser = role === 'user' || role === 'franchisee'
        const { data } = yield call(api.fetch, action.payload.search, isUser)
        yield put(actions.fetchSuccess({ data: isUser ? data : data.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export default function* watchRegionSelector(): SagaIterator {
    yield all([fork(watchFetch)])
}
