import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    ChangeMultiplePublishedFailurePayload,
    ChangeMultiplePublishedPayload,
    ChangeMultiplePublishedSuccessPayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    isFetching: false,
    isProcessChangeMultiplePublishing: false,
    error: null
}

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        changeMultiplePublished(
            state: InitialStateType,
            _action: PayloadAction<ChangeMultiplePublishedPayload>
        ) {
            state.isProcessChangeMultiplePublishing = true
        },
        changeMultiplePublishedSuccess(
            state: InitialStateType,
            _action: PayloadAction<ChangeMultiplePublishedSuccessPayload>
        ) {
            state.isProcessChangeMultiplePublishing = false
        },
        changeMultiplePublishedFailure(
            state: InitialStateType,
            action: PayloadAction<ChangeMultiplePublishedFailurePayload>
        ) {
            state.isProcessChangeMultiplePublishing = false
            state.error = action.payload.error
        }
    }
})

export const actions = eventsSlice.actions

export default eventsSlice.reducer
