import type { ReactElement } from 'react'
import { Button, Col, Input, Radio, Row, Select, Space, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import NextStepButton from '../../../../components/NextStepButton'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { actions } from '../../slice'
import type { EventPrice } from '../../types.ts'

const { Paragraph } = Typography

interface IOptions {
    show: 'show' | 'free' | 'only-percentage'
    view: 'united' | 'by-age'
    price: string
    typeDiscount: 'percent' | 'rub'
    discount: string
    discountRub: string
    percent: string
    ages: {
        from: string
        to: string
        price: string
        typeDiscount: 'percent' | 'rub'
        discount: string
        discountRub: string
    }[]
}

function DiscountDescription({
    type,
    price,
    discount
}: {
    type: 'percent' | 'rub'
    price: number
    discount: number
}) {
    if (!price || !discount) {
        return null
    }

    if (type === 'percent') {
        return (
            <span
                style={{
                    fontSize: 12,
                    color: 'gray'
                }}
            >
                {`Цена со скидкой: ${Math.floor(
                    price - (price / 100) * discount
                )} руб.`}
            </span>
        )
    }

    return (
        <span
            style={{
                fontSize: 12,
                color: 'gray'
            }}
        >
            {`Скидка составляет: ${
                100 - Math.floor((discount / price) * 100)
            }%`}
        </span>
    )
}

export default function PriceStep(): ReactElement {
    const [tempOptions, setTempOptions] = useState('')
    const [lastChanged, setLastChanged] = useState(Date.now())
    const [options, setOptions] = useState<IOptions>({
        show: 'show',
        view: 'united',
        price: '',
        typeDiscount: 'percent',
        discount: '',
        discountRub: '',
        percent: '',
        ages: []
    })

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } = useAppSelector(
        state => state.eventWizard
    )

    const promoTypes = ['offer', 'discount']
    const hasPromo = currentEvent && promoTypes.includes(currentEvent.badge)

    const isOffer = currentEvent.badge === 'offer'

    const isDisabled = useMemo(() => {
        if (options.show === 'free') {
            return false
        }
        if (options.show === 'only-percentage') {
            return options.percent.length === 0
        }
        if (options.show === 'show') {
            if (options.view === 'united') {
                if (hasPromo) {
                    return options.price.length === 0 || isOffer
                        ? false
                        : options.typeDiscount === 'percent'
                          ? options.discount.length === 0
                          : options.discountRub.length === 0
                }
                return options.price.length === 0
            }

            if (options.ages.length === 0) {
                return true
            }

            if (hasPromo) {
                return options.ages.some(item => {
                    if (isOffer) {
                        return (
                            item.from.length === 0 ||
                            item.to.length === 0 ||
                            item.price.length === 0
                        )
                    } else {
                        return item.from.length === 0 ||
                            item.to.length === 0 ||
                            item.price.length === 0 ||
                            item.typeDiscount === 'percent'
                            ? item.discount.length === 0
                            : item.discountRub.length === 0
                    }
                })
            }

            return options.ages.some(
                item =>
                    item.from.length === 0 ||
                    item.to.length === 0 ||
                    item.price.length === 0
            )
        }
    }, [options])

    useEffect(() => {
        try {
            setTempOptions(JSON.stringify(options))
        } catch {
            //
        }
    }, [lastChanged])

    useEffect(() => {
        try {
            const hasChanges = JSON.stringify(options) !== tempOptions
            dispatch(actions.setHasStepChanges(hasChanges))
        } catch {
            //
        }
    }, [tempOptions, options])

    const handleUndoChanges = () => {
        setLastChanged(Date.now())
        if (currentEvent) {
            const priceType = currentEvent.price_type

            if (priceType === 'free') {
                setOptions(prev => ({
                    ...prev,
                    show: 'free'
                }))
                return
            }

            if (
                priceType === 'united' &&
                Array.isArray(currentEvent.eventPrices)
            ) {
                setOptions(prev => ({
                    ...prev,
                    show: 'show',
                    view: 'united',
                    price: currentEvent.eventPrices[0]?.price ?? '',
                    ...(hasPromo
                        ? {
                              typeDiscount:
                                  currentEvent.eventPrices[0]
                                      ?.calculation_method === 'percentage'
                                      ? 'percent'
                                      : 'rub',
                              discount:
                                  currentEvent.eventPrices[0]
                                      ?.calculation_method === 'percentage'
                                      ? currentEvent.eventPrices[0]
                                            ?.discount_percentage
                                      : '',
                              discountRub:
                                  currentEvent.eventPrices[0]
                                      ?.calculation_method === 'value'
                                      ? currentEvent.eventPrices[0]
                                            ?.discount_price
                                      : ''
                          }
                        : {})
                }))
                return
            }

            if (
                priceType === 'by-age' &&
                Array.isArray(currentEvent.eventPrices)
            ) {
                // @ts-ignore
                setOptions(prev => ({
                    ...prev,
                    show: 'show',
                    view: 'by-age',
                    ages: currentEvent.eventPrices.map((price: EventPrice) => ({
                        from: price.age_after,
                        to: price.age_before,
                        price: price.price,
                        ...(hasPromo
                            ? {
                                  typeDiscount:
                                      price?.calculation_method === 'percentage'
                                          ? 'percent'
                                          : 'rub',
                                  discount:
                                      price?.calculation_method === 'percentage'
                                          ? price?.discount_percentage
                                          : '',
                                  discountRub:
                                      price?.calculation_method === 'value'
                                          ? price?.discount_price
                                          : ''
                              }
                            : {})
                    }))
                }))
            }

            if (priceType === 'only-percentage') {
                setOptions(prev => ({
                    ...prev,
                    show: 'only-percentage',
                    percent: currentEvent?.discount_percentage ?? ''
                }))
            }
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleChangeAge = (index: number, key: string, value: string) => {
        setOptions(prev => ({
            ...prev,
            ages: prev.ages.map((item, ageIndex) => {
                if (ageIndex === index) {
                    return {
                        ...item,
                        [key]: value
                    }
                }
                return item
            })
        }))
    }

    const handleSavePrice = () => {
        let form = {}

        if (options.show === 'free') {
            form = {
                price_type: 'free'
            }
        }

        if (options.show === 'show' && options.view === 'united') {
            const isPercent = options.typeDiscount === 'percent'

            form = {
                price_type: 'united',
                prices: [
                    {
                        age_after: 0,
                        age_before: 100,
                        calculation_method: isPercent ? 'percentage' : 'value',
                        price: options.price,
                        ...(hasPromo
                            ? {
                                  discount_price: !isPercent
                                      ? parseInt(options.discountRub)
                                      : undefined,
                                  discount_percentage: isPercent
                                      ? options.discount || 0
                                      : 0
                              }
                            : {
                                  discount_price: parseInt(options.price),
                                  discount_percentage: 0
                              })
                    }
                ]
            }
        }

        if (options.show === 'show' && options.view === 'by-age') {
            form = {
                price_type: 'by-age',
                prices: options.ages.map(age => ({
                    age_after: age.from,
                    age_before: age.to,
                    calculation_method:
                        age.typeDiscount === 'percent' ? 'percentage' : 'value',
                    price: age.price,
                    ...(hasPromo
                        ? {
                              discount_price:
                                  age.typeDiscount !== 'percent'
                                      ? parseInt(age.discountRub)
                                      : undefined,
                              discount_percentage:
                                  age.typeDiscount === 'percent'
                                      ? age.discount || 0
                                      : undefined
                          }
                        : {
                              discount_price: parseInt(age.price),
                              discount_percentage: 0
                          })
                }))
            }
        }

        if (options.show === 'only-percentage') {
            form = {
                price_type: 'only-percentage',
                discount_percentage: options.percent
            }
        }

        if (currentEvent) {
            dispatch(
                actions.saveEvent({ event_id: currentEvent.event_id, form })
            )
        }
    }

    const handleChangeInput = (value: string, key: string) => {
        setOptions(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <>
            <Space direction={'vertical'}>
                <Paragraph style={{ margin: '0' }}>
                    {'Отображать стоимость в предложении:'}
                </Paragraph>
                <Radio.Group
                    style={{ marginBottom: 7 }}
                    value={options.show}
                    options={[
                        {
                            label: 'Указывать',
                            value: 'show'
                        },
                        {
                            label: 'Не указывать',
                            value: 'free'
                        },
                        ...(hasPromo
                            ? [
                                  {
                                      label: 'Только %',
                                      value: 'only-percentage'
                                  }
                              ]
                            : [])
                    ]}
                    onChange={({ target }) =>
                        setOptions(prev => ({
                            ...prev,
                            show: target.value
                        }))
                    }
                    optionType={'button'}
                    buttonStyle={'solid'}
                />
                {options.show === 'show' ? (
                    <>
                        <Paragraph style={{ margin: '0' }}>
                            {'Вид стоимости:'}
                        </Paragraph>
                        <Radio.Group
                            value={options.view}
                            style={{ marginBottom: 7 }}
                            onChange={({ target }) =>
                                setOptions(prev => ({
                                    ...prev,
                                    view: target.value
                                }))
                            }
                            options={[
                                {
                                    label: 'Единая',
                                    value: 'united'
                                },
                                {
                                    label: 'По возрастам',
                                    value: 'by-age'
                                }
                            ]}
                            optionType={'button'}
                            buttonStyle={'solid'}
                        />
                        {options.view === 'united' ? (
                            <>
                                <Paragraph style={{ margin: '0' }}>
                                    {'Цена:'}
                                </Paragraph>
                                <Input
                                    value={options.price}
                                    placeholder={'Введите цену'}
                                    maxLength={7}
                                    suffix={'₽'}
                                    onKeyDown={event => {
                                        if (
                                            !/[0-9]/.test(event.key) &&
                                            event.key !== 'Backspace'
                                        ) {
                                            event.preventDefault()
                                        }
                                    }}
                                    onChange={({ target }) =>
                                        handleChangeInput(target.value, 'price')
                                    }
                                />
                                {hasPromo ? (
                                    <>
                                        <Paragraph
                                            style={{ margin: '10px 0 0' }}
                                        >
                                            {options.typeDiscount === 'rub'
                                                ? 'Цена со скидкой:'
                                                : 'Скидка:'}
                                        </Paragraph>
                                        <Space.Compact
                                            style={{ width: '100%' }}
                                        >
                                            <Input
                                                value={
                                                    options[
                                                        options.typeDiscount ===
                                                        'rub'
                                                            ? 'discountRub'
                                                            : 'discount'
                                                    ]
                                                }
                                                placeholder={
                                                    options.typeDiscount ===
                                                    'rub'
                                                        ? 'Укажите цену с учетом скидки'
                                                        : 'Укажите процент скидки'
                                                }
                                                maxLength={
                                                    options.typeDiscount ===
                                                    'rub'
                                                        ? undefined
                                                        : 3
                                                }
                                                onKeyDown={event => {
                                                    if (
                                                        !/[0-9]/.test(
                                                            event.key
                                                        ) &&
                                                        event.key !==
                                                            'Backspace'
                                                    ) {
                                                        event.preventDefault()
                                                    }
                                                }}
                                                onChange={({ target }) => {
                                                    if (
                                                        options.typeDiscount ===
                                                        'rub'
                                                    ) {
                                                        if (
                                                            parseInt(
                                                                options.price
                                                            ) >=
                                                                parseInt(
                                                                    target.value
                                                                ) ||
                                                            target.value === ''
                                                        ) {
                                                            handleChangeInput(
                                                                target.value,
                                                                'discountRub'
                                                            )
                                                        }
                                                    } else {
                                                        if (
                                                            parseInt(
                                                                target.value
                                                            ) <= 100 ||
                                                            target.value === ''
                                                        ) {
                                                            handleChangeInput(
                                                                target.value,
                                                                'discount'
                                                            )
                                                        }
                                                    }
                                                }}
                                            />
                                            <Select
                                                value={options.typeDiscount}
                                                onChange={value =>
                                                    handleChangeInput(
                                                        value,
                                                        'typeDiscount'
                                                    )
                                                }
                                                options={[
                                                    {
                                                        label: '%',
                                                        value: 'percent'
                                                    },
                                                    { label: '₽', value: 'rub' }
                                                ]}
                                            />
                                        </Space.Compact>
                                        {options.typeDiscount === 'percent' &&
                                        options.discount ? (
                                            <DiscountDescription
                                                type={'percent'}
                                                price={parseInt(options.price)}
                                                discount={parseInt(
                                                    options.discount
                                                )}
                                            />
                                        ) : null}
                                        {options.typeDiscount === 'rub' &&
                                        options.discountRub ? (
                                            <DiscountDescription
                                                type={'rub'}
                                                price={parseInt(options.price)}
                                                discount={parseInt(
                                                    options.discountRub
                                                )}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <>
                                {options.ages.map((age, index) => (
                                    <div
                                        style={{ marginBottom: 5 }}
                                        key={`age-${index}`}
                                    >
                                        <Row>
                                            <Col>{'Возраст:'}</Col>
                                            <Col style={{ marginLeft: 52 }}>
                                                {'Цена:'}
                                            </Col>
                                        </Row>
                                        <Space
                                            key={`space-${index}`}
                                            direction={'horizontal'}
                                        >
                                            <Space.Compact>
                                                <Input
                                                    value={age.from}
                                                    style={{ width: 50 }}
                                                    placeholder={'от'}
                                                    maxLength={2}
                                                    onKeyDown={event => {
                                                        if (
                                                            !/[0-9]/.test(
                                                                event.key
                                                            ) &&
                                                            event.key !==
                                                                'Backspace'
                                                        ) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                    onChange={({ target }) =>
                                                        handleChangeAge(
                                                            index,
                                                            'from',
                                                            target.value
                                                        )
                                                    }
                                                />
                                                <Input
                                                    value={age.to}
                                                    style={{ width: 50 }}
                                                    placeholder={'до'}
                                                    maxLength={2}
                                                    onKeyDown={event => {
                                                        if (
                                                            !/[0-9]/.test(
                                                                event.key
                                                            ) &&
                                                            event.key !==
                                                                'Backspace'
                                                        ) {
                                                            event.preventDefault()
                                                        }
                                                    }}
                                                    onChange={({ target }) =>
                                                        handleChangeAge(
                                                            index,
                                                            'to',
                                                            target.value
                                                        )
                                                    }
                                                />
                                            </Space.Compact>
                                            <Input
                                                value={age.price}
                                                placeholder={'Введите цену'}
                                                maxLength={7}
                                                suffix={'₽'}
                                                onKeyDown={event => {
                                                    if (
                                                        !/[0-9]/.test(
                                                            event.key
                                                        ) &&
                                                        event.key !==
                                                            'Backspace'
                                                    ) {
                                                        event.preventDefault()
                                                    }
                                                }}
                                                onChange={({ target }) =>
                                                    handleChangeAge(
                                                        index,
                                                        'price',
                                                        target.value
                                                    )
                                                }
                                            />
                                            {hasPromo ? (
                                                <Space.Compact
                                                    style={{ width: '100%' }}
                                                >
                                                    <Input
                                                        value={
                                                            age[
                                                                age.typeDiscount ===
                                                                'rub'
                                                                    ? 'discountRub'
                                                                    : 'discount'
                                                            ]
                                                        }
                                                        placeholder={
                                                            age.typeDiscount ===
                                                            'rub'
                                                                ? 'Укажите цену с учетом скидки'
                                                                : 'Укажите процент скидки'
                                                        }
                                                        maxLength={
                                                            age.typeDiscount ===
                                                            'rub'
                                                                ? undefined
                                                                : 3
                                                        }
                                                        onKeyDown={event => {
                                                            if (
                                                                !/[0-9]/.test(
                                                                    event.key
                                                                ) &&
                                                                event.key !==
                                                                    'Backspace'
                                                            ) {
                                                                event.preventDefault()
                                                            }
                                                        }}
                                                        onChange={({
                                                            target
                                                        }) => {
                                                            if (
                                                                age.typeDiscount ===
                                                                'rub'
                                                            ) {
                                                                if (
                                                                    parseInt(
                                                                        age.price
                                                                    ) >=
                                                                        parseInt(
                                                                            target.value
                                                                        ) ||
                                                                    target.value ===
                                                                        ''
                                                                ) {
                                                                    handleChangeAge(
                                                                        index,
                                                                        'discountRub',
                                                                        target.value
                                                                    )
                                                                }
                                                            } else {
                                                                if (
                                                                    parseInt(
                                                                        target.value
                                                                    ) <= 100 ||
                                                                    target.value ===
                                                                        ''
                                                                ) {
                                                                    handleChangeAge(
                                                                        index,
                                                                        'discount',
                                                                        target.value
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Select
                                                        value={age.typeDiscount}
                                                        onChange={value =>
                                                            handleChangeAge(
                                                                index,
                                                                'typeDiscount',
                                                                value
                                                            )
                                                        }
                                                        options={[
                                                            {
                                                                label: '%',
                                                                value: 'percent'
                                                            },
                                                            {
                                                                label: '₽',
                                                                value: 'rub'
                                                            }
                                                        ]}
                                                    />
                                                </Space.Compact>
                                            ) : null}
                                            <Button
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() =>
                                                    setOptions(prev => ({
                                                        ...prev,
                                                        ages: prev.ages.filter(
                                                            (_, ageIndex) =>
                                                                ageIndex !==
                                                                index
                                                        )
                                                    }))
                                                }
                                            />
                                            <div style={{ marginLeft: 5 }}>
                                                {age.typeDiscount ===
                                                    'percent' &&
                                                age.discount ? (
                                                    <DiscountDescription
                                                        type={'percent'}
                                                        price={parseInt(
                                                            age.price
                                                        )}
                                                        discount={parseInt(
                                                            age.discount
                                                        )}
                                                    />
                                                ) : null}
                                                {age.typeDiscount === 'rub' &&
                                                age.discountRub ? (
                                                    <DiscountDescription
                                                        type={'rub'}
                                                        price={parseInt(
                                                            age.price
                                                        )}
                                                        discount={parseInt(
                                                            age.discountRub
                                                        )}
                                                    />
                                                ) : null}
                                            </div>
                                        </Space>
                                    </div>
                                ))}
                                <Button
                                    size={'small'}
                                    icon={<PlusOutlined />}
                                    style={{ marginTop: 10 }}
                                    onClick={() =>
                                        setOptions(prev => ({
                                            ...prev,
                                            ages: [
                                                ...prev.ages,
                                                {
                                                    from: '',
                                                    to: '',
                                                    price: '',
                                                    discount: '',
                                                    discountRub: '',
                                                    typeDiscount: 'percent'
                                                }
                                            ]
                                        }))
                                    }
                                >
                                    {'Добавить возраст'}
                                </Button>
                            </>
                        )}
                    </>
                ) : options.show === 'only-percentage' ? (
                    <>
                        <Paragraph style={{ margin: '0' }}>
                            {'Скидка:'}
                        </Paragraph>
                        <Input
                            value={options.percent}
                            placeholder={'Введите скидку'}
                            maxLength={3}
                            suffix={'%'}
                            onKeyDown={event => {
                                if (
                                    !/[0-9]/.test(event.key) &&
                                    event.key !== 'Backspace'
                                ) {
                                    event.preventDefault()
                                }
                            }}
                            onChange={({ target }) =>
                                parseInt(target.value) <= 100 ||
                                target.value === ''
                                    ? handleChangeInput(target.value, 'percent')
                                    : null
                            }
                        />
                    </>
                ) : null}
            </Space>
            <NextStepButton
                disabled={isDisabled || !hasStepChanges}
                loading={isSaving}
                onClick={handleSavePrice}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
