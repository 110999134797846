import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { ICompany } from '../Companies/types.ts'

export const fetch = async (
    search: string
): Promise<AxiosResponse<ICompany[]>> =>
    await get<ICompany[]>('/admin/companies', {
        count: 10,
        'filter[name]': search,
        'filter[cached_displayed]': 1,
        sort: '-created_at',
        append: 'cached_displayed'
    })
