import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    FetchPromoCodePayload,
    FetchPromoCodeFailurePayload,
    FetchPromoCodeSuccessPayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    promoCode: null,
    isFetching: false,
    isFetchingPromoCode: false,
    error: null
}

const promoCodesReportSlice = createSlice({
    name: 'promoCodesReport',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        fetchPromoCode(
            state: InitialStateType,
            _action: PayloadAction<FetchPromoCodePayload>
        ) {
            state.isFetchingPromoCode = true
        },
        fetchPromoCodeSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPromoCodeSuccessPayload>
        ) {
            state.promoCode = action.payload.promoCode
            state.isFetchingPromoCode = false
        },
        fetchPromoCodeFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPromoCodeFailurePayload>
        ) {
            state.isFetchingPromoCode = false
            state.error = action.payload.error
        },
        clearPromoCode(state: InitialStateType) {
            state.promoCode = null
        }
    }
})

export const actions = promoCodesReportSlice.actions

export default promoCodesReportSlice.reducer
