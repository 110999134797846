import type { ReactElement } from 'react'
import EventWizard from '../containers/EventWizard'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppSelector } from '../hooks/useAppSelector'
import Error500 from '../components/Error500'
import { useDispatch } from 'react-redux'
import { actions } from '../containers/EventWizard/slice'
import { LoadingStep } from '../components/LoadingStep'
import { eventWizardSelector } from '../containers/EventWizard/selectors'

export default function EventEditPage(): ReactElement {
    const { event_id } = useParams()

    const dispatch = useDispatch()

    const { currentEvent, isFetchingEvent } =
        useAppSelector(eventWizardSelector)

    useEffect(() => {
        dispatch(actions.destroy())
    }, [])

    useEffect(() => {
        if (event_id) {
            dispatch(actions.fetchEvent({ event_id }))
        }
    }, [event_id])

    if (isFetchingEvent && !currentEvent) {
        return <LoadingStep />
    }

    return currentEvent ? (
        <EventWizard />
    ) : (
        <Error500 description={'Произошла ошибка запроса предложения'} />
    )
}
