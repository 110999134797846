import type { Method, AxiosResponse } from 'axios'
import axios from 'axios'
import FormData from 'form-data'
import { store } from '../store'

type IFileData = {
    field: string
    file: File
}

export const request = async <T>(
    endpoint = '',
    method: Method = 'GET',
    params = {},
    data = {},
    fileData: IFileData[] = [],
    token?: string,
    isOneFile?: boolean,
    settings?: Record<string, unknown>
): Promise<AxiosResponse<T>> => {
    const accessToken = token ?? store.getState().authorization.token

    const formData = new FormData()

    if (fileData.length > 0) {
        for (const file of fileData) {
            formData.append(
                isOneFile ? file.field : `${file.field}[]`,
                file.file
            )
        }
    }

    return await new Promise((resolve, reject) => {
        axios({
            baseURL: process.env.API_URL,
            method,
            headers: {
                Authorization: accessToken ? `Bearer ${accessToken}` : '',
                'Content-Type':
                    fileData.length > 0 ? 'multipart/form-data' : undefined
            },
            url: endpoint,
            params,
            data: ['POST', 'PATCH'].includes(method)
                ? fileData.length > 0
                    ? formData
                    : data
                : null,
            ...settings
        })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const get = async <T>(
    url: string,
    params?: Record<string, unknown>,
    settings?: Record<string, unknown>
): Promise<AxiosResponse<T>> =>
    await request<T>(
        url,
        'GET',
        params,
        undefined,
        undefined,
        undefined,
        undefined,
        settings
    )

export const put = async <T>(
    url: string,
    data: Record<string, unknown>
): Promise<AxiosResponse<T>> => await request<T>(url, 'PUT', data)

export const del = async <T>(
    url: string,
    params?: Record<string, unknown>
): Promise<AxiosResponse<T>> => await request<T>(url, 'DELETE', params)

export const post = async <T>(
    url: string,
    data?: Record<string, unknown>,
    params?: Record<string, unknown>,
    token?: string
): Promise<AxiosResponse<T>> =>
    await request<T>(url, 'POST', params, data, undefined, token)

export const patch = async <T>(
    url: string,
    data: Record<string, unknown>
): Promise<AxiosResponse<T>> => await request<T>(url, 'PATCH', {}, data)

export const upload = async <T>(
    url: string,
    files: IFileData[],
    params?: Record<string, unknown>,
    data?: Record<string, unknown>,
    isOneFile?: boolean
): Promise<AxiosResponse<T>> =>
    await request<T>(url, 'POST', params, data, files, undefined, isOneFile)
