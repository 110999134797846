import type { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { eventWizardSelector } from '../../selectors'
import { Button, Form, Input, Radio, Row, Space } from 'antd'
import RadioDescription from '../../../../components/RadioDescription'
// import { CrownFilled } from '@ant-design/icons'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import isUrl from 'is-url'

// Styles
// import styles from './styles.module.scss'

const values = {
    favorite: {
        selected: true,
        value: undefined
    },
    booking: {
        selected: false,
        value: ''
    },
    pay: {
        selected: false,
        value: ''
    },
    'sign-up': {
        selected: false,
        value: ''
    },
    'promo-code': {
        selected: false,
        value: ''
    },
    website: {
        selected: false,
        value: ''
    }
}

export default function TargetStep(): ReactElement {
    const [targetActions, setTargetActions] = useState<typeof values>(values)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [typeCheck, setTypeCheck] = useState<false | 'success' | 'failure'>(
        false
    )

    // const [api, contextHolder] = notification.useNotification()

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } =
        useAppSelector(eventWizardSelector)

    const selectedTargetAction: keyof typeof targetActions | undefined =
        useMemo(() => {
            for (const item in targetActions) {
                if (
                    targetActions[item as keyof typeof targetActions].selected
                ) {
                    return item as keyof typeof targetActions
                }
            }
        }, [targetActions])

    const isUrlChecked =
        selectedTargetAction &&
        typeof targetActions[selectedTargetAction as keyof typeof targetActions]
            .value == 'string' &&
        selectedTargetAction !== 'promo-code'
            ? isUrl(
                  targetActions[
                      selectedTargetAction as keyof typeof targetActions
                  ].value ?? ''
              )
            : selectedTargetAction === 'promo-code'
              ? // @ts-ignore
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value?.length > 3
              : true

    const isShowOk =
        isConfirmed ||
        targetActions[selectedTargetAction as keyof typeof targetActions]
            ?.value === '' ||
        !hasStepChanges

    useEffect(() => {
        setTypeCheck(false)
    }, [currentEvent])

    useEffect(() => {
        if (currentEvent) {
            let selectedTargetKey, selectedTargetValue

            for (const key in targetActions) {
                if (targetActions[key as keyof typeof targetActions].selected) {
                    selectedTargetKey = key
                    selectedTargetValue =
                        targetActions[key as keyof typeof targetActions].value
                    break
                }
            }

            const hasChange =
                currentEvent.target_action != selectedTargetKey ||
                (selectedTargetKey !== 'favorite' &&
                selectedTargetKey !== 'promo-code'
                    ? currentEvent.target_action_url != selectedTargetValue
                    : selectedTargetKey === 'promo-code'
                      ? currentEvent.event_promo_code_content !=
                        selectedTargetValue
                      : false)

            dispatch(actions.setHasStepChanges(hasChange))
        }
    }, [currentEvent, targetActions])

    // useEffect(() => {
    //     api.info({
    //         message: 'Информация',
    //         description:
    //             'По умолчанию, без добавленного "Премиум статуса" к компании, будет выводиться кнопка "Добавить в избранное"',
    //         placement: 'bottomRight',
    //         duration: 5000
    //     })
    // }, [])

    const handleUndoChanges = () => {
        if (currentEvent) {
            const newValues: typeof targetActions = targetActions
            for (const item in newValues) {
                const isCurrent = currentEvent.target_action === item
                newValues[item as keyof typeof newValues].selected = isCurrent
                if (isCurrent && item !== 'favorite') {
                    newValues[item as keyof typeof newValues].value =
                        item === 'promo-code'
                            ? currentEvent.event_promo_code_content ?? undefined
                            : currentEvent.target_action_url ?? undefined
                }
            }
            setTargetActions({ ...newValues })
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleChangeTargetActions = (value: keyof typeof targetActions) => {
        const newValues = targetActions
        for (const item in targetActions) {
            newValues[item as keyof typeof targetActions].selected =
                value === item
        }
        setTargetActions({ ...newValues })
    }

    const handleChangeValue = (value: string) => {
        const newValues = targetActions
        newValues[selectedTargetAction as keyof typeof newValues].value = value
        setTargetActions({ ...newValues })
    }

    const handleConfirmUrl = () => {
        if (
            targetActions[
                selectedTargetAction as keyof typeof targetActions
            ].value?.startsWith('http')
        ) {
            const isChecked = isUrl(
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value ?? ''
            )
            setTypeCheck(isChecked ? 'success' : 'failure')
            setIsConfirmed(true)
        } else {
            const value = `http://${
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value
            }`
            const isChecked = isUrl(value)
            setTypeCheck(isChecked ? 'success' : 'failure')
            handleChangeValue(value)
            setIsConfirmed(true)
        }
    }

    const handleSaveTarget = () => {
        if (currentEvent) {
            let form = {}

            if (selectedTargetAction === 'promo-code') {
                form = {
                    target_action: 'promo-code',
                    event_promo_code_content:
                        targetActions[
                            selectedTargetAction as keyof typeof targetActions
                        ].value,
                    event_promo_code_is_unlimited: true,
                    event_promo_code_count: 1
                }
            } else {
                form = {
                    target_action: selectedTargetAction,
                    target_action_url:
                        targetActions[
                            selectedTargetAction as keyof typeof targetActions
                        ].value
                }
            }

            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form
                })
            )
        }
    }

    return (
        <>
            <Space direction={'vertical'}>
                <Radio.Group
                    onChange={({ target }) =>
                        handleChangeTargetActions(target.value)
                    }
                    value={selectedTargetAction}
                >
                    <Space direction={'vertical'}>
                        <Radio value={'favorite'}>
                            {'Добавить в избранное'}
                            <RadioDescription>
                                {
                                    'Добавление предложения в список избранного пользователя'
                                }
                            </RadioDescription>
                        </Radio>
                        <Radio value={'booking'}>
                            <Row>
                                {'Забронировать'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {'Переход на сторонний ресурс с бронированием'}
                            </RadioDescription>
                        </Radio>
                        <Radio value={'pay'}>
                            <Row>
                                {'Перейти к оплате'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {'Переход на сторонний ресурс с оплатой'}
                            </RadioDescription>
                        </Radio>
                        <Radio value={'sign-up'}>
                            <Row>
                                {'Записаться'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {
                                    'Переход на сторонний ресурс с возможностью записи'
                                }
                            </RadioDescription>
                        </Radio>
                        <Radio value={'promo-code'}>
                            <Row>
                                {'Получить промокод'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {
                                    'Если хотите предложить скидку за использование выданного промо кода'
                                }
                            </RadioDescription>
                        </Radio>
                        <Radio value={'website'}>
                            <Row>
                                {'Перейти на сайт'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {
                                    'Если проводите вебинар, оформляете бронирование/оплату в стороннем ресурсе'
                                }
                            </RadioDescription>
                        </Radio>
                    </Space>
                </Radio.Group>
                {selectedTargetAction &&
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value != null ? (
                    <Form layout={'vertical'}>
                        <Form.Item
                            style={{ marginTop: 20 }}
                            label={
                                selectedTargetAction === 'promo-code'
                                    ? 'Промокод'
                                    : 'Ссылка на сайт, другой ресурс, мессенджер'
                            }
                        >
                            <Input
                                addonAfter={
                                    selectedTargetAction !== 'promo-code' &&
                                    selectedTargetAction !==
                                        'favorite' ? null : isUrlChecked ? (
                                        <CheckCircleOutlined
                                            style={{ color: 'green' }}
                                        />
                                    ) : (
                                        <CloseCircleOutlined
                                            style={{ color: 'red' }}
                                        />
                                    )
                                }
                                value={
                                    targetActions[selectedTargetAction].value
                                }
                                suffix={
                                    selectedTargetAction !== 'promo-code' &&
                                    selectedTargetAction !== 'favorite' ? (
                                        <Button
                                            style={{
                                                visibility: !isShowOk
                                                    ? 'visible'
                                                    : 'hidden',
                                                transition: '0s'
                                            }}
                                            onClick={handleConfirmUrl}
                                        >
                                            {isShowOk ? '' : 'Ок'}
                                        </Button>
                                    ) : null
                                }
                                placeholder={
                                    selectedTargetAction === 'promo-code'
                                        ? 'Введите промокод'
                                        : 'Ссылка на сайт, другой ресурс, мессенджер'
                                }
                                onChange={({ target }) => {
                                    handleChangeValue(target.value)
                                    setIsConfirmed(false)
                                }}
                            />
                            {typeCheck &&
                            selectedTargetAction !== 'promo-code' ? (
                                typeCheck === 'success' ? (
                                    <div
                                        style={{ fontSize: 14, color: 'green' }}
                                    >
                                        {'Адрес введен корректно'}
                                    </div>
                                ) : (
                                    <div style={{ fontSize: 14, color: 'red' }}>
                                        {'Адрес введен не верно!'}
                                    </div>
                                )
                            ) : null}
                        </Form.Item>
                    </Form>
                ) : null}
            </Space>
            <NextStepButton
                disabled={
                    !selectedTargetAction ||
                    !isUrlChecked ||
                    !hasStepChanges ||
                    (selectedTargetAction === 'promo-code' ||
                    selectedTargetAction === 'favorite'
                        ? false
                        : !isConfirmed)
                }
                loading={isSaving}
                onClick={handleSaveTarget}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            {/*{contextHolder}*/}
        </>
    )
}
