import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    CreateFailurePayload,
    CreatePayload,
    CreateSuccessPayload,
    EditFailurePayload,
    EditPayload,
    EditSuccessPayload,
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    InitialStateType,
    UploadCoverFailurePayload,
    UploadCoverPayload,
    UploadCoverSuccessPayload,
    ClearAdminMessagePayload,
    ClearAdminMessageSuccessPayload,
    ClearAdminMessageFailurePayload
} from './types'

const initialState: InitialStateType = {
    currentPost: null,
    currentCover: null,
    isFetching: false,
    isProcess: false,
    isUploadCover: false,
    isProcessClearAdminMessage: false,
    error: null
}

const blogWizardSlice = createSlice({
    name: 'blogWizard',
    initialState,
    reducers: {
        create(
            state: InitialStateType,
            _action: PayloadAction<CreatePayload>
        ): void {
            state.isProcess = true
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ): void {
            state.isProcess = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ): void {
            state.isProcess = false
            state.error = action.payload.error
        },
        edit(
            state: InitialStateType,
            _action: PayloadAction<EditPayload>
        ): void {
            state.isProcess = true
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ): void {
            state.isProcess = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ): void {
            state.isProcess = false
            state.error = action.payload.error
        },
        fetch(
            state: InitialStateType,
            _action: PayloadAction<FetchPayload>
        ): void {
            state.isFetching = true
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ): void {
            state.currentPost = action.payload.data
            state.currentCover = action.payload.data.image
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ): void {
            state.isFetching = false
            state.error = action.payload.error
        },
        uploadCover(
            state: InitialStateType,
            _action: PayloadAction<UploadCoverPayload>
        ): void {
            state.isUploadCover = true
        },
        uploadCoverSuccess(
            state: InitialStateType,
            action: PayloadAction<UploadCoverSuccessPayload>
        ): void {
            state.isUploadCover = false
            state.currentCover = action.payload.data
        },
        uploadCoverFailure(
            state: InitialStateType,
            action: PayloadAction<UploadCoverFailurePayload>
        ): void {
            state.isUploadCover = false
            state.error = action.payload.error
        },
        clearAdminMessage(
            state: InitialStateType,
            _action: PayloadAction<ClearAdminMessagePayload>
        ): void {
            state.isProcessClearAdminMessage = true
        },
        clearAdminMessageSuccess(
            state: InitialStateType,
            _action: PayloadAction<ClearAdminMessageSuccessPayload>
        ): void {
            state.isProcessClearAdminMessage = false
        },
        clearAdminMessageFailure(
            state: InitialStateType,
            action: PayloadAction<ClearAdminMessageFailurePayload>
        ): void {
            state.isProcessClearAdminMessage = false
            state.error = action.payload.error
        },
        destroy(_state: InitialStateType, _action: PayloadAction<undefined>) {
            return initialState
        }
    }
})

export const actions = blogWizardSlice.actions

export default blogWizardSlice.reducer
