import type { ReactElement } from 'react'
import ruRU from 'antd/locale/ru_RU'
import { ConfigProvider, App } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector'
import { applicationSelector } from '../../containers/Application/selectors'

const transparentBgContainerPaths = [
    '/',
    '/companies',
    '/events',
    '/promocodes',
    '/promocodes/report',
    '/promocodes/agents',
    '/advertising',
    '/advertising/report',
    '/blog'
]

interface Props {
    children: ReactElement[]
}

export default function CustomConfigProvider({
    children
}: Props): ReactElement {
    const { currentPathName } = useAppSelector(applicationSelector)

    return (
        <ConfigProvider
            locale={ruRU}
            theme={{
                token: {
                    colorPrimary: '#ff57a5',
                    // colorText: '#444B69',
                    fontFamily: 'SF Compact Rounded, sans-serif',
                    colorBgContainer: transparentBgContainerPaths.includes(
                        currentPathName
                    )
                        ? 'transparent'
                        : 'rgba(255, 255, 255, 0.5)',
                    colorBgLayout: 'transparent'
                }
            }}
        >
            <App>{children}</App>
        </ConfigProvider>
    )
}
