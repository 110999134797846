import type { CSSProperties, ReactElement } from 'react'
import { Form } from 'antd'
import type { LabelTooltipType } from 'antd/es/form/FormItemLabel'

// Styles
import styles from './styles.module.scss'

interface Props {
    label: string
    name: string
    children: ReactElement
    labelComponent?: ReactElement
    style?: CSSProperties
    required?: boolean
    requiredText?: string
    tooltip?: LabelTooltipType & { onClick: () => void }
    valuePropName?: string
}

export default function FormItemWithLabel({
    label,
    name,
    children,
    labelComponent,
    style,
    required,
    requiredText,
    tooltip,
    valuePropName
}: Props): ReactElement {
    return (
        <Form.Item
            name={name}
            tooltip={tooltip}
            label={
                labelComponent || <span className={styles.label}>{label}</span>
            }
            labelCol={{
                style: { padding: 0, marginBottom: 3 }
            }}
            style={style || { marginBottom: 12 }}
            rules={
                required
                    ? [{ required: true, message: requiredText }]
                    : undefined
            }
            valuePropName={valuePropName}
        >
            {children}
        </Form.Item>
    )
}
