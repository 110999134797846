import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import type {
    FetchPayload,
    IAdvertisingItem,
    IMeta,
    RemovePayload,
    ChangeStatusPayload
} from './types.ts'
import * as effects from 'redux-saga/effects'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import type { PayloadAction } from '@reduxjs/toolkit'
import { advertisingSelector } from './selectors.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        const { data }: { data: { data: IAdvertisingItem[]; meta: IMeta } } =
            yield effects.call(api.fetch, page, pageSize, filter)
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleRemove(
    action: PayloadAction<RemovePayload>
): SagaIterator {
    try {
        yield effects.call(api.remove, action.payload.advertising_id)
        yield put(
            actions.removeSuccess({
                advertising_id: action.payload.advertising_id
            })
        )
        const { filter, meta } = yield effects.select(advertisingSelector)
        yield put(
            actions.fetch({
                page: meta?.currentPage || 1,
                pageSize: meta?.perPage || 10,
                filter
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.removeFailure({
                advertising_id: action.payload.advertising_id,
                error: message
            })
        )
    }
}

export function* watchRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export function* handleChangeStatus(
    action: PayloadAction<ChangeStatusPayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(
            api.changeStatus,
            action.payload.advertisingGroupId
        )
        yield put(
            actions.changeStatusSuccess({
                advertisingGroupId: action.payload.advertisingGroupId,
                status: data.status
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.changeStatusFailure({
                advertisingGroupId: action.payload.advertisingGroupId,
                error: message
            })
        )
    }
}

export function* watchChangeStatus(): SagaIterator {
    yield takeEvery(actions.changeStatus, handleChangeStatus)
}

export default function* watchAdvertising(): SagaIterator {
    yield all([fork(watchFetch), fork(watchRemove), fork(watchChangeStatus)])
}
