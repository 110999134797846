import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    isFetching: false,
    error: null
}

const businessUserSelectorSlice = createSlice({
    name: 'businessUserSelector',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        destroy(
            _state: InitialStateType,
            _action: PayloadAction<undefined>
        ): InitialStateType {
            return initialState
        }
    }
})

export const actions = businessUserSelectorSlice.actions

export default businessUserSelectorSlice.reducer
