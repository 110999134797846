import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IRegion } from './types'

export const fetch = async (
    search: string,
    isUser: boolean
): Promise<AxiosResponse<IRegion[]>> =>
    await get<IRegion[]>(isUser ? '/v2/areas' : '/admin/areas', {
        count: 20,
        'filter[name]': search,
        sort: 'name_length'
    })
