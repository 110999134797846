import type { ReactElement } from 'react'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import { promoCodesSelector } from './selectors'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import type { IPromoCode } from './types'
import {
    Button,
    Pagination,
    Popconfirm,
    Row,
    Table,
    Tooltip,
    Typography
} from 'antd'
import {
    CheckCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import PromoCodesWizardModal from '../../components/PromoCodesWizardModal/PromoCodesWizardModal.tsx'
import {
    checkAdminRole,
    checkFranchiseeRole,
    ignoreKeys
} from '../../utils/helpers.ts'
import { authorizationSelector } from '../Authorization/selectors.ts'

const { Title, Paragraph } = Typography

// Styles
import styles from './styles.module.scss'
import PromoCodesFilter from '../../components/PromoCodesFilter'

export default function PromoCodes(): ReactElement {
    const [selectedPromoCode, setSelectedPromoCode] =
        useState<IPromoCode | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const [selectedPopConfirm, setSelectedPopConfirm] = useState('')

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')
    const creatorQuery = searchParams.get('creator')
    const isActiveQuery = searchParams.get('is_active')
    const codeQuery = searchParams.get('code')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const dispatch = useDispatch()

    const {
        data,
        isFetching,
        meta,
        checkedDeletablePromoCodes,
        isProcessEdit,
        isProcessRemove,
        isProcessCheckDeletable
    } = useAppSelector(promoCodesSelector)

    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = checkAdminRole(profile)
    const isFranchisee = checkFranchiseeRole(profile)

    useEffect(() => {
        if (!isProcessEdit) {
            setSelectedPopConfirm('')
        }
    }, [isProcessEdit])

    const getHistoryParam = () => {
        const historyParams: { [key: string]: string } = {}

        for (const [key, value] of searchParams.entries()) {
            if (ignoreKeys.includes(key)) {
                continue
            }
            historyParams[key] = value
        }

        return historyParams
    }

    useEffect(() => {
        const filter: Record<string, string> = {}

        if (isAdmin && creatorQuery) {
            filter['creator_id'] = creatorQuery.split('{d}')[1]
        }

        filter['is_active'] = isActiveQuery as string
        filter['code'] = codeQuery as string

        dispatch(actions.fetch({ page, pageSize, filter }))

        const historyParams = getHistoryParam()

        setSearchParams({
            ...historyParams,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const historyParams = getHistoryParam()

        setSearchParams({
            ...historyParams,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const columns: ColumnsType<IPromoCode> = [
        {
            title: 'Название',
            dataIndex: 'caption',
            key: 'caption',
            width: 300,
            render: (value: string, data: IPromoCode) => (
                <span
                    className={styles.link}
                    onClick={() => {
                        dispatch(actions.setWizardModal(true))
                        setSelectedPromoCode(data)
                    }}
                >
                    {value}
                </span>
            )
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            width: 200,
            render: (value: string, data: IPromoCode) => (
                <Tooltip
                    placement={'bottom'}
                    title={data.admin_note}
                    overlayInnerStyle={{ whiteSpace: 'pre-line' }}
                >
                    <Paragraph copyable={true}>{value}</Paragraph>
                </Tooltip>
            )
        },
        {
            title: 'Скидка',
            dataIndex: 'discount_percents',
            key: 'discount_percents',
            align: 'center',
            render: (value: string) => `${value}%`
        },
        {
            title: 'Переиспользуемый',
            dataIndex: 'reusable',
            key: 'reusable',
            align: 'center',
            render: (value: string) =>
                value ? (
                    <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                    <span>{'—'}</span>
                )
        },
        {
            title: 'Только первый заказ',
            dataIndex: 'for_first_order',
            key: 'for_first_order',
            align: 'center',
            render: (value: string) =>
                value ? (
                    <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                    <span>{'—'}</span>
                )
        },
        {
            title: 'Активен',
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            render: (value: boolean) =>
                value ? (
                    <Paragraph type={'success'} style={{ margin: '0' }}>
                        {'Да'}
                    </Paragraph>
                ) : (
                    <Paragraph type={'danger'} style={{ margin: '0' }}>
                        {'Нет'}
                    </Paragraph>
                )
        },
        {
            title: 'Годен до',
            dataIndex: 'valid_until',
            key: 'valid_until',
            align: 'center',
            width: 150,
            render: value => (
                <Paragraph
                    style={{ marginBottom: 0 }}
                    type={!value ? 'success' : undefined}
                >
                    {value
                        ? dayjs(value).format('DD.MM.YYYY HH:mm')
                        : 'Бессрочный'}
                </Paragraph>
            )
        },
        {
            width: 100,
            key: 'buttons',
            render: (_: string, data: IPromoCode) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        style={{ marginRight: 10 }}
                        icon={<EditOutlined />}
                        onClick={() => {
                            dispatch(actions.setWizardModal(true))
                            setSelectedPromoCode(data)
                        }}
                    />
                    <Popconfirm
                        open={
                            typeof checkedDeletablePromoCodes[
                                data.promocode_id
                            ] === 'boolean' &&
                            selectedPopConfirm === data.promocode_id
                        }
                        placement={'left'}
                        title={
                            checkedDeletablePromoCodes[data.promocode_id]
                                ? 'Вы уверены, что хотите удалить?'
                                : 'Снять промокод с публикации?'
                        }
                        onConfirm={() => {
                            if (checkedDeletablePromoCodes[data.promocode_id]) {
                                dispatch(actions.remove(data.promocode_id))
                            } else {
                                dispatch(
                                    actions.edit({
                                        promoCodeId: data.promocode_id,
                                        data: {
                                            caption: data.caption,
                                            code: data.code,
                                            discount_percents:
                                                data.discount_percents,
                                            reusable: data.reusable,
                                            for_first_order:
                                                data.for_first_order,
                                            is_active: false
                                        }
                                    })
                                )
                            }
                        }}
                        okText={'Да'}
                        cancelText={'Нет'}
                        onCancel={() => setSelectedPopConfirm('')}
                        okButtonProps={{
                            loading: isProcessEdit || isProcessRemove
                        }}
                    >
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            loading={
                                selectedPopConfirm === data.promocode_id &&
                                isProcessCheckDeletable
                            }
                            onClick={() => {
                                setSelectedPopConfirm(data.promocode_id)
                                dispatch(
                                    actions.checkDeletable(data.promocode_id)
                                )
                            }}
                        />
                    </Popconfirm>
                </div>
            )
        }
    ]

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => dispatch(actions.setWizardModal(true))}
                    type={'primary'}
                >
                    {'Создать промокод'}
                </Button>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Title
                    level={3}
                    style={{ margin: isAdmin ? '0 0 25px' : '0px' }}
                >
                    {'Мои промокоды'}
                </Title>
                <PromoCodesFilter isAdmin={isAdmin} />
            </div>
            <div className={styles.container}>
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey={'promocode_id'}
                    loading={isFetching}
                    pagination={false}
                    sticky={{ offsetHeader: 0 }}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
            </div>
            <PromoCodesWizardModal
                selectedPromoCode={selectedPromoCode}
                onClose={() => setSelectedPromoCode(null)}
                isAdmin={isAdmin}
                isFranchisee={isFranchisee}
            />
        </>
    )
}
