import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { FetchPayload, RemovePayload, IMeta, IPost } from './types.ts'
import * as effects from 'redux-saga/effects'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import { blogSelector } from './selectors.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        const { data }: { data: { data: IPost[]; meta: IMeta } } =
            yield effects.call(api.fetch, page, pageSize, filter)
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleRemove(
    action: PayloadAction<RemovePayload>
): SagaIterator {
    try {
        yield effects.call(api.remove, action.payload.post_id)
        yield put(
            actions.removeSuccess({
                post_id: action.payload.post_id
            })
        )
        const { filter, meta } = yield effects.select(blogSelector)
        yield put(
            actions.fetch({
                page: meta?.currentPage || 1,
                pageSize: meta?.perPage || 10,
                filter
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.removeFailure({
                post_id: action.payload.post_id,
                error: message
            })
        )
    }
}

export function* watchRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export default function* watchBlog(): SagaIterator {
    yield all([fork(watchFetch), fork(watchRemove)])
}
