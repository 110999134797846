import type { AxiosResponse } from 'axios'
import { get, post } from '../../utils/api.ts'
import type { IPaymentForm } from './types.ts'

export const fetch = (
    page: number,
    page_size: number
): Promise<AxiosResponse<void>> =>
    get<void>('/admin/user/balance-transactions', {
        page,
        page_size,
        sort: 'datetime'
    })

export const fetchPaymentUrl = (
    amount: number
): Promise<AxiosResponse<IPaymentForm>> =>
    post<IPaymentForm>('/admin/user/balance/top-up', {
        amount
    })
