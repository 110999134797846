import type { ReactElement } from 'react'
import { useEffect } from 'react'
import {
    Button,
    Divider,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Table,
    Typography
} from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { CoverageWizardModal } from '../../components/CoverageWizardModal'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsCoverageSelector } from './selectors.ts'
import { useSearchParams } from 'react-router-dom'
import { ignoreKeys } from '../../utils/helpers.ts'
import type { ColumnsType } from 'antd/es/table'
import type { ICoverageItem } from './types.ts'

const { Title } = Typography

const columns = (
    onEditCoverage: (
        type: 'edit' | 'remove',
        advertising_coverage_id: string
    ) => void
): ColumnsType<ICoverageItem> => [
    {
        title: 'Регион / Город',
        dataIndex: '',
        key: 'area',
        render: (data: ICoverageItem) => data.area.name
    },
    {
        title: 'Количество пользователей',
        dataIndex: 'count',
        key: 'count',
        align: 'center',
        render: data => data.toLocaleString('ru')
    },
    {
        title: 'Количество районов',
        dataIndex: 'districts_count',
        key: 'districts_count',
        align: 'center',
        render: data => data.toLocaleString('ru')
    },
    {
        title: 'Размер района (км)',
        dataIndex: 'district_size',
        key: 'district_size',
        align: 'center',
        render: data => data.toLocaleString('ru')
    },
    {
        title: '',
        dataIndex: '',
        key: 'nav',
        width: 150,
        render: (data: ICoverageItem) => (
            <Space size={'middle'}>
                <Button
                    onClick={() =>
                        onEditCoverage('edit', data.advertising_coverage_id)
                    }
                    type={'primary'}
                    icon={<EditOutlined />}
                />
                <Popconfirm
                    title={'Удаление охвата'}
                    description={'Вы уверены, что хотите удалить охват?'}
                    onConfirm={() =>
                        onEditCoverage('remove', data.advertising_coverage_id)
                    }
                    okText={'Да'}
                    cancelText={'Нет'}
                >
                    <Button
                        type={'primary'}
                        danger={true}
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </Space>
        )
    }
]

export default function SettingsCoverage(): ReactElement {
    const [searchParams, setSearchParams] = useSearchParams()
    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const dispatch = useDispatch()

    const { data, meta, isFetching } = useAppSelector(settingsCoverageSelector)

    useEffect(() => {
        dispatch(actions.fetch({ page, pageSize }))
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    const onEditCoverage = (
        type: 'edit' | 'remove',
        advertising_coverage_id: string
    ) => {
        if (type === 'edit') {
            const findCoverageItem = data.find(
                item => item.advertising_coverage_id === advertising_coverage_id
            )
            if (findCoverageItem) {
                dispatch(actions.setCoverageItem(findCoverageItem))
            }
        } else {
            dispatch(actions.remove({ advertising_coverage_id }))
        }
    }

    return (
        <>
            <Row style={{ marginBottom: 20 }} justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Настройка охватов'}
                </Title>
                <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    onClick={() =>
                        dispatch(actions.toggleModalWizardOpen(true))
                    }
                >
                    {'Добавить настройку охватов'}
                </Button>
            </Row>
            <Divider style={{ background: '#ff57a5' }} />
            <Table
                dataSource={data}
                columns={columns(onEditCoverage)}
                rowKey={'advertising_coverage_id'}
                loading={isFetching}
                pagination={false}
                sticky={{ offsetHeader: 0 }}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={page}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            defaultPageSize={pageSize}
                            total={meta?.total}
                            onChange={handleChangePagination}
                        />
                    </div>
                )}
            />
            <CoverageWizardModal />
        </>
    )
}
