import { Button, Result } from 'antd'
import type { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface Props {
    description?: string
}

export default function Error500({ description }: Props): ReactElement {
    const navigate = useNavigate()

    return (
        <Result
            status={'500'}
            title={'500'}
            subTitle={description ?? 'Произошла ошибка'}
            extra={
                <Button
                    onClick={() => navigate(-1)}
                    type={'primary'}
                    icon={<ArrowLeftOutlined />}
                >
                    {'Вернуться назад'}
                </Button>
            }
        />
    )
}
