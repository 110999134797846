import type { ReactElement } from 'react'
import { useEffect } from 'react'
import AdvertisingWizard from '../containers/AdvertisingWizard'
import { useParams } from 'react-router-dom'
import { actions } from '../containers/AdvertisingWizard/slice.ts'
import { useDispatch } from 'react-redux'
import { LoadingStep } from '../components/LoadingStep'
import { useAppSelector } from '../hooks/useAppSelector.ts'
import { advertisingWizardSelector } from '../containers/AdvertisingWizard/selectors.ts'
import Error404 from '../components/Error404'

export default function AdvertisingEditPage(): ReactElement {
    const { advertising_group_id } = useParams()

    const dispatch = useDispatch()

    const { currentAdvertisingCompany, isFetching } = useAppSelector(
        advertisingWizardSelector
    )

    useEffect(() => {
        dispatch(actions.destroy())
    }, [])

    useEffect(() => {
        if (advertising_group_id) {
            dispatch(
                actions.fetch({ advertisingGroupId: advertising_group_id })
            )
        }
    }, [advertising_group_id])

    if (!currentAdvertisingCompany && isFetching) {
        return <LoadingStep />
    }

    if (!currentAdvertisingCompany) {
        return <Error404 />
    }

    return <AdvertisingWizard />
}
