import type { ReactElement } from 'react'
import { Button, Divider, Radio, Row, Space, Typography } from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import RadioDescription from '../../../../components/RadioDescription'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { useEffect } from 'react'

// Styles
import styles from './styles.module.scss'
import { eventWizardSelector } from '../../selectors'

const { Paragraph } = Typography

export default function ViewStep(): ReactElement {
    const dispatch = useDispatch()

    const { step, currentEvent, hasStepChanges, preCreateData, isSaving } =
        useAppSelector(eventWizardSelector)

    useEffect(() => {
        if (currentEvent) {
            const badge =
                currentEvent.badge === 'default'
                    ? undefined
                    : currentEvent.badge
            const hasChanges =
                currentEvent.publication_type !==
                    preCreateData.publication_type ||
                badge !== preCreateData.badge
            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentEvent, preCreateData])

    const handleUndoChanges = () => {
        if (currentEvent) {
            dispatch(
                actions.setParam({
                    publication_type: currentEvent.publication_type,
                    badge:
                        currentEvent.badge === 'default'
                            ? undefined
                            : currentEvent.badge
                })
            )
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleSaveView = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        publication_type: preCreateData.publication_type,
                        badge: preCreateData.badge ?? 'default'
                    }
                })
            )
        } else {
            dispatch(actions.setStep(step + 1))
        }
    }

    return (
        <>
            <Paragraph type={'success'}>{'Тип публикации'}</Paragraph>
            <Radio.Group
                value={preCreateData.publication_type}
                onChange={event =>
                    dispatch(
                        actions.setParam({
                            publication_type: event.target.value
                        })
                    )
                }
            >
                <Space direction={'vertical'}>
                    <Radio value={'poster'}>
                        {'Афиша'}
                        <RadioDescription>
                            {
                                'Мероприятие или предложение доступное к просмотру или посещению на выбранную дату или период'
                            }
                        </RadioDescription>
                    </Radio>
                    <Radio value={'offer'}>
                        {'Предложение'}
                        <RadioDescription>
                            {
                                'Услуга, занятие, промо предложение или направление периодическое или не ограниченное по времени'
                            }
                        </RadioDescription>
                    </Radio>
                </Space>
            </Radio.Group>
            <Divider style={{ margin: '20px 0' }} />
            <Row>
                <Paragraph type={'success'}>
                    {'Вид промо (опционально)'}
                </Paragraph>
                {preCreateData.badge ? (
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                            dispatch(
                                actions.setParam({
                                    badge: undefined
                                })
                            )
                        }
                        size={'small'}
                    >
                        {'Убрать'}
                    </Button>
                ) : null}
            </Row>
            <Radio.Group
                value={preCreateData.badge}
                onChange={event =>
                    dispatch(
                        actions.setParam({
                            badge: event.target.value
                        })
                    )
                }
            >
                <Space direction={'vertical'}>
                    <Radio value={'offer'}>
                        <Row>
                            {'Предложение'}
                            <div
                                className={styles.badge}
                                style={{ background: '#f85979' }}
                            >
                                {'Акция'}
                            </div>
                        </Row>
                        <RadioDescription>
                            {'Акционное предложение или подарок'}
                        </RadioDescription>
                    </Radio>
                    <Radio value={'discount'}>
                        <Row>
                            {'Предложение'}
                            <div
                                className={styles.badge}
                                style={{ background: '#ff9d05' }}
                            >
                                {'Скидка'}
                            </div>
                        </Row>
                        <RadioDescription>
                            {'Цена предложения со скидкой'}
                        </RadioDescription>
                    </Radio>
                    <Radio value={'free'}>
                        <Row>
                            {'Предложение'}
                            <div
                                className={styles.badge}
                                style={{ background: '#9673f2' }}
                            >
                                {'Бесплатно'}
                            </div>
                        </Row>
                        <RadioDescription>
                            {'Промо посещение, бесплатный вход и участие'}
                        </RadioDescription>
                    </Radio>
                </Space>
            </Radio.Group>
            <NextStepButton
                loading={isSaving}
                disabled={currentEvent ? !hasStepChanges : false}
                onClick={handleSaveView}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {currentEvent ? 'Сохранить' : 'Выбрать и продолжить'}
            </NextStepButton>
        </>
    )
}
