import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, takeEvery, put } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import { actions } from './slice'
import { actions as applicationActions } from '../Application/slice.ts'
import type {
    GetPaymentSumPayload,
    LogInPayload,
    RegisterPayload,
    SendForgotPayload,
    SendNewPasswordPayload
} from './types'
import * as api from './api'
import {
    formatApiError,
    getRole,
    sagaNotificationError
} from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'

export function* handleLogIn(
    action: PayloadAction<LogInPayload>
): SagaIterator {
    try {
        const { username, password } = action.payload
        const { data } = yield call(api.logIn, username, password)

        if (!data.data.business) {
            yield call(api.registerBusiness, username, data.data.jwt)
        }

        yield put(actions.logInSuccess(data))
    } catch (error) {
        const { code, message } = formatApiError(error)
        if (code === 401) {
            yield call(
                sagaNotificationError,
                'По введенным данным пользователь не найден. Измените данные или перейдите к регистрации.',
                error,
                true,
                action.payload.btn
            )
        } else {
            yield call(sagaNotificationError, message, error, true)
        }

        yield put(
            actions.logInFailure({
                error: message
            })
        )

        if (code !== 422) {
            Sentry.captureException(error)
        }
    }
}

export function* watchLogin(): SagaIterator {
    yield takeEvery(actions.logIn, handleLogIn)
}

export function* handleLogOut(): SagaIterator {
    try {
        yield call(api.logOut)
        yield put(actions.logOutSuccess())
    } catch (error) {
        const { message, code } = formatApiError(error)

        if (code === 401) {
            yield put(actions.logOutSuccess())
        }

        if (code !== 401) {
            yield call(sagaNotificationError, message, error)
            yield put(
                actions.logOutFailure({
                    error: message
                })
            )
        }
    }
}

export function* watchLogOut(): SagaIterator {
    yield takeEvery(actions.logOut, handleLogOut)
}

export function* handleFetchProfile(): SagaIterator {
    try {
        const { data } = yield call(api.fetchProfile)
        yield put(actions.fetchProfileSuccess({ data }))

        if (data?.roles && getRole(data.roles) !== 'user') {
            yield put(applicationActions.fetchCompanyProperties())
            yield put(applicationActions.fetchPlans())
        }

        yield put(actions.getPaymentSum())
    } catch (error) {
        const { message, code } = formatApiError(error)

        if (code === 401) {
            try {
                yield call(api.refreshToken)
                yield put(actions.fetchProfile())
            } catch {
                yield put(actions.logOutSuccess())
            }
        }

        if (code !== 401) {
            yield call(sagaNotificationError, message, error)
            yield put(
                actions.fetchProfileFailure({
                    error: message
                })
            )
        }
    }
}

export function* watchFetchProfile(): SagaIterator {
    yield takeEvery(actions.fetchProfile, handleFetchProfile)
}

export function* handleRegister(
    action: PayloadAction<RegisterPayload>
): SagaIterator {
    try {
        const { email, phone, password, address } = action.payload
        const { data } = yield call(api.register, email, password)
        yield call(api.registerBusiness, email, data.data.jwt, phone)
        if (address) {
            yield call(api.setAddressProfile, address, data.data.jwt)
        }
        yield put(actions.registerSuccess(data))
    } catch (error) {
        const { code, message } = formatApiError(error)
        yield call(sagaNotificationError, message, error, true)
        yield put(
            actions.registerFailure({
                error: message
            })
        )

        if (code !== 422) {
            Sentry.captureException(error)
        }
    }
}

export function* watchRegister(): SagaIterator {
    yield takeEvery(actions.register, handleRegister)
}

export function* handleSendForgot(
    action: PayloadAction<SendForgotPayload>
): SagaIterator {
    try {
        yield call(api.sendForgot, action.payload.email)
        yield put(actions.sendForgotSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error, true)
        yield put(
            actions.sendForgotFailure({
                error: message
            })
        )
    }
}

export function* watchSendForgot(): SagaIterator {
    yield takeEvery(actions.sendForgot, handleSendForgot)
}

export function* handleSendNewPassword(
    action: PayloadAction<SendNewPasswordPayload>
): SagaIterator {
    try {
        const { email, token, password } = action.payload
        yield call(api.sendNewPassword, email, token, password)
        yield put(actions.sendNewPasswordSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error, true)
        yield put(
            actions.sendNewPasswordFailure({
                error: message
            })
        )
    }
}

export function* watchSendNewPassword(): SagaIterator {
    yield takeEvery(actions.sendNewPassword, handleSendNewPassword)
}

export function* handleGetPaymentSum(
    _action: PayloadAction<GetPaymentSumPayload>
): SagaIterator {
    try {
        const { data } = yield call(api.getPaymentSum)
        yield put(actions.getPaymentSumSuccess({ amount: data.data.amount }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error, true)
        yield put(
            actions.getPaymentSumFailure({
                error: message
            })
        )
    }
}

export function* watchGetPaymentSum(): SagaIterator {
    yield takeEvery(actions.getPaymentSum, handleGetPaymentSum)
}

export default function* watchAuthorization(): SagaIterator {
    yield all([
        fork(watchLogin),
        fork(watchLogOut),
        fork(watchFetchProfile),
        fork(watchRegister),
        fork(watchSendForgot),
        fork(watchSendNewPassword),
        fork(watchGetPaymentSum)
    ])
}
