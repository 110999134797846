import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import { contentPageSelector } from './selectors'
import { LoadingStep } from '../../components/LoadingStep'
import Markdown from 'react-markdown'
import { Button, Modal, Space } from 'antd'
import { Link } from 'react-router-dom'
import Error500 from '../../components/Error500'
import ReactPlayer from 'react-player'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import BannersBlock from '../BannersBlock'
import GalleryModal from '../../components/GalleryModal'

// Styles
import styles from './styles.module.scss'
import { CloseOutlined, ExportOutlined } from '@ant-design/icons'

interface Props {
    code: string
}

export default function ContentPage({ code }: Props): ReactElement {
    const [initialize, setInitialize] = useState(false)
    const [videoUrl, setVideoUrl] = useState<boolean | string>(false)
    const [blocks, setBlocks] = useState<string[]>([])
    const [isGalleryModal, setIsGalleryModal] = useState<false | number>(false)

    const dispatch = useDispatch()

    const { pages, pagesIsFetching } = useAppSelector(contentPageSelector)

    const currentPage = pages[code]

    useEffect(() => {
        if (code) {
            dispatch(actions.fetchPage({ code }))
            setInitialize(true)
        }
    }, [code])

    useEffect(() => {
        if (currentPage) {
            const result: string[] = []
            const { content } = currentPage
            const matchBlocks = [
                ...content.matchAll(/@\[bannerBlock]\((.*)\)/g)
            ]
            matchBlocks.forEach(item => result.push(item[1]))
            setBlocks(result)
        }
    }, [currentPage])

    if (!initialize || pagesIsFetching.includes(code)) {
        return <LoadingStep />
    }

    if (!currentPage) {
        return <Error500 />
    }

    return (
        <>
            <div className={styles.row}>
                <h1 className={styles.title}>{currentPage.caption}</h1>
                <Space>
                    {currentPage.target_action === 'url' &&
                    currentPage.target_content ? (
                        currentPage.target_content?.startsWith(
                            location.origin
                        ) ? (
                            <Link to={currentPage.target_content}>
                                <Button
                                    icon={<ExportOutlined />}
                                    type={'primary'}
                                >
                                    {currentPage.target_caption}
                                </Button>
                            </Link>
                        ) : (
                            <a
                                href={currentPage.target_content}
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                <Button
                                    type={'primary'}
                                    icon={<ExportOutlined />}
                                >
                                    {currentPage.target_caption}
                                </Button>
                            </a>
                        )
                    ) : null}
                    <Link to={'/'}>
                        <Button icon={<CloseOutlined />}>{'Закрыть'}</Button>
                    </Link>
                </Space>
            </div>
            <div className={styles.content}>
                <div
                    style={
                        currentPage.videos.length === 0
                            ? { flex: '0 0 100%', maxWidth: '100%' }
                            : {}
                    }
                    className={styles.content__item}
                >
                    <div className={styles.content__content}>
                        <Markdown>
                            {currentPage.content.replace(
                                /@\[bannerBlock]\(.*\)/g,
                                ''
                            )}
                        </Markdown>
                    </div>
                </div>
                {currentPage.videos.length > 0 ? (
                    <div className={styles.content__item}>
                        <div
                            onClick={() =>
                                setVideoUrl(currentPage.videos[0].url)
                            }
                            className={styles.content__video}
                        />
                    </div>
                ) : null}
            </div>
            {currentPage.gallery.length > 0 ? (
                <Swiper
                    modules={[Pagination, Navigation]}
                    style={{ maxWidth: 950, marginBottom: 40 }}
                    pagination={{
                        enabled: true,
                        clickable: true,
                        dynamicBullets: true
                    }}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={3}
                >
                    {currentPage.gallery.map((item, index) => (
                        <SwiperSlide className={styles.slide} key={item.id}>
                            <img
                                onClick={() => setIsGalleryModal(index)}
                                src={item.url}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : null}
            {blocks.map(block => (
                <BannersBlock
                    key={block}
                    code={block}
                    isMinBanners={true}
                    style={{ marginBottom: 30 }}
                />
            ))}
            <GalleryModal
                isVisible={isGalleryModal}
                onClose={() => setIsGalleryModal(false)}
                images={currentPage.gallery.map(image => ({ url: image.url }))}
            />
            <Modal
                open={!!videoUrl}
                title={'Просмотр видео'}
                width={'100%'}
                style={{ top: 20 }}
                cancelText={'Закрыть'}
                destroyOnClose={true}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => setVideoUrl(false)}
            >
                {typeof videoUrl === 'string' ? (
                    <ReactPlayer
                        width={'100%'}
                        height={'75vh'}
                        url={videoUrl}
                        controls={true}
                    />
                ) : null}
            </Modal>
        </>
    )
}
