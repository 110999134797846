import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { ICompany } from '../Companies/types'

export const fetch = async (
    search: string
): Promise<AxiosResponse<ICompany[]>> => {
    return await get<ICompany[]>('/admin/companies', {
        count: 10,
        'filter[name]': search
    })
}
