import type { CSSProperties, ReactElement } from 'react'
import { theme } from 'antd'

interface Props {
    children: ReactElement
    style?: CSSProperties
}

export default function CustomContainer({
    children,
    style
}: Props): ReactElement {
    const {
        token: { colorBgContainer }
    } = theme.useToken()

    return (
        <div
            style={{
                padding: 24,
                minHeight: '100%',
                borderRadius: 30,
                background: colorBgContainer,
                ...style
            }}
        >
            {children}
        </div>
    )
}
