import type { ReactElement } from 'react'
import { Select } from 'antd'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import { companyPropertySelector } from './selectors'

interface Props {
    selectedValue?: string
    propertyId: string
    placeholder: string
    onChange: ({
        company_property_option_id,
        value
    }: {
        company_property_option_id: string
        value: string
    }) => void
    loading?: boolean
}

export default function CompanyPropertySelector({
    selectedValue,
    propertyId,
    placeholder,
    onChange,
    loading
}: Props): ReactElement {
    const dispatch = useDispatch()

    const { properties } = useAppSelector(companyPropertySelector)

    useEffect(() => {
        dispatch(actions.fetch({ propertyId }))
    }, [])

    const currentProperty = properties[propertyId]

    return (
        <Select
            allowClear
            defaultValue={selectedValue}
            options={
                currentProperty
                    ? [
                          { label: 'Не указано', value: '' },
                          ...currentProperty.data.map(item => ({
                              label: item.caption,
                              value: item.value
                          }))
                      ]
                    : []
            }
            filterOption={false}
            loading={!!currentProperty?.isFetching || loading}
            placeholder={placeholder}
            onChange={value =>
                onChange({
                    company_property_option_id:
                        currentProperty?.data.find(item => item.value === value)
                            ?.company_property_option_id ?? '',
                    value
                })
            }
        />
    )
}
