import type { FetchPayload, ChangeMultiplePublishedPayload } from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload

        const { data } = yield call(api.fetch, page, pageSize, filter)

        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleChangeMultiplePublished(
    action: PayloadAction<ChangeMultiplePublishedPayload>
): SagaIterator {
    try {
        const { event_ids, published, page, pageSize, filter } = action.payload
        yield call(api.changeMultiplePublished, event_ids, published)
        yield put(actions.changeMultiplePublishedSuccess())

        yield put(
            actions.fetch({
                page,
                pageSize,
                filter
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.changeMultiplePublishedFailure({
                error: message
            })
        )
    }
}

export function* watchChangeMultiplePublished(): SagaIterator {
    yield takeEvery(
        actions.changeMultiplePublished,
        handleChangeMultiplePublished
    )
}

export default function* watchEvents(): SagaIterator {
    yield all([fork(watchFetch), fork(watchChangeMultiplePublished)])
}
