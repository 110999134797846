import type { ReactElement } from 'react'
import { Row, Spin } from 'antd'
import type { SpinSize } from 'antd/es/spin'

interface Props {
    size?: SpinSize
}

export function LoadingStep({ size }: Props): ReactElement {
    return (
        <Row style={{ margin: '40px 0' }} justify={'center'}>
            <Spin size={size} />
        </Row>
    )
}
