import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import type { ReactElement } from 'react'
import { useState } from 'react'

// Styles
import styles from './styles.module.scss'

interface Props {
    companyId?: string
    show: boolean
}

export default function CompanyPaymentBanner({
    companyId,
    show
}: Props): ReactElement {
    const [isVisible, setVisible] = useState(true)

    const navigate = useNavigate()

    return (
        <div
            className={styles.outer}
            style={
                show && isVisible ? { opacity: 1, visibility: 'visible' } : {}
            }
        >
            <button
                className={styles.close}
                onClick={() => setVisible(false)}
            />
            <div className={styles.title}>
                {'Компания доступна к публикации'}
            </div>
            <div className={styles.description}>
                {'Для активации перейдите к оплате статуса «Премиум».'}
                <br />
                {'После оплаты компания будет опубликована автоматически.'}
            </div>
            {companyId ? (
                <Button
                    onClick={() =>
                        navigate(`/companies/${companyId}?tab=premium`)
                    }
                    type={'primary'}
                >
                    {'Перейти к оплате'}
                </Button>
            ) : null}
        </div>
    )
}
