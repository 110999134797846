import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchCompanyPayload,
    FetchCompanySuccessPayload,
    FetchCompanyFailurePayload,
    SaveCompanyFailurePayload,
    SaveCompanyPayload,
    SaveCompanySuccessPayload,
    FetchAnalyticsPayload,
    FetchAnalyticsSuccessPayload,
    FetchAnalyticsFailurePayload,
    FetchPlanSubscriptionsPayload,
    FetchPlanSubscriptionsSuccessPayload,
    FetchPlanSubscriptionsFailurePayload,
    CreatePropertyCompanyPayload,
    CreatePropertyCompanySuccessPayload,
    CreatePropertyCompanyFailurePayload,
    ChangeCompanyUserFailurePayload,
    ChangeCompanyUserPayload,
    ChangeCompanyUserSuccessPayload,
    ChangeCompanyFranchiseePayload,
    ChangeCompanyFranchiseeSuccessPayload,
    ChangeCompanyFranchiseeFailurePayload,
    ChangePropertyCompanyFailurePayload,
    ChangePropertyCompanyPayload,
    ChangePropertyCompanySuccessPayload,
    ClearPropertyCompanyFailurePayload,
    ClearPropertyCompanyPayload,
    ClearPropertyCompanySuccessPayload,
    FetchPaymentHistoryPayload,
    FetchPaymentHistorySuccessPayload,
    FetchPaymentHistoryFailurePayload,
    DeletePlanSubscriptionsPayload,
    DeletePlanSubscriptionsSuccessPayload,
    DeletePlanSubscriptionsFailurePayload,
    AddPlanSubscribeFailurePayload,
    AddPlanSubscribePayload,
    AddPlanSubscribeSuccessPayload,
    FetchInvitationFailurePayload,
    FetchInvitationPayload,
    FetchInvitationSuccessPayload,
    FetchPlansFailurePayload,
    FetchPlansPayload,
    FetchPlansSuccessPayload,
    GetPaymentUrlPayload,
    GetPaymentUrlSuccessPayload,
    GetPaymentUrlFailurePayload,
    SendPromoCodeFailurePayload,
    SendPromoCodePayload,
    SendPromoCodeSuccessPayload,
    CreateInvitationPayload,
    CreateInvitationSuccessPayload,
    CreateInvitationFailurePayload,
    UploadLogoPayload,
    UploadLogoSuccessPayload,
    UploadLogoFailurePayload,
    DeleteLogoPayload,
    DeleteLogoSuccessPayload,
    DeleteLogoFailurePayload,
    FetchCompanyEventsPayload,
    FetchCompanyEventsSuccessPayload,
    FetchCompanyEventsFailurePayload,
    RemoveOwnerPayload,
    RemoveOwnerSuccessPayload,
    RemoveOwnerFailurePayload,
    UploadMediaImagePayload,
    UploadMediaImageSuccessPayload,
    UploadMediaImageFailurePayload,
    AddMediaVideoPayload,
    IClearAdminMessagePayload,
    IClearAdminMessageSuccessPayload,
    IClearAdminMessageFailurePayload,
    PaymentFromBalancePayload,
    PaymentFromBalanceSuccessPayload,
    PaymentFromBalanceFailurePayload
} from './types'

const initialState: InitialStateType = {
    invitation: null,
    general: {
        kf_drinking_water: false,
        kf_recreation_area: false,
        kf_wc: false,
        checked_by_mamado: false,
        on_mamado_title: null,
        on_mamado_content: null,
        on_mamado_video: null,
        manager_status: null,
        admin_message: null
    },
    generalMedia: [],
    tempLandingData: null,
    analytics: [],
    isFetchingAnalytics: false,
    plans: [],
    companyEvents: [],
    isFetchingCompanyEvents: false,
    companyEventsMeta: null,
    isFetchingPlans: true,
    promoCode: null,
    isModalPromoCode: false,
    isSendPromoCode: false,
    isProcessPaymentUrl: false,
    paymentUrl: null,
    planSubscriptions: {
        data: [],
        links: null,
        meta: null
    },
    isFetchingPlanSubscriptions: false,
    currentCompany: null,
    isFetchingCompany: false,
    sendChangeCompanyProperties: [],
    isModalChangeOwner: false,
    isModalChangeFranchisee: false,
    isProcessChangeOwner: false,
    isProcessChangeFranchisee: false,
    isModalAddPlanSubscribe: false,
    removePlanSubscriptions: [],
    isProcessAddPlanSubscribe: false,
    isProcessInvitation: false,
    isProcessRemoveOwner: false,
    isClearAdminMessage: false,
    isProcessLogo: false,
    isUploadingMediaImage: false,
    paymentHistory: {
        data: [],
        links: null,
        meta: null
    },
    isFetchingPaymentHistory: false,
    isProcessPaymentFromBalance: false,
    isSaving: false,
    error: null
}

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setGeneralParam(
            state: InitialStateType,
            action: PayloadAction<{
                key: keyof typeof state.general
                value: boolean | string
            }>
        ) {
            // @ts-ignore
            state.general[action.payload.key] = action.payload.value
        },
        fetchCompany(
            state: InitialStateType,
            _action: PayloadAction<FetchCompanyPayload>
        ) {
            state.isFetchingCompany = true
            state.error = null
        },
        fetchCompanySuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCompanySuccessPayload>
        ) {
            for (const item in action.payload.data) {
                if (item in state.general) {
                    if (
                        item === 'on_mamado_title' &&
                        !action.payload.data[
                            item as keyof typeof action.payload.data
                        ]
                    ) {
                        state.general[item] = 'Почему детям у нас нравится?'
                        continue
                    }
                    // @ts-ignore
                    state.general[item] =
                        action.payload.data[
                            item as keyof typeof action.payload.data
                        ]
                }
            }
            state.currentCompany = action.payload.data
            if (action.payload.data?.landing) {
                const parseLanding = JSON.parse(action.payload.data.landing)
                state.tempLandingData = parseLanding
                if (parseLanding?.choice_mamado_media) {
                    state.generalMedia = parseLanding.choice_mamado_media
                }
            }
            state.isFetchingCompany = false
        },
        fetchCompanyFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCompanyFailurePayload>
        ) {
            state.isFetchingCompany = false
            state.error = action.payload.error
        },
        fetchCompanyEvents(
            state: InitialStateType,
            _action: PayloadAction<FetchCompanyEventsPayload>
        ) {
            state.isFetchingCompanyEvents = true
            state.error = null
        },
        fetchCompanyEventsSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCompanyEventsSuccessPayload>
        ) {
            state.companyEvents = action.payload.data
            state.companyEventsMeta = action.payload.meta
            state.isFetchingCompanyEvents = false
        },
        fetchCompanyEventsFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCompanyEventsFailurePayload>
        ) {
            state.isFetchingCompanyEvents = false
            state.error = action.payload.error
        },
        saveCompany(
            state: InitialStateType,
            _action: PayloadAction<SaveCompanyPayload>
        ) {
            state.isSaving = true
            state.error = null
        },
        saveCompanySuccess(
            state: InitialStateType,
            _action: PayloadAction<SaveCompanySuccessPayload>
        ) {
            state.isSaving = false
        },
        saveCompanyFailure(
            state: InitialStateType,
            action: PayloadAction<SaveCompanyFailurePayload>
        ) {
            state.isSaving = false
            state.error = action.payload.error
        },
        fetchAnalytics(
            state: InitialStateType,
            _action: PayloadAction<FetchAnalyticsPayload>
        ): void {
            state.isFetchingAnalytics = true
            state.error = null
        },
        fetchAnalyticsSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchAnalyticsSuccessPayload>
        ): void {
            state.analytics = action.payload.data
            state.isFetchingAnalytics = false
        },
        fetchAnalyticsFailure(
            state: InitialStateType,
            action: PayloadAction<FetchAnalyticsFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isFetchingAnalytics = false
        },
        fetchPlanSubscriptions(
            state: InitialStateType,
            _action: PayloadAction<FetchPlanSubscriptionsPayload>
        ): void {
            state.isFetchingPlanSubscriptions = true
            state.error = null
        },
        fetchPlanSubscriptionsSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPlanSubscriptionsSuccessPayload>
        ): void {
            state.planSubscriptions = action.payload.data
            state.isFetchingPlanSubscriptions = false
        },
        fetchPlanSubscriptionsFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPlanSubscriptionsFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isFetchingPlanSubscriptions = false
        },
        deletePlanSubscriptions(
            state: InitialStateType,
            action: PayloadAction<DeletePlanSubscriptionsPayload>
        ): void {
            state.removePlanSubscriptions.push(
                action.payload.plan_subscription_id
            )
            state.error = null
        },
        deletePlanSubscriptionsSuccess(
            state: InitialStateType,
            action: PayloadAction<DeletePlanSubscriptionsSuccessPayload>
        ): void {
            state.removePlanSubscriptions =
                state.removePlanSubscriptions.filter(
                    id => id !== action.payload.plan_subscription_id
                )
        },
        deletePlanSubscriptionsFailure(
            state: InitialStateType,
            action: PayloadAction<DeletePlanSubscriptionsFailurePayload>
        ): void {
            state.removePlanSubscriptions =
                state.removePlanSubscriptions.filter(
                    id => id !== action.payload.plan_subscription_id
                )
            state.error = action.payload.error
        },
        createCompanyProperty(
            state: InitialStateType,
            action: PayloadAction<CreatePropertyCompanyPayload>
        ) {
            state.sendChangeCompanyProperties.push(
                action.payload.company_property_id
            )
            state.error = null
        },
        createCompanyPropertySuccess(
            state: InitialStateType,
            action: PayloadAction<CreatePropertyCompanySuccessPayload>
        ) {
            state.sendChangeCompanyProperties =
                state.sendChangeCompanyProperties.filter(
                    property_id =>
                        property_id !== action.payload.company_property_id
                )

            if (state.currentCompany) {
                if (
                    Array.isArray(
                        state.currentCompany.companyPropertyOptionValues
                    )
                ) {
                    state.currentCompany.companyPropertyOptionValues.push(
                        action.payload.data
                    )
                } else {
                    state.currentCompany.companyPropertyOptionValues = [
                        action.payload.data
                    ]
                }
            }
        },
        createCompanyPropertyFailure(
            state: InitialStateType,
            action: PayloadAction<CreatePropertyCompanyFailurePayload>
        ) {
            state.sendChangeCompanyProperties =
                state.sendChangeCompanyProperties.filter(
                    property_id =>
                        property_id !== action.payload.company_property_id
                )
            state.error = action.payload.error
        },
        changeCompanyProperty(
            state: InitialStateType,
            action: PayloadAction<ChangePropertyCompanyPayload>
        ) {
            state.sendChangeCompanyProperties.push(
                action.payload.company_property_id
            )
            state.error = null
        },
        changeCompanyPropertySuccess(
            state: InitialStateType,
            action: PayloadAction<ChangePropertyCompanySuccessPayload>
        ) {
            state.sendChangeCompanyProperties =
                state.sendChangeCompanyProperties.filter(
                    property_id =>
                        property_id !== action.payload.company_property_id
                )

            if (
                state.currentCompany &&
                Array.isArray(state.currentCompany.companyPropertyOptionValues)
            ) {
                state.currentCompany.companyPropertyOptionValues =
                    state.currentCompany.companyPropertyOptionValues.map(
                        option => {
                            if (
                                option.company_property_id ===
                                action.payload.data.company_property_id
                            ) {
                                return action.payload.data
                            }
                            return option
                        }
                    )
            }
        },
        changeCompanyPropertyFailure(
            state: InitialStateType,
            action: PayloadAction<ChangePropertyCompanyFailurePayload>
        ) {
            state.sendChangeCompanyProperties =
                state.sendChangeCompanyProperties.filter(
                    property_id =>
                        property_id !== action.payload.company_property_id
                )
            state.error = action.payload.error
        },
        clearCompanyProperty(
            state: InitialStateType,
            action: PayloadAction<ClearPropertyCompanyPayload>
        ) {
            state.sendChangeCompanyProperties.push(
                action.payload.company_property_id
            )
            state.error = null
        },
        clearCompanyPropertySuccess(
            state: InitialStateType,
            action: PayloadAction<ClearPropertyCompanySuccessPayload>
        ) {
            state.sendChangeCompanyProperties =
                state.sendChangeCompanyProperties.filter(
                    property_id =>
                        property_id !== action.payload.company_property_id
                )

            if (
                state.currentCompany &&
                Array.isArray(state.currentCompany.companyPropertyOptionValues)
            ) {
                state.currentCompany.companyPropertyOptionValues =
                    state.currentCompany.companyPropertyOptionValues.filter(
                        option =>
                            option.company_property_id !==
                            action.payload.company_property_id
                    )
            }
        },
        clearCompanyPropertyFailure(
            state: InitialStateType,
            action: PayloadAction<ClearPropertyCompanyFailurePayload>
        ) {
            state.sendChangeCompanyProperties =
                state.sendChangeCompanyProperties.filter(
                    property_id =>
                        property_id !== action.payload.company_property_id
                )
            state.error = action.payload.error
        },
        toggleModalChangeOwner(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalChangeOwner = action.payload
        },
        toggleModalChangeFranchisee(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalChangeFranchisee = action.payload
        },
        changeCompanyOwner(
            state: InitialStateType,
            _action: PayloadAction<ChangeCompanyUserPayload>
        ) {
            state.isProcessChangeOwner = true
            state.error = null
        },
        changeCompanyOwnerSuccess(
            state: InitialStateType,
            _action: PayloadAction<ChangeCompanyUserSuccessPayload>
        ) {
            state.isProcessChangeOwner = false
            state.isModalChangeOwner = false
        },
        changeCompanyOwnerFailure(
            state: InitialStateType,
            action: PayloadAction<ChangeCompanyUserFailurePayload>
        ) {
            state.isProcessChangeOwner = false
            state.isModalChangeOwner = false
            state.error = action.payload.error
        },
        changeCompanyFranchisee(
            state: InitialStateType,
            _action: PayloadAction<ChangeCompanyFranchiseePayload>
        ) {
            state.isProcessChangeFranchisee = true
            state.error = null
        },
        changeCompanyFranchiseeSuccess(
            state: InitialStateType,
            _action: PayloadAction<ChangeCompanyFranchiseeSuccessPayload>
        ) {
            state.isProcessChangeFranchisee = false
            state.isModalChangeFranchisee = false
        },
        changeCompanyFranchiseeFailure(
            state: InitialStateType,
            action: PayloadAction<ChangeCompanyFranchiseeFailurePayload>
        ) {
            state.isProcessChangeFranchisee = false
            state.isModalChangeFranchisee = false
            state.error = action.payload.error
        },
        toggleModalAddPlanSubscribe(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalAddPlanSubscribe = action.payload
        },
        addPlanSubscribe(
            state: InitialStateType,
            _action: PayloadAction<AddPlanSubscribePayload>
        ) {
            state.isProcessAddPlanSubscribe = true
            state.error = null
        },
        addPlanSubscribeSuccess(
            state: InitialStateType,
            _action: PayloadAction<AddPlanSubscribeSuccessPayload>
        ) {
            state.isProcessAddPlanSubscribe = false
            state.isModalAddPlanSubscribe = false
        },
        addPlanSubscribeFailure(
            state: InitialStateType,
            action: PayloadAction<AddPlanSubscribeFailurePayload>
        ) {
            state.isProcessAddPlanSubscribe = false
            state.isModalAddPlanSubscribe = false
            state.error = action.payload.error
        },
        fetchPaymentHistory(
            state: InitialStateType,
            _action: PayloadAction<FetchPaymentHistoryPayload>
        ): void {
            state.isFetchingPaymentHistory = true
            state.error = null
        },
        fetchPaymentHistorySuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPaymentHistorySuccessPayload>
        ): void {
            state.paymentHistory = action.payload.data
            state.isFetchingPaymentHistory = false
        },
        fetchPaymentHistoryFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPaymentHistoryFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isFetchingPaymentHistory = false
        },
        fetchInvitation(
            state: InitialStateType,
            _action: PayloadAction<FetchInvitationPayload>
        ): void {
            state.isProcessInvitation = true
            state.error = null
        },
        fetchInvitationSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchInvitationSuccessPayload>
        ): void {
            state.invitation = action.payload.data
            state.isProcessInvitation = false
        },
        fetchInvitationFailure(
            state: InitialStateType,
            action: PayloadAction<FetchInvitationFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isProcessInvitation = false
        },
        createInvitation(
            state: InitialStateType,
            _action: PayloadAction<CreateInvitationPayload>
        ): void {
            state.isProcessInvitation = true
            state.error = null
        },
        createInvitationSuccess(
            state: InitialStateType,
            action: PayloadAction<CreateInvitationSuccessPayload>
        ): void {
            state.invitation = action.payload.data
            state.isProcessInvitation = false
        },
        createInvitationFailure(
            state: InitialStateType,
            action: PayloadAction<CreateInvitationFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isProcessInvitation = false
        },
        fetchPlans(
            state: InitialStateType,
            _action: PayloadAction<FetchPlansPayload>
        ): void {
            state.isFetchingPlans = true
            state.error = null
        },
        fetchPlansSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchPlansSuccessPayload>
        ): void {
            state.plans = action.payload.data
            state.isFetchingPlans = false
        },
        fetchPlansFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPlansFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isFetchingPlans = false
        },
        setModalPromoCode(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ): void {
            state.isModalPromoCode = action.payload
        },
        sendPromoCode(
            state: InitialStateType,
            _action: PayloadAction<SendPromoCodePayload>
        ): void {
            state.isSendPromoCode = true
            state.error = null
        },
        sendPromoCodeSuccess(
            state: InitialStateType,
            action: PayloadAction<SendPromoCodeSuccessPayload>
        ): void {
            state.promoCode = action.payload.data
            state.isSendPromoCode = false
            state.isModalPromoCode = false
        },
        sendPromoCodeFailure(
            state: InitialStateType,
            action: PayloadAction<SendPromoCodeFailurePayload>
        ): void {
            state.error = action.payload.error
            state.isSendPromoCode = false
        },
        getPaymentUrl(
            state: InitialStateType,
            _action: PayloadAction<GetPaymentUrlPayload>
        ): void {
            state.isProcessPaymentUrl = true
            state.error = null
        },
        getPaymentUrlSuccess(
            state: InitialStateType,
            action: PayloadAction<GetPaymentUrlSuccessPayload>
        ): void {
            state.isProcessPaymentUrl = false
            state.paymentUrl = action.payload.payment_url
        },
        getPaymentUrlFailure(
            state: InitialStateType,
            action: PayloadAction<GetPaymentUrlFailurePayload>
        ): void {
            state.isProcessPaymentUrl = false
            state.error = action.payload.error
        },
        uploadLogo(
            state: InitialStateType,
            _action: PayloadAction<UploadLogoPayload>
        ): void {
            state.isProcessLogo = true
            state.error = null
        },
        uploadLogoSuccess(
            state: InitialStateType,
            _action: PayloadAction<UploadLogoSuccessPayload>
        ): void {
            state.isProcessLogo = false
        },
        uploadLogoFailure(
            state: InitialStateType,
            action: PayloadAction<UploadLogoFailurePayload>
        ): void {
            state.isProcessLogo = false
            state.error = action.payload.error
        },
        deleteLogo(
            state: InitialStateType,
            _action: PayloadAction<DeleteLogoPayload>
        ): void {
            state.isProcessLogo = true
            state.error = null
        },
        deleteLogoSuccess(
            state: InitialStateType,
            _action: PayloadAction<DeleteLogoSuccessPayload>
        ): void {
            state.isProcessLogo = false
        },
        deleteLogoFailure(
            state: InitialStateType,
            action: PayloadAction<DeleteLogoFailurePayload>
        ): void {
            state.isProcessLogo = false
            state.error = action.payload.error
        },
        clearPromoCode(
            state: InitialStateType,
            _action: PayloadAction<undefined>
        ) {
            state.promoCode = null
        },
        removeOwner(
            state: InitialStateType,
            _action: PayloadAction<RemoveOwnerPayload>
        ) {
            state.isProcessRemoveOwner = true
        },
        removeOwnerSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveOwnerSuccessPayload>
        ) {
            state.isProcessRemoveOwner = false
        },
        removeOwnerFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveOwnerFailurePayload>
        ) {
            state.isProcessRemoveOwner = false
            state.error = action.payload.error
        },
        uploadMediaImage(
            state: InitialStateType,
            _action: PayloadAction<UploadMediaImagePayload>
        ) {
            state.isUploadingMediaImage = true
        },
        uploadMediaImageSuccess(
            state: InitialStateType,
            action: PayloadAction<UploadMediaImageSuccessPayload>
        ) {
            state.generalMedia.push({
                url: action.payload.data.url
            })
            state.isUploadingMediaImage = false
        },
        uploadMediaImageFailure(
            state: InitialStateType,
            action: PayloadAction<UploadMediaImageFailurePayload>
        ) {
            state.isUploadingMediaImage = false
            state.error = action.payload.error
        },
        addMediaVideo(
            state: InitialStateType,
            action: PayloadAction<AddMediaVideoPayload>
        ) {
            state.generalMedia.push({ url: action.payload.url })
        },
        removeVideo(state: InitialStateType, action: PayloadAction<number>) {
            state.generalMedia = state.generalMedia.filter(
                (_, index) => index !== action.payload
            )
        },
        clearAdminMessage(
            state: InitialStateType,
            _action: PayloadAction<IClearAdminMessagePayload>
        ): void {
            state.isClearAdminMessage = true
        },
        clearAdminMessageSuccess(
            state: InitialStateType,
            _action: PayloadAction<IClearAdminMessageSuccessPayload>
        ): void {
            state.isClearAdminMessage = false
        },
        clearAdminMessageFailure(
            state: InitialStateType,
            action: PayloadAction<IClearAdminMessageFailurePayload>
        ): void {
            state.isClearAdminMessage = false
            state.error = action.payload.error
        },
        paymentFromBalance(
            state: InitialStateType,
            _action: PayloadAction<PaymentFromBalancePayload>
        ): void {
            state.isProcessPaymentFromBalance = true
        },
        paymentFromBalanceSuccess(
            state: InitialStateType,
            _action: PayloadAction<PaymentFromBalanceSuccessPayload>
        ): void {
            state.isProcessPaymentFromBalance = false
        },
        paymentFromBalanceFailure(
            state: InitialStateType,
            action: PayloadAction<PaymentFromBalanceFailurePayload>
        ): void {
            state.isProcessPaymentFromBalance = false
            state.error = action.payload.error
        },
        destroy(_state: InitialStateType, _action: PayloadAction<undefined>) {
            return initialState
        }
    }
})

export const actions = companySlice.actions

export default companySlice.reducer
