import type { ReactElement } from 'react'
import EventWizard from '../containers/EventWizard'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../containers/EventWizard/slice'

export default function EventCreatePage(): ReactElement {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.destroy())
    }, [])

    return <EventWizard isCreate />
}
