import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { TreeSelect } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { companyCategoriesSelector } from './selectors.ts'
import type { ICategory } from './types.ts'

const { SHOW_PARENT } = TreeSelect

interface Props {
    initialValue?: string
    onChange?: (value: string) => void
    placeholder?: string
    isSingleMode?: boolean
}

interface ITreeDataItem {
    label: string
    value: string
    children?: ITreeDataItem[]
    selectable?: boolean
}

export default function CompanyCategoriesSelector({
    initialValue,
    onChange,
    placeholder,
    isSingleMode
}: Props): ReactElement {
    const { data, isFetching } = useAppSelector(companyCategoriesSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    const mapTreeData = (item: ICategory, level: number = 1): ITreeDataItem => {
        const result: ITreeDataItem = {
            label: item.name,
            value: item.company_category_id,
            selectable: isSingleMode ? level > 1 : true
        }
        if (Array.isArray(item.children) && (isSingleMode ? level < 2 : true)) {
            result.children = item.children.map(child =>
                mapTreeData(child, level + 1)
            )
        }
        return result
    }

    return (
        <TreeSelect
            value={
                initialValue
                    ? data.length > 0
                        ? initialValue
                        : undefined
                    : undefined
            }
            onChange={onChange}
            style={{ width: '100%' }}
            placeholder={placeholder || 'Категория'}
            treeCheckable={!isSingleMode}
            showCheckedStrategy={isSingleMode ? undefined : SHOW_PARENT}
            allowClear={true}
            disabled={isFetching}
            loading={isFetching}
            treeData={data.map(item => mapTreeData(item))}
        />
    )
}
