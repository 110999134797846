import type { ReactElement } from 'react'
import { Col, Form, Input, Row, Typography } from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { actions } from '../../slice'
import { useEffect } from 'react'
import { eventWizardSelector } from '../../selectors'

const { TextArea } = Input
const { Paragraph } = Typography

export default function DescriptionStep(): ReactElement {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } =
        useAppSelector(eventWizardSelector)

    const descriptionValue = Form.useWatch('description', form)

    useEffect(() => {
        if (currentEvent) {
            dispatch(
                actions.setHasStepChanges(
                    (currentEvent.about ?? '') !== descriptionValue
                )
            )
        }
    }, [currentEvent, descriptionValue])

    const handleUndoChanges = () => {
        if (currentEvent && currentEvent.about) {
            form.setFieldValue('description', currentEvent.about)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleSaveDescription = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: { about: form.getFieldValue('description') }
                })
            )
        }
    }

    const count = descriptionValue?.length ?? 0

    const isCorrect = count > 100 && count <= 4000

    return (
        <>
            <Row gutter={10} style={{ marginBottom: -25 }}>
                <Col span={24}>
                    <Row justify={'end'}>
                        <Paragraph style={{ marginRight: 5 }}>
                            {'Кол-во символов:'}
                        </Paragraph>
                        <Paragraph
                            type={isCorrect ? 'success' : 'danger'}
                        >{`${count}/4000`}</Paragraph>
                    </Row>
                    <Form
                        form={form}
                        initialValues={{
                            description: ''
                        }}
                    >
                        <Form.Item name={'description'}>
                            <TextArea
                                autoFocus
                                style={{ minHeight: 500, resize: 'none' }}
                                placeholder={'Введите описание'}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <NextStepButton
                disabled={!hasStepChanges || !isCorrect}
                loading={isSaving}
                onClick={handleSaveDescription}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
