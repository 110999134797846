import {
    Col,
    Divider,
    Form,
    Row,
    Space,
    Switch,
    Typography,
    notification,
    Button
} from 'antd'
import { IMaskInput, IMask } from 'react-imask'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ru.js'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { companyWizardSelector } from '../../selectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { regExpTime, weekdaysTemplate } from '../../../../utils/helpers.ts'

const { Paragraph } = Typography

dayjs.locale('ru')

dayjs.extend(localeData)
dayjs.extend(customParseFormat)

export default function ScheduleStep() {
    const [isAroundClock, setIsAroundClock] = useState(false)
    const [weekdays, setWeekdays] = useState(weekdaysTemplate)

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const dispatch = useDispatch()

    const [api, contextHolder] = notification.useNotification()

    const isCorrectlyTime = weekdays.every(item =>
        item.time[0] === '' && item.time[1] === ''
            ? true
            : regExpTime.test(item.time[0]) && regExpTime.test(item.time[1])
    )

    const handleUndoChanges = () => {
        if (currentCompany && currentCompany.companyTimetables) {
            setIsAroundClock(currentCompany.works_around_the_clock)
            setWeekdays(prev =>
                prev.map((week, index) => {
                    const findTime = currentCompany.companyTimetables.find(
                        table => table.dayOfWeek === index
                    )

                    if (findTime) {
                        return {
                            ...week,
                            isOpen: findTime.isOpen,
                            time: [
                                findTime.openTime.substring(0, 5),
                                findTime.closeTime.substring(0, 5)
                            ]
                        }
                    }

                    return week
                })
            )
        }
    }

    const allDaysFilledTime = () => {
        const findTime = weekdays.find(
            item =>
                regExpTime.test(item.time[0]) && regExpTime.test(item.time[1])
        )
        if (findTime) {
            setWeekdays(prev =>
                prev.map(week => ({ ...week, time: findTime.time }))
            )
        }
    }

    const openNotificationFullFilled = () => {
        const key = `open${Date.now()}`
        const btn = (
            <Space>
                <Button
                    type={'link'}
                    size={'small'}
                    onClick={() => api.destroy()}
                >
                    {'Не нужно'}
                </Button>
                <Button
                    type={'primary'}
                    size={'small'}
                    onClick={() => {
                        api.destroy(key)
                        allDaysFilledTime()
                    }}
                >
                    {'Заполнить'}
                </Button>
            </Space>
        )
        api.open({
            type: 'info',
            placement: 'bottomRight',
            duration: 15000,
            message: 'Внимание!',
            description: 'Заполнить все дни введённым временем?',
            btn,
            key
        })
    }

    useEffect(() => {
        const isOneDayFilled =
            weekdays.filter(
                item =>
                    regExpTime.test(item.time[0]) &&
                    regExpTime.test(item.time[1])
            ).length === 1 &&
            weekdays.filter(item => item.time[0] === '' && item.time[1] === '')
                .length === 6
        if (isOneDayFilled) {
            openNotificationFullFilled()
        }
    }, [weekdays])

    useEffect(() => {
        if (currentCompany) {
            const hasChangesAroundClock =
                currentCompany.works_around_the_clock !== isAroundClock

            let hasChangesWeekdays

            if (currentCompany.companyTimetables.length > 0) {
                hasChangesWeekdays = currentCompany.companyTimetables.some(
                    item => {
                        const day = item.dayOfWeek
                        if (weekdays[day].isOpen !== item.isOpen) {
                            return true
                        }
                        return Array.isArray(weekdays[day].time)
                            ? weekdays[day].time![0] !==
                                  item.openTime.substring(0, 5) ||
                                  weekdays[day].time![1] !==
                                      item.closeTime.substring(0, 5)
                            : true
                    }
                )
            } else {
                hasChangesWeekdays =
                    JSON.stringify(weekdays) !==
                    JSON.stringify(weekdaysTemplate)
            }

            dispatch(
                actions.setHasStepChanges(
                    hasChangesAroundClock || hasChangesWeekdays
                )
            )
        }
    }, [currentCompany, isAroundClock, weekdays])

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleSendSchedule = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        timetables: weekdays.map(week => {
                            if (week.time[0] && week.time[1]) {
                                return [
                                    regExpTime.test(week.time[0])
                                        ? `${week.time[0]}:00`
                                        : week.time[0],
                                    regExpTime.test(week.time[1])
                                        ? `${week.time[1]}:00`
                                        : week.time[1],
                                    week.isOpen
                                ]
                            }
                            return ['00:00:00', '00:00:00', week.isOpen]
                        }),
                        works_around_the_clock: isAroundClock
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    return (
        <>
            {weekdays.map((week, index) => {
                const refSecondInput = useRef<HTMLInputElement | null>(null)
                return (
                    <Row
                        gutter={[16, 16]}
                        key={week.name}
                        style={{ marginBottom: 15 }}
                        align={'middle'}
                    >
                        <Col
                            style={
                                isAroundClock || !week.isOpen
                                    ? { opacity: 0.4, transition: '0.2s' }
                                    : { transition: '0.2s' }
                            }
                            span={3}
                        >
                            {week.name}
                        </Col>
                        <Col span={6}>
                            <Space>
                                <IMaskInput
                                    mask={Date}
                                    disabled={isAroundClock || !week.isOpen}
                                    pattern={'HH:mm'}
                                    blocks={{
                                        HH: {
                                            mask: IMask.MaskedRange,
                                            from: 0,
                                            to: 23
                                        },
                                        mm: {
                                            mask: IMask.MaskedRange,
                                            from: 0,
                                            to: 59
                                        }
                                    }}
                                    format={(date: string) =>
                                        dayjs(date).format('HH:mm')
                                    }
                                    parse={(str: string) => dayjs(str, 'HH:mm')}
                                    value={week.time[0]}
                                    placeholder={'Начало'}
                                    className={'custom-input'}
                                    onAccept={value => {
                                        if (regExpTime.test(value)) {
                                            refSecondInput.current?.focus()
                                        }
                                        setWeekdays(prev =>
                                            prev.map((prevItem, prevIndex) => {
                                                if (prevIndex === index) {
                                                    return {
                                                        ...prevItem,
                                                        time: [
                                                            value,
                                                            prevItem.time[1]
                                                        ]
                                                    }
                                                }
                                                return prevItem
                                            })
                                        )
                                    }}
                                />
                                <IMaskInput
                                    mask={Date}
                                    pattern={'HH:mm'}
                                    disabled={isAroundClock || !week.isOpen}
                                    inputRef={refSecondInput}
                                    blocks={{
                                        HH: {
                                            mask: IMask.MaskedRange,
                                            from: 0,
                                            to: 23
                                        },
                                        mm: {
                                            mask: IMask.MaskedRange,
                                            from: 0,
                                            to: 59
                                        }
                                    }}
                                    className={'custom-input'}
                                    format={(date: string) =>
                                        dayjs(date).format('HH:mm')
                                    }
                                    parse={(str: string) => dayjs(str, 'HH:mm')}
                                    value={week.time[1]}
                                    placeholder={'Конец'}
                                    onAccept={value =>
                                        setWeekdays(prev =>
                                            prev.map((prevItem, prevIndex) => {
                                                if (prevIndex === index) {
                                                    return {
                                                        ...prevItem,
                                                        time: [
                                                            prevItem.time[0],
                                                            value
                                                        ]
                                                    }
                                                }
                                                return prevItem
                                            })
                                        )
                                    }
                                />
                            </Space>
                        </Col>
                        <Switch
                            defaultChecked={week.isOpen}
                            checked={week.isOpen}
                            disabled={isAroundClock}
                            onChange={isOpen =>
                                setWeekdays(prev =>
                                    prev.map((prevItem, prevIndex) => {
                                        if (prevIndex === index) {
                                            return {
                                                ...prevItem,
                                                isOpen
                                            }
                                        }
                                        return prevItem
                                    })
                                )
                            }
                        />
                        <Paragraph
                            style={{
                                margin: 0,
                                fontSize: 12,
                                marginLeft: 10,
                                cursor: 'default'
                            }}
                            type={week.isOpen ? 'success' : 'secondary'}
                        >
                            {week.isOpen ? 'Рабочий день' : 'Выходной'}
                        </Paragraph>
                    </Row>
                )
            })}
            <Divider style={{ background: '#bdbcbc' }} />
            <Form.Item label={'Круглосуточно'}>
                <Switch
                    checked={isAroundClock}
                    defaultChecked={isAroundClock}
                    onClick={setIsAroundClock}
                />
            </Form.Item>
            <NextStepButton
                loading={isSaving}
                disabled={!hasStepChanges || !isCorrectlyTime}
                onClick={handleSendSchedule}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            {contextHolder}
        </>
    )
}
