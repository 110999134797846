import type { ChangeEvent, ReactElement, ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Tree } from 'antd'
import type { DataNode } from 'antd/es/tree'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useEffect, useMemo, useState } from 'react'
import { actions } from '../../slice'
import { LoadingStep } from '../../../../components/LoadingStep'
import NextStepButton from '../../../../components/NextStepButton'
import { DownOutlined } from '@ant-design/icons'
import type { Key } from 'antd/es/table/interface'
import type { ICategory } from '../../types'
import { companyWizardSelector } from '../../selectors'
import { useDebouncedCallback } from 'use-debounce'
import SelectedTreeParent from '../../../../components/SelectedTreeParent'
import {
    getTreeParentKey,
    hasTreeChildrenChecked
} from '../../../../utils/helpers'

interface ITreeItem {
    title: string
    key: string
    icon?: ReactElement
    children?: ITreeItem[]
}

export default function CategoriesStep(): ReactElement {
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
    const [selectedCategories, setSelectedCategories] = useState<Key[]>([])
    const [searchValue, setSearchValue] = useState<string>('')

    const dispatch = useDispatch()

    const {
        currentCompany,
        hasStepChanges,
        categories,
        isFetchingCategories,
        isSaving
    } = useAppSelector(companyWizardSelector)

    const isDisabled = isFetchingCategories || isSaving

    const defaultData = useMemo(() => {
        const result: DataNode[] = []
        const loop = (item: DataNode[]) => {
            item.forEach(i => {
                result.push({ title: i.title, key: i.key })
                if (i.children) {
                    loop(i.children)
                }
            })
        }
        loop(treeData)
        return result
    }, [treeData])

    const findCollection = useMemo(() => {
        const loop = (data: DataNode[]): DataNode[] =>
            data.map(item => {
                const strTitle = item.title as string
                const strTitleLower = (item.title as string).toLowerCase()
                const index = strTitleLower.indexOf(searchValue.toLowerCase())
                const beforeStr = strTitle.substring(0, index)
                const afterStr = strTitle.slice(index + searchValue.length)
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className={'site-tree-search-value'}>
                                {strTitle.substring(
                                    index,
                                    index + searchValue.length
                                )}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{strTitle}</span>
                    )
                if (item.children) {
                    return {
                        title,
                        icon: item.icon,
                        key: item.key,
                        children: loop(item.children)
                    }
                }

                return {
                    title,
                    icon: item.icon,
                    key: item.key
                }
            })

        return loop(treeData)
    }, [treeData, searchValue])

    useEffect(() => {
        if (currentCompany) {
            const companyCategories = currentCompany.companyCategories.map(
                item => item.companyCategoryId
            )

            const hasChanges =
                companyCategories.length !== selectedCategories.length ||
                companyCategories.some(
                    category_id => !selectedCategories.includes(category_id)
                )

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentCompany, selectedCategories])

    const handleUndoChanges = () => {
        if (currentCompany) {
            setSelectedCategories(
                currentCompany.companyCategories.map(
                    category => category.companyCategoryId
                )
            )
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    useEffect(() => {
        const deepMap = (category: ICategory): ITreeItem => {
            return {
                title: category.name,
                key: category.companyCategoryId,
                icon: category.icoUrl ? (
                    <img height={12} src={category.icoUrl} />
                ) : undefined,
                children: category.children
                    ? category.children.map(deepMap)
                    : undefined
            }
        }

        setTreeData(categories.map(deepMap))
    }, [categories])

    useEffect(() => {
        if (currentCompany && categories.length === 0) {
            dispatch(actions.fetchCategories({ type: currentCompany.type }))
        }
    }, [])

    const handleSaveEvent = (isTrusted: boolean): void => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: { categories: selectedCategories },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const expandToggle = (selectedKey: Key) => {
        setExpandedKeys(prev =>
            prev.includes(selectedKey)
                ? prev.filter(key => key !== selectedKey)
                : [...prev, selectedKey]
        )
    }

    const debounced = useDebouncedCallback(value => {
        const additional: Key[] = []
        const newExpandedKeys = [
            ...defaultData.map(item => {
                if (
                    (item.title as string)
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                ) {
                    const parent = getTreeParentKey(item.key, treeData)
                    additional.push(getTreeParentKey(parent, treeData))
                    return parent
                }
                return null
            }),
            ...additional
        ].filter(
            (item, i, self): item is Key =>
                !!(item && self.indexOf(item) === i) ||
                treeData.some(data => data.key === item)
        )
        setExpandedKeys(value ? newExpandedKeys : [])
    }, 500)

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSearchValue(value)
        debounced(value)
    }

    return (
        <>
            {isFetchingCategories ? (
                <LoadingStep />
            ) : (
                findCollection.length > 0 && (
                    <>
                        <Input
                            value={searchValue}
                            onChange={onChange}
                            placeholder={'Найти категорию'}
                            style={{ marginBottom: 15 }}
                            allowClear={true}
                        />
                        <Tree
                            expandedKeys={expandedKeys}
                            showIcon
                            checkable
                            checkedKeys={selectedCategories}
                            switcherIcon={<DownOutlined />}
                            treeData={findCollection.map(item => ({
                                ...item,
                                title: hasTreeChildrenChecked(
                                    item,
                                    selectedCategories
                                ) ? (
                                    <SelectedTreeParent>
                                        {item.title as ReactNode}
                                    </SelectedTreeParent>
                                ) : (
                                    item.title
                                ),
                                checkable: false
                            }))}
                            onCheck={(value, { node }) => {
                                if (Array.isArray(value)) {
                                    setSelectedCategories(value)
                                    if (!node.expanded) {
                                        expandToggle(node.key)
                                    }
                                }
                            }}
                            style={{ background: '#FFF' }}
                            onSelect={(_, { node }) => {
                                if (typeof node.checkable !== 'boolean') {
                                    if (
                                        node.children &&
                                        !selectedCategories.includes(node.key)
                                    ) {
                                        setSelectedCategories(prev => [
                                            ...prev,
                                            node.key
                                        ])
                                    }
                                }
                                if (!expandedKeys.includes(node.key)) {
                                    expandToggle(node.key)
                                }
                            }}
                            onExpand={(_, { node }) => expandToggle(node.key)}
                        />
                    </>
                )
            )}
            <NextStepButton
                disabled={
                    isDisabled ||
                    selectedCategories.length === 0 ||
                    !hasStepChanges
                }
                loading={isDisabled}
                onClick={handleSaveEvent}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
