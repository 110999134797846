import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { Button, Row, Typography } from 'antd'
import AreaSelector from '../../../AreaSelector'
import NextStepButton from '../../../../components/NextStepButton'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { eventWizardSelector } from '../../selectors'

const { Paragraph } = Typography

interface IArea {
    area_id: string
    title: string
}

export default function RegionStep(): ReactElement {
    const [selectedAreas, setSelectedAreas] = useState<IArea[]>([])
    const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch()

    const { step, currentEvent, hasStepChanges, preCreateData, isSaving } =
        useAppSelector(eventWizardSelector)

    useEffect(() => {
        if (currentEvent) {
            const hasChanges =
                currentEvent.eventAreas.length === selectedAreas.length
                    ? !currentEvent.eventAreas.every(item =>
                          selectedAreas.some(
                              area => area.area_id === item.area_id
                          )
                      )
                    : true
            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentEvent, selectedAreas])

    useEffect(() => {
        if (!currentEvent) {
            dispatch(
                actions.setParam({
                    areas: selectedAreas
                })
            )
        }
    }, [selectedAreas])

    const handleUndoChanges = () => {
        if (currentEvent && Array.isArray(currentEvent.eventAreas)) {
            setSelectedAreas(
                currentEvent.eventAreas.map(area => ({
                    area_id: area.area_id,
                    title: area.name
                }))
            )
        } else if (Array.isArray(preCreateData.areas)) {
            setSelectedAreas(
                preCreateData.areas.map(area => ({
                    area_id: area.area_id,
                    title: area.title
                }))
            )
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleAddAreas = (areas: { area_id: string; title: string }[]) => {
        const result = areas.filter(
            area =>
                !selectedAreas.some(
                    selected => selected.area_id === area.area_id
                )
        )
        setSelectedAreas(prev =>
            [...prev, ...result].sort((a, b) => a.title.localeCompare(b.title))
        )
        setShowModal(false)
    }

    const handleSaveAreas = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        areas: selectedAreas.map(area => area.area_id)
                    }
                })
            )
        } else {
            dispatch(actions.setStep(step + 1))
        }
    }

    return (
        <>
            <Paragraph
                type={'success'}
                style={{ fontSize: 14, margin: '0 0 5px' }}
            >
                {'Выбранные регионы:'}
            </Paragraph>
            {selectedAreas.map((area, index) => (
                <Row
                    align={'middle'}
                    style={{
                        marginBottom: 10,
                        marginTop: index === 0 ? 10 : 0
                    }}
                    key={area.area_id}
                >
                    <Paragraph style={{ fontSize: 14, margin: '0' }}>
                        {area.title}
                    </Paragraph>
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        style={{ marginLeft: 10 }}
                        size={'small'}
                        onClick={() =>
                            setSelectedAreas(prev =>
                                prev.filter(
                                    item => item.area_id !== area.area_id
                                )
                            )
                        }
                    />
                </Row>
            ))}
            <Button
                size={'small'}
                style={{ marginTop: 5 }}
                icon={<PlusOutlined />}
                onClick={() => setShowModal(true)}
            >
                {'Добавить регионы'}
            </Button>
            <AreaSelector
                onClose={() => setShowModal(false)}
                showModal={showModal}
                onAdd={handleAddAreas}
            />
            <NextStepButton
                disabled={
                    selectedAreas.length === 0 ||
                    (!!currentEvent && !hasStepChanges)
                }
                loading={isSaving}
                onClick={handleSaveAreas}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {currentEvent ? 'Сохранить' : 'Выбрать и продолжить'}
            </NextStepButton>
        </>
    )
}
