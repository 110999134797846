import type { ReactElement } from 'react'
import { Button, Col, Form, Input, message, Row, Typography } from 'antd'
import FormItemWithLabel from '../../../../components/FormItemWithLabel'
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { getCompanySlug } from '../../../../utils/helpers'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companySelector } from '../../selectors'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import copy from 'copy-text-to-clipboard'
import dayjs from 'dayjs'
import BannersBlock from '../../../BannersBlock'
import MobilePreview from '../../../../components/MobilePreview'

const { Paragraph } = Typography

// Styles
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

const invitation_text =
    'Добрый день! Теперь за всеми акциями, закрытыми скидками, розыгрышами и предложениями нашей компании - {{name}}, вы можете следить в приложении MAMADO. По ссылке ниже скачайте приложение MAMADO - маркетплейс услуг и предложений нашего города.'

interface Props {
    onChangeTab: (key: string) => void
}

export default function TabGeneralUser({ onChangeTab }: Props): ReactElement {
    const dispatch = useDispatch()

    const { currentCompany, invitation, isProcessInvitation } =
        useAppSelector(companySelector)

    const [messageApi, contextHolder] = message.useMessage()

    const currentPlan = currentCompany?.activePlanSubscription

    const dynamicLink = invitation?.dynamic_code
        ? `https://${
              process.env.APP_ENV === 'staging' ? 'r1' : 'r'
          }.mamado.su/${invitation.dynamic_code}`
        : ''

    const landingLink =
        currentCompany && !currentCompany?.displayed
            ? `${process.env.SITE_URL}/${getCompanySlug(
                  currentCompany.slug,
                  currentCompany.short_id,
                  currentCompany.company_id
              )}`
            : ''

    const handleCreateInvitation = () => {
        if (currentCompany) {
            dispatch(
                actions.createInvitation({
                    company_id: currentCompany.company_id,
                    invitation_text
                })
            )
        }
    }

    const handleCopyLink = (link: string) => {
        copy(link)
        messageApi.open({
            type: 'success',
            content: 'Ссылка была скопирована'
        })
    }

    return (
        <Row gutter={55} style={{ marginTop: 20 }}>
            <Col span={16}>
                <Row gutter={47}>
                    <Col span={12}>
                        <div className={styles.card}>
                            <Form key={dynamicLink} layout={'vertical'}>
                                <FormItemWithLabel
                                    label={''}
                                    labelComponent={
                                        <h4 style={{ marginBottom: 5 }}>
                                            {'Реферальная ссылка'}
                                        </h4>
                                    }
                                    name={'referral'}
                                    style={{ marginBottom: 8 }}
                                    tooltip={{
                                        title: 'Прочитать подробнее',
                                        icon: (
                                            <InfoCircleOutlined
                                                style={{ cursor: 'pointer' }}
                                            />
                                        ),
                                        onClick: () =>
                                            window.open(
                                                '/content/admin-referral-page',
                                                '_blank'
                                            )
                                    }}
                                >
                                    <>
                                        <span
                                            style={{
                                                display: 'block',
                                                fontSize: 12,
                                                lineHeight: '16px',
                                                marginBottom: 10
                                            }}
                                        >
                                            {
                                                'Приглашение ваших клиентов в приложение Mamado для рассылки Push-уведомлений о предложениях вашей компании.'
                                            }
                                        </span>
                                        <Input
                                            defaultValue={dynamicLink}
                                            value={dynamicLink}
                                            placeholder={
                                                isProcessInvitation
                                                    ? 'Получение ссылки...'
                                                    : 'Реферальная ссылка-приглашение для клиентов'
                                            }
                                            addonAfter={
                                                dynamicLink ? (
                                                    <CopyOutlined
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() =>
                                                            handleCopyLink(
                                                                dynamicLink
                                                            )
                                                        }
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    </>
                                </FormItemWithLabel>
                                {!invitation?.dynamic_code &&
                                currentCompany?.activePlanSubscription ? (
                                    <Button
                                        size={'small'}
                                        style={{
                                            width: '100%',
                                            marginBottom: 15
                                        }}
                                        onClick={handleCreateInvitation}
                                        loading={isProcessInvitation}
                                    >
                                        {'Создать приглашение'}
                                    </Button>
                                ) : !invitation?.dynamic_code ? (
                                    <div
                                        style={{
                                            marginTop: -5,
                                            marginBottom: 15,
                                            fontSize: 11,
                                            color: 'gray'
                                        }}
                                    >
                                        {
                                            'Создать реферальную ссылку для приглашения клиентов можно после активации статуса «Премиум»'
                                        }
                                    </div>
                                ) : null}
                                <FormItemWithLabel
                                    label={''}
                                    labelComponent={
                                        <h4 style={{ marginBottom: 5 }}>
                                            {'Ссылка на лендинг'}
                                        </h4>
                                    }
                                    name={'landing'}
                                    style={{ margin: 0 }}
                                >
                                    <Input
                                        defaultValue={landingLink}
                                        value={landingLink}
                                        placeholder={
                                            'Компания не доступна для просмотра'
                                        }
                                        addonAfter={
                                            landingLink ? (
                                                <CopyOutlined
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        handleCopyLink(
                                                            landingLink
                                                        )
                                                    }
                                                />
                                            ) : undefined
                                        }
                                    />
                                </FormItemWithLabel>
                                <div
                                    style={{
                                        marginTop: 2,
                                        marginBottom: 15,
                                        fontSize: 11,
                                        color: 'gray'
                                    }}
                                >
                                    {
                                        'Ссылка станет активной, после подключения статуса «Премиум» и публикации компании'
                                    }
                                </div>
                            </Form>
                            <Link to={'?tab=settings'}>
                                <Button
                                    style={{ width: '100%' }}
                                    type={'primary'}
                                >
                                    {'Перейти к настройкам'}
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={`${styles.card} ${styles.premium}`}>
                            <h4>{'Статус «Премиум»'}</h4>
                            <Paragraph
                                style={{ marginBottom: 0, marginTop: 0 }}
                                type={
                                    currentPlan?.is_active
                                        ? 'success'
                                        : 'danger'
                                }
                            >
                                {currentPlan?.is_active ? (
                                    'Подписка активна'
                                ) : (
                                    <>
                                        {'Подписка не активна'}
                                        <Paragraph
                                            style={{
                                                maxWidth: '80%',
                                                fontSize: 12,
                                                lineHeight: 1.57,
                                                marginTop: 5
                                            }}
                                            type={'secondary'}
                                        >
                                            {
                                                'Компания будет опубликована, после подключения статуса «Премиум»'
                                            }
                                        </Paragraph>
                                    </>
                                )}
                            </Paragraph>
                            {currentPlan?.is_active ? (
                                <Paragraph type={'secondary'}>
                                    {'Подписка активна до: '}
                                    <span
                                        style={{
                                            fontWeight: '700',
                                            color: '#000'
                                        }}
                                    >
                                        {dayjs(currentPlan.endsAt).format(
                                            'DD.MM.YYYY HH:mm'
                                        )}
                                    </span>
                                </Paragraph>
                            ) : (
                                <Button
                                    type={'primary'}
                                    style={{ marginTop: 5 }}
                                    onClick={() => onChangeTab('premium')}
                                >
                                    {'Перейти к подключению'}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
                <BannersBlock
                    code={'admin-company-basic'}
                    style={{ marginTop: 47 }}
                />
            </Col>
            <Col span={8}>
                <MobilePreview />
            </Col>
            {contextHolder}
        </Row>
    )
}
