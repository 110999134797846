import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    FetchBlocksPayload,
    FetchBlocksSuccessPayload,
    FetchBlocksFailurePayload,
    InitialStateType,
    FetchStatsPayload,
    FetchStatsSuccessPayload,
    FetchStatsFailurePayload,
    FetchStatsSlowPayload,
    FetchStatsSlowSuccessPayload,
    FetchStatsSlowFailurePayload
} from './types'
import dayjs from 'dayjs'

const initialState: InitialStateType = {
    blocks: {},
    stats: null,
    statsSlow: null,
    lastDateStats: '',
    blocksIsFetching: false,
    statsIsFetching: false,
    statsSlowIsFetching: false,
    error: null
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        fetchBlocks(
            state: InitialStateType,
            _action: PayloadAction<FetchBlocksPayload>
        ) {
            state.blocksIsFetching = true
            state.error = null
        },
        fetchBlocksSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchBlocksSuccessPayload>
        ) {
            state.blocks = action.payload.blocks
            state.blocksIsFetching = false
        },
        fetchBlocksFailure(
            state: InitialStateType,
            action: PayloadAction<FetchBlocksFailurePayload>
        ) {
            state.blocksIsFetching = false
            state.error = action.payload.error
        },
        fetchStats(
            state: InitialStateType,
            _action: PayloadAction<FetchStatsPayload>
        ) {
            state.statsIsFetching = true
        },
        fetchStatsSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchStatsSuccessPayload>
        ) {
            state.stats = action.payload.data
            state.statsIsFetching = false
        },
        fetchStatsFailure(
            state: InitialStateType,
            action: PayloadAction<FetchStatsFailurePayload>
        ) {
            state.statsIsFetching = false
            state.error = action.payload.error
        },
        fetchStatsSlow(
            state: InitialStateType,
            _action: PayloadAction<FetchStatsSlowPayload>
        ) {
            state.statsSlowIsFetching = true
        },
        fetchStatsSlowSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchStatsSlowSuccessPayload>
        ) {
            state.statsSlow = action.payload.data
            state.lastDateStats = dayjs().format('DD.MM.YYYY HH:mm')
            state.statsSlowIsFetching = false
        },
        fetchStatsSlowFailure(
            state: InitialStateType,
            action: PayloadAction<FetchStatsSlowFailurePayload>
        ) {
            state.statsSlowIsFetching = false
            state.error = action.payload.error
        }
    }
})

export const actions = dashboardSlice.actions

export default dashboardSlice.reducer
