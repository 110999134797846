import type { ReactElement } from 'react'
import { useEffect } from 'react'
import BlogWizard from '../containers/BlogWizard'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../hooks/useAppSelector.ts'
import { blogWizardSelector } from '../containers/BlogWizard/selectors.ts'
import { actions } from '../containers/BlogWizard/slice.ts'
import { LoadingStep } from '../components/LoadingStep'
import Error404 from '../components/Error404'

export default function BlogEditPage(): ReactElement {
    const { post_id } = useParams()

    const dispatch = useDispatch()

    const { currentPost, isFetching } = useAppSelector(blogWizardSelector)

    useEffect(() => {
        dispatch(actions.destroy())
    }, [])

    useEffect(() => {
        if (post_id) {
            dispatch(actions.fetch({ postId: post_id }))
        }
    }, [post_id])

    if (!currentPost && isFetching) {
        return <LoadingStep />
    }

    if (!currentPost) {
        return <Error404 />
    }

    return <BlogWizard />
}
