import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import dayjs from 'dayjs'
import ruRU from 'antd/es/date-picker/locale/ru_RU'
import { SearchOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import RegionSelector from '../../containers/RegionSelector'
import FranchiseeSelector from '../../containers/FranchiseeSelector'
import { useAppSelector } from '../../hooks/useAppSelector'
import { authorizationSelector } from '../../containers/Authorization/selectors'
import { getRole } from '../../utils/helpers'

const { RangePicker } = DatePicker

export default function UsersFilter(): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : false

    const [form] = Form.useForm()

    useEffect(() => {
        form.resetFields()
        const result: Record<string, unknown> = {}
        searchParams.forEach((value, key) => {
            if (key === 'period') {
                const [from, to] = value.split(',')

                const regExp = /(\d{4})(\d{2})(\d{2})/

                const formatFrom = from.replace(regExp, '$1-$2-$3')
                const formatTo = to.replace(regExp, '$1-$2-$3')

                result[key] = [dayjs(formatFrom), dayjs(formatTo)]
            } else {
                result[key] = value
            }
        })
        form.setFieldsValue(result)
        setDateUpdate(Date.now().toString())
    }, [searchParams])

    const handleChangeFormItem = (
        key: string,
        value: string | string[] | dayjs.ConfigType[]
    ): void => {
        form.setFieldValue(key, value)
    }

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        for (const param in values) {
            if (param === 'period' && Array.isArray(values[param])) {
                const [from, to] = values[param] as dayjs.ConfigType[]
                const formatFrom = dayjs(from).format('YYYYMMDD')
                const formatTo = dayjs(to).format('YYYYMMDD')

                result[param] = `${formatFrom},${formatTo}`
                continue
            }

            const isEmptyString =
                typeof values[param] === 'string' &&
                (values[param] as string).length === 0

            if (values[param] != null && !isEmptyString) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10}>
                <Col push={0} span={24}>
                    <Row gutter={10}>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'search'}
                                label={'Имя пользователя'}
                            >
                                <Input
                                    allowClear
                                    placeholder={'Имя пользователя'}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'last_position'}
                                label={'Локация'}
                            >
                                <RegionSelector
                                    initialValue={form.getFieldValue(
                                        'last_position'
                                    )}
                                    placeholder={'Локация'}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'last_position',
                                            value as string
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'period'}
                                label={'Дата регистрации'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'period',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'has_business_account'}
                                label={'Есть бизнес аккаунт'}
                            >
                                <Select
                                    placeholder={'Есть бизнес аккаунт'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'Да'
                                        },
                                        {
                                            value: 'false',
                                            label: 'Нет'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                        {isAdmin ? (
                            <Col span={6}>
                                <FormItemWithLabel
                                    name={'franchisee_id'}
                                    label={'Франчайзи'}
                                >
                                    <FranchiseeSelector
                                        initialValue={form.getFieldValue(
                                            'franchisee_id'
                                        )}
                                        onChange={value =>
                                            handleChangeFormItem(
                                                'franchisee_id',
                                                value
                                            )
                                        }
                                    />
                                </FormItemWithLabel>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<SearchOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Искать пользователей'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={() => {
                        setSearchParams({})
                        form.resetFields()
                    }}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
