import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import dayjs from 'dayjs'
import ruRU from 'antd/es/date-picker/locale/ru_RU'
import { BarChartOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import UserSelector from '../../containers/UserSelector'

const { RangePicker } = DatePicker

interface Props {
    isAdmin: boolean
}

export default function PromoCodesReportFilter({
    isAdmin
}: Props): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const [form] = Form.useForm()

    useEffect(() => {
        const result: Record<string, unknown> = {}
        searchParams.forEach((value, key) => {
            if (key === 'period') {
                const [from, to] = value.split(',')

                const regExp = /(\d{4})(\d{2})(\d{2})/

                const formatFrom = from.replace(regExp, '$1-$2-$3')
                const formatTo = to.replace(regExp, '$1-$2-$3')

                result[key] = [dayjs(formatFrom), dayjs(formatTo)]
            } else {
                result[key] = value
            }
        })
        form.setFieldsValue(result)
        setDateUpdate(Date.now().toString())
    }, [])

    const handleChangeFormItem = (
        key: string,
        value: string | string[] | dayjs.ConfigType[]
    ): void => {
        form.setFieldValue(key, value)
    }

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        for (const param in values) {
            if (param === 'period' && Array.isArray(values[param])) {
                const [from, to] = values[param] as dayjs.ConfigType[]
                const formatFrom = dayjs(from).format('YYYYMMDD')
                const formatTo = dayjs(to).format('YYYYMMDD')

                result[param] = `${formatFrom},${formatTo}`
                continue
            }

            if (values[param] != null) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
    }

    const handleResetForm = () => {
        setSearchParams({})
        form.resetFields()
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            initialValues={{
                period: [dayjs().subtract(1, 'month'), dayjs()]
            }}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10}>
                <Col push={0} span={24}>
                    <Row gutter={10}>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'promocode'}
                                label={'Промокод'}
                                required={!isAdmin}
                                requiredText={'Введите промокод.'}
                            >
                                <Input
                                    allowClear={true}
                                    placeholder={'Промокод'}
                                />
                            </FormItemWithLabel>
                        </Col>
                        {isAdmin ? (
                            <Col span={6}>
                                <FormItemWithLabel
                                    name={'owner'}
                                    label={'Владелец промокода'}
                                >
                                    <UserSelector
                                        initialValue={form.getFieldValue(
                                            'owner'
                                        )}
                                        onChange={value =>
                                            form.setFieldValue('owner', value)
                                        }
                                    />
                                </FormItemWithLabel>
                            </Col>
                        ) : null}
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'period'}
                                label={'Период оплаты'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    allowClear={false}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'period',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<BarChartOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Сформировать'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={handleResetForm}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
