import type { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { message, notification } from 'antd'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { routes } from './routes'
import { sagaMiddleware, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import Initialization from './components/Initialization'
import CustomConfigProvider from './components/CustomConfigProvider'

import 'dayjs/locale/ru.js'

// Styles
import './styles/styles.css'
import './styles/custom-antd.css'
import './styles/custom-swiper.css'
import './styles/custom-editor-js.css'
import 'react-dadata/dist/react-dadata.css'
import 'react-image-crop/src/ReactCrop.scss'

// Swiper
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-flip'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'

export default function App(): ReactElement {
    const [messageApi, messageContextHolder] = message.useMessage()
    const [notificationApi, notificationContextHolder] =
        notification.useNotification()

    const router = createBrowserRouter(routes)
    const persistor = persistStore(store)

    sagaMiddleware.setContext({
        router,
        message: messageApi,
        notification: notificationApi
    })

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <CustomConfigProvider>
                    <Initialization />
                    <RouterProvider router={router} />
                    {messageContextHolder}
                    {notificationContextHolder}
                </CustomConfigProvider>
            </PersistGate>
        </Provider>
    )
}
