import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import dayjs from 'dayjs'
import ruRU from 'antd/es/date-picker/locale/ru_RU'
import { SearchOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import FranchiseeSelector from '../../containers/FranchiseeSelector'
import { useAppSelector } from '../../hooks/useAppSelector'
import { authorizationSelector } from '../../containers/Authorization/selectors'
import { getRole } from '../../utils/helpers'
import UserSelector from '../../containers/UserSelector'
import TagsSelector from '../../containers/TagsSelector'

const { RangePicker } = DatePicker

export default function BlogFilter(): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : false

    const [form] = Form.useForm()

    const watchTags = Form.useWatch('tags', form)

    useEffect(() => {
        form.resetFields()
        const result: Record<string, unknown> = {}
        searchParams.forEach((value, key) => {
            if (key === 'created') {
                const [from, to] = value.split(',')

                const regExp = /(\d{4})(\d{2})(\d{2})/

                const formatFrom = from.replace(regExp, '$1-$2-$3')
                const formatTo = to.replace(regExp, '$1-$2-$3')

                result[key] = [dayjs(formatFrom), dayjs(formatTo)]
            } else if (key === 'tags') {
                result[key] = value.split(',').filter(Boolean)
            } else {
                result[key] = value
            }
        })
        form.setFieldsValue(result)
        setDateUpdate(Date.now().toString())
    }, [searchParams])

    const handleChangeFormItem = (
        key: string,
        value: string | string[] | dayjs.ConfigType[]
    ): void => {
        form.setFieldValue(key, value)
    }

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        for (const param in values) {
            if (param === 'created' && Array.isArray(values[param])) {
                const [from, to] = values[param] as dayjs.ConfigType[]
                const formatFrom = dayjs(from).format('YYYYMMDD')
                const formatTo = dayjs(to).format('YYYYMMDD')

                result[param] = `${formatFrom},${formatTo}`
                continue
            } else if (Array.isArray(values[param])) {
                const valueArray = (values[param] as string[]).join(',')
                if (valueArray.length > 0) {
                    result[param] = valueArray
                }
                continue
            }

            const isEmptyString =
                typeof values[param] === 'string' &&
                (values[param] as string).length === 0

            if (values[param] != null && !isEmptyString) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10}>
                <Col push={0} span={24}>
                    <Row gutter={10}>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'title'}
                                label={'Название статьи'}
                            >
                                <Input
                                    allowClear
                                    placeholder={'Название статьи'}
                                />
                            </FormItemWithLabel>
                        </Col>
                        {isAdmin ? (
                            <>
                                <Col span={6}>
                                    <FormItemWithLabel
                                        name={'user_id'}
                                        label={'Владелец'}
                                    >
                                        <UserSelector
                                            initialValue={form.getFieldValue(
                                                'user_id'
                                            )}
                                            onChange={value =>
                                                handleChangeFormItem(
                                                    'user_id',
                                                    value
                                                )
                                            }
                                        />
                                    </FormItemWithLabel>
                                </Col>
                                <Col span={6}>
                                    <FormItemWithLabel
                                        name={'franchisee_id'}
                                        label={'Франчайзи'}
                                    >
                                        <FranchiseeSelector
                                            initialValue={form.getFieldValue(
                                                'franchisee_id'
                                            )}
                                            onChange={value =>
                                                handleChangeFormItem(
                                                    'franchisee_id',
                                                    value
                                                )
                                            }
                                        />
                                    </FormItemWithLabel>
                                </Col>
                            </>
                        ) : null}
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'created'}
                                label={'Период создания'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'created',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel name={'status'} label={'Статус'}>
                                <Select
                                    placeholder={'Статус'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: '3',
                                            label: 'Опубликовано'
                                        },
                                        {
                                            value: '4',
                                            label: 'Не опубликовано'
                                        },
                                        {
                                            value: '1',
                                            label: 'Черновик'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel name={'tags'} label={'Теги'}>
                                <TagsSelector
                                    initialValue={watchTags}
                                    onChange={value =>
                                        handleChangeFormItem('tags', value)
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<SearchOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Искать статьи'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={() => {
                        setSearchParams({})
                        form.resetFields()
                    }}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
