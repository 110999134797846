import type { ReactElement, ReactNode } from 'react'
import { Modal, TreeSelect } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector'
import { actions } from './slice'
import type { AreaItemType } from './types'
import LoadingSelector from '../../components/LoadingSelector'
import { useDebounce } from '../../hooks/useDebounce.ts'
import { areaSelector } from './selectors.ts'

const { SHOW_PARENT } = TreeSelect

interface ITree {
    id: string
    title: string
    value: string
    children?: ITree[]
    isLeaf?: boolean
}

interface Props {
    showModal: boolean
    onClose: () => void
    onAdd: (areas: { area_id: string; title: string }[]) => void
    isTreeCheckable?: boolean
    title?: string
    okButtonText?: string
}

export default function AreaSelector({
    showModal,
    onClose,
    onAdd,
    isTreeCheckable = true,
    title,
    okButtonText
}: Props): ReactElement {
    const [search, setSearch] = useState('')
    const [value, setValue] = useState<{ label: string; value: string }[]>([])
    const [treeData, setTreeData] = useState<ITree[]>([])

    const dispatch = useDispatch()

    const debounceSearch = useDebounce(search, 600)

    const { areas, areasSearch, areasIsFetching, searchIsFetching } =
        useAppSelector(areaSelector)

    const cityAreas = ['Москва']

    useEffect(() => {
        if (!showModal) {
            setValue([])
        }
    }, [showModal])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.search({ name: debounceSearch }))
        }
    }, [debounceSearch])

    useEffect(() => {
        const areaData = search.length > 0 ? areasSearch : areas

        if (areaData.length > 0) {
            const mapChildren = (
                children: AreaItemType[] | undefined,
                pid: number
            ): ITree[] | undefined => {
                return children?.map(children => ({
                    pid,
                    id: children.area_id,
                    title: children.name,
                    value: children.area_id,
                    isLeaf: !children.has_children,
                    children: mapChildren(children.children, pid + 1)
                }))
            }
            setTreeData(
                areaData.map(area => ({
                    pid: 1,
                    id: area.area_id,
                    title: area.name,
                    value: area.area_id,
                    isLeaf: !area.has_children,
                    children: mapChildren(area.children, 2)?.sort((a, b) => {
                        if (a.isLeaf && !b.isLeaf) {
                            return -1
                        }

                        if (
                            cityAreas.includes(a.title) &&
                            !cityAreas.includes(b.title)
                        ) {
                            return -1
                        }

                        return 1
                    })
                }))
            )
        }
    }, [areas, areasSearch, debounceSearch])

    useEffect(() => {
        dispatch(actions.fetch({ id: 'country' }))
    }, [])

    const onLoadData = ({
        id,
        pid,
        title
    }: {
        id: string
        pid: number
        title: ReactNode
    }): Promise<void> => {
        if (cityAreas.includes(title as string)) {
            return new Promise(resolve =>
                dispatch(actions.fetch({ id, depth: 3, resolve }))
            )
        } else {
            return new Promise(resolve =>
                dispatch(actions.fetch({ id, depth: pid, resolve }))
            )
        }
    }

    const handleAddAreas = () => {
        if (value.length > 0) {
            onAdd(
                value.map(({ label, value }) => ({
                    area_id: value,
                    title: label
                }))
            )
        }
    }

    return (
        <Modal
            title={title || 'Добавить регионы'}
            open={showModal}
            onCancel={onClose}
            okText={okButtonText || 'Добавить'}
            onOk={handleAddAreas}
        >
            <TreeSelect
                style={{
                    width: '100%'
                }}
                notFoundContent={
                    debounceSearch && !searchIsFetching ? (
                        <span style={{ marginLeft: 10 }}>
                            {'По вашему запросу ничего не найдено'}
                        </span>
                    ) : (
                        <LoadingSelector />
                    )
                }
                loading={areasIsFetching.length > 0 || searchIsFetching}
                disabled={areas.length === 0}
                value={value}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto'
                }}
                placeholder={
                    areas.length === 0
                        ? 'Получение регионов...'
                        : 'Выберите регионы'
                }
                showSearch={true}
                searchValue={search}
                onSearch={setSearch}
                treeNodeFilterProp={'title'}
                onChange={(ids: typeof value, labels) =>
                    Array.isArray(ids)
                        ? setValue(
                              ids.map((item: unknown, index: number) => ({
                                  value: item as string,
                                  label: labels[index] as string
                              }))
                          )
                        : ids
                          ? setValue([
                                { value: ids, label: labels[0] as string }
                            ])
                          : null
                }
                loadData={({ id, pid, title }) =>
                    onLoadData({ id, pid, title })
                }
                treeData={treeData}
                treeCheckable={isTreeCheckable}
                showCheckedStrategy={SHOW_PARENT}
            />
        </Modal>
    )
}
