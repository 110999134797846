import type { ReactElement } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import Page404 from '../../pages/404'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../Layout'
import { authorizationSelector } from '../../containers/Authorization/selectors'
import { getRole } from '../../utils/helpers'
import { LoadingStep } from '../LoadingStep'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/Application/slice'

interface Props {
    children: ReactElement
    isHomePage?: boolean
    hideBreadcrumbs?: boolean
}

const userProtectedPages = ['/users', '/promocodes/agents']
const onlyAdminPages = ['/settings/coverage']

export default function ProtectedPage({
    children,
    isHomePage,
    hideBreadcrumbs
}: Props): ReactElement {
    const { profile, token } = useAppSelector(authorizationSelector)

    const prevToken = useRef(token)

    const { pathname } = useLocation()
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.changePathName(pathname))
    }, [pathname])

    useEffect(() => {
        if (prevToken && !token && pathname !== '/') {
            navigate('/', { replace: true })
        }
    }, [token])

    if (isHomePage && !token) {
        return children
    }

    if ((prevToken && !token) || !profile) {
        return <LoadingStep />
    }

    const isUser = getRole(profile.roles) === 'user'
    const isAdmin = getRole(profile.roles) === 'admin'

    if (isUser && userProtectedPages.some(path => pathname.startsWith(path))) {
        return <Page404 />
    }

    if (!isAdmin && onlyAdminPages.some(path => pathname.startsWith(path))) {
        return <Page404 />
    }

    if (token) {
        return <Layout hideBreadcrumbs={hideBreadcrumbs}>{children}</Layout>
    }

    return <Page404 />
}
