import type { AxiosResponse } from 'axios'
import { del, get, post } from '../../utils/api'
import type { IAdvertisingItem, IMeta } from './types.ts'

export const fetch = (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse<{ data: IAdvertisingItem[]; meta: IMeta }>> => {
    const filterParams: Record<string, string> = {}

    const selectorsKeys = ['franchisee_id', 'user_id']
    const dateKeys = ['created', 'updated']

    for (const key in filter) {
        if (dateKeys.includes(key)) {
            const [from, to] = filter[key].split(',')

            const regExp = /(\d{4})(\d{2})(\d{2})/

            const formatFrom = from.replace(regExp, '$1-$2-$3')
            const formatTo = to.replace(regExp, '$1-$2-$3')

            filterParams[key] = `${formatFrom},${formatTo}`
            continue
        }
        if (selectorsKeys.includes(key)) {
            filterParams[key] =
                filter[key as keyof typeof filter]?.split('{d}')[1]
            continue
        }
        filterParams[key] = filter[key]
    }

    return get<{ data: IAdvertisingItem[]; meta: IMeta }>(
        '/admin/advertising_groups',
        {
            page,
            page_size,
            filter: filterParams,
            include: ['user', 'franchisee'],
            sort: '-created_at'
        }
    )
}

export const remove = (advertising_id: string): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/advertising_groups/${advertising_id}`)

export const changeStatus = (
    advertising_group_id: string
): Promise<AxiosResponse<void>> =>
    post<void>(
        `/admin/advertising_groups/${advertising_group_id}/change_status`
    )
