import type { ReactElement, ReactNode } from 'react'
import { Button, Col, Row } from 'antd'
import {
    ArrowDownOutlined,
    CheckCircleOutlined,
    UndoOutlined
} from '@ant-design/icons'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { companyWizardSelector } from '../../containers/CompanyWizard/selectors'
import { eventWizardSelector } from '../../containers/EventWizard/selectors'
import { CompanyWizardContext } from '../../context/companyWizard-context'
import { EventWizardContext } from '../../context/eventWizard-context'

interface Props {
    children?: ReactNode
    onClick?: (isTrusted: boolean) => void
    loading?: boolean
    disabled?: boolean
    showReturnChangesButton?: boolean
    onReturnChanges?: () => void
    isLeft?: boolean
    hideNext?: boolean
}

const ignoreNextButtonCompanyPages = ['addresses', 'city']
const ignoreNextButtonEventPages = ['details']

export default function NextStepButton({
    children,
    onClick,
    loading,
    disabled,
    showReturnChangesButton,
    onReturnChanges,
    isLeft,
    hideNext
}: Props): ReactElement {
    const [isHideUndo, setIsHideUndo] = useState(true)
    const [type, setType] = useState<'company' | 'event'>('company')

    const [searchParams] = useSearchParams()
    const pageName = searchParams.get('page')

    const { step: companyStep, currentCompany } = useAppSelector(
        companyWizardSelector
    )
    const { step: eventStep, currentEvent } =
        useAppSelector(eventWizardSelector)

    const location = useLocation()

    const { changeMenu: companyChangeMenu } = useContext(CompanyWizardContext)
    const { changeMenu: eventChangeMenu } = useContext(EventWizardContext)

    useEffect(() => {
        if (location.pathname.startsWith('/companies')) {
            setIsHideUndo(currentCompany?.status === 'draft')
            setType('company')
        }
        if (location.pathname.startsWith('/events')) {
            setIsHideUndo(currentEvent?.status === 'draft')
            setType('event')
        }
    }, [location, currentCompany, currentEvent])

    return (
        <Row
            style={{ marginTop: 30 }}
            gutter={15}
            justify={isLeft ? 'start' : 'end'}
        >
            {children && onClick ? (
                <Col>
                    <Button
                        id={'saveButton'}
                        icon={<CheckCircleOutlined />}
                        loading={loading}
                        disabled={disabled}
                        onClick={event => onClick(event.isTrusted)}
                        type={'primary'}
                    >
                        {children}
                    </Button>
                </Col>
            ) : null}
            {showReturnChangesButton && onReturnChanges && !isHideUndo ? (
                <Col>
                    <Button
                        icon={<UndoOutlined />}
                        onClick={onReturnChanges}
                        type={'default'}
                    >
                        {'Вернуть изменения'}
                    </Button>
                </Col>
            ) : null}
            {!hideNext &&
            isHideUndo &&
            !(
                type === 'company'
                    ? ignoreNextButtonCompanyPages
                    : ignoreNextButtonEventPages
            ).includes(pageName as string) &&
            !location.pathname.endsWith('/create') ? (
                <Col>
                    <Button
                        icon={<ArrowDownOutlined />}
                        onClick={() => {
                            if (type === 'event') {
                                if (eventChangeMenu) {
                                    eventChangeMenu(eventStep + 1)
                                }
                            } else {
                                if (companyChangeMenu) {
                                    companyChangeMenu(companyStep + 1)
                                }
                            }
                        }}
                        type={'default'}
                    >
                        {'Далее'}
                    </Button>
                </Col>
            ) : null}
        </Row>
    )
}
