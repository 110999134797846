import type { ReactElement } from 'react'
import { Col, Form, Input, notification, Row, Typography } from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { actions } from '../../slice'
import { useEffect } from 'react'
import { companyWizardSelector } from '../../selectors'

const { TextArea } = Input
const { Paragraph } = Typography

export default function DescriptionStep(): ReactElement {
    const [form] = Form.useForm()

    const [api, contextHolder] = notification.useNotification()
    const dispatch = useDispatch()

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const descriptionValue = Form.useWatch('description', form)

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                actions.setHasStepChanges(
                    (currentCompany.about ?? '') !== descriptionValue
                )
            )
        }
    }, [currentCompany, descriptionValue])

    useEffect(() => {
        api.info({
            message: 'Дети - ваши посетители?',
            description:
                'Укажите здесь в описании какие услуги, сервис есть у вас для посетителей с детьми или детей.',
            placement: 'bottomRight',
            duration: 5000
        })
    }, [])

    const handleUndoChanges = () => {
        if (currentCompany && currentCompany.about) {
            form.setFieldValue('description', currentCompany.about)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleSaveDescription = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: { about: form.getFieldValue('description') },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const count = descriptionValue?.length ?? 0

    const isCorrect = count > 100 && count <= 4000

    return (
        <>
            <Row gutter={10} style={{ marginBottom: -25 }}>
                <Col span={24}>
                    <Row justify={'end'}>
                        <Paragraph style={{ marginRight: 5 }}>
                            {'Кол-во символов:'}
                        </Paragraph>
                        <Paragraph
                            type={isCorrect ? 'success' : 'danger'}
                        >{`${count}/4000`}</Paragraph>
                    </Row>
                    <Form
                        form={form}
                        initialValues={{
                            description: ''
                        }}
                    >
                        <Form.Item name={'description'}>
                            <TextArea
                                autoFocus
                                style={{ minHeight: 500, resize: 'none' }}
                                placeholder={'Введите описание'}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <NextStepButton
                disabled={!hasStepChanges || !isCorrect}
                loading={isSaving}
                onClick={handleSaveDescription}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            {contextHolder}
        </>
    )
}
