import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { Typography, Radio, Checkbox, Space } from 'antd'
import RadioDescription from '../../../../components/RadioDescription'
import NextStepButton from '../../../../components/NextStepButton'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'

const { Paragraph } = Typography

export default function DetailsStep(): ReactElement {
    const [detail, setDetail] = useState<'individual' | 'group' | null>(null)
    const [onlinePurchase, setOnlinePurchase] = useState(false)

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } = useAppSelector(
        state => state.eventWizard
    )

    useEffect(() => {
        if (currentEvent) {
            let hasChanges = currentEvent.type_of_participation !== detail
            if (currentEvent?.badge !== 'default' && !hasChanges) {
                hasChanges =
                    currentEvent.online_booking_or_purchase !== onlinePurchase
            }

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentEvent, detail, onlinePurchase])

    const handleUndoChanges = () => {
        if (currentEvent && currentEvent.type_of_participation) {
            setDetail(currentEvent.type_of_participation)
            setOnlinePurchase(!!currentEvent?.online_booking_or_purchase)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleDetailsSend = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        type_of_participation: detail,
                        online_booking_or_purchase:
                            currentEvent.badge !== 'default'
                                ? onlinePurchase
                                : false
                    },
                    isLastStep: true
                })
            )
        }
    }

    return (
        <>
            <Paragraph type={'success'} style={{ margin: 0 }}>
                {'Тип участия'}
            </Paragraph>
            <RadioDescription>
                {
                    'Предложение проводится в индвидуальном формате или для группы'
                }
            </RadioDescription>
            <Space direction={'vertical'}>
                <Radio.Group
                    value={detail}
                    onChange={({ target }) => setDetail(target.value)}
                    buttonStyle={'solid'}
                    style={{ marginTop: 15 }}
                >
                    <Radio.Button value={'individual'}>
                        {'Индивидуальное'}
                    </Radio.Button>
                    <Radio.Button value={'group'}>{'Групповое'}</Radio.Button>
                </Radio.Group>
                {currentEvent?.badge !== 'default' ? (
                    <Checkbox
                        style={{ marginTop: 10 }}
                        checked={onlinePurchase}
                        onChange={({ target }) =>
                            setOnlinePurchase(target.checked)
                        }
                    >
                        {'Онлайн запись/покупка'}
                        <RadioDescription>
                            {
                                'На вашем ресурсе доступна возможность к онлайн бронированию или покупки'
                            }
                        </RadioDescription>
                    </Checkbox>
                ) : null}
            </Space>
            <NextStepButton
                disabled={!hasStepChanges}
                loading={isSaving}
                onClick={handleDetailsSend}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
