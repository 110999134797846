import type { SyntheticEvent } from 'react'
import { useState } from 'react'
import { Modal } from 'antd'
import type { PercentCrop } from 'react-image-crop'
import ReactCrop, {
    type Crop,
    centerCrop,
    makeAspectCrop
} from 'react-image-crop'

interface Props {
    image: string
    open: boolean
    onCancel: () => void
    onOk: ({
        width,
        height,
        x,
        y
    }: {
        width?: number
        height?: number
        x?: number
        y?: number
    }) => void
    aspect: number
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: aspect === 1 ? 40 : 60
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    )
}

export default function ModalCrop({
    image,
    open,
    onCancel,
    onOk,
    aspect
}: Props) {
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PercentCrop>()
    const [naturalSize, setNaturalSize] = useState({
        width: 0,
        height: 0
    })

    function onImageLoad(e: SyntheticEvent<HTMLImageElement>) {
        const { width, height, naturalWidth, naturalHeight } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
        setNaturalSize({
            width: naturalWidth,
            height: naturalHeight
        })
    }

    return (
        <Modal
            title={'Обрезать фотографию'}
            open={open}
            onCancel={onCancel}
            onOk={() => {
                onOk({
                    width:
                        (naturalSize.width / 100) * (completedCrop?.width ?? 0),
                    height:
                        (naturalSize.height / 100) *
                        (completedCrop?.height ?? 0),
                    x: (naturalSize.width / 100) * (completedCrop?.x ?? 0),
                    y: (naturalSize.height / 100) * (completedCrop?.y ?? 0)
                })
            }}
            okText={'Обрезать'}
            destroyOnClose={true}
        >
            <ReactCrop
                crop={crop}
                minHeight={100}
                aspect={aspect}
                onChange={setCrop}
                onComplete={(_, percent) => setCompletedCrop(percent)}
            >
                <img alt={'Фотография'} src={image} onLoad={onImageLoad} />
            </ReactCrop>
        </Modal>
    )
}
