import type {
    CheckPromoCodePayload,
    CreatePromoCodePayload,
    EditPromoCodePayload,
    FetchPayload,
    RemovePromoCodePayload
} from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import { promoCodesSelector } from './selectors.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        const { data } = yield call(api.fetch, page, pageSize, filter)
        yield put(
            actions.fetchSuccess({ data: data.data, meta: data.meta, filter })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

function* handleCreatePromoCode(
    action: PayloadAction<CreatePromoCodePayload>
): SagaIterator {
    try {
        yield call(api.createPromoCode, action.payload.data)
        yield put(actions.createSuccess())
        const { meta, filter } = yield select(promoCodesSelector)
        if (meta && meta.currentPage === 1) {
            yield put(
                actions.fetch({
                    page: 1,
                    pageSize: 10,
                    filter
                })
            )
        } else {
            action.payload.clearSearchParams()
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreatePromoCode(): SagaIterator {
    yield takeEvery(actions.create, handleCreatePromoCode)
}

function* handleCheckPromoCode(
    action: PayloadAction<CheckPromoCodePayload>
): SagaIterator {
    try {
        const { data } = yield call(api.checkPromoCode, action.payload)
        yield put(actions.checkPromoCodeSuccess(data.data.result))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.checkPromoCodeFailure({
                error: message
            })
        )
    }
}

export function* watchCheckPromoCode(): SagaIterator {
    yield takeEvery(actions.checkPromoCode, handleCheckPromoCode)
}

function* handleEditPromoCode(
    action: PayloadAction<EditPromoCodePayload>
): SagaIterator {
    try {
        const { promoCodeId, data } = action.payload
        yield call(api.editPromoCode, promoCodeId, data)
        yield put(actions.editSuccess())
        const { meta, filter } = yield select(promoCodesSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage,
                    filter
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEditPromoCode(): SagaIterator {
    yield takeEvery(actions.edit, handleEditPromoCode)
}

function* handleCheckPromoCodeDeletable(
    action: PayloadAction<RemovePromoCodePayload>
): SagaIterator {
    try {
        const { data } = yield call(api.checkPromoCodeDeletable, action.payload)

        yield put(
            actions.checkDeletableSuccess({
                promoCodeId: action.payload,
                isDeletable: data.data.result
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.checkDeletableFailure({
                error: message
            })
        )
    }
}

export function* watchCheckPromoCodeDeletable(): SagaIterator {
    yield takeEvery(actions.checkDeletable, handleCheckPromoCodeDeletable)
}

function* handleRemovePromoCode(
    action: PayloadAction<RemovePromoCodePayload>
): SagaIterator {
    try {
        yield call(api.removePromoCode, action.payload)
        yield put(actions.removeSuccess())
        const { meta, filter } = yield select(promoCodesSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage,
                    filter
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                error: message
            })
        )
    }
}

export function* watchRemovePromoCode(): SagaIterator {
    yield takeEvery(actions.remove, handleRemovePromoCode)
}

export default function* watchPromoCodes(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchCreatePromoCode),
        fork(watchCheckPromoCode),
        fork(watchEditPromoCode),
        fork(watchCheckPromoCodeDeletable),
        fork(watchRemovePromoCode)
    ])
}
