import {
    Button,
    Card,
    Row,
    Tooltip,
    Typography,
    Slider,
    InputNumber
} from 'antd'
import type { SliderMarks } from 'antd/es/slider'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { PercentageOutlined } from '@ant-design/icons'

// Styles
import styles from './styles.module.scss'

const { Text, Paragraph } = Typography

const marks: SliderMarks = {
    0: '3%',
    25: '5%',
    50: '10%',
    75: '15%',
    100: '20%'
}

const reverse = {
    0: 3,
    25: 5,
    50: 10,
    75: 15,
    100: 20
}

export default function DetailsStep() {
    const [isOnlineBooking, setIsOnlineBooking] = useState(false)
    const [isMagnet, setIsMagnet] = useState(false)
    const [percent, setPercent] = useState(0)
    const [showInputPercent, setShowInputPercent] = useState(false)
    const [inputPercent, setInputPercent] = useState(1)

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentCompany) {
            const hasChanges =
                currentCompany.possibility_of_online_booking_or_purchase !==
                    isOnlineBooking ||
                currentCompany.accept_discount !== isMagnet ||
                currentCompany.discount !==
                    (showInputPercent
                        ? inputPercent
                        : currentCompany.discount === 0 &&
                            reverse[percent as keyof typeof reverse] === 3
                          ? 0
                          : reverse[percent as keyof typeof reverse])

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [
        currentCompany,
        isOnlineBooking,
        isMagnet,
        percent,
        showInputPercent,
        inputPercent
    ])

    const handleUndoChanges = () => {
        if (currentCompany) {
            setIsOnlineBooking(
                currentCompany.possibility_of_online_booking_or_purchase
            )
            setIsMagnet(currentCompany.accept_discount)

            if (currentCompany.discount) {
                for (const item in reverse) {
                    const current = parseInt(item)
                    if (
                        currentCompany.discount ===
                        reverse[current as keyof typeof reverse]
                    ) {
                        setPercent(current)
                        setShowInputPercent(false)
                        return
                    }
                }

                setInputPercent(currentCompany.discount)
                setShowInputPercent(true)
            }
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleSaveDetails = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        possibility_of_online_booking_or_purchase:
                            isOnlineBooking,
                        accept_discount: isMagnet,
                        discount: showInputPercent
                            ? inputPercent
                            : reverse[percent as keyof typeof reverse]
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    return (
        <>
            <Paragraph>
                {
                    'Скидка посетителям при показе мобильного приложения Mamado (в компании появится баннер с процентом скидки)'
                }
            </Paragraph>
            {currentCompany && currentCompany.type === 'offline' ? (
                <>
                    <Card
                        title={
                            <>
                                {/*<CrownFilled className={styles.crown} />*/}
                                {'Скидка при показе приложения Mamado'}
                            </>
                        }
                        bordered={false}
                        style={{ width: '100%', maxWidth: 350 }}
                        bodyStyle={{
                            padding: '15px 25px 0px'
                        }}
                    >
                        <Paragraph>
                            {'Скидка посетителям при'}
                            <br />
                            {'показе приложения Mamado'}
                        </Paragraph>
                        <Row style={{ marginBottom: 25 }} align={'middle'}>
                            <Button
                                type={isMagnet ? 'default' : 'primary'}
                                onClick={() => setIsMagnet(prev => !prev)}
                                style={{ marginRight: 15 }}
                            >
                                {isMagnet ? 'Отменить' : 'Применить'}
                            </Button>
                            <Tooltip
                                placement={'top'}
                                title={
                                    'Посетитель, показывает экран на мобильном телефоне с вашей компанией в приложении MAMADO, получает скидку на услуги. Акция увеличивает охват клиентов и будет отображена в фильтрах приложения, а так же в карточке вашей компании появится особая метка. При использовании этой функции предупредите персонал о применении акции.'
                                }
                            >
                                <span style={{ fontSize: 13, cursor: 'help' }}>
                                    {'Что это?'}
                                </span>
                            </Tooltip>
                        </Row>
                        {isMagnet ? (
                            <>
                                {showInputPercent ? (
                                    <>
                                        <InputNumber
                                            addonAfter={<PercentageOutlined />}
                                            value={inputPercent}
                                            onChange={value =>
                                                value
                                                    ? setInputPercent(value)
                                                    : null
                                            }
                                            style={{
                                                width: '100%',
                                                marginBottom: 10
                                            }}
                                            maxLength={3}
                                            max={100}
                                            min={0}
                                            controls
                                        />
                                        <Text
                                            onClick={() =>
                                                setShowInputPercent(false)
                                            }
                                            className={styles['text-button']}
                                            underline
                                        >
                                            {'Показать варианты'}
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Slider
                                            value={percent}
                                            marks={marks}
                                            defaultValue={3}
                                            min={0}
                                            max={100}
                                            step={null}
                                            tooltip={{ formatter: null }}
                                            onChange={setPercent}
                                        />
                                        <Text
                                            onClick={() =>
                                                setShowInputPercent(true)
                                            }
                                            className={styles['text-button']}
                                            underline
                                        >
                                            {'Заполнить самому'}
                                        </Text>
                                    </>
                                )}
                            </>
                        ) : null}
                    </Card>
                </>
            ) : null}
            <NextStepButton
                disabled={!hasStepChanges}
                loading={isSaving}
                onClick={handleSaveDetails}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
