import type { CSSProperties, ReactElement } from 'react'
import { Select } from 'antd'
import { useDebounce } from '../../hooks/useDebounce'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import LoadingSelector from '../../components/LoadingSelector'
import { franchiseeSelectorSelector } from './selectors.ts'

interface Props {
    initialValue?: string
    onChange: (value: string) => void
    style?: CSSProperties
    hasEmptyValue?: boolean
}

export default function FranchiseeSelector({
    initialValue,
    onChange,
    style,
    hasEmptyValue
}: Props): ReactElement {
    const [hasInitialize, setHasInitialize] = useState(false)
    const [preData, setPreData] = useState<{ label: string; value: string }[]>(
        []
    )
    const [search, setSearch] = useState('')
    const debounceSearch = useDebounce<string>(search, 600)

    const dispatch = useDispatch()

    const { data, isFetching } = useAppSelector(franchiseeSelectorSelector)

    useEffect(() => {
        if (!hasInitialize && initialValue) {
            if (initialValue.includes('{d}')) {
                const [label, value] = initialValue.split('{d}')
                setPreData([{ label, value: `${label}{d}${value}` }])
            }
        }
    }, [initialValue])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.fetch({ search: debounceSearch }))
            setHasInitialize(true)
        } else if (data.length > 0) {
            dispatch(actions.destroy())
        }
    }, [debounceSearch])

    return (
        <Select
            showSearch
            allowClear
            value={!hasInitialize ? preData[0]?.label : undefined}
            options={
                !hasInitialize
                    ? hasEmptyValue
                        ? [
                              { label: 'Не установлен', value: '' },
                              ...preData.filter(
                                  item => item.label !== 'Не установлен'
                              )
                          ]
                        : preData
                    : hasEmptyValue
                      ? [
                            { label: 'Не установлен', value: '' },
                            ...data
                                .map(item => ({
                                    label: item.name,
                                    value: item.franchisee_id
                                }))
                                .filter(item => item.label !== 'Не установлен')
                        ]
                      : data.map(item => ({
                            label: item.name,
                            value: item.franchisee_id
                        }))
            }
            style={style}
            filterOption={false}
            notFoundContent={isFetching ? <LoadingSelector /> : null}
            loading={isFetching}
            placeholder={'Франчайзи'}
            searchValue={search}
            onSearch={setSearch}
            onClear={() => setHasInitialize(true)}
            onChange={(value, options) => {
                if (options && !Array.isArray(options)) {
                    if (
                        value.includes('{d}') &&
                        options.value.includes('{d}')
                    ) {
                        onChange(value)
                    } else {
                        onChange(`${options.label}{d}${options.value}`)
                    }
                } else {
                    onChange(value)
                }
            }}
        />
    )
}
