import type { ReactElement } from 'react'
import { Row, Typography } from 'antd'

const { Paragraph } = Typography

// Styles
import styles from './styles.module.scss'

interface Props {
    type: 'online' | 'offline'
    published?: boolean
    isEvent?: boolean
}

export default function Status({
    type,
    published,
    isEvent
}: Props): ReactElement {
    return (
        <div className={styles.outer}>
            <Paragraph
                style={{ margin: 0 }}
                type={published ? 'success' : 'secondary'}
            >
                {published
                    ? `Опубликован${isEvent ? 'о' : 'а'}`
                    : `Не опубликован${isEvent ? 'о' : 'а'}`}
            </Paragraph>
            <div className={styles.circle} />
            <Row align={'middle'}>
                <Paragraph style={{ margin: '0 5px 0 0' }} type={'secondary'}>
                    {'Тип: '}
                </Paragraph>
                <Paragraph style={{ margin: 0 }}>
                    {type === 'online' ? 'Онлайн' : 'Оффлайн'}
                </Paragraph>
            </Row>
        </div>
    )
}
