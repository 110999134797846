import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    CreateFailurePayload,
    CreatePayload,
    CreateSuccessPayload,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    EditPayload,
    EditSuccessPayload,
    EditFailurePayload,
    FetchCategoriesPayload,
    FetchCategoriesSuccessPayload,
    FetchCategoriesFailurePayload,
    IBannerForm,
    CreateNormalBannerPayload,
    CreateNormalBannerSuccessPayload,
    CreateNormalBannerFailurePayload,
    FetchCoveragePayload,
    FetchCoverageSuccessPayload,
    FetchCoverageFailurePayload
} from './types'

const initialState: InitialStateType = {
    currentAdvertisingCompany: null,
    categories: [],
    banners: [],
    coverage: null,
    editBannerIndex: null,
    modalBanner: null,
    isFetching: false,
    isFetchingCategories: false,
    isCreateNormalBanner: false,
    isCreateInfoModal: false,
    isProcess: false,
    isProcessCoverage: false,
    error: null
}

const advertisingWizardSlice = createSlice({
    name: 'advertisingWizard',
    initialState,
    reducers: {
        create(
            state: InitialStateType,
            _action: PayloadAction<CreatePayload>
        ): void {
            state.isProcess = true
        },
        createSuccess(
            state: InitialStateType,
            action: PayloadAction<CreateSuccessPayload>
        ): void {
            state.isCreateInfoModal = action.payload.isCreateInfoModal
            state.isProcess = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ): void {
            state.isProcess = false
            state.error = action.payload.error
        },
        edit(
            state: InitialStateType,
            _action: PayloadAction<EditPayload>
        ): void {
            state.isProcess = true
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ): void {
            state.isProcess = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ): void {
            state.isProcess = false
            state.error = action.payload.error
        },
        fetch(
            state: InitialStateType,
            _action: PayloadAction<FetchPayload>
        ): void {
            state.isFetching = true
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ): void {
            state.currentAdvertisingCompany = action.payload.data
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ): void {
            state.isFetching = false
            state.error = action.payload.error
        },
        fetchCategories(
            state: InitialStateType,
            _action: PayloadAction<FetchCategoriesPayload>
        ): void {
            state.isFetchingCategories = true
        },
        fetchCategoriesSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCategoriesSuccessPayload>
        ): void {
            state.categories = action.payload.data
            state.isFetchingCategories = false
        },
        fetchCategoriesFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCategoriesFailurePayload>
        ): void {
            state.isFetchingCategories = false
            state.error = action.payload.error
        },
        initialBanners(
            state: InitialStateType,
            action: PayloadAction<IBannerForm[]>
        ): void {
            state.banners = action.payload
        },
        addBanner(
            state: InitialStateType,
            action: PayloadAction<IBannerForm>
        ): void {
            state.banners.push(action.payload)
        },
        removeBanner(
            state: InitialStateType,
            action: PayloadAction<number>
        ): void {
            state.banners = state.banners.filter(
                (_, index) => index !== action.payload
            )
        },
        createNormalBanner(
            state: InitialStateType,
            _action: PayloadAction<CreateNormalBannerPayload>
        ): void {
            state.isCreateNormalBanner = true
        },
        createNormalBannerSuccess(
            state: InitialStateType,
            action: PayloadAction<CreateNormalBannerSuccessPayload>
        ): void {
            if (action.payload.isCustomBanner) {
                state.modalBanner = action.payload.data
            } else {
                state.banners.push({
                    title: null,
                    type: 'normal',
                    description: null,
                    tag: null,
                    color: null,
                    normal_banner: {
                        id: action.payload.data.id,
                        original: action.payload.data.original
                    }
                })
            }
            state.isCreateNormalBanner = false
        },
        createNormalBannerFailure(
            state: InitialStateType,
            action: PayloadAction<CreateNormalBannerFailurePayload>
        ): void {
            state.isCreateNormalBanner = false
            state.error = action.payload.error
        },
        clearModalBanner(
            state: InitialStateType,
            _action: PayloadAction<undefined>
        ): void {
            state.modalBanner = null
        },
        setEditBannerIndex(
            state: InitialStateType,
            action: PayloadAction<null | number>
        ): void {
            state.editBannerIndex = action.payload
        },
        setModalBanner(
            state: InitialStateType,
            action: PayloadAction<string | null>
        ): void {
            state.modalBanner = action.payload
                ? {
                      id: 0,
                      original: action.payload
                  }
                : null
        },
        editBanner(
            state: InitialStateType,
            action: PayloadAction<{
                index: number
                banner: IBannerForm
            }>
        ): void {
            state.banners[action.payload.index] = action.payload.banner
        },
        fetchCoverage(
            state: InitialStateType,
            _action: PayloadAction<FetchCoveragePayload>
        ): void {
            state.isProcessCoverage = true
        },
        fetchCoverageSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCoverageSuccessPayload>
        ): void {
            state.coverage = action.payload.coverage
            state.isProcessCoverage = false
        },
        fetchCoverageFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCoverageFailurePayload>
        ): void {
            state.isProcessCoverage = false
            state.error = action.payload.error
        },
        destroy(_state: InitialStateType, _action: PayloadAction<undefined>) {
            return initialState
        }
    }
})

export const actions = advertisingWizardSlice.actions

export default advertisingWizardSlice.reducer
