import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { ICompanyProperties, IPlan } from './types'

export const fetchCompanyProperties = async (): Promise<
    AxiosResponse<ICompanyProperties[]>
> =>
    get<ICompanyProperties[]>('/admin/companies/properties', {
        includes:
            'companyPropertyOptionValues,companyPropertyOptionValues.companyProperty'
    })

export const fetchPlans = (): Promise<AxiosResponse<IPlan[]>> =>
    get<IPlan[]>('/admin/plans', {
        includes:
            'companyPropertyOptionValues,companyPropertyOptionValues.companyProperty'
    })
