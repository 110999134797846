import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'

export const fetchCountries = (id: string): Promise<AxiosResponse> =>
    get('/v2/areas', { 'filter[group]': id })

export const fetchRegions = (
    id: string,
    depth?: string,
    group?: string
): Promise<AxiosResponse> =>
    get('/v2/areas', {
        'filter[root]': id,
        'filter[depth]': depth,
        'filter[group]': group
    })

export const fetchSearchCities = (
    name: string,
    depth?: string,
    group?: string
): Promise<AxiosResponse> =>
    get('/v2/areas', {
        'filter[name]': name,
        'filter[depth]': depth,
        'filter[group]': group
    })
