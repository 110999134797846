import { useEffect, useState } from 'react'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companySelector } from '../../selectors'
import {
    Col,
    DatePicker,
    Result,
    Row,
    Spin,
    Statistic,
    Tooltip,
    Typography
} from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import 'dayjs/locale/ru'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import {
    analyticsMainNames,
    analyticsSecondaryNames
} from '../../../../utils/helpers'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { RangePicker } = DatePicker
const { Paragraph } = Typography

export default function TabAnalytics() {
    const [isInitial, setIsInitial] = useState(false)
    const [rangeDates, setRangeDates] = useState<[Dayjs, Dayjs]>([
        dayjs().subtract(1, 'month'),
        dayjs()
    ])

    const navigate = useNavigate()

    const { currentCompany, analytics, isFetchingAnalytics } =
        useAppSelector(companySelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (
            currentCompany &&
            Array.isArray(rangeDates) &&
            rangeDates[0] &&
            rangeDates[1]
        ) {
            dispatch(
                actions.fetchAnalytics({
                    companyId: currentCompany.company_id,
                    dateStart: rangeDates[0].format('YYYYMMDD'),
                    dateEnd: rangeDates[1].format('YYYYMMDD')
                })
            )
            setIsInitial(true)
        }
    }, [rangeDates])

    if ((isFetchingAnalytics && analytics.length === 0) || !isInitial) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '80px 0'
                }}
            >
                <Spin size={'large'} />
                <Paragraph
                    style={{ margin: 25, fontSize: 16 }}
                    type={'success'}
                >
                    {'Время загрузки может составлять ~3 минуты'}
                </Paragraph>
            </div>
        )
    }

    if (analytics.length === 0) {
        return <Result title={'Аналитический отчет не доступен!'} />
    }

    return (
        <>
            <Paragraph style={{ margin: '0 0 10px' }} type={'secondary'}>
                {'Выберите период:'}
            </Paragraph>
            <Row>
                <RangePicker
                    format={'DD.MM.YYYY'}
                    locale={locale}
                    style={{ marginBottom: 20 }}
                    allowClear={false}
                    defaultValue={rangeDates}
                    onChange={value => setRangeDates(value as [Dayjs, Dayjs])}
                />
                {isFetchingAnalytics ? (
                    <Spin
                        size={'small'}
                        style={{ marginTop: 8, marginLeft: 12 }}
                    />
                ) : null}
                <Tooltip title={'Прочитать подробнее'}>
                    <QuestionCircleOutlined
                        onClick={() => navigate('/content/admin-analytics')}
                        style={{
                            cursor: 'pointer',
                            marginBottom: 22,
                            marginLeft: 12
                        }}
                    />
                </Tooltip>
            </Row>
            <Paragraph
                style={{ margin: '-16px 0 10px', fontSize: 10 }}
                type={'success'}
            >
                {'Время загрузки может составлять ~3 минуты'}
            </Paragraph>
            <Row gutter={16}>
                <Col span={6}>
                    <h3>{'Общие показатели'}</h3>
                    {analytics
                        .filter(
                            i =>
                                analyticsMainNames[
                                    i.name as keyof typeof analyticsMainNames
                                ]
                        )
                        .map((i, index) => (
                            <Statistic
                                key={`main-${index}`}
                                title={
                                    analyticsMainNames[
                                        i.name as keyof typeof analyticsMainNames
                                    ]
                                }
                                value={i.count}
                            />
                        ))}
                </Col>
                <Col span={8}>
                    <h3>{'Взаимодействие через приложение Mamado'}</h3>
                    {analytics
                        .filter(
                            i =>
                                analyticsSecondaryNames[
                                    i.name as keyof typeof analyticsSecondaryNames
                                ]
                        )
                        .map((i, index) => (
                            <Statistic
                                key={`secondary-${index}`}
                                title={
                                    analyticsSecondaryNames[
                                        i.name as keyof typeof analyticsSecondaryNames
                                    ]
                                }
                                value={i.count}
                            />
                        ))}
                </Col>
            </Row>
        </>
    )
}
