import type { ReactElement } from 'react'
import { Form, Radio, Space, Switch, Typography } from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import RadioDescription from '../../../../components/RadioDescription'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import { useEffect } from 'react'

const { Paragraph } = Typography

export default function TypeStep(): ReactElement {
    const dispatch = useDispatch()

    const { step, hasStepChanges, preCreateData, currentCompany, isSaving } =
        useAppSelector(companyWizardSelector)

    const isCreated = !!currentCompany?.company_id

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                actions.setHasStepChanges(
                    preCreateData.on_house !== currentCompany.on_house
                )
            )
        }
    }, [preCreateData.on_house, currentCompany])

    const handleSaveCompany = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        on_house: preCreateData.on_house
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const handleUndoChanges = () => {
        if (currentCompany) {
            dispatch(
                actions.setParam({
                    on_house: currentCompany.on_house
                })
            )
        }
    }

    return (
        <>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {'Тип компании нельзя изменить после создания компании.'}
            </Paragraph>
            <Radio.Group
                value={currentCompany?.type ?? preCreateData.type}
                disabled={isCreated}
                onChange={event =>
                    dispatch(actions.setParam({ type: event.target.value }))
                }
                style={{ marginBottom: 32 }}
            >
                <Space direction={'vertical'}>
                    <Radio value={'offline'}>
                        {'Офлайн'}
                        <RadioDescription>
                            {
                                'Основная деятельность компании проходит по физическому адресу или с выездом на дом клиенту по городу.'
                            }
                            <br />
                            {
                                'Будет отображена точка на карте, в подборках по услугам и местам рядом с клиентом.'
                            }
                            <br />
                            {
                                'Можно отдельно создавать онлайн-предложения компании.'
                            }
                        </RadioDescription>
                    </Radio>
                    <Radio value={'online'}>
                        {'Онлайн'}
                        <RadioDescription>
                            {
                                'Основная деятельность компании проходит в онлайн сфере (например, онлайн консультации).'
                            }
                            <br />
                            {'Не требуется указание адреса компании.'}
                            <br />
                            {
                                'Вывод в списках онлайн ресурсов и онлайн подборках.'
                            }
                        </RadioDescription>
                    </Radio>
                </Space>
            </Radio.Group>
            <Form.Item label={'Выезд или доставка на дом'}>
                <Switch
                    defaultChecked={preCreateData.on_house}
                    checked={preCreateData.on_house}
                    onChange={value =>
                        dispatch(actions.setParam({ on_house: value }))
                    }
                />
            </Form.Item>
            <NextStepButton
                loading={isSaving}
                disabled={isCreated && !hasStepChanges}
                onClick={isTrusted =>
                    currentCompany
                        ? handleSaveCompany(isTrusted)
                        : dispatch(actions.setStep(step + 1))
                }
                showReturnChangesButton={isCreated && hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {currentCompany ? 'Сохранить' : 'Выбрать и продолжить'}
            </NextStepButton>
        </>
    )
}
