import type { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import ContentPageContainer from '../containers/ContentPage'

export default function ContentPage(): ReactElement {
    const { code } = useParams()

    if (!code) {
        return <></>
    }

    return <ContentPageContainer code={code} />
}
