import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export default function EventPage() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('edit', { replace: true })
    }, [])

    return <></>
}
