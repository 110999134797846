import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    FetchPayload,
    CreateAgentPayload,
    EditAgentPayload,
    RemoveAgentPayload
} from './types.ts'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as api from './api.ts'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import { promoCodesAgentsSelector } from './selectors.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        const { data } = yield call(api.fetch, page, pageSize, filter)
        yield put(
            actions.fetchSuccess({ data: data.data, meta: data.meta, filter })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

function* handleCreatePromoCode(
    action: PayloadAction<CreateAgentPayload>
): SagaIterator {
    try {
        yield call(api.createAgent, action.payload.data)
        yield put(actions.createSuccess())
        const { meta } = yield select(promoCodesAgentsSelector)
        if (meta && meta.currentPage === 1) {
            yield put(
                actions.fetch({
                    page: 1,
                    pageSize: 10,
                    filter: {}
                })
            )
        } else {
            action.payload.clearSearchParams()
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreatePromoCode(): SagaIterator {
    yield takeEvery(actions.create, handleCreatePromoCode)
}

function* handleEditAgent(
    action: PayloadAction<EditAgentPayload>
): SagaIterator {
    try {
        const { agentId, data } = action.payload
        yield call(api.editAgent, agentId, data)
        yield put(actions.editSuccess())
        const { meta, filter } = yield select(promoCodesAgentsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage,
                    filter
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEditAgent(): SagaIterator {
    yield takeEvery(actions.edit, handleEditAgent)
}

function* handleRemoveAgent(
    action: PayloadAction<RemoveAgentPayload>
): SagaIterator {
    try {
        yield call(api.removeAgent, action.payload)
        yield put(actions.removeSuccess())
        const { meta, filter } = yield select(promoCodesAgentsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page: meta.currentPage,
                    pageSize: meta.perPage,
                    filter
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                error: message
            })
        )
    }
}

export function* watchRemoveAgent(): SagaIterator {
    yield takeEvery(actions.remove, handleRemoveAgent)
}

export default function* watchPromoCodesAgents(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchCreatePromoCode),
        fork(watchEditAgent),
        fork(watchRemoveAgent)
    ])
}
