import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    InitialStateType,
    CreatePayload,
    CreateSuccessPayload,
    CreateFailurePayload,
    ChangeTagsPayload,
    SendWizardPayload,
    SendWizardSuccessPayload,
    SendWizardFailurePayload,
    CategoryRemovePayload,
    CategoryRemoveSuccessPayload,
    CategoryRemoveFailurePayload,
    SortCategoriesPayload,
    SortCategoriesSuccessPayload,
    SortCategoriesFailurePayload
} from './types'

const initialState: InitialStateType = {
    categories: [],
    tempCategories: [],
    isFetching: false,
    isSaving: false,
    isSendWizard: false,
    isModalWizard: false,
    isCategoryRemove: false,
    isSortCategoriesProcess: false,
    lastFetchTime: 0,
    error: null
}

const settingsTagsSlice = createSlice({
    name: 'settingsTags',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            const data = action.payload.data
                .map(category => ({
                    ...category,
                    tags: action.payload.tags
                        .filter(
                            tag =>
                                tag.post_category_id ===
                                category.post_category_id
                        )
                        .map(tag => tag.title)
                }))
                .sort((a, b) => {
                    if (
                        typeof a.order === 'number' &&
                        typeof b.order === 'number'
                    ) {
                        return a.order - b.order
                    }
                    return 0
                })
            state.categories = data
            state.tempCategories = data
            state.isFetching = false
            state.lastFetchTime = Date.now()
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        save(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isSaving = true
            state.error = null
        },
        saveSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isSaving = false
        },
        saveFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isSaving = false
            state.error = action.payload.error
        },
        changeTags(
            state: InitialStateType,
            action: PayloadAction<ChangeTagsPayload>
        ) {
            state.categories = state.categories.map(category => {
                if (category.post_category_id === action.payload.categoryId) {
                    return {
                        ...category,
                        tags: action.payload.tags
                    }
                }
                return category
            })
        },
        sendWizard(
            state: InitialStateType,
            _action: PayloadAction<SendWizardPayload>
        ) {
            state.isSendWizard = true
            state.error = null
        },
        sendWizardSuccess(
            state: InitialStateType,
            _action: PayloadAction<SendWizardSuccessPayload>
        ) {
            state.isSendWizard = false
            state.isModalWizard = false
        },
        sendWizardFailure(
            state: InitialStateType,
            action: PayloadAction<SendWizardFailurePayload>
        ) {
            state.isSendWizard = false
            state.error = action.payload.error
        },
        removeCategory(
            state: InitialStateType,
            _action: PayloadAction<CategoryRemovePayload>
        ) {
            state.isCategoryRemove = true
            state.error = null
        },
        removeCategorySuccess(
            state: InitialStateType,
            _action: PayloadAction<CategoryRemoveSuccessPayload>
        ) {
            state.isCategoryRemove = false
            state.isModalWizard = false
        },
        removeCategoryFailure(
            state: InitialStateType,
            action: PayloadAction<CategoryRemoveFailurePayload>
        ) {
            state.isCategoryRemove = false
            state.error = action.payload.error
        },
        sortCategories(
            state: InitialStateType,
            _action: PayloadAction<SortCategoriesPayload>
        ) {
            state.isSortCategoriesProcess = true
            state.error = null
        },
        sortCategoriesSuccess(
            state: InitialStateType,
            _action: PayloadAction<SortCategoriesSuccessPayload>
        ) {
            state.isSortCategoriesProcess = false
        },
        sortCategoriesFailure(
            state: InitialStateType,
            action: PayloadAction<SortCategoriesFailurePayload>
        ) {
            state.isSortCategoriesProcess = false
            state.error = action.payload.error
        },
        toggleModalWizard(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalWizard = action.payload
        }
    }
})

export const actions = settingsTagsSlice.actions

export default settingsTagsSlice.reducer
