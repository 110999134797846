import type { ReactElement } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { convertAge, declOfNum, formatAvg } from '../../utils/helpers'
import { useAppSelector } from '../../hooks/useAppSelector'
import { companySelector } from '../../containers/Company/selectors'
import Lottie from 'lottie-react'

import mamadoLottie from './mamado.lottie.json'

// Styles
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import { useMemo } from 'react'

// Images
import appStore from './images/app-store.png'
import googlePlay from './images/google-play.png'

export default function MobilePreview(): ReactElement {
    const { currentCompany } = useAppSelector(companySelector)

    if (!currentCompany) {
        return <></>
    }

    const timeWork = useMemo(() => {
        const currentWeekDay = dayjs().day()

        const mask = {
            0: 1,
            1: 2,
            2: 3,
            3: 4,
            4: 5,
            5: 6,
            6: 0
        }

        const findDay = currentCompany.companyTimetables
            .filter(el => el.isOpen)
            .find(
                el => mask[el.dayOfWeek as keyof typeof mask] === currentWeekDay
            )

        if (findDay) {
            const currentDate = dayjs()

            const openDate = dayjs()
                .set('hours', parseInt(findDay.openTime.substring(0, 2)))
                .set('minutes', parseInt(findDay.openTime.substring(3, 5)))
                .set('seconds', 0)

            const closeDate = dayjs()
                .set('hours', parseInt(findDay.closeTime.substring(0, 2)))
                .set('minutes', parseInt(findDay.closeTime.substring(3, 5)))
                .set('seconds', 0)

            const isOpen = currentDate >= openDate && currentDate < closeDate

            const nextWeekDay = dayjs().add(1, 'day').day()

            const nextDay = currentCompany.companyTimetables.find(
                el => mask[el.dayOfWeek as keyof typeof mask] === nextWeekDay
            )

            const nextOpenDate = dayjs()
                .set('hours', parseInt(findDay.openTime.substring(0, 2)))
                .set('minutes', parseInt(findDay.openTime.substring(3, 5)))
                .set('seconds', 0)

            return {
                isOpen,
                openTime: nextDay ? nextOpenDate.format('HH:mm') : undefined,
                closeTime: closeDate.format('HH:mm')
            }
        }

        return {
            isOpen: false
        }
    }, [currentCompany.companyTimetables])

    return (
        <div className={styles.outer}>
            <div className={styles.content__phone}>
                <div className={styles['content__phone-background']}>
                    <div className={styles['content__phone-photo']}>
                        <LazyLoadImage
                            src={
                                currentCompany.preview_thumbnails?.wide ??
                                currentCompany.main_image_thumbnail
                            }
                            width={312}
                            height={192}
                        />
                        {currentCompany.checked_by_mamado ? (
                            <div className={styles.checked}>
                                {'Проверено MAMADO'}
                            </div>
                        ) : null}
                    </div>
                    <div className={styles['content__phone-info']}>
                        <div className={styles['content__phone-row']}>
                            <div className={styles['content__phone-row__item']}>
                                {convertAge(currentCompany.age_category, [
                                    currentCompany.age_min,
                                    currentCompany.age_max
                                ])}
                            </div>
                            {timeWork.isOpen ? (
                                <div
                                    className={
                                        styles['content__phone-row__item']
                                    }
                                >
                                    {currentCompany.works_around_the_clock ? (
                                        <>{'Круглосуточно'}</>
                                    ) : (
                                        <>
                                            {timeWork.isOpen
                                                ? 'Открыто'
                                                : 'Закрыто'}{' '}
                                            {timeWork.isOpen ? (
                                                <span>
                                                    {'до '}
                                                    {timeWork.closeTime}
                                                </span>
                                            ) : timeWork.openTime ? (
                                                <span>
                                                    {'до '}
                                                    {timeWork.openTime}
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            ) : null}
                            {/*<div className={styles['content__phone-row__item']}>{distance} км</div>*/}
                        </div>
                        <div className={styles['content__phone-title']}>
                            {currentCompany.name}
                        </div>
                        <div className={styles['content__phone-rating']}>
                            {new Array(5).fill(false).map((_, index) => {
                                if (
                                    index <
                                    Math.round(
                                        Number(
                                            formatAvg(currentCompany.rating_avg)
                                        )
                                    )
                                ) {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                styles[
                                                    'content__phone-rating__active'
                                                ]
                                            }
                                        ></div>
                                    )
                                }
                                return (
                                    <div
                                        key={index}
                                        className={
                                            styles[
                                                'content__phone-rating__inactive'
                                            ]
                                        }
                                    ></div>
                                )
                            })}
                            <span>
                                {currentCompany.rating_count}{' '}
                                {declOfNum(currentCompany.rating_count, [
                                    'отзыв',
                                    'отзыва',
                                    'отзывов'
                                ])}
                            </span>
                        </div>
                        {currentCompany.is_premium ? (
                            <div className={styles['content__phone-popular']}>
                                {'Популярное'}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className={styles.links}>
                <div className={styles.links__col}>
                    <h4>{'Ваша компания размещена\nв экосистеме MAMADO'}</h4>
                    <div className={styles.links__row}>
                        <a
                            href={process.env.APP_STORE_LINK}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <img src={appStore} />
                        </a>
                        <a
                            href={process.env.GOOGLE_PLAY_LINK}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            <img src={googlePlay} />
                        </a>
                    </div>
                </div>
                <div className={styles.links__logo}>
                    <Lottie animationData={mamadoLottie} loop={true} />
                </div>
            </div>
        </div>
    )
}
