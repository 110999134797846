import type { ReactElement, ReactNode } from 'react'

// Styles
import styles from './styles.module.scss'

interface Props {
    children: ReactNode
}

export default function SelectedTreeParent({ children }: Props): ReactElement {
    return (
        <div className={styles.outer}>
            {children}
            <div className={styles.dot} />
        </div>
    )
}
