import type { ReactElement } from 'react'
import CompanyWizard from '../containers/CompanyWizard'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { actions } from '../containers/CompanyWizard/slice'
import { actions as actionsCompany } from '../containers/Company/slice'

export default function CompanyCreatePage(): ReactElement {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.destroy())
        dispatch(actionsCompany.destroy())
    }, [])

    return <CompanyWizard isCreate />
}
