import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    IPreCreateData,
    CreateCompanyPayload,
    CreateCompanySuccessPayload,
    CreateCompanyFailurePayload,
    SaveCompanyPayload,
    SaveCompanySuccessPayload,
    SaveCompanyFailurePayload,
    FetchCompanyPayload,
    FetchCompanySuccessPayload,
    FetchCompanyFailurePayload,
    FetchCategoriesFailurePayload,
    FetchCategoriesPayload,
    FetchCategoriesSuccessPayload,
    FetchFeaturesPayload,
    FetchFeaturesSuccessPayload,
    FetchFeaturesFailurePayload,
    UploadGalleryPayload,
    UploadGallerySuccessPayload,
    UploadGalleryFailurePayload,
    RemovePhotoFailurePayload,
    RemovePhotoPayload,
    RemovePhotoSuccessPayload,
    SortGalleryFailurePayload,
    SortGalleryPayload,
    SortGallerySuccessPayload,
    RemoveVideoPayload,
    RemoveVideoSuccessPayload,
    RemoveVideoFailurePayload,
    SaveVideoFailurePayload,
    SaveVideoPayload,
    SaveVideoSuccessPayload,
    SaveDeliveryPayload,
    SaveDeliverySuccessPayload,
    SaveDeliveryFailurePayload,
    CroppingPhotoPayload,
    CroppingPhotoSuccessPayload,
    CroppingPhotoFailurePayload
} from './types'

const initialState: InitialStateType = {
    step: 0,
    hasStepChanges: false,
    preCreateData: {
        type: 'offline',
        name: '',
        slug: '',
        individual_tax_number: null,
        age_category: 'all',
        age_min: 0,
        age_max: 18,
        on_house: false,
        checked_by_mamado: false
    },
    currentCompany: null,
    categories: [],
    isFetchingCategories: false,
    features: [],
    isFetchingFeatures: false,
    isCreate: false,
    isProcessCreate: false,
    isFetchingCompany: false,
    isSaving: false,
    isSavingDelivery: false,
    isCroppingPhoto: false,
    isUploadPhoto: false,
    removingPhotos: [],
    isSortingGallery: false,
    isSavingVideo: false,
    removingVideos: [],
    error: null
}

const companyWizardSlice = createSlice({
    name: 'companyWizard',
    initialState,
    reducers: {
        setStep(state: InitialStateType, action: PayloadAction<number>) {
            state.step = action.payload
        },
        setHasStepChanges(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.hasStepChanges = action.payload
        },
        setParam(
            state: InitialStateType,
            action: PayloadAction<IPreCreateData>
        ) {
            state.preCreateData = {
                ...state.preCreateData,
                ...action.payload
            }
        },
        createCompany(
            state: InitialStateType,
            _action: PayloadAction<CreateCompanyPayload>
        ) {
            state.isProcessCreate = true
            state.error = null
        },
        createCompanySuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateCompanySuccessPayload>
        ) {
            state.isProcessCreate = false
        },
        createCompanyFailure(
            state: InitialStateType,
            action: PayloadAction<CreateCompanyFailurePayload>
        ) {
            state.isProcessCreate = false
            state.error = action.payload.error
        },
        saveCompany(
            state: InitialStateType,
            _action: PayloadAction<SaveCompanyPayload>
        ) {
            state.isSaving = true
            state.error = null
        },
        saveCompanySuccess(
            state: InitialStateType,
            _action: PayloadAction<SaveCompanySuccessPayload>
        ) {
            state.isSaving = false
        },
        saveCompanyFailure(
            state: InitialStateType,
            action: PayloadAction<SaveCompanyFailurePayload>
        ) {
            state.isSaving = false
            state.error = action.payload.error
        },
        fetchCompany(
            state: InitialStateType,
            _action: PayloadAction<FetchCompanyPayload>
        ) {
            state.isFetchingCompany = true
            state.error = null
        },
        fetchCompanySuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCompanySuccessPayload>
        ) {
            state.preCreateData.on_house = action.payload.data.on_house
            state.currentCompany = action.payload.data
            state.isFetchingCompany = false
        },
        fetchCompanyFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCompanyFailurePayload>
        ) {
            state.isFetchingCompany = false
            state.error = action.payload.error
        },
        fetchCategories(
            state: InitialStateType,
            _action: PayloadAction<FetchCategoriesPayload>
        ) {
            state.isFetchingCategories = true
            state.error = null
        },
        fetchCategoriesSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCategoriesSuccessPayload>
        ) {
            state.categories = action.payload.data
            state.isFetchingCategories = false
        },
        fetchCategoriesFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCategoriesFailurePayload>
        ) {
            state.isFetchingCategories = false
            state.error = action.payload.error
        },
        fetchFeatures(
            state: InitialStateType,
            _action: PayloadAction<FetchFeaturesPayload>
        ) {
            state.isFetchingFeatures = true
            state.error = null
        },
        fetchFeaturesSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchFeaturesSuccessPayload>
        ) {
            state.features = action.payload.data
            state.isFetchingFeatures = false
        },
        fetchFeaturesFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFeaturesFailurePayload>
        ) {
            state.isFetchingFeatures = false
            state.error = action.payload.error
        },
        croppingPhoto(
            state: InitialStateType,
            _action: PayloadAction<CroppingPhotoPayload>
        ) {
            state.isCroppingPhoto = true
        },
        croppingPhotoSuccess(
            state: InitialStateType,
            _action: PayloadAction<CroppingPhotoSuccessPayload>
        ) {
            state.isCroppingPhoto = false
        },
        croppingPhotoFailure(
            state: InitialStateType,
            action: PayloadAction<CroppingPhotoFailurePayload>
        ) {
            state.isCroppingPhoto = false
            state.error = action.payload.error
        },
        uploadGallery(
            state: InitialStateType,
            _action: PayloadAction<UploadGalleryPayload>
        ) {
            state.isUploadPhoto = true
        },
        uploadGallerySuccess(
            state: InitialStateType,
            action: PayloadAction<UploadGallerySuccessPayload>
        ) {
            state.isUploadPhoto = false
            if (state.currentCompany) {
                state.currentCompany.gallery = action.payload.gallery
            }
        },
        uploadGalleryFailure(
            state: InitialStateType,
            action: PayloadAction<UploadGalleryFailurePayload>
        ) {
            state.isUploadPhoto = false
            state.error = action.payload.error
        },
        removePhoto(
            state: InitialStateType,
            action: PayloadAction<RemovePhotoPayload>
        ) {
            state.removingPhotos = [
                ...state.removingPhotos,
                action.payload.media_id
            ]
        },
        removePhotoSuccess(
            state: InitialStateType,
            action: PayloadAction<RemovePhotoSuccessPayload>
        ) {
            state.removingPhotos = state.removingPhotos.filter(
                item => item !== action.payload.media_id
            )
            if (state.currentCompany) {
                state.currentCompany.gallery =
                    state.currentCompany.gallery.filter(
                        item => item.id !== action.payload.media_id
                    )
            }
        },
        removePhotoFailure(
            state: InitialStateType,
            action: PayloadAction<RemovePhotoFailurePayload>
        ) {
            state.removingPhotos = state.removingPhotos.filter(
                item => item !== action.payload.media_id
            )
            state.error = action.payload.error
        },
        sortGallery(
            state: InitialStateType,
            _action: PayloadAction<SortGalleryPayload>
        ) {
            state.isSortingGallery = true
        },
        sortGallerySuccess(
            state: InitialStateType,
            _action: PayloadAction<SortGallerySuccessPayload>
        ) {
            state.isSortingGallery = false
        },
        sortGalleryFailure(
            state: InitialStateType,
            action: PayloadAction<SortGalleryFailurePayload>
        ) {
            state.isSortingGallery = false
            state.error = action.payload.error
        },
        saveVideo(
            state: InitialStateType,
            _action: PayloadAction<SaveVideoPayload>
        ) {
            state.isSavingVideo = true
        },
        saveVideoSuccess(
            state: InitialStateType,
            action: PayloadAction<SaveVideoSuccessPayload>
        ) {
            state.isSavingVideo = false
            if (state.currentCompany) {
                state.currentCompany.videos = action.payload.videos
            }
        },
        saveVideoFailure(
            state: InitialStateType,
            action: PayloadAction<SaveVideoFailurePayload>
        ) {
            state.isSavingVideo = false
            state.error = action.payload.error
        },
        removeVideo(
            state: InitialStateType,
            action: PayloadAction<RemoveVideoPayload>
        ) {
            state.removingVideos = [
                ...state.removingVideos,
                action.payload.media_id
            ]
        },
        removeVideoSuccess(
            state: InitialStateType,
            action: PayloadAction<RemoveVideoSuccessPayload>
        ) {
            state.removingVideos = state.removingVideos.filter(
                item => item !== action.payload.media_id
            )
            if (state.currentCompany) {
                state.currentCompany.videos =
                    state.currentCompany.videos.filter(
                        item => item.id !== action.payload.media_id
                    )
            }
        },
        removeVideoFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveVideoFailurePayload>
        ) {
            state.removingVideos = state.removingVideos.filter(
                item => item !== action.payload.media_id
            )
            state.error = action.payload.error
        },
        saveDelivery(
            state: InitialStateType,
            _action: PayloadAction<SaveDeliveryPayload>
        ) {
            state.isSavingDelivery = true
            state.error = null
        },
        saveDeliverySuccess(
            state: InitialStateType,
            _action: PayloadAction<SaveDeliverySuccessPayload>
        ) {
            state.isSavingDelivery = false
        },
        saveDeliveryFailure(
            state: InitialStateType,
            action: PayloadAction<SaveDeliveryFailurePayload>
        ) {
            state.isSavingDelivery = false
            state.error = action.payload.error
        },
        destroy(_state: InitialStateType, _action: PayloadAction<undefined>) {
            return initialState
        }
    }
})

export const actions = companyWizardSlice.actions

export default companyWizardSlice.reducer
