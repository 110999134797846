import type { AxiosResponse } from 'axios'
import { del, get, post } from '../../utils/api'
import type { tokenData, IProfile, IAddress } from './types'

export const logIn = (
    username: string,
    password: string
): Promise<AxiosResponse<void>> =>
    post<void>('/auth/login', {
        username,
        password
    })

export const logOut = (): Promise<AxiosResponse<tokenData>> =>
    del<tokenData>('/auth/jwt/token')

export const fetchProfile = (): Promise<AxiosResponse<IProfile>> =>
    get<IProfile>('/auth/me')

export const register = (
    email: string,
    password: string
): Promise<AxiosResponse<void>> =>
    post<void>('/auth/register/email', {
        email,
        password
    })

export const registerBusiness = (
    email: string,
    token: string,
    phone?: string
): Promise<AxiosResponse<void>> =>
    post<void>(
        '/business/create',
        {
            email,
            phone,
            communication_method: 'email'
        },
        undefined,
        token
    )

export const sendForgot = (email: string): Promise<AxiosResponse<void>> =>
    post<void>('/auth/forgot/email', {
        email
    })

export const sendNewPassword = (
    email: string,
    token: string,
    password: string
): Promise<AxiosResponse<void>> =>
    post<void>('/auth/password/reset/email', {
        email,
        token,
        password
    })

export const getPaymentSum = (): Promise<AxiosResponse<void>> =>
    get('/admin/user/balance')

export const setAddressProfile = (
    address: IAddress,
    token: string
): Promise<AxiosResponse<void>> =>
    post<void>('/user/addresses', { ...address }, undefined, token)

export const refreshToken = (): Promise<AxiosResponse<void>> =>
    get<void>('/auth/jwt/refresh')
