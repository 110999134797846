import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Spin,
    Upload
} from 'antd'
import { AppstoreAddOutlined, InboxOutlined } from '@ant-design/icons'
import type {
    IBannerForm,
    IThumbnails
} from '../../containers/AdvertisingWizard/types.ts'
import ImgCrop from 'antd-img-crop'

const { TextArea } = Input
const { Dragger } = Upload

// Styles
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { advertisingWizardSelector } from '../../containers/AdvertisingWizard/selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/AdvertisingWizard/slice.ts'
import { bannerTypes, getCroppedImg } from '../../utils/helpers.ts'
import ModalCrop from '../ModalCrop'

interface Props {
    showModal: boolean
    onClose: () => void
    onAddBanner: (banner: IBannerForm) => void
}

const exampleCroppingImages = {
    first: {
        image: '',
        coords: {
            width: 0,
            height: 0,
            x_offset: 0,
            y_offset: 0
        }
    },
    second: {
        image: '',
        coords: {
            width: 0,
            height: 0,
            x_offset: 0,
            y_offset: 0
        }
    }
}

export default function AdBannerModal({
    showModal,
    onClose,
    onAddBanner
}: Props): ReactElement {
    const [isModalCrop, setIsModalCrop] = useState<'first' | 'second' | false>(
        false
    )
    const [croppingImages, setCroppingImages] = useState({
        ...exampleCroppingImages
    })

    const [form] = Form.useForm()

    const { modalBanner, banners, isCreateNormalBanner, editBannerIndex } =
        useAppSelector(advertisingWizardSelector)

    const watchTitle = Form.useWatch('title', form)
    const watchDescription = Form.useWatch('description', form)
    const watchColor = Form.useWatch('color', form)
    const watchTag = Form.useWatch('tag', form)

    const dispatch = useDispatch()

    const isEditBanner = typeof editBannerIndex === 'number'

    const handleClose = () => {
        if (isEditBanner) {
            dispatch(actions.setModalBanner(null))
            dispatch(actions.setEditBannerIndex(null))
        }
        form.resetFields()
        dispatch(actions.clearModalBanner())
        setCroppingImages({ ...exampleCroppingImages })
        onClose()
    }

    useEffect(() => {
        if (isEditBanner) {
            const findBanner = banners[editBannerIndex]

            if (findBanner) {
                const data = {
                    title: findBanner.title,
                    description: findBanner.description,
                    tag: findBanner.tag,
                    color: findBanner.color
                }
                form.setFieldsValue(data)

                const result = structuredClone(exampleCroppingImages)

                if (
                    typeof findBanner.custom_banner?.thumbnails === 'object' &&
                    !Array.isArray(findBanner.custom_banner?.thumbnails)
                ) {
                    for (const thumb in findBanner.custom_banner
                        .thumbnails as IThumbnails[]) {
                        if (thumb in result) {
                            result[thumb as keyof typeof result].image =
                                findBanner.custom_banner.thumbnails[
                                    thumb as keyof typeof findBanner.custom_banner.thumbnails
                                ] as string
                        }
                    }
                }

                if (Array.isArray(findBanner.thumbnails)) {
                    for (const thumb of findBanner.thumbnails) {
                        const key =
                            result[thumb.crop_type as keyof typeof result]
                        if (key) {
                            key.image = thumb.image as string
                            key.coords = {
                                width: thumb.width,
                                height: thumb.height,
                                x_offset: thumb.x_offset,
                                y_offset: thumb.y_offset
                            }
                        }
                    }
                }

                setCroppingImages({ ...result })

                if (findBanner.custom_banner) {
                    dispatch(
                        actions.setModalBanner(
                            findBanner.custom_banner.original
                        )
                    )
                }
            }
        }
    }, [editBannerIndex])

    const handleFinishForm = (data: IBannerForm) => {
        const thumbnails = []

        for (const thumb in croppingImages) {
            const image = croppingImages[thumb as keyof typeof croppingImages]

            if (image.image && image.image.startsWith('data')) {
                thumbnails.push({
                    crop_type: thumb,
                    image: image.image,
                    ...image.coords
                })
            }
        }

        if (isEditBanner) {
            const currentBanner = banners[editBannerIndex]
            dispatch(
                actions.editBanner({
                    index: editBannerIndex,
                    banner: {
                        ...currentBanner,
                        ...data,
                        ...(modalBanner && modalBanner.id !== 0
                            ? {
                                  custom_banner: {
                                      id: modalBanner.id,
                                      original: modalBanner.original
                                  }
                              }
                            : {}),
                        type: 'custom',
                        thumbnails
                    }
                })
            )
        } else {
            onAddBanner({
                ...data,
                ...(modalBanner
                    ? {
                          custom_banner: {
                              id: modalBanner.id,
                              original: modalBanner.original
                          }
                      }
                    : {}),
                type: 'custom',
                thumbnails
            })
        }
        handleClose()
    }

    return (
        <Modal
            title={
                <>
                    <AppstoreAddOutlined style={{ marginRight: 5 }} />{' '}
                    {isEditBanner ? 'Изменение баннера' : 'Сборка баннера'}
                </>
            }
            width={700}
            open={showModal}
            onCancel={isEditBanner ? handleClose : onClose}
            destroyOnClose
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            style={{ top: 20 }}
        >
            <Row gutter={[10, 10]}>
                <Col span={12}>
                    <Form
                        form={form}
                        layout={'vertical'}
                        initialValues={{
                            color: 'pink',
                            tag: 'empty'
                        }}
                        onFinish={handleFinishForm}
                        autoComplete={'off'}
                    >
                        <Form.Item
                            style={{ marginBottom: 10 }}
                            label={'Заголовок'}
                            name={'title'}
                        >
                            <Input placeholder={'Заголовок'} maxLength={38} />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: 10 }}
                            label={'Текст подзаголовка'}
                            name={'description'}
                        >
                            <TextArea
                                placeholder={'Текст подзаголовка'}
                                maxLength={80}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: 10 }}
                            label={'Тег'}
                            name={'tag'}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={'Тег'}
                                options={[
                                    { value: 'empty', label: 'Не выбрано' },
                                    { value: 'akcia', label: 'Акция' },
                                    { value: 'free', label: 'Бесплатно' },
                                    { value: 'promo', label: 'Промо' },
                                    {
                                        value: 'discount',
                                        label: 'Скидка'
                                    },
                                    {
                                        value: 'new',
                                        label: 'Новинка'
                                    }
                                ]}
                            />
                        </Form.Item>
                        <Form.Item
                            label={'Выбор цвета затемнения'}
                            name={'color'}
                            style={{ marginBottom: 15 }}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder={'Тег'}
                                options={[
                                    { value: 'pink', label: 'Розовый' },
                                    { value: 'green', label: 'Зеленый' },
                                    { value: 'blue', label: 'Голубой' },
                                    {
                                        value: 'yellow',
                                        label: 'Желтый'
                                    },
                                    {
                                        value: 'purple',
                                        label: 'Фиолетовый'
                                    },
                                    {
                                        value: 'black',
                                        label: 'Черный'
                                    }
                                ]}
                            />
                        </Form.Item>
                        <ImgCrop
                            rotationSlider={false}
                            modalTitle={'Выбрать область'}
                            modalOk={'Выбрать'}
                        >
                            <Dragger
                                className={styles.upload}
                                showUploadList={false}
                                accept={'image/*'}
                                multiple
                                disabled={false}
                                beforeUpload={file => {
                                    dispatch(
                                        actions.createNormalBanner({
                                            image: file,
                                            isCustomBanner: true
                                        })
                                    )
                                    return false
                                }}
                            >
                                {isCreateNormalBanner ? (
                                    <Spin size={'large'} />
                                ) : (
                                    <>
                                        <p className={'ant-upload-drag-icon'}>
                                            <InboxOutlined />
                                        </p>
                                        <p className={'ant-upload-text'}>
                                            {`Перенесите или нажмите для ${modalBanner ? 'изменения' : 'добавления'} баннера`}
                                        </p>
                                    </>
                                )}
                            </Dragger>
                        </ImgCrop>
                    </Form>
                </Col>
                <Col span={12}>
                    {modalBanner ? (
                        <>
                            <div className={styles.label}>
                                {'Предпросмотр баннера для зоны показа 1'}
                            </div>
                            <div
                                className={classNames({
                                    [styles.banner]: true,
                                    [styles[watchColor]]: true
                                })}
                                onClick={() => setIsModalCrop('first')}
                            >
                                {watchTag !== 'empty' ? (
                                    <div
                                        className={classNames({
                                            [styles.type]: true,
                                            [styles[`type__${watchTag}`]]: true
                                        })}
                                    >
                                        {
                                            bannerTypes[
                                                watchTag as keyof typeof bannerTypes
                                            ]
                                        }
                                    </div>
                                ) : null}
                                <div className={styles.banner__bottom}>
                                    <div
                                        className={
                                            styles['banner__bottom-title']
                                        }
                                    >
                                        {watchTitle}
                                    </div>
                                    <div
                                        className={
                                            styles['banner__bottom-description']
                                        }
                                    >
                                        {watchDescription}
                                    </div>
                                </div>
                                <img
                                    src={
                                        croppingImages.first.image ||
                                        modalBanner.original
                                    }
                                    draggable={false}
                                />
                            </div>
                            <div className={styles.label}>
                                {'Предпросмотр баннера для зоны показа 2'}
                            </div>
                            <div className={styles.second}>
                                {watchTag !== 'empty' ? (
                                    <div
                                        className={classNames({
                                            [styles.type]: true,
                                            [styles[`type__${watchTag}`]]: true
                                        })}
                                    >
                                        {
                                            bannerTypes[
                                                watchTag as keyof typeof bannerTypes
                                            ]
                                        }
                                    </div>
                                ) : null}
                                <div className={styles.second__image}>
                                    <img
                                        src={
                                            croppingImages.second.image ||
                                            modalBanner.original
                                        }
                                        draggable={false}
                                        onClick={() => setIsModalCrop('second')}
                                    />
                                </div>
                                <div className={styles.second__info}>
                                    <div
                                        className={styles['second__info-title']}
                                    >
                                        {watchTitle}
                                    </div>
                                    <div
                                        className={
                                            styles['second__info-description']
                                        }
                                    >
                                        {watchDescription}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div
                            style={{ marginTop: 250 }}
                            className={styles.label}
                        >
                            {
                                'Для предпросмотра вариантов баннера, добавьте изображение.'
                            }
                        </div>
                    )}
                </Col>
            </Row>
            <Button
                disabled={!modalBanner}
                type={'primary'}
                style={{ marginTop: 20 }}
                onClick={() => form.submit()}
            >
                {isEditBanner ? 'Изменить баннер' : 'Добавить баннер'}
            </Button>
            {modalBanner?.original ? (
                <ModalCrop
                    image={modalBanner.original}
                    open={typeof isModalCrop === 'string'}
                    onCancel={() => setIsModalCrop(false)}
                    onOk={({ width, height, x, y }) => {
                        ;(async () => {
                            const image = await getCroppedImg(
                                modalBanner.original,
                                {
                                    width: width || 0,
                                    height: height || 0,
                                    x: x || 0,
                                    y: y || 0
                                }
                            )
                            setCroppingImages(prev => ({
                                ...prev,
                                [isModalCrop as 'first' | 'second']: {
                                    image,
                                    coords: {
                                        width: Math.round(width || 0),
                                        height: Math.round(height || 0),
                                        x_offset: Math.round(x || 1),
                                        y_offset: Math.round(y || 1)
                                    }
                                }
                            }))
                            setIsModalCrop(false)
                        })()
                    }}
                    aspect={isModalCrop === 'first' ? 1 : 300 / 165}
                />
            ) : null}
        </Modal>
    )
}
