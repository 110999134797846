import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    RemoveFailurePayload,
    RemovePayload,
    RemoveSuccessPayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    filter: {},
    isFetching: false,
    removing: [],
    error: null
}

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        fetch(
            state: InitialStateType,
            action: PayloadAction<FetchPayload>
        ): void {
            state.isFetching = true
            state.filter = action.payload.filter
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ): void {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ): void {
            state.isFetching = false
            state.error = action.payload.error
        },
        remove(
            state: InitialStateType,
            action: PayloadAction<RemovePayload>
        ): void {
            state.removing.push(action.payload.post_id)
        },
        removeSuccess(
            state: InitialStateType,
            action: PayloadAction<RemoveSuccessPayload>
        ): void {
            state.removing = state.removing.filter(
                id => id !== action.payload.post_id
            )
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ): void {
            state.removing = state.removing.filter(
                id => id !== action.payload.post_id
            )
            state.error = action.payload.error
        }
    }
})

export const actions = blogSlice.actions

export default blogSlice.reducer
