import type { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { companyWizardSelector } from '../../selectors'
import { Button, Form, Input, Radio, Row, Space, Typography } from 'antd'
import RadioDescription from '../../../../components/RadioDescription'
// import { CrownFilled } from '@ant-design/icons'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import isUrl from 'is-url'

const { Paragraph } = Typography

// Styles
// import styles from './styles.module.scss'

const values = {
    offers: {
        selected: true,
        value: undefined
    },
    website: {
        selected: false,
        value: ''
    },
    booking: {
        selected: false,
        value: ''
    },
    purchase: {
        selected: false,
        value: ''
    }
}

export default function TargetStep(): ReactElement {
    const [targetActions, setTargetActions] = useState<typeof values>(values)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [typeCheck, setTypeCheck] = useState<false | 'success' | 'failure'>(
        false
    )
    // const [api, contextHolder] = notification.useNotification()

    const dispatch = useDispatch()

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const selectedTargetAction: keyof typeof targetActions | undefined =
        useMemo(() => {
            for (const item in targetActions) {
                if (
                    targetActions[item as keyof typeof targetActions].selected
                ) {
                    return item as keyof typeof targetActions
                }
            }
        }, [targetActions])

    const isUrlChecked =
        selectedTargetAction &&
        typeof targetActions[selectedTargetAction as keyof typeof targetActions]
            .value == 'string'
            ? isUrl(
                  targetActions[
                      selectedTargetAction as keyof typeof targetActions
                  ].value ?? ''
              )
            : true

    const isShowOk =
        isConfirmed ||
        targetActions[selectedTargetAction as keyof typeof targetActions]
            ?.value === '' ||
        !hasStepChanges

    const handleChangeValue = (value: string) => {
        const newValues = targetActions
        newValues[selectedTargetAction as keyof typeof newValues].value = value
        setTargetActions({ ...newValues })
    }

    const handleConfirmUrl = () => {
        if (
            targetActions[
                selectedTargetAction as keyof typeof targetActions
            ].value?.startsWith('http')
        ) {
            const isChecked = isUrl(
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value ?? ''
            )
            setTypeCheck(isChecked ? 'success' : 'failure')
            setIsConfirmed(true)
        } else {
            const value = `http://${
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value
            }`
            const isChecked = isUrl(value)
            setTypeCheck(isChecked ? 'success' : 'failure')
            handleChangeValue(value)
            setIsConfirmed(true)
        }
    }

    useEffect(() => {
        setTypeCheck(false)
    }, [currentCompany])

    useEffect(() => {
        if (currentCompany) {
            let selectedTargetKey, selectedTargetValue

            for (const key in targetActions) {
                if (targetActions[key as keyof typeof targetActions].selected) {
                    selectedTargetKey = key
                    selectedTargetValue =
                        targetActions[key as keyof typeof targetActions].value
                    break
                }
            }

            const hasChange =
                currentCompany.target_action != selectedTargetKey ||
                (currentCompany.target_action !== 'offers'
                    ? currentCompany.target_action_url != selectedTargetValue
                    : false)

            dispatch(actions.setHasStepChanges(hasChange))
        }
    }, [currentCompany, targetActions])

    // useEffect(() => {
    //     api.info({
    //         message: 'Информация',
    //         description:
    //             'По умолчанию, без добавленного "Премиум статуса" к компании, будет выводиться кнопка "Перейти к предложениям"',
    //         placement: 'bottomRight',
    //         duration: 5000
    //     })
    // }, [])

    const handleUndoChanges = () => {
        if (currentCompany) {
            const newValues: typeof targetActions = targetActions
            for (const item in newValues) {
                const isCurrent =
                    currentCompany.target_action != null &&
                    currentCompany.target_action === item
                newValues[item as keyof typeof newValues].selected = isCurrent
                if (isCurrent) {
                    if (currentCompany.target_action_url) {
                        newValues[item as keyof typeof newValues].value =
                            currentCompany.target_action_url
                    }
                }
            }
            setTargetActions({ ...newValues })
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleChangeTargetActions = (value: keyof typeof targetActions) => {
        const newValues = targetActions
        for (const item in targetActions) {
            newValues[item as keyof typeof targetActions].selected =
                value === item
        }
        setTargetActions({ ...newValues })
    }

    const handleSaveTarget = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        target_action: selectedTargetAction,
                        target_action_url:
                            targetActions[
                                selectedTargetAction as keyof typeof targetActions
                            ].value
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    return (
        <>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {'Укажите какую кнопку выводить в карточке компании.'}
            </Paragraph>
            <Space direction={'vertical'}>
                <Radio.Group
                    onChange={({ target }) =>
                        handleChangeTargetActions(target.value)
                    }
                    value={selectedTargetAction}
                >
                    <Space direction={'vertical'}>
                        <Radio value={'offers'}>
                            {'Перейти к предложениям'}
                            <RadioDescription>
                                {'Переход в список предложений компании'}
                            </RadioDescription>
                        </Radio>
                        <Radio value={'website'}>
                            <Row>
                                {'Перейти на сайт'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {'Переход на сайт или другой ресурс компании'}
                            </RadioDescription>
                        </Radio>
                        <Radio value={'booking'}>
                            <Row>
                                {'Перейти к бронированию'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {'Переход на внешнюю ссылку бронирования'}
                            </RadioDescription>
                        </Radio>
                        <Radio value={'purchase'}>
                            <Row>
                                {'Перейти к покупке'}
                                {/*<CrownFilled className={styles.crown} />*/}
                            </Row>
                            <RadioDescription>
                                {'Переход на внешнюю ссылку покупки'}
                            </RadioDescription>
                        </Radio>
                    </Space>
                </Radio.Group>
                {selectedTargetAction &&
                selectedTargetAction !== 'offers' &&
                targetActions[
                    selectedTargetAction as keyof typeof targetActions
                ].value != null ? (
                    <Form layout={'vertical'}>
                        <Form.Item
                            style={{ marginTop: 20 }}
                            label={'Ссылка на сайт, другой ресурс, мессенджер'}
                        >
                            <Input
                                suffix={
                                    <Button
                                        style={{
                                            visibility: !isShowOk
                                                ? 'visible'
                                                : 'hidden',
                                            transition: '0s'
                                        }}
                                        onClick={handleConfirmUrl}
                                    >
                                        {isShowOk ? '' : 'Ок'}
                                    </Button>
                                }
                                value={
                                    targetActions[selectedTargetAction].value
                                }
                                style={{ height: 42 }}
                                placeholder={
                                    'Ссылка на сайт, другой ресурс, мессенджер'
                                }
                                onChange={({ target }) => {
                                    handleChangeValue(target.value)
                                    setIsConfirmed(false)
                                }}
                            />
                            {typeCheck ? (
                                typeCheck === 'success' ? (
                                    <div
                                        style={{ fontSize: 14, color: 'green' }}
                                    >
                                        {'Адрес введен корректно'}
                                    </div>
                                ) : (
                                    <div style={{ fontSize: 14, color: 'red' }}>
                                        {'Адрес введен не верно!'}
                                    </div>
                                )
                            ) : null}
                        </Form.Item>
                    </Form>
                ) : null}
            </Space>
            <NextStepButton
                disabled={
                    !selectedTargetAction ||
                    !hasStepChanges ||
                    (selectedTargetAction !== 'offers'
                        ? !isConfirmed || !isUrlChecked
                        : false)
                }
                loading={isSaving}
                onClick={handleSaveTarget}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            {/*{contextHolder}*/}
        </>
    )
}
