import { Button, Flex, Result } from 'antd'
import type { ReactElement } from 'react'
import { HomeOutlined } from '@ant-design/icons'

function ErrorWrapper(): ReactElement {
    return (
        <Flex style={{ height: '100vh' }} justify={'center'} align={'center'}>
            <Result
                status={'500'}
                title={'500'}
                subTitle={
                    <>
                        {'Упс, у нас что-то сломалось.'}
                        <br />
                        {'Скоро исправим.'}
                    </>
                }
                extra={
                    <Button
                        onClick={() => (document.location.href = '/')}
                        type={'primary'}
                        icon={<HomeOutlined />}
                    >
                        {'Вернуться на главную'}
                    </Button>
                }
            />
        </Flex>
    )
}

export default ErrorWrapper
