import {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload, FetchPaymentUrlPayload, FetchPaymentUrlSuccessPayload, FetchPaymentUrlFailurePayload
} from "./types.ts";
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    isFetching: false,
    isFetchingPaymentUrl: false,
    error: null
}

const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        fetchPaymentUrl(
            state: InitialStateType,
            _action: PayloadAction<FetchPaymentUrlPayload>
        ) {
            state.isFetchingPaymentUrl = true
            state.error = null
        },
        fetchPaymentUrlSuccess(
            state: InitialStateType,
            _action: PayloadAction<FetchPaymentUrlSuccessPayload>
        ) {
            state.isFetchingPaymentUrl = false
        },
        fetchPaymentUrlFailure(
            state: InitialStateType,
            action: PayloadAction<FetchPaymentUrlFailurePayload>
        ) {
            state.isFetchingPaymentUrl = false
            state.error = action.payload.error
        }
    }
})

export const actions = balanceSlice.actions

export default balanceSlice.reducer
