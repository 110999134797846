import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import dayjs from 'dayjs'
import ruRU from 'antd/es/date-picker/locale/ru_RU'
import { SearchOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import UserSelector from '../../containers/UserSelector'
import BusinessUserSelector from '../../containers/BusinessUserSelector'
import RegionSelector from '../../containers/RegionSelector'
import FranchiseeSelector from '../../containers/FranchiseeSelector'
import { getRole, ignoreKeys } from '../../utils/helpers'
import { useAppSelector } from '../../hooks/useAppSelector'
import { applicationSelector } from '../../containers/Application/selectors'
import { authorizationSelector } from '../../containers/Authorization/selectors'
import CompanyPropertySelector from '../../containers/CompanyPropertySelector'
import CompanyCategoriesSelector from '../../containers/CompanyCategoriesSelector'
// import CompanyPropertySelector from '../../containers/CompanyPropertySelector'

const { RangePicker } = DatePicker

export default function CompanyFilter(): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const { plans, plansIsFetching } = useAppSelector(applicationSelector)
    const { profile } = useAppSelector(authorizationSelector)

    const isUser = profile ? getRole(profile.roles) === 'user' : true

    const [form] = Form.useForm()

    const watchFranchiseeId = Form.useWatch('franchisee_id', form)
    const watchSmartCategories = Form.useWatch('smart_categories', form)

    useEffect(() => {
        form.resetFields()
        const result: Record<string, unknown> = {}

        const dateKeys = ['created', 'subscription_range']
        const arrayKeys = ['smart_categories']

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }

            if (dateKeys.includes(key)) {
                const [from, to] = value.split(',')

                const regExp = /(\d{4})(\d{2})(\d{2})/

                const formatFrom = from.replace(regExp, '$1-$2-$3')
                const formatTo = to.replace(regExp, '$1-$2-$3')

                result[key] = [dayjs(formatFrom), dayjs(formatTo)]
            } else if (key === 'subscription_active') {
                result[key] = dayjs(value)
            } else if (arrayKeys.includes(key)) {
                result[key] = value.split(',')
            } else {
                result[key] = value
            }
        })
        form.setFieldsValue(result)
        setDateUpdate(Date.now().toString())
    }, [searchParams])

    const { companyProperties } = useAppSelector(applicationSelector)

    const handleChangeFormItem = (
        key: string,
        value: string | string[] | dayjs.ConfigType[] | dayjs.Dayjs
    ): void => {
        form.setFieldValue(key, value)
    }

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        const dateKeys = ['created', 'subscription_range']
        const arrayKeys = ['smart_categories']

        for (const param in values) {
            if (dateKeys.includes(param) && Array.isArray(values[param])) {
                const [from, to] = values[param] as dayjs.ConfigType[]
                const formatFrom = dayjs(from).format('YYYYMMDD')
                const formatTo = dayjs(to).format('YYYYMMDD')

                result[param] = `${formatFrom},${formatTo}`
                continue
            }

            if (param === 'subscription_active' && !!values[param]) {
                result[param] = dayjs(values[param] as dayjs.Dayjs).format(
                    'YYYY-MM-DD'
                )
                continue
            }

            if (arrayKeys.includes(param)) {
                const arr = values[param] as string[]
                if (Array.isArray(arr) && arr.length > 0) {
                    result[param] = arr.join(',')
                }
                continue
            }

            const isEmptyString =
                typeof values[param] === 'string' &&
                (values[param] as string).length === 0

            const isCompanyProperties =
                typeof values[param] === 'string' &&
                companyProperties.some(property => property.key === param)

            if (
                (values[param] != null && !isEmptyString) ||
                isCompanyProperties
            ) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10} wrap={true}>
                <Col span={6}>
                    <FormItemWithLabel
                        name={'name'}
                        label={'Название компании'}
                    >
                        <Input allowClear placeholder={'Название компании'} />
                    </FormItemWithLabel>
                </Col>
                {!isUser ? (
                    <>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'owner_id'}
                                label={'Пользователь'}
                            >
                                <UserSelector
                                    initialValue={form.getFieldValue(
                                        'owner_id'
                                    )}
                                    onChange={value =>
                                        handleChangeFormItem('owner_id', value)
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'business_account_id'}
                                label={'Бизнес аккаунт'}
                            >
                                <BusinessUserSelector
                                    initialValue={form.getFieldValue(
                                        'business_account_id'
                                    )}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'business_account_id',
                                            value
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                    </>
                ) : null}
                <Col span={6}>
                    <FormItemWithLabel name={'area_id'} label={'Регион'}>
                        <RegionSelector
                            initialValue={form.getFieldValue('area_id')}
                            onChange={value =>
                                handleChangeFormItem('area_id', value as string)
                            }
                        />
                    </FormItemWithLabel>
                </Col>
                {!isUser ? (
                    <Col span={6}>
                        <FormItemWithLabel
                            name={'created'}
                            label={'Дата создания компании'}
                        >
                            <RangePicker
                                style={{ width: '100%' }}
                                format={'DD.MM.YYYY'}
                                locale={ruRU}
                                allowClear={true}
                                onChange={value =>
                                    handleChangeFormItem(
                                        'created',
                                        value as dayjs.ConfigType[]
                                    )
                                }
                            />
                        </FormItemWithLabel>
                    </Col>
                ) : null}
                {!isUser ? (
                    <Col span={3}>
                        <FormItemWithLabel
                            name={'has_owner'}
                            label={'Есть владелец'}
                        >
                            <Select
                                allowClear={true}
                                placeholder={'Есть владелец'}
                                style={{ width: '100%' }}
                                options={[
                                    {
                                        value: 'true',
                                        label: 'Да'
                                    },
                                    {
                                        value: 'false',
                                        label: 'Нет'
                                    }
                                ]}
                            />
                        </FormItemWithLabel>
                    </Col>
                ) : null}
                {!isUser ? (
                    <>
                        <Col span={3}>
                            <FormItemWithLabel
                                name={'cached_displayed'}
                                label={'Статус публикации'}
                            >
                                <Select
                                    placeholder={'Статус публикации'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'Опубликован'
                                        },
                                        {
                                            value: 'false',
                                            label: 'Снят с публикации'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={3}>
                            <FormItemWithLabel
                                name={'has_premium'}
                                label={'Премиум статус'}
                            >
                                <Select
                                    allowClear={true}
                                    placeholder={'Премиум статус'}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'Премиум'
                                        },
                                        {
                                            value: 'false',
                                            label: 'Обычный'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                    </>
                ) : null}
                <Col span={isUser ? 6 : 3}>
                    <FormItemWithLabel
                        name={'has_events'}
                        label={'Есть предложения'}
                    >
                        <Select
                            placeholder={'Есть предложения'}
                            allowClear={true}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 'true',
                                    label: 'Да'
                                },
                                {
                                    value: 'false',
                                    label: 'Нет'
                                }
                            ]}
                        />
                    </FormItemWithLabel>
                </Col>
                {/*<Col span={6}>*/}
                {/*    <FormItemWithLabel*/}
                {/*        name={'empty_about'}*/}
                {/*        label={'Есть описание'}*/}
                {/*    >*/}
                {/*        <Select*/}
                {/*            placeholder={'Есть описание'}*/}
                {/*            allowClear={true}*/}
                {/*            style={{ width: '100%' }}*/}
                {/*            options={[*/}
                {/*                {*/}
                {/*                    value: 'false',*/}
                {/*                    label: 'Да'*/}
                {/*                },*/}
                {/*                {*/}
                {/*                    value: 'true',*/}
                {/*                    label: 'Нет'*/}
                {/*                }*/}
                {/*            ]}*/}
                {/*        />*/}
                {/*    </FormItemWithLabel>*/}
                {/*</Col>*/}
                {!isUser ? (
                    <>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'franchisee_id'}
                                label={'Франчайзи'}
                            >
                                <FranchiseeSelector
                                    initialValue={watchFranchiseeId}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'franchisee_id',
                                            value
                                        )
                                    }
                                    hasEmptyValue={true}
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={3}>
                            <FormItemWithLabel
                                name={'checked'}
                                label={'Проверено Mamado'}
                            >
                                <Select
                                    allowClear={true}
                                    placeholder={'Проверено Mamado'}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'Да'
                                        },
                                        {
                                            value: 'false',
                                            label: 'Нет'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                    </>
                ) : null}
                <Col span={isUser ? 6 : 3}>
                    <FormItemWithLabel name={'type'} label={'Тип компании'}>
                        <Select
                            placeholder={'Тип компании'}
                            allowClear={true}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 'online',
                                    label: 'Онлайн'
                                },
                                {
                                    value: 'offline',
                                    label: 'Оффлайн'
                                }
                            ]}
                        />
                    </FormItemWithLabel>
                </Col>
                {!isUser ? (
                    <>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'subscription_range'}
                                label={'Период подключения тарифа'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    allowClear={true}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'subscription_range',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'subscription_active'}
                                label={'Тариф активен на дату'}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    allowClear={true}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'subscription_active',
                                            value as dayjs.Dayjs
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'plan_subscription_id'}
                                label={'Активный тариф'}
                            >
                                <Select
                                    placeholder={'Активный тариф'}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    loading={plansIsFetching}
                                    options={plans.map(item => ({
                                        label: item.name.title,
                                        value: item.planId
                                    }))}
                                />
                            </FormItemWithLabel>
                        </Col>
                        {!isUser ? (
                            <>
                                <Col span={3}>
                                    <FormItemWithLabel
                                        name={'status'}
                                        label={'Черновик'}
                                    >
                                        <Select
                                            allowClear={true}
                                            placeholder={'Черновик'}
                                            style={{ width: '100%' }}
                                            options={[
                                                {
                                                    value: 'draft',
                                                    label: 'Да'
                                                },
                                                {
                                                    value: 'active',
                                                    label: 'Нет'
                                                }
                                            ]}
                                        />
                                    </FormItemWithLabel>
                                </Col>
                                <Col span={3}>
                                    <FormItemWithLabel
                                        name={'published'}
                                        label={'Компания в архиве'}
                                    >
                                        <Select
                                            allowClear={true}
                                            placeholder={'Компания в архиве'}
                                            style={{ width: '100%' }}
                                            options={[
                                                {
                                                    value: 'false',
                                                    label: 'Да'
                                                },
                                                {
                                                    value: 'true',
                                                    label: 'Нет'
                                                }
                                            ]}
                                        />
                                    </FormItemWithLabel>
                                </Col>
                            </>
                        ) : null}
                    </>
                ) : null}
                <Col span={6}>
                    <FormItemWithLabel
                        name={'smart_categories'}
                        label={'Категория'}
                    >
                        <CompanyCategoriesSelector
                            placeholder={'Компания в архиве'}
                            initialValue={watchSmartCategories}
                            onChange={value =>
                                handleChangeFormItem('smart_categories', value)
                            }
                        />
                    </FormItemWithLabel>
                </Col>
                {/*todo Для правильной работы, нужно изменение логики на беке*/}
                {companyProperties.map(property => (
                    <Col key={property.company_property_id} span={6}>
                        <FormItemWithLabel
                            name={property.key}
                            label={property.caption}
                        >
                            <CompanyPropertySelector
                                key={form.getFieldValue(property.key)}
                                selectedValue={form.getFieldValue(property.key)}
                                propertyId={property.company_property_id}
                                placeholder={property.caption}
                                onChange={value =>
                                    handleChangeFormItem(
                                        property.key,
                                        value.value
                                    )
                                }
                            />
                        </FormItemWithLabel>
                    </Col>
                ))}
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<SearchOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Искать компании'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={() => {
                        setSearchParams({})
                        form.resetFields()
                        form.setFieldsValue({ subscription_active: undefined })
                    }}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
