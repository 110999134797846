import type { ReactElement } from 'react'
import CompanyWizard from '../containers/CompanyWizard'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppSelector } from '../hooks/useAppSelector'
import Error500 from '../components/Error500'
import { useDispatch } from 'react-redux'
import { actions } from '../containers/CompanyWizard/slice'
import { LoadingStep } from '../components/LoadingStep'
import { companyWizardSelector } from '../containers/CompanyWizard/selectors'

export default function CompanyEditPage(): ReactElement {
    const { company_id } = useParams()

    const dispatch = useDispatch()

    const { currentCompany, isFetchingCompany } = useAppSelector(
        companyWizardSelector
    )

    useEffect(() => {
        dispatch(actions.destroy())
    }, [])

    useEffect(() => {
        if (company_id) {
            dispatch(actions.fetchCompany(company_id))
        }
    }, [company_id])

    if (isFetchingCompany && !currentCompany) {
        return <LoadingStep />
    }

    return currentCompany ? (
        <CompanyWizard />
    ) : (
        <Error500 description={'Произошла ошибка запроса компании'} />
    )
}
