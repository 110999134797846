import type { ReactElement } from 'react'
import { useMemo } from 'react'
import { useRef } from 'react'
import { useState, useEffect } from 'react'
import type { InputRef } from 'antd'
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    message,
    Typography
} from 'antd'
import {
    BarcodeOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import BusinessUserSelector from '../../containers/BusinessUserSelector'
import { generatePromoCode } from '../../utils/helpers.ts'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { promoCodesSelector } from '../../containers/PromoCodes/selectors.ts'
import { actions } from '../../containers/PromoCodes/slice.ts'
import type {
    FormItemsType,
    IPromoCode
} from '../../containers/PromoCodes/types.ts'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import UserSelector from '../../containers/UserSelector'
import copy from 'copy-text-to-clipboard'
import { useDebouncedCallback } from 'use-debounce'
import { authorizationSelector } from '../../containers/Authorization/selectors.ts'

const { TextArea } = Input

const { Link } = Typography

const dateFormat = 'DD.MM.YYYY HH:mm:ss'

interface Props {
    selectedPromoCode: IPromoCode | null
    onClose: () => void
    isAdmin: boolean
    isFranchisee: boolean
}

export default function PromoCodesWizardModal({
    selectedPromoCode,
    onClose,
    isAdmin,
    isFranchisee
}: Props): ReactElement {
    const [selectedBusinessUser, setSelectedBusinessUser] = useState('')
    const [firstOrder, setFirstOrder] = useState(false)

    const [_, setSearchParams] = useSearchParams()

    const {
        isWizardModal,
        isProcessCreate,
        isProcessEdit,
        isCheckingPromoCode,
        enteredPromoCodeIsCorrect
    } = useAppSelector(promoCodesSelector)

    const [messageApi, contextHolder] = message.useMessage()

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const codeWatch = Form.useWatch('code', form)
    const creatorIdWatch = Form.useWatch('creator_id', form)

    const refCodeInput = useRef<InputRef>(null)

    const { profile } = useAppSelector(authorizationSelector)

    const generatedPromoCode = useMemo(() => {
        if (isWizardModal) {
            const promoCode = generatePromoCode()
            dispatch(actions.checkPromoCode(promoCode))
            return promoCode
        }
        return ''
    }, [isWizardModal])

    const debouncedCheckPromoCode = useDebouncedCallback(
        (code: string) => dispatch(actions.checkPromoCode(code)),
        300
    )

    const handleCopyCode = () => {
        if (selectedPromoCode) {
            copy(codeWatch)
            void messageApi.open({
                type: 'info',
                content: 'Промокод был скопирован'
            })
        }
    }

    const handleCopyOwner = () => {
        if (creatorIdWatch) {
            const parseSelectedCreator = creatorIdWatch.split('{d}')[0]
            copy(parseSelectedCreator)
            void messageApi.open({
                type: 'info',
                content: 'Владелец был скопирован'
            })
        }
    }

    const handleResetForm = () => {
        setSelectedBusinessUser('')
        setFirstOrder(false)
        form.resetFields()
        dispatch(actions.resetCheckPromoCode())
    }

    useEffect(() => {
        if (!isWizardModal) {
            setSelectedBusinessUser('')
            onClose()
            handleResetForm()
        }
    }, [isWizardModal])

    useEffect(() => {
        if (firstOrder && selectedBusinessUser) {
            form.setFields([{ name: 'reusable', value: false }])
        }
    }, [firstOrder, selectedBusinessUser])

    useEffect(() => {
        if (selectedPromoCode) {
            const {
                caption,
                code,
                business_account,
                discount_percents,
                reusable,
                for_first_order,
                valid_until,
                admin_note,
                creator,
                is_active,
                referral_reward
            } = selectedPromoCode
            form.setFields([
                { name: 'caption', value: caption },
                { name: 'code', value: code },
                { name: 'discount_percents', value: discount_percents },
                { name: 'reusable', value: reusable },
                { name: 'for_first_order', value: for_first_order },
                {
                    name: 'valid_until',
                    value: valid_until ? dayjs(valid_until) : undefined
                },
                { name: 'admin_note', value: admin_note },
                { name: 'referral_reward', value: referral_reward ?? '15' }
            ])
            setFirstOrder(for_first_order)
            form.setFieldValue('is_active', is_active)
            if (business_account) {
                setSelectedBusinessUser(
                    `${[business_account.email, business_account.phone]
                        .filter(Boolean)
                        .join(' - ')}{d}${business_account.business_account_id}`
                )
            }
            if (isAdmin && creator) {
                form.setFieldValue(
                    'creator_id',
                    `${[creator.first_name, creator.email, creator.phone]
                        .filter(Boolean)
                        .join(' - ')}{d}${creator.user_id}`
                )
            }
        } else {
            handleResetForm()
        }
    }, [selectedPromoCode])

    const handleSendForm = (form: FormItemsType): void => {
        if (form.valid_until) {
            form.valid_until = dayjs(form.valid_until).format(
                'YYYY-MM-DD HH:mm:ss'
            )
        } else {
            form.valid_until = null
        }

        const data = {
            ...form,
            business_account_id: selectedBusinessUser
                ? selectedBusinessUser.split('{d}')[1]
                : undefined,
            creator_id: isAdmin
                ? form.creator_id
                    ? form.creator_id.split('{d}')[1]
                    : profile?.user_id
                : undefined
        }

        if (selectedPromoCode) {
            dispatch(
                actions.edit({
                    promoCodeId: selectedPromoCode.promocode_id,
                    data
                })
            )
        } else {
            dispatch(
                actions.create({
                    data,
                    clearSearchParams: () => setSearchParams({})
                })
            )
        }
    }

    return (
        <Modal
            title={
                <>
                    <BarcodeOutlined style={{ marginRight: 5 }} />{' '}
                    {selectedPromoCode
                        ? 'Редактировать промокод'
                        : 'Создать промокод'}
                </>
            }
            width={750}
            style={{ top: 30 }}
            open={isWizardModal}
            onCancel={() => dispatch(actions.setWizardModal(false))}
            destroyOnClose={true}
            footer={null}
        >
            <Form
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                autoComplete={'off'}
                initialValues={{
                    code: generatedPromoCode,
                    reusable: false,
                    for_first_order: false,
                    discount_percents:
                        isAdmin || isFranchisee ? undefined : '15',
                    is_active: true,
                    referral_reward: '15'
                }}
                style={{ marginTop: 20 }}
                onFinish={handleSendForm}
            >
                <Form.Item
                    name={'caption'}
                    label={'Название промокода'}
                    rules={[
                        {
                            required: true,
                            message: 'Введите название промокода!'
                        }
                    ]}
                >
                    <Input maxLength={255} placeholder={'Название промокода'} />
                </Form.Item>
                <div style={{ position: 'relative' }}>
                    <Form.Item
                        name={'code'}
                        label={'Промокод'}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp('^[A-Z0-9]{4,16}/*$'),
                                message: 'Промокод введен не верно!'
                            }
                        ]}
                        style={{ marginBottom: 30 }}
                        help={
                            enteredPromoCodeIsCorrect ||
                            enteredPromoCodeIsCorrect === null ? (
                                'от 4 до 16 символов'
                            ) : (
                                <span style={{ color: 'red' }}>
                                    {'Этот промокод занят'}
                                </span>
                            )
                        }
                    >
                        <Input
                            minLength={4}
                            maxLength={16}
                            disabled={!!selectedPromoCode}
                            onInput={(e): void => {
                                const code = ((
                                    e.target as HTMLInputElement
                                ).value = (
                                    e.target as HTMLInputElement
                                ).value.toUpperCase())
                                if (
                                    code.length >= 4 &&
                                    /^[A-Z0-9]{4,16}\/*$/.test(code)
                                ) {
                                    debouncedCheckPromoCode(code)
                                }
                                return void code
                            }}
                            placeholder={'Введите промокод'}
                            ref={refCodeInput}
                            suffix={
                                selectedPromoCode ? (
                                    <CopyOutlined />
                                ) : isCheckingPromoCode ? (
                                    <LoadingOutlined />
                                ) : enteredPromoCodeIsCorrect &&
                                  /^[A-Z0-9]{4,16}\/*$/.test(codeWatch) ? (
                                    <CheckCircleOutlined
                                        style={{ color: 'green' }}
                                    />
                                ) : (
                                    <CloseCircleOutlined
                                        style={{ color: 'red' }}
                                    />
                                )
                            }
                        />
                    </Form.Item>
                    {selectedPromoCode ? (
                        <div
                            onClick={handleCopyCode}
                            style={{
                                position: 'absolute',
                                left: '41.5%',
                                right: 0,
                                top: 0,
                                bottom: 28,
                                cursor: 'copy'
                            }}
                        />
                    ) : null}
                </div>
                {isAdmin || isFranchisee ? (
                    <Form.Item label={'Бизнес-аккаунт'}>
                        <BusinessUserSelector
                            disabled={!!selectedPromoCode}
                            initialValue={selectedBusinessUser}
                            onChange={setSelectedBusinessUser}
                        />
                    </Form.Item>
                ) : null}
                <Form.Item
                    name={'discount_percents'}
                    label={'Процент скидки'}
                    tooltip={
                        'По созданному промокоду при оплате тарифа «Премиум статус» будет применена указанная скидка. Дарите промокод друзьям или размещайте на ваших страницах в соц. сетях.'
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Введите процент скидки!'
                        }
                    ]}
                >
                    <InputNumber
                        disabled={!(isAdmin || isFranchisee)}
                        min={0}
                        max={100}
                        placeholder={'Процент скидки'}
                        formatter={value => `${value}%`}
                        // @ts-ignore
                        parser={value => value!.replace('%', '')}
                    />
                </Form.Item>
                {isAdmin || isFranchisee ? (
                    <Form.Item
                        name={'reusable'}
                        label={'Возможность повторного использования'}
                        valuePropName={'checked'}
                        tooltip={
                            'При установке данного флага созданный промокод можно будет применять ко всем последующим оплатам «Премиум статуса» в рамках одного личного кабинета.'
                        }
                    >
                        <Checkbox
                            disabled={!!(firstOrder && selectedBusinessUser)}
                        />
                    </Form.Item>
                ) : null}
                <Form.Item
                    name={'for_first_order'}
                    label={'Только на первый заказ'}
                    valuePropName={'checked'}
                    tooltip={
                        'Созданный промокод можно будет применять в рамках одного личного кабинета только на первую оплату тарифа «Премиум статус».'
                    }
                >
                    <Checkbox
                        onChange={(e): void => setFirstOrder(e.target.checked)}
                    />
                </Form.Item>
                <Form.Item
                    name={'is_active'}
                    label={'Активный промокод'}
                    valuePropName={'checked'}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name={'referral_reward'}
                    label={
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            {'Процент вознаграждения'}
                            <br />
                            <div style={{ fontSize: 10 }}>
                                {'(за применение промокода другом)'}
                            </div>
                        </div>
                    }
                    tooltip={
                        'При оплате по тарифу «Премиум статус» с применением созданного промокода, будет начислено вознаграждение владельцу промокода на общий баланс в личном кабинете. Указанный процент вознаграждения рассчитывается с итоговой суммы оплаты другом. Вознаграждение можно будет потратить на услуги в Mamado или создать запрос на вывод средств.'
                    }
                >
                    <InputNumber
                        disabled={!(isAdmin || isFranchisee)}
                        min={0}
                        max={100}
                        placeholder={'Процент вознаграждения'}
                        formatter={value => `${value}%`}
                        // @ts-ignore
                        parser={value => value!.replace('%', '')}
                    />
                </Form.Item>
                <Form.Item
                    name={'valid_until'}
                    label={'Срок истечения промокода'}
                >
                    <DatePicker
                        showTime={true}
                        format={dateFormat}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item name={'admin_note'} label={'Заметка'}>
                    <TextArea rows={4} placeholder={'Заметка'} />
                </Form.Item>
                {isAdmin ? (
                    <div>
                        <Form.Item
                            name={'creator_id'}
                            label={'Владелец промокода'}
                            rules={
                                selectedPromoCode
                                    ? [{ required: true }]
                                    : undefined
                            }
                        >
                            <UserSelector
                                initialValue={creatorIdWatch}
                                onChange={value =>
                                    form.setFieldValue('creator_id', value)
                                }
                            />
                        </Form.Item>
                        {creatorIdWatch ? (
                            <Form.Item
                                style={{ marginTop: -27 }}
                                colon={false}
                                label={' '}
                            >
                                <Link
                                    style={{ fontSize: 12 }}
                                    onClick={event => {
                                        event.preventDefault()
                                        handleCopyOwner()
                                    }}
                                >
                                    {'Скопировать владельца'}
                                    <CopyOutlined style={{ marginLeft: 4 }} />
                                </Link>
                            </Form.Item>
                        ) : null}
                    </div>
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        loading={isProcessCreate || isProcessEdit}
                        type={'primary'}
                        htmlType={'submit'}
                    >
                        {`${
                            selectedPromoCode ? 'Сохранить' : 'Создать'
                        } промокод`}
                    </Button>
                </div>
            </Form>
            {contextHolder}
        </Modal>
    )
}
