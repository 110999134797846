import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import UserSelector from '../../containers/UserSelector'

interface Props {
    isAdmin: boolean
}

export default function PromoCodesFilter({ isAdmin }: Props): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const [form] = Form.useForm()

    useEffect(() => {
        const result: Record<string, unknown> = {}
        searchParams.forEach((value, key) => (result[key] = value))
        form.setFieldsValue(result)
    }, [])

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        if (values.is_active === 'default') {
            values.is_active = undefined
        }

        for (const param in values) {
            if (values[param] != null) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
        setDateUpdate(Date.now().toString())
    }

    const handleResetForm = (): void => {
        setSearchParams({})
        form.resetFields()
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            initialValues={{
                is_active: 'default'
            }}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10}>
                <Col push={0} span={24}>
                    <Row gutter={10}>
                        <Col span={6}>
                            <FormItemWithLabel name={'code'} label={'Промокод'}>
                                <Input placeholder={'Промокод'} />
                            </FormItemWithLabel>
                        </Col>
                        {isAdmin ? (
                            <Col span={6}>
                                <FormItemWithLabel
                                    name={'creator'}
                                    label={'Владелец'}
                                >
                                    <UserSelector
                                        initialValue={form.getFieldValue(
                                            'creator'
                                        )}
                                        onChange={value =>
                                            form.setFieldValue('creator', value)
                                        }
                                    />
                                </FormItemWithLabel>
                            </Col>
                        ) : null}
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'is_active'}
                                label={'Статус промокодов'}
                            >
                                <Select
                                    defaultValue={'default'}
                                    style={{ width: '100%' }}
                                    options={[
                                        {
                                            value: 'default',
                                            label: 'Не указано'
                                        },
                                        { value: 'true', label: 'Активные' },
                                        {
                                            value: 'false',
                                            label: 'Не активные'
                                        }
                                    ]}
                                />
                            </FormItemWithLabel>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<SearchOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Поиск промокода'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={handleResetForm}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
