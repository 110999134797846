import type { ReactElement } from 'react'
import { useEffect } from 'react'
import AdvertisingWizard from '../containers/AdvertisingWizard'
import { actions } from '../containers/AdvertisingWizard/slice.ts'
import { useDispatch } from 'react-redux'

export default function AdvertisingCreatePage(): ReactElement {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.destroy())
    }, [])

    return <AdvertisingWizard isCreate />
}
