import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api.ts'
import type { ICoverageItem } from './types.ts'

export const fetch = (
    page: number,
    page_size: number
): Promise<AxiosResponse<ICoverageItem[]>> =>
    get<ICoverageItem[]>('/admin/advertising_coverages', {
        page,
        page_size,
        include: ['area'],
        sort: '-created_at'
    })

export const create = (
    form: Record<string, unknown>
): Promise<AxiosResponse<ICoverageItem>> =>
    post<ICoverageItem>('/admin/advertising_coverages', form)

export const edit = (
    advertising_coverage_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<ICoverageItem>> =>
    patch<ICoverageItem>(
        `/admin/advertising_coverages/${advertising_coverage_id}`,
        form
    )

export const remove = (
    advertising_coverage_id: string
): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/advertising_coverages/${advertising_coverage_id}`)
