import type {
    FetchBlocksPayload,
    FetchStatsPayload,
    IBlock,
    IStats,
    IStatsSlow
} from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { CallEffect } from '@redux-saga/core/effects'
import * as effects from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'

export function* handleFetchBlocks(action: {
    payload: FetchBlocksPayload
}): SagaIterator {
    try {
        const calls: CallEffect[] = []
        const blocks: { [name: string]: IBlock } = {}

        for (const name of action.payload.names) {
            calls.push(call(api.fetchBlock, name))
        }

        const response = yield all(calls)

        for (const [index, data] of response.entries()) {
            blocks[action.payload.names[index]] = data.data
        }

        yield put(actions.fetchBlocksSuccess({ blocks }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        if (code !== 404) {
            yield call(sagaNotificationError, message, error)
        }
        yield put(
            actions.fetchBlocksFailure({
                error: message
            })
        )
    }
}

export function* watchFetchBlocks(): SagaIterator {
    yield takeEvery(actions.fetchBlocks, handleFetchBlocks)
}

export function* handleFetchStats(
    action: PayloadAction<FetchStatsPayload>
): SagaIterator {
    try {
        const { data }: { data: IStats } = yield effects.call(api.fetchStats)
        yield put(actions.fetchStatsSuccess({ data }))
        if (action.payload.isFetchSlowStats) {
            yield put(actions.fetchStatsSlow())
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield put(
            actions.fetchStatsFailure({
                error: message
            })
        )
    }
}

export function* watchFetchStats(): SagaIterator {
    yield takeEvery(actions.fetchStats, handleFetchStats)
}

export function* handleFetchStatsSlow(): SagaIterator {
    try {
        const { data: statsSlowData }: { data: IStatsSlow } =
            yield effects.call(api.fetchStatsSlow)
        yield put(actions.fetchStatsSlowSuccess({ data: statsSlowData }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield put(
            actions.fetchStatsSlowFailure({
                error: message
            })
        )
    }
}

export function* watchFetchStatsSlow(): SagaIterator {
    yield takeEvery(actions.fetchStatsSlow, handleFetchStatsSlow)
}

export default function* watchDashboard(): SagaIterator {
    yield all([
        fork(watchFetchBlocks),
        fork(watchFetchStats),
        fork(watchFetchStatsSlow)
    ])
}
