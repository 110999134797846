import type { ReactElement, ReactNode } from 'react'
import { TreeSelect } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice'
import { useAppSelector } from '../../hooks/useAppSelector'
import LoadingSelector from '../../components/LoadingSelector'
import { regionTreeSelectorSelector } from './selectors.ts'
import type { AreaItemType } from './types.ts'
import { useDebounce } from '../../hooks/useDebounce.ts'

const { SHOW_PARENT } = TreeSelect

interface ITree {
    id: string
    title: string
    value: string
    children?: ITree[]
    isLeaf?: boolean
}

const cityAreas = ['Москва']

interface Props {
    initialValue: { value: string; label: string }[]
    onChange: (a: { value: string; label: string }[]) => void
}

export default function RegionTreeSelector({
    initialValue,
    onChange
}: Props): ReactElement {
    const [search, setSearch] = useState('')
    const [treeData, setTreeData] = useState<ITree[]>([])

    const dispatch = useDispatch()

    const debounceSearch = useDebounce<string>(search, 600)

    const { areas, areasSearch, areasIsFetching, searchIsFetching } =
        useAppSelector(regionTreeSelectorSelector)

    useEffect(() => {
        dispatch(actions.fetch({ id: 'country' }))
    }, [])

    useEffect(() => {
        if (debounceSearch) {
            dispatch(actions.search({ name: debounceSearch }))
        }
    }, [debounceSearch])

    useEffect(() => {
        const areaData = search.length > 0 ? areasSearch : areas

        if (areaData.length > 0) {
            const mapChildren = (
                children: AreaItemType[] | undefined,
                pid: number
            ): ITree[] | undefined => {
                return children?.map(children => ({
                    pid,
                    id: children.area_id,
                    title: children.name,
                    value: children.area_id,
                    isLeaf: !children.has_children,
                    children: mapChildren(children.children, pid + 1)
                }))
            }
            setTreeData(
                areaData.map(area => ({
                    pid: 1,
                    id: area.area_id,
                    title: area.name,
                    value: area.area_id,
                    isLeaf: !area.has_children,
                    children: mapChildren(area.children, 2)?.sort((a, b) => {
                        if (a.isLeaf && !b.isLeaf) {
                            return -1
                        }

                        if (
                            cityAreas.includes(a.title) &&
                            !cityAreas.includes(b.title)
                        ) {
                            return -1
                        }

                        return 1
                    })
                }))
            )
        }
    }, [areas, areasSearch, debounceSearch])

    const onLoadData = ({
        id,
        pid,
        title
    }: {
        id: string
        pid: number
        title: ReactNode
    }): Promise<void> => {
        if (cityAreas.includes(title as string)) {
            return new Promise(resolve =>
                dispatch(actions.fetch({ id, depth: 3, resolve }))
            )
        } else {
            return new Promise(resolve =>
                dispatch(actions.fetch({ id, depth: pid, resolve }))
            )
        }
    }

    return (
        <TreeSelect
            style={{
                width: '100%'
            }}
            value={initialValue}
            notFoundContent={
                debounceSearch && !searchIsFetching ? (
                    <span style={{ marginLeft: 10 }}>
                        {'По вашему запросу ничего не найдено'}
                    </span>
                ) : (
                    <LoadingSelector />
                )
            }
            loading={areasIsFetching.length > 0 || searchIsFetching}
            disabled={areas.length === 0}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto'
            }}
            placeholder={
                areas.length === 0
                    ? 'Получение регионов...'
                    : 'Выберите регионы'
            }
            showSearch={true}
            searchValue={search}
            onSearch={setSearch}
            treeNodeFilterProp={'title'}
            onChange={(ids: typeof initialValue, labels) =>
                onChange(
                    ids.map((item: unknown, index: number) => ({
                        value: item as string,
                        label: labels[index] as string
                    }))
                )
            }
            loadData={({ id, pid, title }) => onLoadData({ id, pid, title })}
            treeData={treeData}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
        />
    )
}
