import type { ReactElement } from 'react'
import {
    Col,
    Row,
    Typography,
    Divider,
    Modal,
    Input,
    Button,
    Tabs,
    Table,
    Pagination,
    Checkbox,
    Tooltip
} from 'antd'
import type { IPlanSubscriptionsItem } from '../../types'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companySelector } from '../../selectors'
import { useDispatch } from 'react-redux'
import { memo, useEffect, useMemo, useState } from 'react'
import { actions } from '../../slice'
import { LoadingStep } from '../../../../components/LoadingStep'
import Error403 from '../../../../components/Error403'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import NextStepButton from '../../../../components/NextStepButton'
import { declOfNum } from '../../../../utils/helpers'
import BannersBlock from '../../../BannersBlock'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { CloseOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

// Styles
import styles from './styles.module.scss'
import { authorizationSelector } from '../../../Authorization/selectors.ts'

const subscriptionColumns: ColumnsType<IPlanSubscriptionsItem> = [
    {
        title: 'Тарифный план',
        dataIndex: 'plan',
        width: '40%',
        render: data => data.name.title
    },
    {
        title: 'Дата активации',
        dataIndex: 'startsAt',
        align: 'center',
        width: '20%',
        render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
    },
    {
        title: 'Активен до',
        dataIndex: 'endsAt',
        align: 'center',
        width: '25%',
        render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
    },
    {
        title: 'Оплата',
        dataIndex: 'plan',
        align: 'center',
        width: '25%',
        render: plan => `${(plan.price / 100).toLocaleString('ru')} руб.`
    }
]

const MemoBannersBlock = memo(
    BannersBlock,
    (prevProps, nextProps) => prevProps.code === nextProps.code
)

function Payment(): ReactElement {
    const [selectedPlan, setSelectedPlan] = useState('')
    const [inputPromoCode, setInputPromoCode] = useState('')
    const [isPaymentFromBalance, setIsPaymentFromBalance] = useState(false)

    const {
        currentCompany,
        plans,
        isFetchingPlans,
        isModalPromoCode,
        isSendPromoCode,
        promoCode,
        isProcessPaymentUrl,
        isProcessPaymentFromBalance,
        paymentUrl
    } = useAppSelector(companySelector)

    const { amount } = useAppSelector(authorizationSelector)

    const dispatch = useDispatch()

    const currentPlan = useMemo(
        () => plans.find(plan => plan.planId === selectedPlan),
        [plans, selectedPlan]
    )

    const isBalanceEnough =
        amount && currentPlan ? currentPlan.price / 100 <= amount / 100 : false

    useEffect(() => {
        dispatch(actions.fetchPlans())
    }, [])

    useEffect(() => {
        if (plans.length > 0) {
            setSelectedPlan(plans[0].planId)
        }
    }, [plans])

    useEffect(() => {
        if (paymentUrl) {
            window.open(paymentUrl, '_blank')?.focus()
        }
    }, [paymentUrl])

    const handlePayment = (): void => {
        if (selectedPlan && currentCompany) {
            if (isPaymentFromBalance && isBalanceEnough) {
                dispatch(
                    actions.paymentFromBalance({
                        plan_id: selectedPlan,
                        companies: [currentCompany.company_id]
                    })
                )
            } else {
                dispatch(
                    actions.getPaymentUrl({
                        plan_id: selectedPlan,
                        companies: [currentCompany.company_id],
                        promocode_id: promoCode?.promocode_id
                    })
                )
            }
        }
    }

    if (isFetchingPlans) {
        return <LoadingStep />
    }

    if (plans.length === 0 || !currentCompany) {
        return (
            <Error403
                title={'Произошла ошибка'}
                description={'Тарифные планы не найдены!'}
                hideButton={true}
            />
        )
    }

    return (
        <>
            <MemoBannersBlock
                code={'admin-business-tariffs'}
                style={{ marginBottom: 20 }}
            />
            <Row gutter={40}>
                <Col span={9}>
                    <Title style={{ marginTop: 0, marginBottom: 15 }} level={5}>
                        {'Тариф для оплаты'}
                    </Title>
                    <div className={styles.plans}>
                        {plans.map(plan => (
                            <div
                                key={plan.planId}
                                style={{
                                    border: `1px solid ${plan.description.color}`
                                }}
                                className={styles.plans__item}
                                onClick={() => setSelectedPlan(plan.planId)}
                            >
                                <div
                                    style={{
                                        background: plan.description.color
                                    }}
                                    className={styles['plans__item-color']}
                                />
                                <Col
                                    style={{
                                        padding: '7px 0 7px 7px',
                                        marginRight: 15
                                    }}
                                >
                                    <b
                                        style={{
                                            color: plan.description.color
                                        }}
                                    >
                                        {plan.name.title}
                                    </b>
                                    <Row align={'middle'}>
                                        <Paragraph
                                            style={{ margin: '0 7px 0 0' }}
                                        >
                                            {plan.description.price}
                                        </Paragraph>
                                        <Paragraph
                                            style={{ margin: 0 }}
                                            type={'secondary'}
                                        >
                                            {plan.description.month}
                                        </Paragraph>
                                    </Row>
                                </Col>
                                <div className={styles['plans__item-icon']}>
                                    {selectedPlan === plan.planId ? (
                                        <FontAwesomeIcon
                                            style={{
                                                color: plan.description.color,
                                                fontSize: 18
                                            }}
                                            icon={faCircleCheck}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            style={{
                                                color: plan.description.color,
                                                fontSize: 18
                                            }}
                                            icon={faCircle}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>
                <Col span={15}>
                    <MemoBannersBlock
                        hideCaption={true}
                        code={'admin-business-tariffs-right-block'}
                        style={{ marginTop: 40 }}
                    />
                </Col>
            </Row>
            {currentPlan ? (
                <>
                    <Divider style={{ marginTop: 10 }} />
                    <Title level={4} style={{ marginBottom: 10 }}>
                        {'Итого: '}
                        <span
                            style={
                                promoCode
                                    ? { textDecoration: 'line-through' }
                                    : {}
                            }
                        >
                            {`${(currentPlan.price / 100).toLocaleString(
                                'ru'
                            )} ${declOfNum(currentPlan.price / 100, [
                                'рубль',
                                'рубля',
                                'рублей'
                            ])}`}
                        </span>
                        {promoCode ? (
                            <span
                                style={{ marginLeft: 10, color: '#ff57a5' }}
                            >{`${Math.round(
                                currentPlan.price / 100 -
                                    (promoCode.discount_percents / 100) *
                                        (currentPlan.price / 100)
                            ).toLocaleString('ru')} ${declOfNum(
                                Math.round(
                                    currentPlan.price / 100 -
                                        (promoCode.discount_percents / 100) *
                                            (currentPlan.price / 100)
                                ),
                                ['рубль', 'рубля', 'рублей']
                            )}`}</span>
                        ) : null}
                    </Title>
                    <Paragraph
                        type={'secondary'}
                        style={{ margin: 0, fontSize: 13 }}
                    >{`Тариф "${currentPlan.name.title}"`}</Paragraph>
                    <Paragraph
                        style={{ marginTop: 0, fontSize: 13, marginBottom: 0 }}
                    >
                        <span style={{ color: '#ff57a5', marginRight: 5 }}>
                            {currentPlan.description.price}
                        </span>{' '}
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                            {currentPlan.description.discount}
                        </span>
                    </Paragraph>
                    <Paragraph
                        type={'secondary'}
                        style={{ fontSize: 10 }}
                    >{`Компания: ${currentCompany.name}`}</Paragraph>
                    <Row style={{ marginTop: 10 }} align={'middle'}>
                        <div
                            className={styles.promocode}
                            onClick={() =>
                                dispatch(actions.setModalPromoCode(true))
                            }
                        >
                            {promoCode ? (
                                <span>
                                    {'Ваш промокод: '}
                                    <span style={{ color: '#000' }}>
                                        {promoCode.code}
                                    </span>
                                </span>
                            ) : (
                                <span>{'Ввести промокод'}</span>
                            )}
                            <FontAwesomeIcon
                                style={{
                                    color: '#ff57a5',
                                    fontSize: 11
                                }}
                                icon={faPencil}
                            />
                        </div>
                        {promoCode ? (
                            <Button
                                style={{ marginLeft: 10 }}
                                icon={<CloseOutlined />}
                                onClick={() =>
                                    dispatch(actions.clearPromoCode())
                                }
                            />
                        ) : null}
                    </Row>
                    <Tooltip
                        placement={'top'}
                        title={
                            isBalanceEnough ? '' : 'Не хватает суммы на балансе'
                        }
                    >
                        <Checkbox
                            checked={isPaymentFromBalance && isBalanceEnough}
                            disabled={!isBalanceEnough}
                            onChange={event =>
                                setIsPaymentFromBalance(event.target.checked)
                            }
                            style={{ marginTop: 15 }}
                        >
                            {`Зачесть с баланса ${
                                currentPlan
                                    ? `${currentPlan.price / 100} ${declOfNum(
                                          Math.round(currentPlan.price / 100),
                                          ['рубль', 'рубля', 'рублей']
                                      )}`
                                    : ''
                            }`}
                        </Checkbox>
                    </Tooltip>
                    <NextStepButton
                        loading={
                            isProcessPaymentUrl || isProcessPaymentFromBalance
                        }
                        onClick={handlePayment}
                        isLeft={true}
                        hideNext={true}
                    >
                        {isPaymentFromBalance && isBalanceEnough
                            ? 'Оплатить премиум статус с баланса'
                            : 'Перейти к оплате'}
                    </NextStepButton>
                </>
            ) : null}
            <Modal
                open={isModalPromoCode}
                title={'Введите промокод'}
                okText={'Активировать'}
                okButtonProps={{
                    disabled: inputPromoCode.length < 4,
                    loading: isSendPromoCode
                }}
                cancelText={'Отмена'}
                onOk={() =>
                    dispatch(actions.sendPromoCode({ code: inputPromoCode }))
                }
                onCancel={() => dispatch(actions.setModalPromoCode(false))}
                destroyOnClose={true}
                afterClose={() => setInputPromoCode('')}
            >
                <Input
                    value={inputPromoCode}
                    placeholder={'XXZ3V'}
                    allowClear={true}
                    onChange={({ target }) =>
                        setInputPromoCode(target.value.toUpperCase())
                    }
                />
            </Modal>
        </>
    )
}

function HistoryPayments(): ReactElement {
    const dispatch = useDispatch()

    const { currentCompany, planSubscriptions, isFetchingPlanSubscriptions } =
        useAppSelector(companySelector)

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                actions.fetchPlanSubscriptions({
                    company_id: currentCompany.company_id,
                    page: 1
                })
            )
        }
    }, [])

    if (isFetchingPlanSubscriptions) {
        return <LoadingStep />
    }

    return (
        <>
            <Title style={{ margin: '0 0 15px' }} level={4}>
                {'История оплат'}
            </Title>
            <Table
                dataSource={planSubscriptions.data}
                columns={subscriptionColumns}
                loading={isFetchingPlanSubscriptions}
                style={{ marginBottom: 30 }}
                rowKey={'planSubscriptionId'}
                locale={{ emptyText: 'Активные подписки не найдены!' }}
                pagination={false}
                footer={() => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Pagination
                            showQuickJumper
                            current={planSubscriptions?.meta?.currentPage}
                            pageSize={25}
                            defaultCurrent={1}
                            defaultPageSize={25}
                            total={planSubscriptions?.meta?.total}
                            onChange={page =>
                                currentCompany
                                    ? dispatch(
                                          actions.fetchPlanSubscriptions({
                                              company_id:
                                                  currentCompany.company_id,
                                              page
                                          })
                                      )
                                    : null
                            }
                        />
                    </div>
                )}
            />
        </>
    )
}

function TabOuter({ children }: { children: ReactElement }): ReactElement {
    return <div className={styles.tab__outer}>{children}</div>
}

export default function TabPremium(): ReactElement {
    return (
        <Tabs
            type={'card'}
            items={[
                {
                    label: 'Оплата тарифа',
                    key: 'payment',
                    children: (
                        <TabOuter>
                            <Payment />
                        </TabOuter>
                    )
                },
                {
                    label: 'История платежей',
                    key: 'history',
                    children: (
                        <TabOuter>
                            <HistoryPayments />
                        </TabOuter>
                    )
                }
            ]}
        />
    )
}
