import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    RemovePayload,
    RemoveSuccessPayload,
    RemoveFailurePayload,
    ChangeStatusPayload,
    ChangeStatusSuccessPayload,
    ChangeStatusFailurePayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    filter: {},
    isFetching: false,
    changingStatus: [],
    removing: [],
    error: null
}

const advertisingSlice = createSlice({
    name: 'advertising',
    initialState,
    reducers: {
        fetch(
            state: InitialStateType,
            action: PayloadAction<FetchPayload>
        ): void {
            state.isFetching = true
            state.filter = action.payload.filter
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ): void {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ): void {
            state.isFetching = false
            state.error = action.payload.error
        },
        remove(
            state: InitialStateType,
            action: PayloadAction<RemovePayload>
        ): void {
            state.removing.push(action.payload.advertising_id)
        },
        removeSuccess(
            state: InitialStateType,
            action: PayloadAction<RemoveSuccessPayload>
        ): void {
            state.removing = state.removing.filter(
                id => id !== action.payload.advertising_id
            )
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ): void {
            state.removing = state.removing.filter(
                id => id !== action.payload.advertising_id
            )
            state.error = action.payload.error
        },
        changeStatus(
            state: InitialStateType,
            action: PayloadAction<ChangeStatusPayload>
        ): void {
            state.changingStatus.push(action.payload.advertisingGroupId)
        },
        changeStatusSuccess(
            state: InitialStateType,
            action: PayloadAction<ChangeStatusSuccessPayload>
        ): void {
            state.changingStatus = state.changingStatus.filter(
                id => id !== action.payload.advertisingGroupId
            )
            state.data = state.data.map(item => {
                if (
                    item.advertising_group_id ===
                    action.payload.advertisingGroupId
                ) {
                    return {
                        ...item,
                        status: action.payload.status
                    }
                }
                return item
            })
        },
        changeStatusFailure(
            state: InitialStateType,
            action: PayloadAction<ChangeStatusFailurePayload>
        ): void {
            state.changingStatus = state.changingStatus.filter(
                id => id !== action.payload.advertisingGroupId
            )
            state.error = action.payload.error
        }
    }
})

export const actions = advertisingSlice.actions

export default advertisingSlice.reducer
