import type { ReactElement } from 'react'
import {
    Button,
    Checkbox,
    Col,
    Form,
    Radio,
    Row,
    Space,
    Typography,
    TimePicker,
    DatePicker,
    message,
    Modal,
    Divider
} from 'antd'
import { useForm } from 'antd/es/form/Form'
import RadioDescription from '../../../../components/RadioDescription'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useState } from 'react'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import type { Dayjs } from 'dayjs'
import type { RangeValue } from 'rc-picker/lib/interface'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import Collapse from '@kunukn/react-collapse'
import { actions } from '../../slice'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import type {
    DateType,
    IFormFields,
    IIntervalDates,
    ITimetable,
    WeekItem
} from './types'
import { timeToDayJs } from '../../../../utils/helpers'
import { eventWizardSelector } from '../../selectors'

const { Paragraph } = Typography
const { RangePicker } = DatePicker

const weeks = [
    {
        id: '0',
        short: 'ПН',
        name: 'Понедельник'
    },
    {
        id: '1',
        short: 'ВТ',
        name: 'Вторник'
    },
    {
        id: '2',
        short: 'СР',
        name: 'Среда'
    },
    {
        id: '3',
        short: 'ЧТ',
        name: 'Четверг'
    },
    {
        id: '4',
        short: 'ПТ',
        name: 'Пятница'
    },
    {
        id: '5',
        short: 'СБ',
        name: 'Суббота'
    },
    {
        id: '6',
        short: 'ВС',
        name: 'Воскресенье'
    }
]

export default function DatesStep(): ReactElement {
    const [type, setType] = useState<DateType>('schedule')
    const [selectedWeeks, setSelectedWeeks] = useState<WeekItem[]>([])
    const [multipleDates, setMultipleDates] = useState<IIntervalDates[]>([])
    const [intervalDates, setIntervalDates] = useState<IIntervalDates[]>([])
    const [tempType, setTempType] = useState<DateType>('schedule')
    const [tempData, setTempData] = useState('')
    const [oneDate, setOneDate] = useState('')
    const [oneTimes, setOneTimes] = useState<(RangeValue<dayjs.Dayjs> | '')[]>(
        []
    )
    const [isModalAddTime, setIsModalAddTime] = useState(false)
    const [allTime, setAllTime] = useState<RangeValue<dayjs.Dayjs>>()
    const [showIntervalDates, setShowIntervalDates] = useState<boolean>(false)

    const [form] = useForm()
    const [messageApi, contextHolder] = message.useMessage()

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } =
        useAppSelector(eventWizardSelector)

    const isDisabled = () => {
        switch (type) {
            case 'schedule':
                return (
                    selectedWeeks.length === 0 ||
                    selectedWeeks.some(
                        item =>
                            item.times.length === 0 ||
                            item.times.some(time => time === '')
                    )
                )
            case 'certain-days':
                return (
                    multipleDates.length === 0 ||
                    multipleDates.some(
                        item =>
                            item.times.length === 0 ||
                            item.times.some(time => time === '')
                    )
                )
            case 'date-interval':
                return (
                    intervalDates.length === 0 ||
                    intervalDates.some(
                        item =>
                            item.times.length === 0 ||
                            item.times.some(time => time === '')
                    )
                )
            case 'one-time':
                return oneDate === '' || oneTimes.length === 0
        }
    }

    const setStringifyData = (
        data: Record<string, unknown> | Array<unknown>,
        isReturnData?: boolean
    ): void | string => {
        try {
            const stringifyData = JSON.stringify(data)
            if (isReturnData) {
                return stringifyData
            } else {
                setTempData(stringifyData)
            }
        } catch {
            //
        }
    }

    useEffect(() => {
        let selectedData

        switch (tempType) {
            case 'schedule':
                selectedData = selectedWeeks
                break
            case 'date-interval':
                selectedData = intervalDates
                break
            case 'certain-days':
                selectedData = multipleDates
                break
            case 'one-time':
                selectedData = { [oneDate]: oneTimes }
        }

        const hasChanges =
            setStringifyData(selectedData, true) !== (tempData || '[]')

        dispatch(actions.setHasStepChanges(type !== tempType || hasChanges))
    }, [
        type,
        tempType,
        tempData,
        selectedWeeks,
        multipleDates,
        intervalDates,
        oneDate,
        oneTimes
    ])

    const handleUndoChanges = () => {
        if (currentEvent && currentEvent.schedule_type) {
            if (
                currentEvent.schedule_type === 'schedule' &&
                Array.isArray(currentEvent.eventSchedules)
            ) {
                let results: WeekItem[] = []
                for (const item of currentEvent.eventSchedules) {
                    const startTime = timeToDayJs(item.start_time)
                    const endTime = timeToDayJs(item.end_time)

                    if (
                        results.some(
                            res => parseInt(res.id) === item.day_of_week
                        )
                    ) {
                        results = results.map(res => {
                            if (parseInt(res.id) === item.day_of_week) {
                                return {
                                    ...res,
                                    times: [...res.times, [startTime, endTime]]
                                }
                            }
                            return res
                        })
                    } else {
                        results.push({
                            id: item.day_of_week.toString(),
                            times: [[startTime, endTime]]
                        })
                    }
                }
                setSelectedWeeks(results)
                setStringifyData(results)
                form.setFieldValue(
                    'weeks',
                    results.map(res => res.id)
                )
            }

            if (
                (currentEvent.schedule_type === 'certain-days' ||
                    currentEvent.schedule_type === 'date-interval') &&
                Array.isArray(currentEvent.eventTimetables)
            ) {
                let results: IIntervalDates[] = []

                for (const item of currentEvent.eventTimetables) {
                    const startTime = timeToDayJs(item.startTime)
                    const endTime = timeToDayJs(item.endTime)

                    if (results.some(res => res.date === item.date)) {
                        results = results.map(res => {
                            if (res.date === item.date) {
                                return {
                                    ...res,
                                    times: [...res.times, [startTime, endTime]]
                                }
                            }
                            return res
                        })
                    } else {
                        results.push({
                            date: item.date,
                            times: [[startTime, endTime]]
                        })
                    }
                }

                setStringifyData(results)
                if (currentEvent.schedule_type === 'date-interval') {
                    setIntervalDates(results)
                    form.setFieldValue('interval', [
                        dayjs(results[0].date),
                        dayjs(results.at(-1)?.date)
                    ])
                } else {
                    setMultipleDates(results)
                }
            }

            if (
                currentEvent.schedule_type === 'one-time' &&
                Array.isArray(currentEvent.eventTimetables)
            ) {
                setOneDate(currentEvent.eventTimetables[0]?.date)
                form.setFieldValue(
                    'one-time',
                    dayjs(currentEvent.eventTimetables[0]?.date)
                )

                const results: (RangeValue<Dayjs> | '')[] = []

                for (const item of currentEvent.eventTimetables) {
                    const startTime = timeToDayJs(item.startTime)
                    const endTime = timeToDayJs(item.endTime)
                    results.push([startTime, endTime])
                }

                setOneTimes(results)
                setStringifyData({
                    [currentEvent.eventTimetables[0]?.date]: results
                })
            }

            setType(currentEvent.schedule_type as DateType)
            setTempType(currentEvent.schedule_type as DateType)
            form.setFieldValue('type', currentEvent.schedule_type)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleChangeForm = (values: IFormFields) => {
        if (values.type) {
            setType(values.type)
        }
        if (values.weeks) {
            setSelectedWeeks(prev =>
                values.weeks.sort().map(week => {
                    const findItem = prev.find(item => item.id === week)
                    if (findItem) {
                        return findItem
                    } else {
                        return {
                            id: week,
                            times: []
                        }
                    }
                })
            )
        }
        if (values.interval) {
            const [from, to] = values.interval
            const list: string[] = []
            let current = dayjs(from)
            while (current.isBefore(to)) {
                list.push(current.format('YYYY-MM-DD'))
                current = current.add(1, 'day')
            }
            list.push(current.format('YYYY-MM-DD'))
            setIntervalDates(prev =>
                list.map(item => {
                    const findDate = prev.find(pre => pre.date === item)
                    if (findDate) {
                        return findDate
                    }
                    return {
                        date: item,
                        times: []
                    }
                })
            )
        }
        if (values['one-time']) {
            setOneDate(values['one-time'])
        }

        if (values['certain-days']) {
            const value = dayjs(values['certain-days']).format('YYYY-MM-DD')

            const findDate = multipleDates.some(date => date.date === value)

            if (findDate) {
                messageApi.open({
                    type: 'error',
                    content: 'Такая дата уже добавлена!'
                })
            } else {
                setMultipleDates(prev =>
                    [...prev, { date: value, times: [] }].sort((a, b) =>
                        dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
                    )
                )
            }
            form.setFieldValue('certain-days', '')
        }
    }

    const handleAddTime = (id: string) => {
        setSelectedWeeks(prev =>
            prev.map(week => {
                if (week.id === id) {
                    return {
                        ...week,
                        times: [...week.times, '']
                    }
                }
                return week
            })
        )
    }

    const handleRemoveTime = (weekId: string, timeIndex: number) => {
        setSelectedWeeks(prev =>
            prev.map(item => {
                if (item.id === weekId) {
                    return {
                        ...item,
                        times: item.times.filter((_, i) => i !== timeIndex)
                    }
                }
                return item
            })
        )
    }

    const handleChangeTime = (
        weekId: string,
        timeIndex: number,
        value: RangeValue<Dayjs>
    ) => {
        setSelectedWeeks(prev =>
            prev.map(item => {
                if (item.id === weekId) {
                    return {
                        ...item,
                        times: item.times.map((time, index) => {
                            if (index === timeIndex) {
                                return value
                            }
                            return time
                        })
                    }
                }
                return item
            })
        )
    }

    const handleIntervalAddTime = (date: string) => {
        setIntervalDates(prev =>
            prev.map(item => {
                if (item.date === date) {
                    return {
                        ...item,
                        times: [...item.times, '']
                    }
                }
                return item
            })
        )
    }

    const handleDeleteIntervalTime = (date: string, timeIndex: number) => {
        setIntervalDates(prev =>
            prev.map(item => {
                if (item.date === date) {
                    return {
                        ...item,
                        times: item.times.filter(
                            (_, index) => index !== timeIndex
                        )
                    }
                }
                return item
            })
        )
    }

    const handleChangeIntervalTime = (
        date: string,
        timeIndex: number,
        value: RangeValue<dayjs.Dayjs>
    ) => {
        setIntervalDates(prev =>
            prev.map(item => {
                if (item.date === date) {
                    return {
                        ...item,
                        times: item.times.map((time, index) => {
                            if (index === timeIndex) {
                                return value
                            }
                            return time
                        })
                    }
                }
                return item
            })
        )
    }

    const handleSaveEvent = () => {
        let form = {}

        if (type === 'schedule') {
            const schedules: ({ day_of_week: string } & ITimetable)[] = []

            for (const item of selectedWeeks) {
                item.times.forEach(
                    time =>
                        Array.isArray(time) &&
                        schedules.push({
                            day_of_week: item.id,
                            start_time: dayjs(time[0]).format('HH:mm:00'),
                            end_time: dayjs(time[1]).format('HH:mm:00')
                        })
                )
            }

            form = {
                schedule_type: type,
                schedules
            }
        }

        if (type === 'certain-days') {
            const timetables: ({ date: string } & ITimetable)[] = []

            for (const item of multipleDates) {
                item.times.forEach(
                    time =>
                        Array.isArray(time) &&
                        timetables.push({
                            date: item.date,
                            start_time: dayjs(time[0]).format('HH:mm:00'),
                            end_time: dayjs(time[1]).format('HH:mm:00')
                        })
                )
            }

            form = {
                schedule_type: type,
                timetables
            }
        }

        if (type === 'date-interval') {
            const timetables: ({ date: string } & ITimetable)[] = []

            for (const item of intervalDates) {
                item.times.forEach(
                    time =>
                        Array.isArray(time) &&
                        timetables.push({
                            date: item.date,
                            start_time: dayjs(time[0]).format('HH:mm:00'),
                            end_time: dayjs(time[1]).format('HH:mm:00')
                        })
                )
            }

            form = {
                schedule_type: type,
                timetables
            }
        }

        if (type === 'one-time') {
            const timetables = []

            for (const time of oneTimes) {
                if (Array.isArray(time)) {
                    timetables.push({
                        date: dayjs(oneDate).format('YYYY-MM-DD'),
                        start_time: dayjs(time[0]).format('HH:mm:00'),
                        end_time: dayjs(time[1]).format('HH:mm:00')
                    })
                }
            }

            form = {
                schedule_type: type,
                timetables
            }
        }

        if (currentEvent) {
            dispatch(
                actions.saveEvent({ event_id: currentEvent.event_id, form })
            )
        }
    }

    const handleAddAllTime = () => {
        const type = form.getFieldValue('type')
        const newSelectedWeeks = [...selectedWeeks]
        const newMultipleDates = [...multipleDates]
        const newIntervalDates = [...intervalDates]

        switch (type) {
            case 'schedule':
                for (const week of newSelectedWeeks) {
                    week.times.push(allTime as RangeValue<Dayjs>)
                }

                setSelectedWeeks([...newSelectedWeeks])
                break
            case 'certain-days':
                for (const date of newMultipleDates) {
                    date.times.push(allTime as RangeValue<Dayjs>)
                }

                setMultipleDates([...newMultipleDates])
                break
            case 'date-interval':
                for (const date of newIntervalDates) {
                    date.times.push(allTime as RangeValue<Dayjs>)
                }

                setIntervalDates([...newIntervalDates])
        }

        setIsModalAddTime(false)
        setAllTime(undefined)
    }

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                onValuesChange={handleChangeForm}
                style={{ maxWidth: '60%' }}
                layout={'vertical'}
                initialValues={{
                    type,
                    weeks: [],
                    interval: '',
                    'certain-days': '',
                    'one-time': ''
                }}
            >
                <Form.Item name={'type'}>
                    <Radio.Group>
                        <Space direction={'vertical'}>
                            <Radio value={'schedule'}>
                                {'Определенные дни недели'}
                                <RadioDescription>
                                    {
                                        'Предложение действует в указанные дни недели по расписанию'
                                    }
                                </RadioDescription>
                            </Radio>
                            <Radio value={'certain-days'}>
                                {'Определенные дни (Даты)'}
                                <RadioDescription>
                                    {
                                        'Предложение действует в указанные дни по расписанию'
                                    }
                                </RadioDescription>
                            </Radio>
                            <Radio value={'date-interval'}>
                                {'Интервал дат (Период)'}
                                <RadioDescription>
                                    {'Предложение проходит в выбранный период'}
                                </RadioDescription>
                            </Radio>
                            <Radio value={'one-time'}>
                                {'Разовое (Одна услуга)'}
                                <RadioDescription>
                                    {
                                        'Предложение действует один день в заданный временной интервал'
                                    }
                                </RadioDescription>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {type === 'schedule' ? (
                    <>
                        <Form.Item
                            style={{ marginTop: 12 }}
                            label={'Определенные дни недели'}
                            name={'weeks'}
                        >
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    {weeks.map(week => (
                                        <Col key={week.id} span={3}>
                                            <Checkbox value={week.id}>
                                                {week.short}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Space direction={'vertical'}>
                            {selectedWeeks.length > 0 ? (
                                <>
                                    <Paragraph
                                        style={{ margin: 0 }}
                                        type={'success'}
                                    >
                                        {'Выберите время:'}
                                    </Paragraph>
                                    {selectedWeeks.length > 1 ? (
                                        <Button
                                            onClick={() =>
                                                setIsModalAddTime(true)
                                            }
                                        >
                                            {'Добавить время на все дни недели'}
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}
                            {selectedWeeks.map(week => {
                                const findWeek = weeks.find(
                                    item => item.id === week.id
                                )

                                return (
                                    <Space direction={'vertical'} key={week.id}>
                                        {findWeek?.name}
                                        {week.times.map((item, index) => (
                                            <Row key={index}>
                                                <TimePicker.RangePicker
                                                    value={item || undefined}
                                                    defaultValue={
                                                        item || undefined
                                                    }
                                                    format={'HH:mm'}
                                                    onChange={value =>
                                                        handleChangeTime(
                                                            week.id,
                                                            index,
                                                            value as RangeValue<Dayjs>
                                                        )
                                                    }
                                                />
                                                <Button
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    style={{ marginLeft: 5 }}
                                                    onClick={() =>
                                                        handleRemoveTime(
                                                            week.id,
                                                            index
                                                        )
                                                    }
                                                />
                                            </Row>
                                        ))}
                                        <Button
                                            size={'small'}
                                            icon={<PlusOutlined />}
                                            onClick={() =>
                                                handleAddTime(week.id)
                                            }
                                        >
                                            {'Добавить время'}
                                        </Button>
                                    </Space>
                                )
                            })}
                        </Space>
                    </>
                ) : null}
                {type === 'certain-days' ? (
                    <>
                        <Form.Item
                            style={{ marginTop: 12, width: '60%' }}
                            label={'Определенные дни'}
                            name={'certain-days'}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                allowClear={false}
                                placeholder={'Добавить дату в список'}
                            />
                        </Form.Item>
                        <Space direction={'vertical'}>
                            {multipleDates.length > 0 ? (
                                <>
                                    <Paragraph
                                        style={{ margin: 0 }}
                                        type={'success'}
                                    >
                                        {'Выберите время:'}
                                    </Paragraph>
                                    {multipleDates.length > 1 ? (
                                        <Button
                                            onClick={() =>
                                                setIsModalAddTime(true)
                                            }
                                        >
                                            {'Добавить время на все дни'}
                                        </Button>
                                    ) : null}
                                </>
                            ) : null}
                            {multipleDates.map((date, dateIndex) => (
                                <div key={date.date}>
                                    <Row align={'middle'}>
                                        <Paragraph
                                            style={{
                                                margin: '0 0 5px',
                                                fontWeight: '600'
                                            }}
                                        >
                                            {dayjs(date.date).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </Paragraph>
                                        <Button
                                            danger
                                            style={{
                                                marginLeft: 7,
                                                marginBottom: 5,
                                                fontSize: 10,
                                                padding: '0 5px',
                                                height: 'auto'
                                            }}
                                            size={'small'}
                                            onClick={() =>
                                                setMultipleDates(prev =>
                                                    prev.filter(
                                                        item =>
                                                            item.date !==
                                                            date.date
                                                    )
                                                )
                                            }
                                        >
                                            {'Удалить дату'}
                                        </Button>
                                    </Row>
                                    {date.times.map((time, timeIndex) => (
                                        <Row
                                            style={{ marginBottom: 10 }}
                                            key={timeIndex}
                                        >
                                            <TimePicker.RangePicker
                                                value={time || undefined}
                                                format={'HH:mm'}
                                                onChange={value =>
                                                    setMultipleDates(
                                                        prev =>
                                                            prev.map(item => {
                                                                if (
                                                                    date.date ===
                                                                    item.date
                                                                ) {
                                                                    return {
                                                                        ...item,
                                                                        times: item.times.map(
                                                                            (
                                                                                time,
                                                                                index
                                                                            ) => {
                                                                                if (
                                                                                    index ===
                                                                                    timeIndex
                                                                                ) {
                                                                                    return value
                                                                                }
                                                                                return time
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                                return item
                                                            }) as IIntervalDates[]
                                                    )
                                                }
                                            />
                                            <Button
                                                danger
                                                icon={<DeleteOutlined />}
                                                style={{ marginLeft: 5 }}
                                                onClick={() =>
                                                    setMultipleDates(prev =>
                                                        prev.map(item => {
                                                            if (
                                                                item.date ===
                                                                date.date
                                                            ) {
                                                                return {
                                                                    ...item,
                                                                    times: item.times.filter(
                                                                        (
                                                                            _,
                                                                            index
                                                                        ) =>
                                                                            index !==
                                                                            timeIndex
                                                                    )
                                                                }
                                                            }
                                                            return item
                                                        })
                                                    )
                                                }
                                            />
                                        </Row>
                                    ))}
                                    <Button
                                        size={'small'}
                                        icon={<PlusOutlined />}
                                        style={{ marginBottom: 12 }}
                                        onClick={() =>
                                            setMultipleDates(prev =>
                                                prev.map(item => {
                                                    if (
                                                        item.date === date.date
                                                    ) {
                                                        return {
                                                            ...item,
                                                            times: [
                                                                ...item.times,
                                                                ''
                                                            ]
                                                        }
                                                    }
                                                    return item
                                                })
                                            )
                                        }
                                    >
                                        {'Добавить время'}
                                    </Button>
                                    {multipleDates.length - 1 > dateIndex ? (
                                        <Divider
                                            style={{
                                                background:
                                                    'rgba(0, 0, 0, 0.2)',
                                                margin: '7px 0 10px'
                                            }}
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </Space>
                    </>
                ) : null}
                {type === 'date-interval' ? (
                    <>
                        <Form.Item
                            style={{ marginTop: 12, width: '60%' }}
                            label={'Интервал дат'}
                            name={'interval'}
                        >
                            <RangePicker
                                format={'DD.MM.YYYY'}
                                style={{ width: '100%' }}
                                allowClear={false}
                            />
                        </Form.Item>
                        <Space direction={'vertical'}>
                            {intervalDates.length > 0 ? (
                                <>
                                    <Paragraph
                                        style={{ margin: 0 }}
                                        type={'success'}
                                    >
                                        {'Выберите время:'}
                                    </Paragraph>
                                    {intervalDates.length > 1 ? (
                                        <Row gutter={8}>
                                            <Col>
                                                <Button
                                                    onClick={() =>
                                                        setIsModalAddTime(true)
                                                    }
                                                >
                                                    {
                                                        'Добавить время на все дни'
                                                    }
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    onClick={() =>
                                                        setShowIntervalDates(
                                                            prev => !prev
                                                        )
                                                    }
                                                >
                                                    {`${
                                                        showIntervalDates
                                                            ? 'Свернуть'
                                                            : 'Развернуть'
                                                    } настройку времени по датам`}
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </>
                            ) : null}
                            <Collapse
                                isOpen={showIntervalDates}
                                transition={
                                    'height 300ms cubic-bezier(.4, 0, .2, 1)'
                                }
                            >
                                {intervalDates.map(item => (
                                    <div key={item.date}>
                                        <Paragraph
                                            style={{
                                                margin: '0 0 5px',
                                                fontWeight: '600'
                                            }}
                                        >
                                            {dayjs(item.date).format(
                                                'DD.MM.YYYY'
                                            )}
                                        </Paragraph>
                                        {item.times.map((time, index) => (
                                            <Row
                                                style={{ marginBottom: 10 }}
                                                key={index}
                                            >
                                                <TimePicker.RangePicker
                                                    value={time || undefined}
                                                    format={'HH:mm'}
                                                    onChange={value =>
                                                        handleChangeIntervalTime(
                                                            item.date,
                                                            index,
                                                            value as RangeValue<Dayjs>
                                                        )
                                                    }
                                                />
                                                <Button
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    style={{ marginLeft: 5 }}
                                                    onClick={() =>
                                                        handleDeleteIntervalTime(
                                                            item.date,
                                                            index
                                                        )
                                                    }
                                                />
                                            </Row>
                                        ))}
                                        <Button
                                            size={'small'}
                                            icon={<PlusOutlined />}
                                            onClick={() =>
                                                handleIntervalAddTime(item.date)
                                            }
                                        >
                                            {'Добавить время'}
                                        </Button>
                                    </div>
                                ))}
                            </Collapse>
                        </Space>
                    </>
                ) : null}
                {type === 'one-time' ? (
                    <>
                        <Form.Item
                            style={{ marginTop: 12, width: '60%' }}
                            label={'Разовое'}
                            name={'one-time'}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                allowClear={false}
                                format={'DD.MM.YYYY'}
                            />
                        </Form.Item>
                        {oneDate ? (
                            <Space direction={'vertical'}>
                                <Paragraph
                                    style={{ margin: 0 }}
                                    type={'success'}
                                >
                                    {'Выберите время:'}
                                </Paragraph>
                                {oneTimes.map((time, index) => (
                                    <Row
                                        style={{ marginBottom: 10 }}
                                        key={index}
                                    >
                                        <TimePicker.RangePicker
                                            value={time || undefined}
                                            format={'HH:mm'}
                                            onChange={value =>
                                                setOneTimes(
                                                    prev =>
                                                        prev.map(
                                                            (
                                                                item,
                                                                indexTime
                                                            ) => {
                                                                if (
                                                                    index ===
                                                                    indexTime
                                                                ) {
                                                                    return value
                                                                }
                                                                return item
                                                            }
                                                        ) as (
                                                            | RangeValue<dayjs.Dayjs>
                                                            | ''
                                                        )[]
                                                )
                                            }
                                        />
                                        <Button
                                            danger
                                            icon={<DeleteOutlined />}
                                            style={{ marginLeft: 5 }}
                                            onClick={() =>
                                                setOneTimes(prev =>
                                                    prev.filter(
                                                        (_, indexTime) =>
                                                            indexTime !== index
                                                    )
                                                )
                                            }
                                        />
                                    </Row>
                                ))}
                                <Button
                                    size={'small'}
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        setOneTimes(prev => [...prev, ''])
                                    }
                                >
                                    {'Добавить время'}
                                </Button>
                            </Space>
                        ) : null}
                    </>
                ) : null}
            </Form>
            <Modal
                open={isModalAddTime}
                onOk={handleAddAllTime}
                okButtonProps={{
                    disabled: !allTime
                }}
                title={'Добавление времени для нескольких дней'}
                okText={'Добавить'}
                cancelText={'Отмена'}
                onCancel={() => setIsModalAddTime(false)}
            >
                <TimePicker.RangePicker
                    value={allTime}
                    format={'HH:mm'}
                    style={{ width: '70%' }}
                    onChange={value =>
                        setAllTime(value as RangeValue<dayjs.Dayjs>)
                    }
                />
            </Modal>
            <NextStepButton
                disabled={isDisabled() || !hasStepChanges}
                loading={isSaving}
                onClick={handleSaveEvent}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
