import { useState } from 'react'
import type { ReactElement } from 'react'
import { Button } from 'antd'

// Styles
import styles from './styles.module.scss'

export default function EventPublishedBanner(): ReactElement {
    const [isShow, setIsShow] = useState(true)

    return (
        <div
            className={styles.outer}
            style={isShow ? { opacity: 1, visibility: 'visible' } : {}}
        >
            <div className={styles.title}>{'Предложение опубликовано'}</div>
            <div className={styles.description}>
                {
                    'Теперь предложение доступно к просмотру в карточке и на странице компании.'
                }
                <br />
                {
                    'После окончания срока, указанного в разделе «Период проведения» будет автоматически снято с публикации.'
                }
            </div>
            <Button onClick={() => setIsShow(false)} type={'primary'}>
                {'Понятно'}
            </Button>
        </div>
    )
}
