import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    DownloadExcelPayload,
    DownloadExcelSuccessPayload,
    DownloadExcelFailurePayload,
    EditUserPayload,
    EditUserSuccessPayload,
    EditUserFailurePayload,
    IUser
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    isFetching: false,
    isDownloadExcel: false,
    editUser: null,
    isUserEditProcess: false,
    error: null
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        downloadExcel(
            state: InitialStateType,
            _action: PayloadAction<DownloadExcelPayload>
        ) {
            state.isDownloadExcel = true
        },
        downloadExcelSuccess(
            state: InitialStateType,
            _action: PayloadAction<DownloadExcelSuccessPayload>
        ) {
            state.isDownloadExcel = false
        },
        downloadExcelFailure(
            state: InitialStateType,
            action: PayloadAction<DownloadExcelFailurePayload>
        ) {
            state.isDownloadExcel = false
            state.error = action.payload.error
        },
        setEditUser(
            state: InitialStateType,
            action: PayloadAction<IUser | null>
        ) {
            state.editUser = action.payload
        },
        editUser(
            state: InitialStateType,
            _action: PayloadAction<EditUserPayload>
        ) {
            state.isUserEditProcess = true
        },
        editUserSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditUserSuccessPayload>
        ) {
            state.editUser = null
            state.isUserEditProcess = false
        },
        editUserFailure(
            state: InitialStateType,
            action: PayloadAction<EditUserFailurePayload>
        ) {
            state.isUserEditProcess = false
            state.error = action.payload.error
        }
    }
})

export const actions = usersSlice.actions

export default usersSlice.reducer
