import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload
} from './types'

const initialState: InitialStateType = {
    properties: {}
}

const companyPropertySelectorSlice = createSlice({
    name: 'companyPropertySelector',
    initialState,
    reducers: {
        fetch(state: InitialStateType, action: PayloadAction<FetchPayload>) {
            state.properties[action.payload.propertyId] = {
                data: [],
                isFetching: true,
                error: null
            }
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.properties[action.payload.propertyId].data =
                action.payload.data
            state.properties[action.payload.propertyId].isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.properties[action.payload.propertyId].isFetching = false
            state.properties[action.payload.propertyId].error =
                action.payload.error
        }
    }
})

export const actions = companyPropertySelectorSlice.actions

export default companyPropertySelectorSlice.reducer
