// Components
import ProtectedPage from './components/ProtectedPage'

// Pages
import GeneralPage from './pages/GeneralPage'
import CompaniesPage from './pages/CompaniesPage'
import CompanyCreatePage from './pages/CompanyCreatePage'
import CompanyPage from './pages/CompanyPage'
import CompanyEditPage from './pages/CompanyEditPage'
import EventsPage from './pages/EventsPage'
import EventCreatePage from './pages/EventCreatePage'
import Page404 from './pages/404'
import EventEditPage from './pages/EventEditPage'
import UsersPage from './pages/UsersPage'
import EventPage from './pages/EventPage'
import ContentPage from './pages/ContentPage'
import PromoCodesPage from './pages/PromoCodesPage'
import PromoCodesAgentsPage from './pages/PromoCodesAgentsPage.tsx'
import PromoCodesReportPage from './pages/PromoCodesReportPage'
import AdvertisingPage from './pages/AdvertisingPage.tsx'
import AdvertisingCreatePage from './pages/AdvertisingCreatePage.tsx'
import AdvertisingReportPage from './pages/AdvertisingReportPage.tsx'
import AdvertisingEditPage from './pages/AdvertisingEditPage.tsx'
import BlogPage from './pages/BlogPage.tsx'
import BlogCreatePage from './pages/BlogCreatePage.tsx'
import SettingsTagsPage from './pages/SettingsTagsPage.tsx'
import BlogEditPage from './pages/BlogEditPage.tsx'
import CoveragePage from './pages/CoveragePage.tsx'
import BalancePage from './pages/BalancePage.tsx'

export const routes = [
    {
        path: '/',
        element: (
            <ProtectedPage hideBreadcrumbs={true} isHomePage={true}>
                <GeneralPage />
            </ProtectedPage>
        )
    },
    {
        path: '/companies',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <CompaniesPage />
            </ProtectedPage>
        )
    },
    {
        path: '/companies/create',
        element: (
            <ProtectedPage>
                <CompanyCreatePage />
            </ProtectedPage>
        )
    },
    {
        path: '/companies/:company_id',
        element: (
            <ProtectedPage>
                <CompanyPage />
            </ProtectedPage>
        )
    },
    {
        path: '/companies/:company_id/edit',
        element: (
            <ProtectedPage>
                <CompanyEditPage />
            </ProtectedPage>
        )
    },
    {
        path: '/events',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <EventsPage />
            </ProtectedPage>
        )
    },
    {
        path: '/events/create',
        element: (
            <ProtectedPage>
                <EventCreatePage />
            </ProtectedPage>
        )
    },
    {
        path: '/events/:event_id/',
        element: (
            <ProtectedPage>
                <EventPage />
            </ProtectedPage>
        )
    },
    {
        path: '/events/:event_id/edit/',
        element: (
            <ProtectedPage>
                <EventEditPage />
            </ProtectedPage>
        )
    },
    {
        path: '/users',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <UsersPage />
            </ProtectedPage>
        )
    },
    {
        path: '/promocodes',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <PromoCodesPage />
            </ProtectedPage>
        )
    },
    {
        path: '/promocodes/agents',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <PromoCodesAgentsPage />
            </ProtectedPage>
        )
    },
    {
        path: '/promocodes/report',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <PromoCodesReportPage />
            </ProtectedPage>
        )
    },
    {
        path: '/content/:code',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <ContentPage />
            </ProtectedPage>
        )
    },
    {
        path: '/advertising',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <AdvertisingPage />
            </ProtectedPage>
        )
    },
    {
        path: '/advertising/create',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <AdvertisingCreatePage />
            </ProtectedPage>
        )
    },
    {
        path: '/advertising/:advertising_group_id',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <AdvertisingEditPage />
            </ProtectedPage>
        )
    },
    {
        path: '/advertising/report',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <AdvertisingReportPage />
            </ProtectedPage>
        )
    },
    {
        path: '/blog',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <BlogPage />
            </ProtectedPage>
        )
    },
    {
        path: '/blog/create',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <BlogCreatePage />
            </ProtectedPage>
        )
    },
    {
        path: '/blog/:post_id',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <BlogEditPage />
            </ProtectedPage>
        )
    },
    {
        path: '/settings/tags',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <SettingsTagsPage />
            </ProtectedPage>
        )
    },
    {
        path: '/settings/coverage',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <CoveragePage />
            </ProtectedPage>
        )
    },
    {
        path: '/balance',
        element: (
            <ProtectedPage hideBreadcrumbs={true}>
                <BalancePage />
            </ProtectedPage>
        )
    },
    {
        path: '*',
        element: <Page404 />
    }
]
