import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchBlockPayload,
    FetchBlockSuccessPayload,
    FetchBlockFailurePayload
} from './types'

const initialState: InitialStateType = {
    blocks: {},
    blocksIsFetching: [],
    error: null
}

export const bannersBlockSlice = createSlice({
    name: 'bannersBlock',
    initialState,
    reducers: {
        fetchBlock(
            state: InitialStateType,
            action: PayloadAction<FetchBlockPayload>
        ): void {
            state.blocksIsFetching.push(action.payload.code)
        },
        fetchBlockSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchBlockSuccessPayload>
        ): void {
            state.blocksIsFetching = state.blocksIsFetching.filter(
                code => code !== action.payload.code
            )
            state.blocks[action.payload.code] = action.payload.data
        },
        fetchBlockFailure(
            state: InitialStateType,
            action: PayloadAction<FetchBlockFailurePayload>
        ): void {
            state.blocksIsFetching = state.blocksIsFetching.filter(
                code => code !== action.payload.code
            )
            state.error = action.payload.error
        }
    }
})

export const actions = bannersBlockSlice.actions

export default bannersBlockSlice.reducer
