import type { CSSProperties, ReactElement } from 'react'
import { Typography } from 'antd'
import { Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Banner from '../../components/Banner'
import { useEffect } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector'
import { bannersBlockSelector } from './selectors'
import { useDispatch } from 'react-redux'
import { actions } from './slice'

const { Title } = Typography

// Styles
import styles from './styles.module.scss'
import { sortBanners } from '../../utils/helpers.ts'

interface Props {
    code: string
    style?: CSSProperties
    hideCaption?: boolean
    isVertical?: boolean
    isMinBanners?: boolean
}

export default function BannersBlock({
    code,
    style,
    hideCaption,
    isVertical,
    isMinBanners
}: Props): ReactElement {
    const dispatch = useDispatch()

    const { blocks } = useAppSelector(bannersBlockSelector)

    const currentBlock = blocks[code]

    useEffect(() => {
        if (code) {
            dispatch(actions.fetchBlock({ code }))
        }
    }, [code])

    if (!currentBlock) {
        return <></>
    }

    return (
        <div style={style}>
            {!hideCaption ? (
                <Title style={{ marginTop: 0, textAlign: 'center' }} level={5}>
                    {currentBlock.caption}
                </Title>
            ) : null}
            {isVertical ? (
                <div className={styles.col}>
                    {sortBanners(currentBlock.banners).map(banner => (
                        <div
                            className={styles.col__item}
                            key={banner.banner_id}
                        >
                            <Banner
                                url={
                                    banner.target_action === 'page'
                                        ? `${document.location.origin}/content/${banner.page_name}`
                                        : banner.target_url
                                }
                                image={banner.image.url}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <Swiper
                    modules={[Pagination, Navigation]}
                    pagination={{
                        enabled: true,
                        clickable: true,
                        dynamicBullets: true
                    }}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={isMinBanners ? 'auto' : 4}
                >
                    {sortBanners(currentBlock.banners).map(banner => (
                        <SwiperSlide
                            style={isMinBanners ? { width: 212 } : undefined}
                            key={banner.banner_id}
                        >
                            <Banner
                                url={
                                    banner.target_action === 'page'
                                        ? `${document.location.origin}/content/${banner.page_name}`
                                        : banner.target_url
                                }
                                image={banner.image.url}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    )
}
