import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IOptions } from './types'

export const fetch = async (
    propertyId: string
): Promise<AxiosResponse<IOptions[]>> => {
    return await get<IOptions[]>(
        `/admin/companies/properties/${propertyId}/options`
    )
}
