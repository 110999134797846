import type { FetchBlockPayload, IBlock } from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import * as effects from 'redux-saga/effects'

export function* handleFetchBlock(action: {
    payload: FetchBlockPayload
}): SagaIterator {
    try {
        const { data }: { data: IBlock } = yield effects.call(
            api.fetchBlock,
            action.payload.code
        )
        yield put(
            actions.fetchBlockSuccess({ data, code: action.payload.code })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        if (code !== 404) {
            yield call(sagaNotificationError, message, code)
        }
        yield put(
            actions.fetchBlockFailure({
                code: action.payload.code,
                error: message
            })
        )
    }
}

export function* watchFetchBlock(): SagaIterator {
    yield takeEvery(actions.fetchBlock, handleFetchBlock)
}

export default function* watchBannersBlock(): SagaIterator {
    yield all([fork(watchFetchBlock)])
}
