import type { ReactElement, Key } from 'react'
import { useEffect, useState } from 'react'
import {
    Button,
    Pagination,
    Row,
    Table,
    Typography,
    Tag,
    Space,
    Tooltip,
    Col
} from 'antd'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector'
import { actions } from './slice'
import type { ColumnsType } from 'antd/es/table'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { eventBadges, eventTypes, ignoreKeys } from '../../utils/helpers'
import dayjs from 'dayjs'
import type { IEvent } from './types'
import EventFilter from '../../components/EventFilter'
import type { ICompany } from '../Companies/types'
import {
    CheckOutlined,
    CloseOutlined,
    DownCircleOutlined,
    PlusOutlined,
    UpCircleOutlined
} from '@ant-design/icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Collapse from '@kunukn/react-collapse'
import { eventsSelector } from './selectors'

// Styles
import styles from './styles.module.scss'

const { Title, Paragraph } = Typography

export const columns = (
    navigate: (path: string) => void
): ColumnsType<IEvent> => [
    {
        title: 'Фото',
        dataIndex: '',
        key: 'main_image_thumbnail',
        width: 250,
        render: data => (
            <Link
                className={styles['image-link']}
                to={`/events/${data.event_id}/edit`}
                target={'_blank'}
            >
                <LazyLoadImage
                    src={data.main_image_thumbnail}
                    className={styles.image}
                />
            </Link>
        )
    },
    {
        title: 'Наименование',
        dataIndex: '',
        key: 'name',
        render: data => (
            <Link
                className={styles.link}
                to={`/events/${data.event_id}/edit`}
                target={'_blank'}
            >
                {data.name || 'Название не заполнено'}
            </Link>
        )
    },
    {
        title: 'Компания',
        dataIndex: '',
        key: 'companies',
        render: data => (
            <Space size={[0, 6]} wrap>
                {data.companies.map((company: ICompany) => (
                    <Tooltip
                        key={company.company_id}
                        placement={'top'}
                        title={company.name}
                    >
                        <Tag
                            onClick={() =>
                                navigate(`/companies/${company.company_id}`)
                            }
                            style={{ cursor: 'pointer', background: '#FFF' }}
                        >
                            {company.name?.length > 30
                                ? `${company.name.substring(0, 27)}...`
                                : company.name}
                        </Tag>
                    </Tooltip>
                ))}
            </Space>
        )
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: (value: keyof typeof eventTypes) => <b>{eventTypes[value]}</b>
    },
    {
        title: 'Вид промо',
        dataIndex: 'badge',
        key: 'badge',
        align: 'center',
        render: (value: keyof typeof eventBadges) => (
            <b>{eventBadges[value] ?? 'Нет'}</b>
        )
    },
    {
        title: 'Статус',
        dataIndex: 'published',
        key: 'published',
        align: 'center',
        render: value => <b>{value ? 'Опубликовано' : 'Не опубликовано'}</b>
    },
    {
        title: 'Создано',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    },
    {
        title: 'Обновлено',
        dataIndex: 'updated_at',
        key: 'updated_at',
        align: 'center',
        render: value => dayjs(value).format('DD.MM.YYYY HH:mm')
    }
]

export default function Events(): ReactElement {
    const [showFilter, setShowFilter] = useState(false)
    const [selectedEvents, setSelectedEvents] = useState<Key[]>([])

    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { data, meta, isFetching, isProcessChangeMultiplePublishing } =
        useAppSelector(eventsSelector)

    useEffect(() => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        dispatch(actions.fetch({ page, pageSize, filter }))
        setSearchParams({
            ...filter,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const checkSelectedEvents = (published: boolean) =>
        data.some(
            event =>
                event.published === published &&
                selectedEvents.includes(event.event_id)
        )

    const handleChangeMultiplePublished = (published: boolean) => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.changeMultiplePublished({
                event_ids: selectedEvents,
                published,
                page,
                pageSize,
                filter
            })
        )
        setSelectedEvents([])
    }

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Row>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => navigate('/events/create')}
                        type={'primary'}
                    >
                        {'Создать предложение'}
                    </Button>
                </Row>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Row justify={'space-between'}>
                    <Title level={3} style={{ margin: 0 }}>
                        {'Поиск предложений'}
                    </Title>
                    <Row>
                        <Button
                            style={{ width: 220 }}
                            onClick={() => setShowFilter(prev => !prev)}
                            icon={
                                showFilter ? (
                                    <UpCircleOutlined />
                                ) : (
                                    <DownCircleOutlined />
                                )
                            }
                        >
                            {showFilter ? 'Скрыть фильтр' : 'Показать фильтр'}
                        </Button>
                    </Row>
                </Row>
                <Collapse
                    isOpen={showFilter}
                    transition={'height 300ms cubic-bezier(.4, 0, .2, 1)'}
                >
                    <div style={{ height: 25 }} />
                    <EventFilter />
                </Collapse>
            </div>
            <div className={styles.container}>
                <Row justify={'space-between'}>
                    <Col span={8}>
                        <Row align={'middle'}>
                            <Space style={{ marginBottom: 15 }}>
                                <Button
                                    type={'primary'}
                                    disabled={!checkSelectedEvents(false)}
                                    onClick={() =>
                                        handleChangeMultiplePublished(true)
                                    }
                                    icon={<CheckOutlined />}
                                >
                                    {'Опубликовать'}
                                </Button>
                                <Button
                                    disabled={!checkSelectedEvents(true)}
                                    type={'primary'}
                                    onClick={() =>
                                        handleChangeMultiplePublished(false)
                                    }
                                    icon={<CloseOutlined />}
                                >
                                    {'Снять с публикации'}
                                </Button>
                                {selectedEvents.length ? (
                                    <div>
                                        {'Выбрано: '}
                                        {selectedEvents.length}
                                    </div>
                                ) : null}
                            </Space>
                        </Row>
                    </Col>
                    <Col span={8}></Col>
                </Row>
                <Table
                    dataSource={data}
                    columns={columns(navigate)}
                    rowKey={'event_id'}
                    loading={isFetching || isProcessChangeMultiplePublishing}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: selectedEvents,
                        onChange: setSelectedEvents
                    }}
                    sticky={{ offsetHeader: 0 }}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
                <Paragraph type={'secondary'} style={{ marginTop: 15 }}>
                    {'Данные в таблице обновляются каждые 5 минут'}
                </Paragraph>
            </div>
        </>
    )
}
