import type { AxiosResponse } from 'axios'
import { get, post, upload } from '../../utils/api.ts'
import type { IForm, IAdvertisingCompany, IImage, ICategory } from './types.ts'

export const create = (form: IForm): Promise<AxiosResponse<void>> =>
    post('/admin/advertising_groups', { ...form })

export const edit = (
    advertising_group_id: string,
    form: IForm
): Promise<AxiosResponse<void>> =>
    post<void>(`/admin/advertising_groups/${advertising_group_id}`, { ...form })

export const editPaymentStrategy = (
    advertising_group_id: string,
    payment_strategy: string
): Promise<AxiosResponse<void>> =>
    post<void>(
        `/admin/advertising_groups/${advertising_group_id}/change_payment_strategy`,
        { payment_strategy }
    )

export const fetch = (
    advertising_group_id: string
): Promise<AxiosResponse<IAdvertisingCompany>> =>
    get<IAdvertisingCompany>(
        `/admin/advertising_groups/${advertising_group_id}`,
        {
            include: [
                'areas',
                'advertisingGroupTimetables',
                'advertisingBanners',
                'user',
                'franchisee',
                'advertisingBanners.normalBanner',
                'advertisingBanners.customBanner',
                'companyCategories'
            ]
        }
    )

export const fetchCategories = (
    isOnline?: boolean
): Promise<AxiosResponse<ICategory[]>> =>
    get<ICategory[]>('/company-categories', {
        type: isOnline ? 'online' : 'offline'
    })

export const uploadImageBanner = (
    image: File
): Promise<AxiosResponse<IImage>> =>
    upload<IImage>(
        '/files',
        [
            {
                field: 'file',
                file: image
            }
        ],
        undefined,
        undefined,
        true
    )

export const uploadFilePDF = (file: File): Promise<AxiosResponse<IImage>> =>
    upload<IImage>(
        '/files',
        [
            {
                field: 'file',
                file
            }
        ],
        undefined,
        undefined,
        true
    )

export const fetchCoverage = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> =>
    post('/admin/advertising_coverages/count', form)
