import type { ReactElement } from 'react'
import { Radio, Space, Typography } from 'antd'
import NextStepButton from '../../../../components/NextStepButton'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import RadioDescription from '../../../../components/RadioDescription'
import { useAppSelector } from '../../../../hooks/useAppSelector'

const { Paragraph } = Typography

export default function TypeStep(): ReactElement {
    const dispatch = useDispatch()

    const { step, preCreateData, currentEvent } = useAppSelector(
        state => state.eventWizard
    )

    const isCreated = !!currentEvent?.event_id

    return (
        <>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {'Тип предложения нельзя изменить после создания предложения.'}
            </Paragraph>
            <Radio.Group
                value={currentEvent?.type ?? preCreateData.type}
                disabled={isCreated}
                onChange={event =>
                    dispatch(actions.setParam({ type: event.target.value }))
                }
            >
                <Space direction={'vertical'}>
                    <Radio value={'offline'}>
                        {'Офлайн'}
                        <RadioDescription>
                            {
                                'Предложение проходит по адресу доступному к посещению'
                            }
                        </RadioDescription>
                    </Radio>
                    <Radio value={'online'}>
                        {'Онлайн'}
                        <RadioDescription>
                            {
                                'Предложение доступно к использованию только в сети интернет'
                            }
                        </RadioDescription>
                    </Radio>
                </Space>
            </Radio.Group>
            {!currentEvent ? (
                <NextStepButton
                    onClick={() => dispatch(actions.setStep(step + 1))}
                >
                    {'Выбрать и продолжить'}
                </NextStepButton>
            ) : null}
        </>
    )
}
