import {
    Circle,
    Map,
    Placemark,
    YMaps,
    ZoomControl
} from '@pbe/react-yandex-maps'
import { App, Modal, Slider, Typography } from 'antd'

import { useEffect, useRef, useState } from 'react'
import { AddressSuggestions, type DaDataAddress } from 'react-dadata'
import type { DaDataSuggestion } from 'react-dadata/dist/types'
import axios from 'axios'
import * as Sentry from '@sentry/react'

import styles from './styles.module.scss'

const { Text } = Typography

interface IAdress {
    address: string
    lat: number
    lng: number
}

interface Props {
    address: IAdress
    selectedRadius: number
    setSelectedRadius: (value: number) => void
    isModalRadius: boolean
    onClose: () => void
    handleSaveRadius: () => void
    setAddress: (address: IAdress) => void
}

export default function ModalAdvertisingRadiusModal({
    address,
    selectedRadius,
    isModalRadius,
    onClose,
    handleSaveRadius,
    setSelectedRadius,
    setAddress
}: Props) {
    const [selectedAddress, setSelectedAddress] =
        useState<DaDataSuggestion<DaDataAddress>>()
    const [visibleCircle, setVisibleCircle] = useState(true)

    const coords = [address.lat, address.lng]

    const { message } = App.useApp()

    const suggestionsRef = useRef<AddressSuggestions>(null)

    useEffect(() => {
        if (isModalRadius && suggestionsRef.current) {
            suggestionsRef.current.setInputValue(address.address)
        }
    }, [isModalRadius, suggestionsRef.current])

    const fetchDadataAddress = async (lat: number, lon: number) => {
        try {
            const data = await axios.get(
                'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address',
                {
                    headers: {
                        Authorization: `Token ${process.env.DADATA_KEY}`,
                        Accept: 'application/json'
                    },
                    params: {
                        count: 1,
                        lat,
                        lon
                    }
                }
            )

            if (
                Array.isArray(data.data.suggestions) &&
                data.data.suggestions[0]?.data
            ) {
                data.data.suggestions[0].data.geo_lat = lat
                data.data.suggestions[0].data.geo_lon = lon

                setSelectedAddress(data.data.suggestions[0])
                setAddress({
                    address: data.data.suggestions[0].value,
                    lat,
                    lng: lon
                })
            } else {
                message.open({
                    type: 'warning',
                    content: 'Не удалось найти адрес!'
                })
            }
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    return (
        <Modal
            title={'Радиус'}
            open={isModalRadius}
            okText={'Принять'}
            onOk={handleSaveRadius}
            onCancel={onClose}
            closable={false}
            cancelButtonProps={{
                style: { display: 'none' }
            }}
            style={{ top: 30 }}
            destroyOnClose
        >
            <Text className={styles.label}>{'Укажите ваш адрес'}</Text>
            <AddressSuggestions
                ref={suggestionsRef}
                token={process.env.DADATA_KEY ?? ''}
                value={selectedAddress}
                onChange={value => {
                    if (value?.data?.geo_lat && value?.data.geo_lon) {
                        setSelectedAddress(value)
                        setAddress({
                            address: value.value,
                            lat: parseFloat(value.data.geo_lat),
                            lng: parseFloat(value.data.geo_lon)
                        })
                    } else {
                        message.open({
                            type: 'warning',
                            content: 'Пожалуйста, введите более точный адрес!'
                        })
                    }
                }}
                filterLanguage={'ru'}
                inputProps={{
                    placeholder: 'Начните вводить название адреса',
                    style: { marginBottom: 10 }
                }}
            />
            {address.lat && address.lng ? (
                <>
                    <Text className={styles.label}>
                        {'Укажите радиус от адреса, км'}
                    </Text>
                    <Slider
                        value={selectedRadius}
                        defaultValue={selectedRadius}
                        min={1}
                        max={100}
                        onChange={setSelectedRadius}
                    />
                    <div
                        style={{
                            width: '100%',
                            height: 470,
                            borderRadius: 10,
                            overflow: 'hidden'
                        }}
                        onMouseEnter={() => setVisibleCircle(false)}
                        onMouseLeave={() => setVisibleCircle(true)}
                    >
                        <YMaps
                            query={{
                                apikey: process.env.YANDEX_API_KEY,
                                mode: 'release',
                                lang: 'ru_RU'
                            }}
                        >
                            <Map
                                key={coords.join('')}
                                width={'100%'}
                                height={'100%'}
                                options={{
                                    copyrightUaVisible: false,
                                    copyrightLogoVisible: false,
                                    copyrightProvidersVisible: false
                                }}
                                defaultState={{
                                    center: coords,
                                    zoom: 8
                                }}
                                onClick={(event: {
                                    get: (type: string) => Array<number>
                                }) => {
                                    const [latitude, longitude] =
                                        event.get('coords')
                                    fetchDadataAddress(latitude, longitude)
                                }}
                            >
                                <Placemark geometry={coords} />
                                <Circle
                                    geometry={[coords, selectedRadius * 1000]}
                                    options={{
                                        fillColor: 'rgba(255, 224, 241, 0.4)',
                                        strokeColor: '#ff57a6',
                                        strokeOpacity: 0.8,
                                        strokeWidth: 5,
                                        visible: visibleCircle
                                    }}
                                />
                                <ZoomControl />
                            </Map>
                        </YMaps>
                    </div>
                </>
            ) : null}
        </Modal>
    )
}
