import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointer } from '@fortawesome/free-solid-svg-icons'
import { Button, Upload, Spin, Typography, Row, Col } from 'antd'
import SortableList, { SortableItem } from 'react-easy-sort'
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { actions } from '../../slice'
import { companyWizardSelector } from '../../selectors'
import type { IGallery } from '../../types'
import ModalCrop from '../../../../components/ModalCrop'
import NextStepButton from '../../../../components/NextStepButton'

const { Dragger } = Upload
const { Paragraph } = Typography

// Styles
import styles from './styles.module.scss'

const limitUploadPhoto = process.env.LIMIT_UPLOAD_PHOTO
    ? parseInt(process.env.LIMIT_UPLOAD_PHOTO)
    : 0

export default function GalleryStep(): ReactElement {
    const [queueUpload, setQueueUpload] = useState<File[]>([])
    const [items, setItems] = useState<IGallery[]>([])
    const [isModalCrop, setIsModalCrop] = useState(false)
    const [aspect, setAspect] = useState(1)

    const debounceQueueUpload = useDebounce<File[]>(queueUpload, 200)

    const dispatch = useDispatch()

    const {
        currentCompany,
        isUploadPhoto,
        removingPhotos,
        isSortingGallery,
        isCroppingPhoto
    } = useAppSelector(companyWizardSelector)

    useEffect(() => {
        if (currentCompany && Array.isArray(currentCompany.gallery)) {
            setItems(currentCompany.gallery)
        }
    }, [currentCompany])

    useEffect(() => {
        if (debounceQueueUpload.length && currentCompany) {
            dispatch(
                actions.uploadGallery({
                    company_id: currentCompany.company_id,
                    files: queueUpload
                })
            )
            setQueueUpload([])
        }
    }, [debounceQueueUpload])

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        setItems(array => {
            const move = arrayMove(array, oldIndex, newIndex)

            if (currentCompany) {
                dispatch(
                    actions.sortGallery({
                        company_id: currentCompany.company_id,
                        sort_ids: move.map(item => item.id)
                    })
                )
            }

            return move
        })
    }

    const handleRemovePhoto = (media_id: number) => {
        if (currentCompany) {
            dispatch(
                actions.removePhoto({
                    company_id: currentCompany.company_id,
                    media_id
                })
            )
        }
    }

    const handleCropPhoto = (data: {
        width?: number
        height?: number
        x?: number
        y?: number
    }) => {
        if (currentCompany && items.length > 0) {
            dispatch(
                actions.croppingPhoto({
                    company_id: currentCompany.company_id,
                    media_id: items[0].id,
                    crop_type: aspect === 1 ? 'square' : 'wide',
                    width: Math.round(data.width ?? 0),
                    height: Math.round(data.height ?? 0),
                    x_offset: Math.round(data.x ?? 0) || 1,
                    y_offset: Math.round(data.y ?? 0) || 1
                })
            )
        }
        setIsModalCrop(false)
    }

    return (
        <>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {'Изменения сохраняются сразу, после загрузки фото.'}
            </Paragraph>
            {currentCompany && items.length > 0 ? (
                <>
                    <Paragraph style={{ margin: 0 }}>
                        {'Миниатюра главной фотографии'}
                    </Paragraph>
                    <Paragraph
                        type={'secondary'}
                        style={{ margin: '0 0 10px', fontSize: 12 }}
                    >
                        {'Нажмите на каждое превью для редактирования'}
                    </Paragraph>
                    <Row gutter={15}>
                        <Col span={5}>
                            <img
                                draggable={false}
                                className={styles.preview__square}
                                src={
                                    currentCompany?.preview_thumbnails
                                        ?.square ?? items[0].url
                                }
                                style={
                                    isCroppingPhoto
                                        ? { cursor: 'default', opacity: 0.6 }
                                        : {}
                                }
                                onClick={() => {
                                    if (isCroppingPhoto) {
                                        return
                                    }
                                    setIsModalCrop(true)
                                    setAspect(1)
                                }}
                            />
                            {isCroppingPhoto ? (
                                <Spin className={styles.preview__spin} />
                            ) : (
                                <FontAwesomeIcon
                                    className={styles.preview__hand}
                                    icon={faHandPointer}
                                />
                            )}
                            <Paragraph
                                type={'secondary'}
                                className={styles.preview__text}
                            >
                                {'Превью квадратное'}
                            </Paragraph>
                        </Col>
                        <Col span={9}>
                            <img
                                draggable={false}
                                className={styles.preview__wide}
                                src={
                                    currentCompany?.preview_thumbnails?.wide ??
                                    items[0].url
                                }
                                style={
                                    isCroppingPhoto
                                        ? { cursor: 'default', opacity: 0.6 }
                                        : {}
                                }
                                onClick={() => {
                                    if (isCroppingPhoto) {
                                        return
                                    }
                                    setIsModalCrop(true)
                                    setAspect(16 / 9)
                                }}
                            />
                            {isCroppingPhoto ? (
                                <Spin className={styles.preview__spin} />
                            ) : (
                                <FontAwesomeIcon
                                    className={styles.preview__hand}
                                    icon={faHandPointer}
                                />
                            )}
                            <Paragraph
                                type={'secondary'}
                                className={styles.preview__text}
                            >
                                {'Превью горизонтальное'}
                            </Paragraph>
                        </Col>
                    </Row>
                    <Paragraph style={{ margin: '20px 0 0' }}>
                        {'Галерея'}
                    </Paragraph>
                </>
            ) : null}
            <SortableList
                className={styles.list}
                onSortEnd={onSortEnd}
                draggedItemClassName={'dragged'}
                allowDrag={
                    !isUploadPhoto ||
                    removingPhotos.length === 0 ||
                    isSortingGallery
                }
            >
                {items.map((item, index) => (
                    <SortableItem key={item.id}>
                        <div
                            className={styles.item}
                            style={{
                                background: `url(${item.url})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <Button
                                danger
                                loading={removingPhotos.includes(item.id)}
                                type={'primary'}
                                shape={'circle'}
                                icon={<DeleteOutlined />}
                                size={'small'}
                                onClick={() => handleRemovePhoto(item.id)}
                            />
                            {index === 0 ? (
                                <Paragraph
                                    type={'secondary'}
                                    style={{
                                        position: 'absolute',
                                        bottom: -30,
                                        fontSize: 10,
                                        textAlign: 'center'
                                    }}
                                >
                                    {'Главное фото'}
                                </Paragraph>
                            ) : null}
                        </div>
                    </SortableItem>
                ))}
                {items.length < limitUploadPhoto ? (
                    <div className={styles.item}>
                        <Dragger
                            className={styles.upload}
                            showUploadList={false}
                            accept={'image/*'}
                            multiple
                            disabled={
                                isUploadPhoto ||
                                removingPhotos.length > 0 ||
                                isSortingGallery
                            }
                            beforeUpload={file => {
                                if (queueUpload.length < limitUploadPhoto) {
                                    setQueueUpload(prev => [...prev, file])
                                }
                                return false
                            }}
                        >
                            {isUploadPhoto ? (
                                <Spin size={'large'} />
                            ) : (
                                <>
                                    <p className={'ant-upload-drag-icon'}>
                                        <InboxOutlined />
                                    </p>
                                    <p className={'ant-upload-text'}>
                                        {
                                            'Перенесите или нажмите для добавления фото'
                                        }
                                    </p>
                                </>
                            )}
                        </Dragger>
                    </div>
                ) : null}
            </SortableList>
            {items.length > 0 ? (
                <ModalCrop
                    image={items[0].original}
                    open={isModalCrop}
                    onCancel={() => setIsModalCrop(false)}
                    onOk={handleCropPhoto}
                    aspect={aspect}
                />
            ) : null}
            <NextStepButton />
        </>
    )
}
