import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    CreatePromoCodePayload,
    CreatePromoCodeSuccessPayload,
    CreatePromoCodeFailurePayload,
    EditPromoCodePayload,
    EditPromoCodeSuccessPayload,
    EditPromoCodeFailurePayload,
    RemovePromoCodePayload,
    RemovePromoCodeSuccessPayload,
    RemovePromoCodeFailurePayload,
    CheckDeletablePromoCodePayload,
    CheckDeletablePromoCodeSuccessPayload,
    CheckDeletablePromoCodeFailurePayload,
    CheckPromoCodePayload,
    CheckPromoCodeSuccessPayload,
    CheckPromoCodeFailurePayload
} from './types'

const initialState: InitialStateType = {
    data: [],
    meta: null,
    filter: {},
    checkedDeletablePromoCodes: {},
    enteredPromoCodeIsCorrect: null,
    isFetching: false,
    isWizardModal: false,
    isProcessCreate: false,
    isCheckingPromoCode: false,
    isProcessEdit: false,
    isProcessRemove: false,
    isProcessCheckDeletable: false,
    error: null
}

const promoCodesSlice = createSlice({
    name: 'promoCodes',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.filter = action.payload.filter
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        create(
            state: InitialStateType,
            _action: PayloadAction<CreatePromoCodePayload>
        ): void {
            state.isProcessCreate = true
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreatePromoCodeSuccessPayload>
        ): void {
            state.isWizardModal = false
            state.isProcessCreate = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreatePromoCodeFailurePayload>
        ): void {
            state.isProcessCreate = false
            state.error = action.payload.error
        },
        checkPromoCode(
            state: InitialStateType,
            _action: PayloadAction<CheckPromoCodePayload>
        ): void {
            state.isCheckingPromoCode = true
        },
        checkPromoCodeSuccess(
            state: InitialStateType,
            action: PayloadAction<CheckPromoCodeSuccessPayload>
        ): void {
            state.enteredPromoCodeIsCorrect = !action.payload
            state.isCheckingPromoCode = false
        },
        checkPromoCodeFailure(
            state: InitialStateType,
            action: PayloadAction<CheckPromoCodeFailurePayload>
        ): void {
            state.isCheckingPromoCode = false
            state.error = action.payload.error
        },
        resetCheckPromoCode(state: InitialStateType): void {
            state.enteredPromoCodeIsCorrect = null
        },
        edit(
            state: InitialStateType,
            _action: PayloadAction<EditPromoCodePayload>
        ): void {
            state.isProcessEdit = true
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditPromoCodeSuccessPayload>
        ): void {
            state.isWizardModal = false
            state.isProcessEdit = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditPromoCodeFailurePayload>
        ): void {
            state.isProcessEdit = false
            state.error = action.payload.error
        },
        checkDeletable(
            state: InitialStateType,
            _action: PayloadAction<CheckDeletablePromoCodePayload>
        ) {
            state.isProcessCheckDeletable = true
        },
        checkDeletableSuccess(
            state: InitialStateType,
            action: PayloadAction<CheckDeletablePromoCodeSuccessPayload>
        ) {
            state.isProcessCheckDeletable = false
            state.checkedDeletablePromoCodes[action.payload.promoCodeId] =
                action.payload.isDeletable
        },
        checkDeletableFailure(
            state: InitialStateType,
            action: PayloadAction<CheckDeletablePromoCodeFailurePayload>
        ) {
            state.isProcessCheckDeletable = false
            state.error = action.payload.error
        },
        remove(
            state: InitialStateType,
            _action: PayloadAction<RemovePromoCodePayload>
        ): void {
            state.isProcessRemove = true
        },
        removeSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemovePromoCodeSuccessPayload>
        ): void {
            state.isProcessRemove = false
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemovePromoCodeFailurePayload>
        ): void {
            state.isProcessRemove = false
            state.error = action.payload.error
        },
        setWizardModal(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ): void {
            state.isWizardModal = action.payload
        }
    }
})

export const actions = promoCodesSlice.actions

export default promoCodesSlice.reducer
