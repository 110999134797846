import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IBlock, IStats, IStatsSlow } from './types'
import { formatApiError } from '../../utils/helpers.ts'

export const fetchBlock = async (
    name: string
): Promise<AxiosResponse<IBlock>> => {
    try {
        return await get<IBlock>(`/blocks/${name}`, {
            include: 'activeBanners'
        })
    } catch (error) {
        const { code } = formatApiError(error)

        if (code === 404) {
            // @ts-ignore
            return {
                data: {
                    block_id: '',
                    name,
                    caption: '',
                    mode: '',
                    published: false,
                    banners: []
                }
            }
        }

        throw error
    }
}

export const fetchStats = (): Promise<AxiosResponse<IStats>> =>
    get<IStats>('/admin/stats')

export const fetchStatsSlow = (): Promise<AxiosResponse<IStatsSlow>> =>
    get<IStatsSlow>('/admin/stats/slow')
