import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Button, Form, InputNumber, Modal, Row } from 'antd'
import RegionSelector from '../../containers/RegionSelector'
import {
    ApartmentOutlined,
    RadiusSettingOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsCoverageSelector } from '../../containers/SettingsCoverage/selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsCoverage/slice.ts'
import { useForm, useWatch } from 'antd/es/form/Form'

export function CoverageWizardModal(): ReactElement {
    const { selectedCoverageItem, isModalWizardOpen, isProcessWizard } =
        useAppSelector(settingsCoverageSelector)

    const dispatch = useDispatch()
    const [form] = useForm()

    const watchAreaId = useWatch('area_id', form)

    useEffect(() => {
        if (selectedCoverageItem) {
            form.setFieldsValue({
                area_id: `${selectedCoverageItem.area.name}{d}${selectedCoverageItem.area_id}`,
                count: selectedCoverageItem.count,
                districts_count: selectedCoverageItem.districts_count,
                district_size: selectedCoverageItem.district_size
            })
        }
    }, [selectedCoverageItem])

    const handleSendForm = (form: Record<string, unknown>) => {
        if (form.area_id) {
            const [_, area_id] = (form.area_id as string).split('{d}')
            form.area_id = area_id
            if (selectedCoverageItem) {
                dispatch(
                    actions.edit({
                        advertising_coverage_id:
                            selectedCoverageItem.advertising_coverage_id,
                        form
                    })
                )
            } else {
                dispatch(actions.create({ form }))
            }
        }
    }

    const handleCloseModal = () => {
        dispatch(actions.toggleModalWizardOpen(false))
    }

    return (
        <Modal
            open={isModalWizardOpen}
            title={`${selectedCoverageItem ? 'Редактирование' : 'Создание'} охвата`}
            onCancel={handleCloseModal}
            afterClose={form.resetFields}
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
        >
            <Form form={form} layout={'vertical'} onFinish={handleSendForm}>
                <Form.Item
                    style={{ margin: '20px 0 10px' }}
                    name={'area_id'}
                    label={'Выбор Региона / Города'}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите регион или город'
                        }
                    ]}
                >
                    <RegionSelector
                        key={watchAreaId}
                        initialValue={watchAreaId}
                        onChange={() => null}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'count'}
                    label={'Количество пользователей'}
                    rules={[
                        {
                            required: true,
                            message:
                                'Пожалуйста введите количество пользователей'
                        }
                    ]}
                >
                    <InputNumber
                        addonAfter={<UsergroupAddOutlined />}
                        style={{ width: '100%' }}
                        maxLength={13}
                        formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        placeholder={'Количество пользователей'}
                        onKeyDown={event => {
                            if (
                                !/[0-9]/.test(event.key) &&
                                event.key !== 'Backspace'
                            ) {
                                event.preventDefault()
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'districts_count'}
                    label={'Количество районов'}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите количество районов'
                        }
                    ]}
                >
                    <InputNumber
                        addonAfter={<ApartmentOutlined />}
                        style={{ width: '100%' }}
                        maxLength={5}
                        formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        placeholder={'Количество районов'}
                        onKeyDown={event => {
                            if (
                                !/[0-9]/.test(event.key) &&
                                event.key !== 'Backspace'
                            ) {
                                event.preventDefault()
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={'district_size'}
                    label={'Размер района (км)'}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите размер района'
                        }
                    ]}
                >
                    <InputNumber
                        addonAfter={<RadiusSettingOutlined />}
                        style={{ width: '100%' }}
                        maxLength={10}
                        formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        placeholder={'Размер района (км)'}
                        onKeyDown={event => {
                            if (
                                !/[0-9]/.test(event.key) &&
                                event.key !== 'Backspace'
                            ) {
                                event.preventDefault()
                            }
                        }}
                    />
                </Form.Item>
                <Row justify={'end'}>
                    <Button
                        loading={isProcessWizard}
                        htmlType={'submit'}
                        type={'primary'}
                    >
                        {`${selectedCoverageItem ? 'Сохранить' : 'Создать'} охват`}
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}
