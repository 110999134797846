import type { AxiosResponse } from 'axios'
import { get, post } from '../../utils/api'
import type { IEvent } from './types'
import type { Key } from 'react'

export const fetch = async (
    page: number,
    page_size: number,
    filter: Record<string, string>
): Promise<AxiosResponse<IEvent[]>> => {
    const filterParam: Record<string, string> = {}

    for (const key in filter) {
        filterParam[`filter[${key}]`] = filter[key].includes('{d}')
            ? filter[key].split('{d}')[1]
            : filter[key]
    }

    return await get<IEvent[]>('/admin/events', {
        page,
        page_size,
        include: 'mainImage,companies',
        sort: '-created_at',
        ...filterParam
    })
}

export const changeMultiplePublished = (
    event_ids: Key[],
    published: boolean
): Promise<AxiosResponse<void>> =>
    post<void>('/admin/events/multiply/publish', {
        event_ids,
        published
    })
