import type { ReactElement } from 'react'
import {
    Col,
    Row,
    Steps,
    Typography,
    Card,
    Divider,
    Space,
    Tag,
    Select,
    Form,
    Modal,
    Button,
    message,
    Flex
} from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector'
import TitleStep from '../../components/TitleStep'
import {
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined
} from '@ant-design/icons'
import { actions } from './slice'
import { useDispatch } from 'react-redux'
import { useEffect, useMemo, useState, useRef } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

const { Title, Paragraph } = Typography

// Steps
import TypeStep from './components/TypeStep'
import BasicStep from './components/BasicStep'
import ScheduleStep from './components/ScheduleStep'
import CategoriesStep from './components/CategoriesStep'
import FeaturesStep from './components/FeaturesStep'
import CommunicationStep from './components/CommunicationStep'
import NetworkStep from './components/NetworkStep'
import GalleryStep from './components/GalleryStep'
import VideoGalleryStep from './components/VideoGalleryStep'
import DescriptionStep from './components/DescriptionStep'
import AddressesStep from './components/AddressesStep'
import DeliveryStep from './components/DeliveryStep'
import TargetStep from './components/TargetStep'
import DetailsStep from './components/DetailsStep'
import RegionStep from './components/RegionStep'
import GeneralCityStep from './components/GeneralCityStep'
import NotFoundStep from '../../components/NotFoundStep'
import Status from '../../components/Status'
import { companyWizardSelector } from './selectors'
import CompanyPaymentBanner from '../../components/CompanyPaymentBanner'
import { authorizationSelector } from '../Authorization/selectors'
import { getRole } from '../../utils/helpers'
import { CompanyWizardContext } from '../../context/companyWizard-context'

// Images
import congratulations from './images/congratulations.png'
import settings from './images/settings.png'
import create from './images/create.png'
import payment from './images/payment.png'
import created from './images/created.png'

const steps = [
    {
        title: 'Тип компании',
        steptitle: 'Выберите тип компании',
        component: <TypeStep />
    },
    {
        title: 'Основное',
        steptitle: 'Основное',
        component: <BasicStep />,
        slug: 'basic'
    },
    {
        title: 'График работы',
        steptitle: 'График работы',
        keyerror: 'invalid_work_time',
        component: <ScheduleStep />,
        slug: 'schedule'
    },
    {
        title: 'Категории',
        steptitle: 'Категории',
        keyerror: 'invalid_categories',
        component: <CategoriesStep />,
        slug: 'categories'
    },
    {
        title: 'Особенности',
        steptitle: 'Особенности',
        keyerror: 'invalid_features',
        component: <FeaturesStep />,
        slug: 'features'
    },
    {
        title: 'Средства связи',
        steptitle: '',
        keyerror: 'invalid_communication_contacts',
        component: <CommunicationStep />,
        slug: 'communication'
    },
    {
        title: 'Сайт и соц.сети',
        steptitle: '',
        component: <NetworkStep />,
        slug: 'network'
    },
    {
        title: 'Зона выезда/доставки',
        steptitle: 'Зона выезда/доставки',
        keyerror: 'invalid_on_house',
        component: <DeliveryStep />,
        slug: 'delivery'
    },
    {
        title: 'Целевое действие',
        steptitle: 'Целевое действие',
        component: <TargetStep />,
        slug: 'target'
    },
    {
        title: 'Доп. скидка',
        steptitle: 'Доп. скидка',
        component: <DetailsStep />,
        slug: 'details'
    },
    {
        title: 'Фотогалерея',
        steptitle: 'Фотогалерея',
        keyerror: 'invalid_gallery',
        component: <GalleryStep />,
        slug: 'gallery'
    },
    {
        title: 'Видеогалерея',
        steptitle: '',
        component: <VideoGalleryStep />,
        slug: 'video'
    },
    {
        title: 'Описание',
        steptitle: 'Описание',
        keyerror: 'invalid_about',
        component: <DescriptionStep />,
        slug: 'description'
    },
    {
        title: 'Адреса компании',
        steptitle: '',
        keyerror: 'invalid_address',
        component: <AddressesStep />,
        slug: 'addresses'
    },
    {
        title: 'Регион показа',
        steptitle: '',
        keyerror: 'invalid_areas',
        component: <RegionStep />,
        slug: 'region'
    },
    {
        title: 'Основной город',
        steptitle: '',
        keyerror: 'invalid_address',
        component: <GeneralCityStep />,
        slug: 'city'
    }
]

interface Props {
    isCreate?: boolean
}

export default function CompanyWizard({ isCreate }: Props): ReactElement {
    const [isModalConfirmPublished, setIsModalConfirmPublished] =
        useState(false)
    const [isModalHasChanges, setIsModalHasChanges] = useState<number | false>(
        false
    )
    const [isDraftModal, setIsDraftModal] = useState(false)
    const [isCreateModal, setIsCreateModal] = useState(false)
    const [isCancelModal, setIsCancelModal] = useState(false)
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const { company_id } = useParams()
    const navigate = useNavigate()

    const [messageApi, contextHolder] = message.useMessage()

    const dispatch = useDispatch()

    const { currentCompany, step, isSaving, hasStepChanges } = useAppSelector(
        companyWizardSelector
    )

    const { profile } = useAppSelector(authorizationSelector)

    const prevStatus = useRef(currentCompany?.status)

    const isUser = profile ? getRole(profile.roles) === 'user' : false

    useEffect(() => {
        if (currentCompany) {
            if (
                currentCompany.status === 'active' &&
                prevStatus.current === 'draft'
            ) {
                setIsCreateModal(true)
            }
            prevStatus.current = currentCompany.status
        }
    }, [currentCompany])

    const ignoreSteps = useMemo(() => {
        if (!currentCompany) {
            return []
        }

        let collect: number[] = []

        if (currentCompany.type === 'online') {
            collect = [13, 7, 9]
        } else {
            collect = [14, 15]
            if (!currentCompany.on_house) {
                collect.push(7)
            }
        }

        return collect
    }, [currentCompany])

    const filteredSteps = steps.filter(
        (_, index) => !ignoreSteps.includes(index)
    )

    useEffect(() => {
        const page = searchParams.get('page')

        const findStepIndex = filteredSteps.findIndex(
            step => step.slug === page
        )

        if (page && findStepIndex) {
            dispatch(actions.setStep(findStepIndex))
        }
    }, [])

    const currentStep = filteredSteps.find((_, index) => index === step)

    const names = currentCompany ? [currentCompany.name].filter(Boolean) : false

    const StepComponent = currentStep ? currentStep.component : null

    const handleChangePublishedStatus = () => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        published: !currentCompany.published
                    }
                })
            )
            setIsModalConfirmPublished(false)
            messageApi.open({
                type: 'info',
                content: 'Изменения вступят в силу, через 5 минут'
            })
        }
    }

    const handleNextStep = (value: number) => {
        const findStep = filteredSteps.find((_, index) => index === value)

        const historyParams: { [key: string]: string } = {}

        for (const [key, value] of searchParams.entries()) {
            historyParams[key] = value
        }

        if (findStep && !isCreate) {
            if (findStep.slug) {
                setSearchParams({
                    ...historyParams,
                    page: findStep.slug
                })
            } else {
                setSearchParams({})
            }
        }

        dispatch(actions.setStep(value))
    }

    const handleSaveClose = () => {
        setIsCancelModal(false)
        const $saveButton = document.getElementById('saveButton')
        if ($saveButton) {
            $saveButton.click()
        }
    }

    const handleOpenCancelModal = () => {
        setIsCancelModal(true)
        const $saveButton = document.getElementById('saveButton')
        if ($saveButton) {
            const isDisabled = $saveButton.getAttribute('disabled') != null
            setIsSaveButtonDisabled(isDisabled)
        }
    }

    if (!StepComponent) {
        return (
            <NotFoundStep>{'Шаг не доступен для вашей компании'}</NotFoundStep>
        )
    }

    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                <Col span={8}>
                    <Title level={3} style={{ margin: 0 }}>
                        {isCreate
                            ? 'Создание компании'
                            : 'Редактирование компании'}
                    </Title>
                    {!isCreate && names ? (
                        <Paragraph style={{ fontSize: 18, marginBottom: 0 }}>
                            {names.join(' / ')}
                        </Paragraph>
                    ) : null}
                    {currentCompany ? (
                        <Status
                            type={currentCompany.type}
                            published={!!currentCompany.cached_displayed}
                        />
                    ) : null}
                </Col>
                {currentCompany ? (
                    <Col
                        span={16}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        {Array.isArray(currentCompany?.summary_status) ? (
                            <Space direction={'vertical'}>
                                {currentCompany.summary_status.length > 0 ? (
                                    <Space size={[0, 'small']} wrap>
                                        {currentCompany.summary_status.map(
                                            (status, index) => (
                                                <Tag
                                                    key={`summary-${index}`}
                                                    bordered={false}
                                                    color={'error'}
                                                >
                                                    {status}
                                                </Tag>
                                            )
                                        )}
                                    </Space>
                                ) : (
                                    <Col style={{ marginRight: 8 }}>
                                        <Paragraph
                                            type={'success'}
                                            style={{ margin: 0, fontSize: 15 }}
                                        >
                                            {'Компания доступна к публикации.'}
                                        </Paragraph>
                                        <Paragraph
                                            style={{
                                                color: '#ff57a5',
                                                margin: 0,
                                                fontSize: 12
                                            }}
                                        >
                                            {
                                                'Для активации перейдите к оплате статуса «Премиум».'
                                            }
                                        </Paragraph>
                                        <Paragraph
                                            style={{
                                                color: '#ff57a5',
                                                margin: 0,
                                                fontSize: 12
                                            }}
                                        >
                                            {
                                                'После оплаты компания будет опубликована автоматически.'
                                            }
                                        </Paragraph>
                                    </Col>
                                )}
                                {currentCompany.status === 'draft' ||
                                !currentCompany.activePlanSubscription
                                    ?.is_active ? (
                                    <Button
                                        style={{
                                            float: 'right',
                                            marginRight: 8
                                        }}
                                        type={'primary'}
                                        onClick={() =>
                                            currentCompany.status === 'draft'
                                                ? setIsDraftModal(true)
                                                : hasStepChanges
                                                  ? handleOpenCancelModal()
                                                  : navigate(
                                                        `/companies/${company_id}`
                                                    )
                                        }
                                        icon={<CheckOutlined />}
                                    >
                                        {'Сохранить и закрыть'}
                                    </Button>
                                ) : null}
                            </Space>
                        ) : (
                            <Row gutter={8}>
                                <Col>
                                    <Form.Item
                                        label={
                                            <b>
                                                {'Статус публикации компании'}
                                            </b>
                                        }
                                    >
                                        <Select
                                            loading={isSaving}
                                            value={
                                                currentCompany?.published
                                                    ? 'published'
                                                    : 'unpublished'
                                            }
                                            defaultValue={
                                                currentCompany?.published
                                                    ? 'published'
                                                    : 'unpublished'
                                            }
                                            style={{ width: 170 }}
                                            onChange={() =>
                                                setIsModalConfirmPublished(true)
                                            }
                                            options={[
                                                {
                                                    value: 'published',
                                                    label: 'Опубликована'
                                                },
                                                {
                                                    value: 'unpublished',
                                                    label: 'Не опубликована'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Link
                                        to={`/companies/${currentCompany.company_id}`}
                                    >
                                        <Button icon={<CloseOutlined />}>
                                            {'Закрыть'}
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        )}
                    </Col>
                ) : null}
            </Row>
            <Divider style={{ marginBottom: 20 }} />
            <Row gutter={[50, 50]}>
                <Col span={5}>
                    <Title level={5} style={{ margin: '0 0 20px' }}>
                        {'Разделы'}
                    </Title>
                    <Steps
                        progressDot
                        size={'small'}
                        direction={'vertical'}
                        current={step}
                        onChange={value => {
                            if (hasStepChanges) {
                                setIsModalHasChanges(value)
                            } else {
                                handleNextStep(value)
                            }
                        }}
                        items={
                            isCreate
                                ? steps.filter((_, index) => index < 2)
                                : filteredSteps.map(item => {
                                      const isEmpty =
                                          item.keyerror &&
                                          currentCompany &&
                                          Array.isArray(
                                              currentCompany.summary_status_all_codes
                                          )
                                              ? currentCompany.summary_status_all_codes.some(
                                                    code =>
                                                        item.keyerror
                                                            .split(',')
                                                            .includes(code)
                                                )
                                              : false

                                      return {
                                          ...item,
                                          ...(isEmpty
                                              ? {
                                                    title: (
                                                        <Row align={'middle'}>
                                                            {item.title}
                                                            <ExclamationCircleFilled
                                                                style={{
                                                                    marginLeft: 4,
                                                                    color: '#ff4d4f'
                                                                }}
                                                            />
                                                        </Row>
                                                    )
                                                }
                                              : {})
                                      }
                                  })
                        }
                    />
                    {currentCompany?.status === 'active' ? (
                        <img
                            src={created}
                            className={'hover-opacity'}
                            style={{ width: '80%', cursor: 'pointer' }}
                            onClick={() => setIsCreateModal(true)}
                        />
                    ) : null}
                </Col>
                <Col span={19}>
                    <Card style={{ background: 'rgba(255, 255, 255, 0.7)' }}>
                        {currentStep?.steptitle ? (
                            <TitleStep>{currentStep.steptitle}</TitleStep>
                        ) : null}
                        <CompanyWizardContext.Provider
                            value={{
                                changeMenu: value => {
                                    if (hasStepChanges) {
                                        setIsModalHasChanges(value)
                                    } else {
                                        handleNextStep(value)
                                    }
                                }
                            }}
                        >
                            {StepComponent}
                        </CompanyWizardContext.Provider>
                    </Card>
                </Col>
            </Row>
            <CompanyPaymentBanner
                companyId={company_id}
                show={
                    isUser &&
                    !currentCompany?.activePlanSubscription &&
                    currentCompany?.status === 'active'
                }
            />
            <Modal
                open={isModalConfirmPublished}
                title={'Изменение статуса публикации'}
                cancelText={'Нет'}
                okText={'Да'}
                onOk={handleChangePublishedStatus}
                onCancel={() => setIsModalConfirmPublished(false)}
            >
                {currentCompany?.published
                    ? 'Вы уверены, что хотите снять компанию с публикации?'
                    : 'Вы уверены, что хотите опубликовать компанию?'}
            </Modal>
            <Modal
                open={typeof isModalHasChanges === 'number'}
                title={
                    <Row align={'middle'}>
                        <ExclamationCircleOutlined
                            style={{
                                fontSize: 22,
                                color: 'red',
                                marginRight: 10
                            }}
                        />{' '}
                        <Paragraph
                            style={{
                                margin: 0,
                                fontWeight: '600',
                                fontSize: 16
                            }}
                        >
                            {'Внимание'}
                        </Paragraph>
                    </Row>
                }
                okText={'Все равно перейти'}
                cancelText={'Остаться'}
                onOk={() => {
                    if (typeof isModalHasChanges === 'number') {
                        handleNextStep(isModalHasChanges)
                    }
                    dispatch(actions.setHasStepChanges(false))
                    setIsModalHasChanges(false)
                }}
                onCancel={() => setIsModalHasChanges(false)}
            >
                <p>
                    {
                        'У вас остались не сохраненные изменения на текущем шаге, все равно перейти на другой шаг?'
                    }
                </p>
            </Modal>
            <Modal
                open={isDraftModal}
                onOk={() => setIsDraftModal(false)}
                onCancel={() => setIsDraftModal(false)}
                afterClose={() =>
                    company_id ? navigate(`/companies/${company_id}`) : null
                }
                okText={'Понятно'}
                cancelButtonProps={{
                    style: { display: 'none' }
                }}
                title={'Черновик сохранен'}
            >
                <Paragraph>
                    {
                        'Можете вернуться к редактированию разделов в любой момент.'
                    }
                </Paragraph>
            </Modal>
            <Modal
                open={isCancelModal}
                onOk={handleSaveClose}
                onCancel={() => setIsCancelModal(false)}
                cancelText={'Остаться'}
                okText={'Сохранить и закрыть'}
                okButtonProps={{
                    disabled: isSaveButtonDisabled
                }}
                title={
                    <>
                        <ExclamationCircleOutlined
                            style={{ color: 'red', marginRight: 6 }}
                        />
                        {'Внимание'}
                    </>
                }
            >
                <Paragraph>
                    {'У вас остались не сохраненные изменения.'}
                    <br />
                    {'Сохранить и закрыть компанию?'}
                </Paragraph>
            </Modal>
            <Modal
                open={isCreateModal}
                footer={null}
                width={720}
                wrapClassName={'modal-flex'}
                className={'pink-modal'}
                style={{ top: 50 }}
                onCancel={() => setIsCreateModal(false)}
            >
                <Flex vertical={true}>
                    <img
                        src={congratulations}
                        draggable={false}
                        style={{ width: '100%', margin: '-45px auto 0' }}
                    />
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {'Следующие шаги'}
                    </Title>
                    <Flex style={{ margin: '0 -18px' }}>
                        {[
                            {
                                url: `/companies/${company_id}/?tab=settings`,
                                image: settings
                            },
                            {
                                url: `/companies/${company_id}/?tab=events`,
                                image: create
                            },
                            {
                                url: `/companies/${company_id}/?tab=${
                                    isUser ? 'payment' : 'subscription'
                                }`,
                                image: payment
                            }
                        ].map((item, index) => (
                            <Link
                                key={`banner-${index}`}
                                style={{
                                    flex: '0 0 33.3333%',
                                    maxWidth: '33.3333%'
                                }}
                                to={item.url}
                                className={'hover-opacity'}
                            >
                                <img
                                    style={{ width: '100%' }}
                                    draggable={false}
                                    src={item.image}
                                />
                            </Link>
                        ))}
                    </Flex>
                </Flex>
                <Flex justify={'center'}>
                    <Button
                        type={'primary'}
                        onClick={() => setIsCreateModal(false)}
                    >
                        {'Спасибо! Понятно'}
                    </Button>
                </Flex>
            </Modal>
            {contextHolder}
        </>
    )
}
