import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Button, Pagination, Popconfirm, Row, Table, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import PromoCodesAgentsFilter from '../../components/PromoCodesAgentsFilter'
import type { ColumnsType } from 'antd/es/table'
import PromoCodesAgentsWizardModal from '../../components/PromoCodesAgentsWizardModal'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { promoCodesAgentsSelector } from './selectors.ts'
import { checkAdminRole, ignoreKeys } from '../../utils/helpers.ts'
import { authorizationSelector } from '../Authorization/selectors.ts'
import dayjs from 'dayjs'
import type { Franchisee, IAgent, User } from './types.ts'

const { Title } = Typography

// Styles
import styles from './styles.module.scss'

export default function PromoCodesAgents(): ReactElement {
    const [selectedAgent, setSelectedAgent] = useState<IAgent | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')
    const userIdQuery = searchParams.get('user_id')
    const parentUserIdQuery = searchParams.get('parent_user_id')
    const periodQuery = searchParams.get('period')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const dispatch = useDispatch()

    const { data, isFetching, meta } = useAppSelector(promoCodesAgentsSelector)
    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = checkAdminRole(profile)

    const columns: ColumnsType<IAgent> = [
        {
            title: 'Название агента',
            key: 'name',
            render: (value: IAgent) => (
                <span
                    className={styles.link}
                    onClick={() => {
                        dispatch(actions.setWizardModal(true))
                        setSelectedAgent(value)
                    }}
                >
                    {value.name}
                </span>
            )
        },
        {
            title: 'Пользователь',
            key: 'user',
            dataIndex: 'user',
            align: 'center',
            render: (value: User) => (
                <>{[value.email, value.phone].filter(Boolean).join(' - ')}</>
            )
        },
        {
            title: 'Процент агента',
            key: 'agent_commission',
            dataIndex: 'agent_commission',
            align: 'center',
            render: value => (
                <>
                    {value}
                    {'%'}
                </>
            )
        },
        {
            title: 'Дата создания агента',
            key: 'created_at',
            dataIndex: 'created_at',
            align: 'center',
            render: value => <>{dayjs(value).format('DD.MM.YYYY HH:mm')}</>
        },
        {
            title: 'Владелец агента',
            key: 'owner',
            align: 'center',
            dataIndex: 'franchisee',
            render: (value: Franchisee | null) => (
                <>
                    {[value?.user?.full_name, value?.user?.email].filter(
                        Boolean
                    ).length > 0
                        ? [value?.user?.full_name, value?.user?.email]
                              .filter(Boolean)
                              .join(' - ')
                        : '—'}
                </>
            )
        },
        {
            width: 100,
            key: 'buttons',
            render: (_: string, data: IAgent) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        style={{ marginRight: 10 }}
                        icon={<EditOutlined />}
                        onClick={() => {
                            dispatch(actions.setWizardModal(true))
                            setSelectedAgent(data)
                        }}
                    />
                    <Popconfirm
                        placement={'left'}
                        title={'Вы уверены, что хотите удалить?'}
                        onConfirm={() =>
                            dispatch(actions.remove(data.agent_id))
                        }
                        okText={'Да'}
                        cancelText={'Нет'}
                    >
                        <Button danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                </div>
            )
        }
    ]

    const getHistoryParam = () => {
        const historyParams: { [key: string]: string } = {}

        for (const [key, value] of searchParams.entries()) {
            if (ignoreKeys.includes(key)) {
                continue
            }
            historyParams[key] = value
        }

        return historyParams
    }

    useEffect(() => {
        const filter: Record<string, string> = {}

        if (isAdmin && userIdQuery) {
            filter['user_id'] = userIdQuery.split('{d}')[1]
        }

        if (parentUserIdQuery) {
            filter['parent_user_id'] = parentUserIdQuery.split('{d}')[1]
        }

        if (periodQuery) {
            filter['from'] = periodQuery.split(',')[0]
            filter['to'] = periodQuery.split(',')[1]
        }

        dispatch(actions.fetch({ page, pageSize, filter }))

        const historyParams = getHistoryParam()

        setSearchParams({
            ...historyParams,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const historyParams = getHistoryParam()

        setSearchParams({
            ...historyParams,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
    }

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Button
                    icon={<PlusOutlined />}
                    onClick={() => dispatch(actions.setWizardModal(true))}
                    type={'primary'}
                >
                    {'Создать агента'}
                </Button>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Title level={3} style={{ margin: '0 0 25px' }}>
                    {'Мои агенты'}
                </Title>
                <PromoCodesAgentsFilter />
            </div>
            <div className={styles.container}>
                <Table
                    dataSource={data}
                    columns={columns}
                    loading={isFetching}
                    pagination={false}
                    rowKey={'agent_id'}
                    sticky={{ offsetHeader: 0 }}
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
            </div>
            <PromoCodesAgentsWizardModal
                selectedAgent={selectedAgent}
                onClose={() => setSelectedAgent(null)}
            />
        </>
    )
}
