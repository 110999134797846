import type { SagaIterator } from 'redux-saga'
import * as effects from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import type { FetchPayload, SearchPayload } from './types'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import { call } from 'redux-saga/effects'

function* fetchAreas(action: { payload: FetchPayload }): SagaIterator {
    try {
        let areas
        switch (action.payload.depth) {
            case 1:
                areas = yield effects.call(
                    api.fetchRegions,
                    action.payload.id,
                    'region'
                )
                break
            case 2:
                areas = yield effects.call(
                    api.fetchRegions,
                    action.payload.id,
                    undefined,
                    'city-district'
                )
                break
            case 3:
                areas = yield effects.call(
                    api.fetchRegions,
                    action.payload.id,
                    undefined,
                    'area'
                )
                break
            default:
                areas = yield effects.call(
                    api.fetchCountries,
                    action.payload.id
                )
        }

        yield effects.put(
            actions.fetchSuccess({
                id: action.payload.id,
                data: areas.data
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield effects.put(
            actions.fetchFailure({
                id: action.payload.id,
                error: message
            })
        )
    }

    if (action.payload.resolve) {
        action.payload.resolve()
    }
}

export function* watchFetchAreas(): SagaIterator {
    yield effects.takeEvery(actions.fetch, fetchAreas)
}

function* fetchSearch(action: { payload: SearchPayload }): SagaIterator {
    try {
        let result
        switch (action.payload.depth) {
            case 1:
                result = yield effects.call(
                    api.fetchSearchCities,
                    action.payload.name,
                    'region'
                )
                break
            case 2:
                result = yield effects.call(
                    api.fetchSearchCities,
                    action.payload.name,
                    undefined,
                    'city-district'
                )
                break
            case 3:
                result = yield effects.call(
                    api.fetchSearchCities,
                    action.payload.name,
                    undefined,
                    'area'
                )
                break
            default:
                result = yield effects.call(
                    api.fetchSearchCities,
                    action.payload.name
                )
        }
        yield effects.put(actions.searchSuccess(result.data))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield effects.put(actions.searchFailure({ error: message }))
    }
}

export function* watchSearchAreas(): SagaIterator {
    yield effects.takeEvery(actions.search, fetchSearch)
}

export default function* watchAreaSelector(): SagaIterator {
    yield effects.all([
        effects.fork(watchFetchAreas),
        effects.fork(watchSearchAreas)
    ])
}
