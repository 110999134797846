import type { ReactElement, ReactNode, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { Input, Tree } from 'antd'
import type { DataNode } from 'antd/es/tree'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useEffect, useMemo, useState } from 'react'
import { actions } from '../../slice'
import { LoadingStep } from '../../../../components/LoadingStep'
import NextStepButton from '../../../../components/NextStepButton'
import { DownOutlined } from '@ant-design/icons'
import type { Key } from 'antd/es/table/interface'
import {
    getTreeParentKey,
    hasTreeChildrenChecked
} from '../../../../utils/helpers'
import SelectedTreeParent from '../../../../components/SelectedTreeParent'
import { useDebouncedCallback } from 'use-debounce'
import type { ICategory } from '../../types.ts'

export default function CategoriesStep(): ReactElement {
    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
    const [selectedCategories, setSelectedCategories] = useState<Key[]>([])
    const [searchValue, setSearchValue] = useState<string>('')

    const dispatch = useDispatch()

    const {
        currentEvent,
        hasStepChanges,
        categories,
        isFetchingCategories,
        isSaving
    } = useAppSelector(state => state.eventWizard)

    const isDisabled = isFetchingCategories || isSaving

    const defaultData = useMemo(() => {
        const result: DataNode[] = []
        const loop = (item: DataNode[]) => {
            item.forEach(i => {
                result.push({ title: i.title, key: i.key })
                if (i.children) {
                    loop(i.children)
                }
            })
        }
        loop(treeData)
        return result
    }, [treeData])

    const findCollection = useMemo(() => {
        const loop = (data: DataNode[]): DataNode[] =>
            data.map(item => {
                const strTitle = item.title as string
                const strTitleLower = (item.title as string).toLowerCase()
                const index = strTitleLower.indexOf(searchValue.toLowerCase())
                const beforeStr = strTitle.substring(0, index)
                const afterStr = strTitle.slice(index + searchValue.length)
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className={'site-tree-search-value'}>
                                {strTitle.substring(
                                    index,
                                    index + searchValue.length
                                )}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{strTitle}</span>
                    )
                if (item.children) {
                    return {
                        title,
                        icon: item.icon,
                        key: item.key,
                        children: loop(item.children)
                    }
                }

                return {
                    title,
                    icon: item.icon,
                    key: item.key
                }
            })

        return loop(treeData)
    }, [treeData, searchValue])

    useEffect(() => {
        if (currentEvent) {
            const eventCategories = currentEvent.eventCategories.map(
                (item: { eventCategoryId: string }) => item.eventCategoryId
            )
            const hasChanges =
                eventCategories.length !== selectedCategories.length ||
                eventCategories.some(
                    (event_id: string) => !selectedCategories.includes(event_id)
                )
            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentEvent, selectedCategories])

    const handleUndoChanges = () => {
        if (currentEvent) {
            setSelectedCategories(
                currentEvent.eventCategories.map(
                    (category: { eventCategoryId: string }) =>
                        category.eventCategoryId
                )
            )
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    useEffect(() => {
        setTreeData(
            categories.map((category: ICategory) => ({
                title: category.name,
                key: category.eventCategoryId,
                icon: <img height={12} src={category.icoUrl} />,
                children: category.children.map(child => ({
                    title: child.name,
                    key: child.eventCategoryId
                }))
            }))
        )
    }, [categories])

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(actions.fetchCategories({ type: 'online' }))
        }
    }, [])

    const handleSaveEvent = (): void => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: { categories: selectedCategories }
                })
            )
        }
    }

    const expandToggle = (selectedKey: Key) => {
        setExpandedKeys(prev =>
            prev.includes(selectedKey)
                ? prev.filter(key => key !== selectedKey)
                : [...prev, selectedKey]
        )
    }

    const debounced = useDebouncedCallback(value => {
        const additional: Key[] = []
        const newExpandedKeys = [
            ...defaultData.map(item => {
                if (
                    (item.title as string)
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                ) {
                    const parent = getTreeParentKey(item.key, treeData)
                    additional.push(getTreeParentKey(parent, treeData))
                    return parent
                }
                return null
            }),
            ...additional
        ].filter(
            (item, i, self): item is Key =>
                !!(item && self.indexOf(item) === i) ||
                treeData.some(data => data.key === item)
        )
        setExpandedKeys(value ? newExpandedKeys : [])
    }, 500)

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSearchValue(value)
        debounced(value)
    }

    return (
        <>
            {isFetchingCategories ? (
                <LoadingStep />
            ) : (
                findCollection.length > 0 && (
                    <>
                        <Input
                            value={searchValue}
                            onChange={onChange}
                            placeholder={'Найти категорию'}
                            style={{ marginBottom: 15 }}
                            allowClear={true}
                        />
                        <Tree
                            showIcon
                            checkable
                            checkedKeys={selectedCategories}
                            switcherIcon={<DownOutlined />}
                            expandedKeys={expandedKeys}
                            treeData={findCollection.map(item => ({
                                ...item,
                                title: hasTreeChildrenChecked(
                                    item,
                                    selectedCategories
                                ) ? (
                                    <SelectedTreeParent>
                                        {item.title as ReactNode}
                                    </SelectedTreeParent>
                                ) : (
                                    item.title
                                ),
                                checkable: false
                            }))}
                            onCheck={(value, { node }) => {
                                if (Array.isArray(value)) {
                                    setSelectedCategories(value)
                                    if (!node.expanded) {
                                        expandToggle(node.key)
                                    }
                                }
                            }}
                            onSelect={(_, { node }) => {
                                if (typeof node.checkable !== 'boolean') {
                                    if (
                                        node.children &&
                                        !selectedCategories.includes(node.key)
                                    ) {
                                        setSelectedCategories(prev => [
                                            ...prev,
                                            node.key
                                        ])
                                    }
                                }
                                if (!expandedKeys.includes(node.key)) {
                                    expandToggle(node.key)
                                }
                            }}
                            onExpand={(_, { node }) => expandToggle(node.key)}
                            style={{ background: '#FFF' }}
                        />
                    </>
                )
            )}
            <NextStepButton
                disabled={
                    isDisabled ||
                    selectedCategories.length === 0 ||
                    !hasStepChanges
                }
                loading={isDisabled}
                onClick={handleSaveEvent}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
        </>
    )
}
