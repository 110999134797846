import type { ReactElement } from 'react'
import TitleStep from '../../../../components/TitleStep'
import NextStepButton from '../../../../components/NextStepButton'
import { useEffect, useState } from 'react'
import { Col, Row, Card, Button, Modal, Form, Input } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import NotFoundStep from '../../../../components/NotFoundStep'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import type { IEventTerms } from '../../types.ts'

interface IQuestion {
    id: number
    quest: string
    answer: string
}

export default function FAQStep(): ReactElement {
    const [isAddModal, setIsAddModal] = useState(false)
    const [questions, setQuestions] = useState<IQuestion[]>([])
    const [selectedQuestion, setSelectedQuestion] = useState<false | number>(
        false
    )

    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { currentEvent, hasStepChanges, isSaving } = useAppSelector(
        state => state.eventWizard
    )

    useEffect(() => {
        if (currentEvent && Array.isArray(currentEvent.eventTerms)) {
            if (
                currentEvent.eventTerms.length === 0 &&
                questions.length === 0
            ) {
                dispatch(actions.setHasStepChanges(false))
                return
            }

            const hasChanges =
                currentEvent.eventTerms.length !== questions.length ||
                !currentEvent.eventTerms.some((item: IEventTerms) =>
                    questions.find(
                        quest =>
                            item.answer === quest.answer &&
                            item.question === quest.quest
                    )
                )

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentEvent, questions])

    const handleUndoChanges = () => {
        if (currentEvent && Array.isArray(currentEvent.eventTerms)) {
            setQuestions(
                currentEvent.eventTerms.map(
                    (item: IEventTerms, index: number) => ({
                        id: index,
                        quest: item.question,
                        answer: item.answer
                    })
                )
            )
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentEvent])

    const handleAddQuestion = () => {
        const values = form.getFieldsValue(['quest', 'answer'])
        if (values.quest && values.answer) {
            if (selectedQuestion !== false) {
                setQuestions(prev =>
                    prev.map(item => {
                        if (item.id === selectedQuestion) {
                            return {
                                ...item,
                                quest: values.quest,
                                answer: values.answer
                            }
                        }
                        return item
                    })
                )
            } else {
                setQuestions(prev => [
                    ...prev,
                    {
                        id: prev.length,
                        quest: values.quest,
                        answer: values.answer
                    }
                ])
            }
            setIsAddModal(false)
            form.resetFields()
        } else {
            for (const field in values) {
                form.setFields([{ name: field, errors: [] }])
                if (!values[field]) {
                    form.setFields([
                        { name: field, errors: ['Необходимо заполнить поле!'] }
                    ])
                }
            }
        }
    }

    const handleOpenModal = () => {
        form.resetFields()
        setIsAddModal(true)
        setSelectedQuestion(false)
    }

    const handleOpenEdit = (quest: IQuestion) => {
        setIsAddModal(true)
        setSelectedQuestion(quest.id)
        form.setFields([
            {
                name: 'quest',
                value: quest.quest
            },
            {
                name: 'answer',
                value: quest.answer
            }
        ])
    }

    const handleRemove = (quest: IQuestion) => {
        setQuestions(prev => prev.filter(item => item.id !== quest.id))
    }

    const handleSaveFAQ = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        event_terms: questions.map(quest => ({
                            question: quest.quest,
                            answer: quest.answer,
                            condition_of_order_use: false
                        }))
                    }
                })
            )
        }
    }

    return (
        <>
            <TitleStep
                rightElement={
                    <Button
                        disabled={isSaving}
                        onClick={handleOpenModal}
                        icon={<PlusOutlined />}
                    >
                        {'Добавить'}
                    </Button>
                }
            >
                {'Частые вопросы и условия'}
            </TitleStep>
            <Row style={{ marginTop: 20 }} gutter={10}>
                {questions.length > 0 ? (
                    questions.map(quest => (
                        <Col span={8} key={quest.id}>
                            <Card
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                                bodyStyle={{
                                    height: '100%'
                                }}
                                title={quest.quest}
                                actions={[
                                    <EditOutlined
                                        key={'edit'}
                                        onClick={() => handleOpenEdit(quest)}
                                    />,
                                    <DeleteOutlined
                                        key={'remove'}
                                        onClick={() => handleRemove(quest)}
                                    />
                                ]}
                            >
                                {quest.answer}
                            </Card>
                        </Col>
                    ))
                ) : (
                    <NotFoundStep style={{ margin: '20px 0' }}>
                        {'Частые вопросы и условия не добавлены'}
                    </NotFoundStep>
                )}
            </Row>
            <NextStepButton
                disabled={!hasStepChanges}
                loading={isSaving}
                onClick={handleSaveFAQ}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <Modal
                title={`${
                    selectedQuestion !== false ? 'Изменить' : 'Добавить'
                } частые вопросы и условия`}
                open={isAddModal}
                onOk={handleAddQuestion}
                okText={selectedQuestion !== false ? 'Изменить' : 'Добавить'}
                onCancel={() => {
                    setIsAddModal(false)
                    setSelectedQuestion(false)
                }}
            >
                <Form
                    form={form}
                    style={{ marginTop: 15 }}
                    layout={'vertical'}
                    preserve={false}
                >
                    <Form.Item label={'Вопрос'} name={'quest'}>
                        <Input placeholder={'Вопрос'} />
                    </Form.Item>
                    <Form.Item label={'Ответ'} name={'answer'}>
                        <Input.TextArea
                            style={{ minHeight: 150 }}
                            placeholder={'Ответ'}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
