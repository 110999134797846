import {
    Circle,
    Map,
    Placemark,
    YMaps,
    ZoomControl
} from '@pbe/react-yandex-maps'
import { Button, Col, Modal, Row, Slider } from 'antd'

interface Props {
    coords: [number, number]
    selectedRadius: number
    setSelectedRadius: (value: number) => void
    isModalRadius: boolean
    onClose: () => void
    handleSaveRadius: () => void
}

export default function ModalRadiusMap({
    coords,
    selectedRadius,
    isModalRadius,
    onClose,
    handleSaveRadius,
    setSelectedRadius
}: Props) {
    return (
        <Modal
            title={'Радиус доставки'}
            open={isModalRadius}
            okText={'Принять'}
            onOk={handleSaveRadius}
            closable={false}
            cancelButtonProps={{
                onClick: onClose
            }}
            style={{ top: 30 }}
            destroyOnClose
        >
            <div
                style={{
                    width: '100%',
                    height: 470,
                    borderRadius: 10,
                    overflow: 'hidden'
                }}
            >
                <YMaps
                    query={{
                        apikey: process.env.YANDEX_API_KEY,
                        mode: 'release',
                        lang: 'ru_RU'
                    }}
                >
                    <Map
                        width={'100%'}
                        height={'100%'}
                        options={{
                            copyrightUaVisible: false,
                            copyrightLogoVisible: false,
                            copyrightProvidersVisible: false
                        }}
                        defaultState={{
                            center: coords,
                            zoom: 8
                        }}
                    >
                        <Placemark geometry={coords} />
                        <Circle
                            geometry={[coords, selectedRadius * 1000]}
                            options={{
                                fillColor: 'rgba(255, 224, 241, 0.4)',
                                strokeColor: '#ff57a6',
                                strokeOpacity: 0.8,
                                strokeWidth: 5
                            }}
                        />
                        <ZoomControl />
                    </Map>
                </YMaps>
            </div>
            <Slider
                value={selectedRadius}
                defaultValue={selectedRadius}
                min={10}
                max={100}
                onChange={setSelectedRadius}
            />
            <Row wrap gutter={10}>
                {new Array(19).fill('').map((_, index) => {
                    const radius = (index + 1) * 5 + 5
                    return (
                        <Col
                            span={4}
                            key={`range-${index}`}
                            style={{ marginBottom: 10 }}
                        >
                            <Button
                                danger={selectedRadius === radius}
                                onClick={() => setSelectedRadius(radius)}
                                style={{ width: '100%' }}
                            >
                                {radius}
                                {' км'}
                            </Button>
                        </Col>
                    )
                })}
            </Row>
        </Modal>
    )
}
