import type { ReactElement, ReactNode } from 'react'
import { Divider, Row, Typography } from 'antd'

const { Title } = Typography

interface Props {
    children: ReactNode
    rightElement?: ReactElement
}

export default function TitleStep({
    children,
    rightElement
}: Props): ReactElement {
    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                <Title level={5} style={{ margin: 0 }}>
                    {children}
                </Title>
                {rightElement}
            </Row>
            <Divider style={{ margin: '12px 0' }} />
        </>
    )
}
