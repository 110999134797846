import { useEffect, useState } from 'react'
import { Button, Table, Modal, Row, Col, Form, Input } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DeleteOutlined } from '@ant-design/icons'
import NextStepButton from '../../../../components/NextStepButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
    faWhatsapp,
    faTelegram,
    faViber
} from '@fortawesome/free-brands-svg-icons'
import TitleStep from '../../../../components/TitleStep'
import MaskedInput from 'antd-mask-input'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import styles from './styles.module.scss'

interface DataType {
    icon: IconDefinition
    value: string
    color: string
    type: string
    onRemove: (indexRemove: number) => void
}

const types = [
    {
        type: 'phone',
        icon: faPhone,
        color: '#F15373',
        name: 'Номер телефона',
        isPhone: true
    },
    {
        type: 'email',
        icon: faEnvelope,
        color: '#FF9800',
        name: 'Почта',
        isPhone: false
    },
    {
        type: 'whatsapp',
        icon: faWhatsapp,
        color: '#64A850',
        name: 'WhatsApp',
        isPhone: true
    },
    {
        type: 'telegram',
        icon: faTelegram,
        color: '#419FD9',
        name: '@',
        placeholder: 'Укажите username',
        isPhone: false
    },
    {
        type: 'viber',
        icon: faViber,
        color: '#8D6AEC',
        name: 'Viber',
        isPhone: true
    }
]

export default function CommunicationStep() {
    const [communications, setCommunications] = useState<DataType[]>([])
    const [isModal, setIsModal] = useState(false)
    const [selectedType, setSelectedType] = useState(0)
    const [content, setContent] = useState('')
    const [editIndex, setEditIndex] = useState<false | number>(false)

    const currentType = types[selectedType]

    const { currentCompany, hasStepChanges, isSaving } = useAppSelector(
        companyWizardSelector
    )

    const dispatch = useDispatch()

    const regExp = currentType.isPhone
        ? /\+7\d{10}/
        : currentType.icon === faEnvelope
          ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/
          : /.*\B(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/

    const columns: ColumnsType<DataType> = [
        {
            title: 'Тип',
            dataIndex: '',
            key: 'icon',
            align: 'center',
            render: data => (
                <FontAwesomeIcon icon={data.icon} color={data.color} />
            )
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            width: '100%',
            render: (value, data, index) => (
                <span
                    onClick={() => {
                        setContent(data.value)
                        setSelectedType(
                            types.findIndex(({ type }) => type === data.type)
                        )
                        setEditIndex(index)
                    }}
                    className={styles.network}
                >
                    {value}
                </span>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: 'remove',
            render: (data, _, index) => (
                <Button danger onClick={() => data.onRemove(index)}>
                    <DeleteOutlined />
                </Button>
            )
        }
    ]

    const handleRemove = (indexRemove: number) => {
        setCommunications(prev =>
            prev.filter((_, index) => index !== indexRemove)
        )
    }

    useEffect(() => {
        if (currentCompany) {
            const filteredCompanyContacts =
                currentCompany.companyContacts.filter(item =>
                    types.some(type => type.type === item.type)
                )

            const hasChanges =
                communications.length !== filteredCompanyContacts.length ||
                !communications.every(item =>
                    filteredCompanyContacts.some(
                        companyContact =>
                            companyContact.type === item.type &&
                            companyContact.content === item.value
                    )
                )

            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentCompany, communications])

    const handleUndoChanges = () => {
        if (currentCompany) {
            const result: DataType[] = []

            for (const item of currentCompany.companyContacts) {
                const currentItem = types.find(type => type.type === item.type)

                if (currentItem) {
                    result.push({
                        icon: currentItem.icon,
                        value: item.content,
                        color: currentItem.color,
                        type: item.type,
                        onRemove: handleRemove
                    })
                }
            }

            setCommunications(result)
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleCloseModal = (): void => {
        setSelectedType(0)
        setContent('')
        setIsModal(false)
        setEditIndex(false)
    }

    const handleSaveCommunication = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveCompany({
                    company_id: currentCompany.company_id,
                    form: {
                        communication_contacts: communications.map(item => ({
                            type: item.type,
                            content: item.value
                        }))
                    },
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    return (
        <>
            <TitleStep
                rightElement={
                    <Button onClick={() => setIsModal(true)}>
                        {'Добавить контакт'}
                    </Button>
                }
            >
                {'Средства связи'}
            </TitleStep>
            <Table
                columns={columns}
                dataSource={communications}
                rowKey={record => record.value}
                pagination={false}
                locale={{ emptyText: 'Контакты не добавлены' }}
            />
            <NextStepButton
                loading={isSaving}
                disabled={!hasStepChanges}
                onClick={handleSaveCommunication}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <Modal
                title={
                    typeof editIndex === 'number'
                        ? 'Изменить контакт'
                        : 'Добавить контакт'
                }
                open={typeof editIndex === 'number' || isModal}
                onCancel={handleCloseModal}
                onOk={() => {
                    if (typeof editIndex === 'number') {
                        setCommunications(prev =>
                            prev.map((item, index) => {
                                if (index === editIndex) {
                                    return {
                                        icon: currentType.icon,
                                        value: content,
                                        color: currentType.color,
                                        type: currentType.type,
                                        onRemove: handleRemove
                                    }
                                }
                                return item
                            })
                        )
                    } else {
                        setCommunications(prev => [
                            ...prev,
                            {
                                icon: currentType.icon,
                                value: content,
                                color: currentType.color,
                                type: currentType.type,
                                onRemove: handleRemove
                            }
                        ])
                    }
                    handleCloseModal()
                }}
                okButtonProps={{
                    disabled: !regExp.test(content)
                }}
                okText={typeof editIndex === 'number' ? 'Изменить' : 'Добавить'}
            >
                <Row wrap justify={'center'} style={{ margin: '30px 0' }}>
                    {types.map((item, index) => (
                        <Col key={`type-${index}`} span={3}>
                            <Button
                                danger={selectedType === index}
                                style={
                                    selectedType === index
                                        ? { backgroundColor: '#FFF' }
                                        : {}
                                }
                                onClick={() => {
                                    setSelectedType(index)
                                    setContent('')
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    color={item.color}
                                />
                            </Button>
                        </Col>
                    ))}
                </Row>
                <Form.Item label={currentType.name}>
                    {currentType.isPhone ? (
                        <MaskedInput
                            value={content}
                            mask={'+7 (000) 000-00-00'}
                            style={{ background: '#FFF' }}
                            onChange={({ target }) =>
                                setContent(
                                    target.value.replace(/[^0-9.+]/g, '')
                                )
                            }
                        />
                    ) : (
                        <Input
                            value={content}
                            placeholder={
                                currentType.placeholder ?? currentType.name
                            }
                            style={{ background: '#FFF' }}
                            onChange={({ target }) => setContent(target.value)}
                        />
                    )}
                </Form.Item>
            </Modal>
        </>
    )
}
