import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    LogInFailurePayload,
    LogInPayload,
    LogInSuccessPayload,
    FetchProfileFailurePayload,
    FetchProfilePayload,
    FetchProfileSuccessPayload,
    LogOutFailurePayload,
    LogOutPayload,
    LogOutSuccessPayload,
    RegisterPayload,
    RegisterFailurePayload,
    RegisterSuccessPayload,
    SendForgotFailurePayload,
    SendForgotPayload,
    SendForgotSuccessPayload,
    SendNewPasswordPayload,
    SendNewPasswordSuccessPayload,
    SendNewPasswordFailurePayload,
    GetPaymentSumPayload,
    GetPaymentSumSuccessPayload,
    GetPaymentSumFailurePayload
} from './types'

const initialState: InitialStateType = {
    tokenData: null,
    token: null,
    profile: null,
    amount: 0,
    isProcessLogIn: false,
    isProcessRegister: false,
    isProcessForgot: false,
    isProcessNewPassword: false,
    isForgotSend: false,
    isNewPasswordSend: false,
    isProcessLogOut: false,
    isProcessProfile: false,
    isProcessPaymentSum: false,
    error: null
}

const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        logIn(state: InitialStateType, _action: PayloadAction<LogInPayload>) {
            state.isProcessLogIn = true
            state.error = null
        },
        logInSuccess(
            state: InitialStateType,
            action: PayloadAction<LogInSuccessPayload>
        ) {
            state.tokenData = action.payload.data
            state.token = action.payload.data.jwt
            state.isProcessLogIn = false
        },
        logInFailure(
            state: InitialStateType,
            action: PayloadAction<LogInFailurePayload>
        ) {
            state.isProcessLogIn = false
            state.error = action.payload.error
        },
        logOut(state: InitialStateType, _action: PayloadAction<LogOutPayload>) {
            state.isProcessLogOut = true
            state.error = null
        },
        logOutSuccess(
            _state: InitialStateType,
            _action: PayloadAction<LogOutSuccessPayload>
        ) {
            return initialState
        },
        logOutFailure(
            state: InitialStateType,
            action: PayloadAction<LogOutFailurePayload>
        ) {
            state.isProcessLogOut = false
            state.error = action.payload.error
        },
        fetchProfile(
            state: InitialStateType,
            _action: PayloadAction<FetchProfilePayload>
        ) {
            state.isProcessProfile = true
        },
        fetchProfileSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchProfileSuccessPayload>
        ) {
            state.profile = action.payload.data
            state.isProcessProfile = false
        },
        fetchProfileFailure(
            state: InitialStateType,
            action: PayloadAction<FetchProfileFailurePayload>
        ) {
            state.isProcessProfile = false
            state.error = action.payload.error
        },
        register(
            state: InitialStateType,
            _action: PayloadAction<RegisterPayload>
        ) {
            state.isProcessRegister = true
            state.error = null
        },
        registerSuccess(
            state: InitialStateType,
            action: PayloadAction<RegisterSuccessPayload>
        ) {
            state.tokenData = action.payload.data
            state.token = action.payload.data.jwt
            state.isProcessRegister = false
        },
        registerFailure(
            state: InitialStateType,
            action: PayloadAction<RegisterFailurePayload>
        ) {
            state.isProcessRegister = false
            state.error = action.payload.error
        },
        sendForgot(
            state: InitialStateType,
            _action: PayloadAction<SendForgotPayload>
        ) {
            state.isProcessForgot = true
            state.error = null
        },
        sendForgotSuccess(
            state: InitialStateType,
            _action: PayloadAction<SendForgotSuccessPayload>
        ) {
            state.isProcessForgot = false
            state.isForgotSend = true
        },
        sendForgotFailure(
            state: InitialStateType,
            action: PayloadAction<SendForgotFailurePayload>
        ) {
            state.isProcessForgot = false
            state.error = action.payload.error
        },
        resetSendForgot(
            state: InitialStateType,
            _action: PayloadAction<undefined>
        ) {
            state.isForgotSend = false
        },
        sendNewPassword(
            state: InitialStateType,
            _action: PayloadAction<SendNewPasswordPayload>
        ) {
            state.isProcessNewPassword = true
            state.error = null
        },
        sendNewPasswordSuccess(
            state: InitialStateType,
            _action: PayloadAction<SendNewPasswordSuccessPayload>
        ) {
            state.isProcessNewPassword = false
            state.isNewPasswordSend = true
        },
        sendNewPasswordFailure(
            state: InitialStateType,
            action: PayloadAction<SendNewPasswordFailurePayload>
        ) {
            state.isProcessNewPassword = false
            state.error = action.payload.error
        },
        getPaymentSum(
            state: InitialStateType,
            _action: PayloadAction<GetPaymentSumPayload>
        ) {
            state.isProcessPaymentSum = true
            state.error = null
        },
        getPaymentSumSuccess(
            state: InitialStateType,
            action: PayloadAction<GetPaymentSumSuccessPayload>
        ) {
            state.amount = action.payload.amount
            state.isProcessPaymentSum = false
        },
        getPaymentSumFailure(
            state: InitialStateType,
            action: PayloadAction<GetPaymentSumFailurePayload>
        ) {
            state.isProcessPaymentSum = false
            state.error = action.payload.error
        },
        resetAllProcess(
            state: InitialStateType,
            _action: PayloadAction<undefined>
        ) {
            state.isProcessLogIn = false
            state.isProcessRegister = false
            state.isProcessForgot = false
            state.isProcessNewPassword = false
            state.isForgotSend = false
            state.isNewPasswordSend = false
            state.isProcessLogOut = false
            state.isProcessProfile = false
            state.isProcessPaymentSum = false
        }
    }
})

export const actions = authorizationSlice.actions

export default authorizationSlice.reducer
