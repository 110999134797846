import { useEffect, useState } from 'react'
import type { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import dayjs from 'dayjs'
import { SearchOutlined } from '@ant-design/icons'
import FormItemWithLabel from '../FormItemWithLabel'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { authorizationSelector } from '../../containers/Authorization/selectors.ts'
import { getRole } from '../../utils/helpers.ts'
import FranchiseeSelector from '../../containers/FranchiseeSelector'
import UserSelector from '../../containers/UserSelector'
import ruRU from 'antd/es/date-picker/locale/ru_RU'

const { RangePicker } = DatePicker

export default function AdvertisingFilter(): ReactElement {
    const [dateUpdate, setDateUpdate] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    const [form] = Form.useForm()

    const { profile } = useAppSelector(authorizationSelector)

    const isAdminOrFranchisee = profile
        ? getRole(profile.roles) === 'admin' ||
          getRole(profile.roles) === 'franchisee'
        : false

    useEffect(() => {
        const result: Record<string, unknown> = {}
        searchParams.forEach((value, key) => {
            result[key] = value
        })
        form.setFieldsValue(result)
        setDateUpdate(Date.now().toString())
    }, [])

    const handleFinishForm = (values: Record<string, unknown>): void => {
        const result: Record<string, unknown> = {}

        for (const param in values) {
            if (
                (param === 'created' || param === 'updated') &&
                Array.isArray(values[param])
            ) {
                const [from, to] = values[param] as dayjs.ConfigType[]
                const formatFrom = dayjs(from).format('YYYYMMDD')
                const formatTo = dayjs(to).format('YYYYMMDD')

                result[param] = `${formatFrom},${formatTo}`
                continue
            }

            const isEmptyString =
                typeof values[param] === 'string' &&
                (values[param] as string).length === 0

            if (values[param] != null && !isEmptyString) {
                result[param] = values[param]
            }
        }

        setSearchParams(result as Record<string, string>)
    }

    const handleChangeFormItem = (
        key: string,
        value: string | string[] | dayjs.ConfigType[]
    ): void => {
        form.setFieldValue(key, value)
    }

    return (
        <Form
            key={dateUpdate}
            form={form}
            layout={'vertical'}
            onFinish={handleFinishForm}
            autoComplete={'off'}
        >
            <Row gutter={10} wrap={true}>
                {isAdminOrFranchisee ? (
                    <>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'franchisee_id'}
                                label={'Франчайзи'}
                            >
                                <FranchiseeSelector
                                    initialValue={form.getFieldValue(
                                        'franchisee_id'
                                    )}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'franchisee_id',
                                            value
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'user_id'}
                                label={'Пользователь'}
                            >
                                <UserSelector
                                    initialValue={form.getFieldValue('user_id')}
                                    onChange={value =>
                                        handleChangeFormItem('user_id', value)
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'created'}
                                label={'Период создания'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    allowClear={true}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'created',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                        <Col span={6}>
                            <FormItemWithLabel
                                name={'updated'}
                                label={'Период последнего изменения'}
                            >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    format={'DD.MM.YYYY'}
                                    locale={ruRU}
                                    allowClear={true}
                                    onChange={value =>
                                        handleChangeFormItem(
                                            'updated',
                                            value as dayjs.ConfigType[]
                                        )
                                    }
                                />
                            </FormItemWithLabel>
                        </Col>
                    </>
                ) : null}
                <Col span={6}>
                    <FormItemWithLabel
                        name={'status'}
                        label={'Статус рекламной кампании'}
                    >
                        <Select
                            placeholder={'Статус рекламной кампании'}
                            allowClear={true}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: '3',
                                    label: 'Идут показы'
                                },
                                {
                                    value: '2',
                                    label: 'На модерации'
                                },
                                {
                                    value: '1',
                                    label: 'Черновик'
                                },
                                {
                                    value: '4',
                                    label: 'Остановлена'
                                }
                            ]}
                        />
                    </FormItemWithLabel>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Form.Item>
                    <Button
                        type={'primary'}
                        icon={<SearchOutlined />}
                        size={'middle'}
                        htmlType={'submit'}
                    >
                        {'Искать рекламные кампании'}
                    </Button>
                </Form.Item>
                <Button
                    style={{ marginLeft: 10 }}
                    size={'middle'}
                    onClick={() => {
                        setSearchParams({})
                        form.resetFields()
                    }}
                >
                    {'Сбросить'}
                </Button>
            </Row>
        </Form>
    )
}
