import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { actions } from './slice.ts'
import * as api from './api.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'
import type {
    FetchPayload,
    FetchPaymentUrlPayload,
    IPaymentForm
} from './types.ts'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { data } = yield call(
            api.fetch,
            action.payload.page,
            action.payload.pageSize
        )
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchHandleFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleFetchPaymentUrl(action: {
    payload: FetchPaymentUrlPayload
}): SagaIterator {
    try {
        const { data }: { data: IPaymentForm } = yield call(
            api.fetchPaymentUrl,
            action.payload.amount
        )
        if (data.payments[0].payment_url) {
            window.location.href = data.payments[0].payment_url
        }
        yield put(actions.fetchPaymentUrlSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchPaymentUrlFailure({
                error: message
            })
        )
    }
}

export function* watchHandleFetchPaymentUrl(): SagaIterator {
    yield takeEvery(actions.fetchPaymentUrl, handleFetchPaymentUrl)
}

export default function* watchBalance(): SagaIterator {
    yield all([fork(watchHandleFetch), fork(watchHandleFetchPaymentUrl)])
}
