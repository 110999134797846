import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { Row, Select } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { postCategorySelector } from './selectors.ts'

interface Props {
    initialValue?: string
    onChange: (value: string | null) => void
    placeholder?: string
    multiple?: boolean
    allowClear?: boolean
}

export default function PostCategorySelector({
    initialValue,
    onChange,
    placeholder,
    multiple,
    allowClear
}: Props): ReactElement {
    const [value, setValue] = useState<string | null>('')
    const { data, isFetching } = useAppSelector(postCategorySelector)

    const dispatch = useDispatch()

    useEffect(() => {
        setValue(initialValue ?? null)
    }, [initialValue])

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <Select
            mode={multiple ? 'multiple' : undefined}
            value={value}
            onChange={value => {
                onChange(value)
                setValue(value)
            }}
            style={{ width: '100%' }}
            placeholder={placeholder ?? 'Категория'}
            optionLabelProp={'label'}
            allowClear={!!allowClear}
            loading={isFetching}
            options={data.map(category => ({
                label: category.name,
                value: category.post_category_id,
                img: category.picture?.url
            }))}
            optionRender={option => (
                <Row align={'middle'}>
                    {option.data.img ? (
                        <img
                            width={12}
                            height={12}
                            style={{ marginRight: 10 }}
                            src={option.data.img}
                        />
                    ) : null}
                    {option.label}
                </Row>
            )}
        />
    )
}
