import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api'
import type { IReportPromoCode } from './types'

export const fetch = (
    page: number,
    page_size: number,
    filter: {
        from: string
        to: string
        promocode?: string
        createdId?: string
    }
): Promise<AxiosResponse<IReportPromoCode[]>> => {
    const split = (value: string): string => {
        const splitValue = value.split('')
        for (const index of [4, 7]) {
            splitValue.splice(index, 0, '-')
        }
        return splitValue.join('')
    }

    return get<IReportPromoCode[]>('/admin/order-products', {
        page,
        page_size,
        'filter[from]': split(filter.from),
        'filter[to]': split(filter.to),
        'filter[promocode]': filter.promocode,
        'filter[creator_id]': filter.createdId,
        'filter[has_promocode]': 1,
        include: 'order.promocode,order.promocode.creator'
    })
}

export const fetchPromoCode = (promoCodeId: string) =>
    get(`/admin/promocodes/${promoCodeId}`, {
        include: 'businessAccount,creator'
    })
