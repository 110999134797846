import { Button, Col, Radio, Row, Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companyWizardSelector } from '../../selectors'
import NextStepButton from '../../../../components/NextStepButton'
import type { ICompany } from '../../types'
import AreaSelector from '../../../AreaSelector'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import ModalRadiusMap from '../../../../components/ModalRadiusMap'

const { Paragraph } = Typography

interface IDeliveryItem {
    company_id: string
    text_address: string
    name: string
    type?: 'radius' | 'areas'
    radius: number
    coords: [number, number]
    areas: { area_id: string; title: string }[]
}

export default function DeliveryStep() {
    const [data, setData] = useState<IDeliveryItem[]>([])
    const [lastData, setLastData] = useState<IDeliveryItem[]>([])
    const [indexEditData, setIndexEditData] = useState(0)
    const [isModalRadius, setIsModalRadius] = useState(false)
    const [isModalAreas, setIsModalAreas] = useState(false)
    const [selectedRadius, setSelectedRadius] = useState(10)
    const [selectedCoords, setSelectedCoords] = useState<[number, number]>([
        55.755864, 37.617698
    ])

    const { currentCompany, hasStepChanges, isSavingDelivery } = useAppSelector(
        companyWizardSelector
    )

    const isDisabled = data.some(
        item =>
            item.type === undefined ||
            (item.type === 'areas' && item.areas.length === 0)
    )

    const dispatch = useDispatch()

    useEffect(() => {
        try {
            dispatch(
                actions.setHasStepChanges(
                    JSON.stringify(data) !== JSON.stringify(lastData)
                )
            )
        } catch {
            //
        }
    }, [data, lastData])

    const handleUndoChanges = () => {
        if (currentCompany) {
            const result: IDeliveryItem[] = []

            const addDelivery = (company: ICompany) => {
                result.push({
                    company_id: company.company_id,
                    name: company.name,
                    text_address:
                        company.text_address ??
                        company.additional_text_address ??
                        company.name,
                    type: company.onHouseParameter?.type,
                    radius: company.onHouseParameter?.radius
                        ? company.onHouseParameter?.radius || 10
                        : 10,
                    coords:
                        company?.lat && company?.lng
                            ? [parseFloat(company.lat), parseFloat(company.lng)]
                            : [55.755864, 37.617698],
                    areas:
                        company.onHouseParameter?.areas.map(item => ({
                            area_id: item.area_id,
                            title: item.name
                        })) ?? []
                })
            }

            addDelivery(currentCompany)

            if (Array.isArray(currentCompany.children)) {
                for (const children of currentCompany.children) {
                    addDelivery(children)
                }
            }

            setData(result)
            setLastData(result)
            dispatch(actions.setHasStepChanges(false))
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [currentCompany])

    const handleSave = (isTrusted: boolean) => {
        if (currentCompany) {
            dispatch(
                actions.saveDelivery({
                    general_company_id: currentCompany.company_id,
                    data: data.map(item => ({
                        company_id: item.company_id,
                        on_house_type: item.type!,
                        on_house_radius: item.radius,
                        on_house_areas: item.areas.map(area => area.area_id)
                    })),
                    isBackSettings: !isTrusted
                })
            )
        }
    }

    const changeDataItem = (
        index: number,
        key: keyof IDeliveryItem,
        value: unknown
    ) => {
        setData(prev =>
            prev.map((prevItem, prevIndex) => {
                if (index === prevIndex) {
                    return {
                        ...prevItem,
                        [key]: value
                    }
                }
                return prevItem
            })
        )
    }

    const handleSaveRadius = () => {
        changeDataItem(indexEditData, 'radius', selectedRadius)
        setIsModalRadius(false)
    }

    const handleSaveAreas = (areas: { area_id: string; title: string }[]) => {
        changeDataItem(indexEditData, 'areas', areas)
        setIsModalAreas(false)
    }

    return (
        <>
            <Paragraph style={{ fontSize: 12 }} type={'secondary'}>
                {
                    'Укажите ваши условия дальности выезда или доставки от выбранного адреса.'
                }
            </Paragraph>
            <Row wrap={true} gutter={10}>
                {data.map((item, index) => (
                    <Col span={8} key={item.company_id}>
                        <Space
                            style={{ marginBottom: 20 }}
                            direction={'vertical'}
                        >
                            <Paragraph style={{ marginBottom: 0 }}>
                                {item.text_address ? (
                                    <>
                                        <span style={{ fontSize: 14 }}>
                                            {'Адрес компании:'}{' '}
                                        </span>
                                        <b>{item.text_address}</b>
                                    </>
                                ) : (
                                    item.name
                                )}
                            </Paragraph>
                            <Radio.Group
                                onChange={({ target }) =>
                                    changeDataItem(index, 'type', target.value)
                                }
                                value={item.type}
                            >
                                <Space direction={'vertical'}>
                                    <Radio value={'radius'}>
                                        {'Радиус выезда/доставки от адреса'}
                                        <Paragraph
                                            type={'secondary'}
                                            style={{ margin: 0 }}
                                        >
                                            {item.radius} {'км'}
                                        </Paragraph>
                                    </Radio>
                                    <Radio value={'areas'}>
                                        {'Регион выезда доставки'}
                                        <Paragraph
                                            type={'secondary'}
                                            style={{ margin: 0 }}
                                        >
                                            {item.areas.length > 0
                                                ? item.areas
                                                      .map(area => area.title)
                                                      .join(', ')
                                                : 'Не добавлен'}
                                        </Paragraph>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                            {item.type !== undefined ? (
                                <Button
                                    onClick={() => {
                                        setIndexEditData(index)
                                        if (item.type === 'radius') {
                                            setSelectedRadius(item.radius)
                                            setSelectedCoords(item.coords)
                                            setIsModalRadius(true)
                                        } else {
                                            setIsModalAreas(true)
                                        }
                                    }}
                                >
                                    {item.type === 'radius'
                                        ? 'Изменить радиус'
                                        : 'Изменить регионы'}
                                </Button>
                            ) : null}
                        </Space>
                    </Col>
                ))}
            </Row>
            <NextStepButton
                disabled={isDisabled || !hasStepChanges}
                loading={isSavingDelivery}
                onClick={handleSave}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {'Сохранить'}
            </NextStepButton>
            <ModalRadiusMap
                coords={selectedCoords}
                selectedRadius={selectedRadius}
                setSelectedRadius={setSelectedRadius}
                isModalRadius={isModalRadius}
                onClose={() => setIsModalRadius(false)}
                handleSaveRadius={handleSaveRadius}
            />
            <AreaSelector
                showModal={isModalAreas}
                onClose={() => setIsModalAreas(false)}
                onAdd={handleSaveAreas}
            />
        </>
    )
}
