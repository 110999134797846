import type {
    DownloadExcelPayload,
    EditUserPayload,
    FetchPayload
} from './types'
import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'

export function* handleFetch(action: { payload: FetchPayload }): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        const { data } = yield call(api.fetch, page, pageSize, filter)
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleDownloadExcel(action: {
    payload: DownloadExcelPayload
}): SagaIterator {
    try {
        const { page, pageSize, filter } = action.payload
        yield call(api.downloadExcel, page, pageSize, filter)
        yield put(actions.downloadExcelSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.downloadExcelFailure({
                error: message
            })
        )
    }
}

export function* watchDownloadExcel(): SagaIterator {
    yield takeEvery(actions.downloadExcel, handleDownloadExcel)
}

export function* handleEditUser(action: {
    payload: EditUserPayload
}): SagaIterator {
    try {
        const { userId, form, fetchPage } = action.payload
        yield call(api.editUser, userId, form)
        yield put(actions.editUserSuccess())
        yield put(actions.fetch(fetchPage))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editUserFailure({
                error: message
            })
        )
    }
}

export function* watchHandleEditUser(): SagaIterator {
    yield takeEvery(actions.editUser, handleEditUser)
}

export default function* watchUsers(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchDownloadExcel),
        fork(watchHandleEditUser)
    ])
}
